@import "../general/mixin";

.brkFix {
    clear: both;

    .accordion-header {
        .accordion-button {
            padding: 8px;
            font-size: 14px;
            position: relative;
            color: #000000;
            background: #f5f5f5 !important;
            border-radius: 1px;
            outline-offset: 0;
            border: none;
            box-shadow: none;

            &:not(.collapsed) {
                color: var(--primaryColor-darkest);
            }

            &.collapsed::after {
                color: #828282;
                content: "\f067";
                @include FontAwesomeFamily;
                font-size: 11px;
                font-weight: 300;
                position: absolute;
                right: 4px;
                top: calc(50% - 16px);
                background-image: none;
                transition: none;
            }

            &:not(.collapsed)::after {
                color: #828282;
                content: "\f068";
                @include FontAwesomeFamily;
                font-size: 11px;
                font-weight: 300;
                position: absolute;
                right: 12px;
                top: calc(50% - 23px);
                background-image: none;
                transition: none;
            }
        }
    }

    .accordion-body {
        .accordion-header {
            .accordion-button {
                &.collapsed::after {
                    top: calc(50% - 5px);
                }

                &:not(.collapsed)::after {
                    top: calc(50% - 12px);
                }
            }
        }
    }

    .panel-title {
        color: #000000;
        font-size: 15px;
        font-weight: normal;
    }

    .panel-group {
        .panel-heading + .panel-collapse > .list-group,
        .panel-heading + .panel-collapse > .panel-body {
            border-top: none;
        }
    }

    &.shortHeading {
        .panel-title {
            font-size: 13px;
        }

        .panel-heading a {
            padding: 3px 5px;
        }

        .panel-group .panel + .panel {
            margin-top: 5px;
        }

        .withOutBor .panel-body {
            padding: 0 5px 10px 8px;
        }

        .panel-heading a:after {
            font-size: 11px;
            line-height: 21px;
        }
    }

    .panel,
    .accordion {
        background-color: inherit;
        border: none;
        box-shadow: none;
        border-radius: 2px;
    }

    .panel-heading a {
        font-weight: 400;
        padding: 8px 12px;
        display: block;
        position: relative;

        &:after {
            color: #565a6d;
            content: "\f068";
            @include FontAwesomeFamily;
            font-size: 12px;
            font-weight: 400;
            float: right;
            position: absolute;
            right: 10px;
            top: 8px;
        }

        &.collapsed:after {
            content: "\f067";
        }
    }

    &.brkFixOrder {
        .accordion-item + .accordion-item,
        .accordion + .accordion {
            margin-top: 10px;
        }

        .accordion-item .accordion-body {
            margin-bottom: 0;
        }

        .panel-heading a {
            padding: 4px 22px 4px 8px;
            font-size: 14px;
            position: relative;

            &:focus {
                font-weight: 600;
            }

            &:after {
                color: #828282;
                content: "\f068";
                @include FontAwesomeFamily;
                font-size: 11px;
                font-weight: 300;
                float: right;
                position: absolute;
                right: 10px;
                top: 5px;
            }

            &.collapsed:after {
                content: "\f067";
            }
        }
    }

    label {
        margin-bottom: 0 !important;
        line-height: 15px;

        &.radio-inline {
            height: inherit;
            line-height: 19px;
        }
    }

    .panel-body label {
        margin-bottom: 5px;
        line-height: 17px;
        height: initial;
    }

    .checkbox {
        input[type="checkbox"] {
            margin-top: 3px;
        }

        &.inline {
            height: 20px;

            input[type="checkbox"] {
                display: block;
            }
        }
    }

    &.withOutBor {
        .panel {
            border: none;
        }

        .panel-body {
            padding: 5px 5px 10px 14px;
        }
    }

    .panel-body .panel-group {
        margin-bottom: 10px;
    }
}

.rBoxGap {
    .brkFix {
        &.brkFixOrder {
            .accordion-header {
                .accordion-button {
                    &.collapsed::after {
                        top: calc(50% - 5px);
                    }

                    &:not(.collapsed)::after {
                        top: calc(50% - 12px);
                    }
                }
            }
        }
    }
}

.wibAccordion {
    .accordion-collapse {
        .accordion-body {
            .list-group-item {
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0px;
                }

                .brkFullAccordion {
                    .accordion-item {
                        .accordion-body {
                            padding: 1rem;

                            .table-responsive {
                                padding-top: 0px !important;

                                .tableView {
                                    margin: 0px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.brkFullAccordion {
    .accordion {
      border: 1px solid #ddd;
    }
  
    .accordion-header {
      .accordion-button {
        &.collapsed::after {
          top: calc(50% - 5px);
        }
  
        &:not(.collapsed)::after {
          top: calc(50% - 12px);
        }
  
        &:focus {
          outline: none !important;
        }
      }
    }
  }
  