@import "../general/mixin";

.userInfoPopup {
    z-index: 9999;

    &Parent {
        padding: 0;

        .popover {
            max-width: initial;
            width: 100%;
            right: initial !important;
            top: 57px !important;

            .userInfoPopupParent {
                .popover {
                    max-width: initial;
                    width: 100%;
                    right: 200px !important;
                    top: 0 !important;
                    left: initial !important;
                }
            }

            .arrow {
                @include displayNone;
            }

            .rPageHeading {
                @include displayNone;
            }

            .rBoxGap {
                padding: 0;
                min-height: initial;
            }

            .popover-content {
                padding: 15px;
            }

            .rwTable:last-child {
                border-bottom: none;
            }
        }

        .userBtn {
            border: none;
            background-color: transparent;
            padding: 0;
            text-align: left;
        }

        .areaPopup {
            >div>div>div {
                padding: 0 15px 15px 15px;

                span.float-r.f-size-17 {
                    position: relative;
                    top: -12px;
                    z-index: 1;
                }

                .actCommts,
                .actCommts .actlogCrd {
                    padding: 0;
                    border-bottom: none;
                }
            }

            &.areaTaskSpace {

                .tskLog,
                .tskListDetail {
                    padding-right: 10px;
                }
            }
        }
    }

    .cardLeftdv {
        padding: 15px 15px 0 15px;

        display: table;
        width: 100%;

        .card-left {
            display: table-cell;
            vertical-align: top;
            width: 100px;
        }

        .card-body {
            display: table-cell;
            vertical-align: top;
            width: 80%;

            .card-header {
                font-size: 18px;
                color: var(--primaryColor) !important;
                position: relative;
                margin-bottom: 5px;
                word-break: break-word;
                background-color: transparent;
                border-bottom: none;
                padding: 0;
                // padding-right: 20px;
            }
        }
    }

    &.bs-popover-start, &.bs-popover-end {
        bottom: initial !important;
        top: -40px !important;

        .popover-arrow {
            top: 40px !important;
        }
    }
}

.bs-popover-start, .bs-popover-end {
    min-width: 400px;
}

.approvalPopup {
    max-width: 391px !important;
    left: 930.08px;

    &Btn {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        @include displayInlineBlock;
    }

    .popover-body {
        max-height: 250px;
        overflow: auto;
    }
}

.posCustom {
    font-size: 12px;
    left: initial;
    position: absolute;
    z-index: 9999 !important;
    overflow-y: hidden;
}

.govrncInfoPopup {
    .areaPopup {
        margin: 10px auto;
    }
}

.popup-info-sla {
    padding: 15px;

    h2 {
        //background-color: #eeeeee;
        padding: 8px 0px;
        //font-size: 18px;
        color: var(--primaryColor);
        font-weight: 400;
        margin-bottom: 10px;
    }

    .rwTable {
        padding: 8px 0px;

        &:last-child {
            border-bottom: none;
        }
    }
}

.hambTab {
    label {
        height: 20px;
    }

    .form-group {
        margin-bottom: 8px;
    }

    .form-control {
        padding: 4px 6px;
    }
}

.filterPopup {
    font-size: 13px;
    top: 34px;
    right: 0;
    padding: 10px 15px 10px 10px;

    h2 {
        font-size: 15px;
        color: var(--primaryColor);
        font-weight: 400;
        margin-bottom: 5px;
    }

    .checkbox {
        margin-bottom: 8px;

        input[type="checkbox"] {
            margin-top: 4px;
            margin-left: -20px;
        }

        label {
            margin-bottom: 0;
            margin-left: 8px;
        }
    }

    &.leftArrow {
        right: initial;
        left: 0;

        &:before,
        &:after {
            right: initial;
            left: 30px;
        }
    }

    .btn-xs {
        padding: 3px 8px !important;
    }
}

.serviceInfoPopup {
    padding: 15px 15px 0 15px;

    .infoPopupSD {
        height: 300px;
        overflow: hidden;
        overflow-y: scroll;
        margin-right: -15px;
        padding-right: 10px;

        /* Scrollbar styles */
        @include scrollbar(8px);
    }
}

.cardLeftdv > .card-left,.cardLeftdv > .card-body{display:inline-block;vertical-align: top;}

.translatePopdv{
    .modal-body{
        > .container{padding:0px;}
        .card{
            border:none;
            .card-body{
                padding:0px;
                .justify-content-end {
                    justify-content: flex-start !important;
                }
            }
        }
    }
}

.asignicnTrans{
    outline-offset:-2px !important;float:right;padding:0px !important;background-color:transparent !important;
    border:none !important;
    > span{
        padding:4px;display:inline-block;cursor: pointer;
    }
}
.transPopdv.k-animation-container{
    z-index: 990 !important;
    width:calc(100vw - 60%) !important;position: relative;
    .k-popover-header{
        font-size:18px;font-weight: 600;
        .disclaimerIcn {
            position: relative;
            margin:0px 0px 0px 12px !important;
            top: -1px;
        }
    }

    .clsbtn{
        position:absolute;top:3px;right:10px;padding: 4px;height: initial;line-height: 14px;
        > button:focus{outline:none !important;}
    }
    .k-dropdownlist{
        background-color:transparent;background-image:none;border-color: #ccc;
    }
    .transmdv{
        margin-bottom:10px;
        > label {display:none;}
        > *{
            width:auto;margin-right:10px;
        }
        .chardv{float:right;margin:10px 0px 0px 0px !important;}
        
    }
    .popfld{
        > label {display:none;}
    }
    .popbot{
        margin-top:5px;padding:2px 5px;
        button{
            padding:0px 4px;
            svg{position: relative;top:-2px;}
        }
    }
}