@import "../general/mixin";

.addBodTxt {
    font-size: 17px;
    color: var(--primaryColor);
    text-transform: uppercase;
    margin-bottom: 10px;
}

.addBgRw {
    @include colorBlack;
    background-color: #f1f1f1;
    padding: 4px 10px;
    font-weight: 600;
    margin-bottom: 5px;
}

#addOrd {
    .panel-default {
        border-color: #eaeaea;

        >.panel-heading {
            padding: 6px 10px 10px;
            border-bottom: none;
            position: relative;
        }
    }

    .panel-heading {
        a {
            &:after {
                color: #808080;
                content: "\f068";
                @include FontAwesomeFamily;
                font-size: 12px;
                font-weight: 300;
                position: absolute;
                top: 20px;
                right: 20px;
            }

            &.collapsed:after {
                content: "\f067";
            }
        }
    }

    .panel-body {
        .panel-heading {
            a:after {
                top: 8px;
                font-size: 12px !important;
            }
        }
    }

    .panel-title {
        a {
            display: block;

            &:hover,
            &:not(.collapsed) {
                color: #3a3a3a;
            }

            &:not(.collapsed) {
                .theme1 {
                    color: var(--primaryColor);
                }
            }

            &:hover {
                @include colorBlack;
            }

            &:focus {
                outline-offset: 2px;
            }
        }
    }
}

.orders {
    a {
        display: inline-block;

        &:hover,
        &:focus {
            outline-offset: 2px;
        }
    }

    h4 {
        a {
            display: inline;
        }
    }

    .card {
        border: none;
        border-bottom: 1px solid #ccc;
        overflow: hidden;
        padding-bottom: 10px;
        margin-bottom: 15px;
        display: table;
        width: 100%;

        &:last-child {
            border-bottom: none;
        }

        .card-header {
            font-size: 15px;
            color: #000000;
            position: relative;
            margin-bottom: 5px;
            word-break: break-word;
            background-color: transparent;
            border-bottom: none;
            padding: 0;
            padding-right: 20px;
        }

        .card-body,
        .card-left,
        .card-right {
            vertical-align: middle;
            position: relative;
            display: table-cell;
        }

        .card-body {
            padding: 0 0 0 10px;
            width: 10000px;
            overflow: hidden;
            zoom: 1;
        }

        .addCaRg,
        .penTdAnchr {
            width: 45px;
            height: 45px;
            border: 1px solid;
            border-color: var(--primaryColor);
            border-radius: 50%;
            padding: 0;
            margin: 0 auto;
            display: inline-block;
            text-align: center;

            img {
                height: 30px;
                width: 30px;
                min-width: initial;
                max-width: initial;
                min-height: initial;
                max-height: initial;
                position: relative;
                top: calc(50% - 14.5px);
                text-indent: -999px;
                overflow: hidden;
                border-radius: 0;
                margin: 0 auto;
                padding: 0;
                text-align: center;
            }
        }
    }
}