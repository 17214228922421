
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/

@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css");

.k-tabstrip-items .k-link {padding-block:0px;padding-inline: 5px;}
.k-tabstrip-items {justify-content: flex-end;}
.k-tabstrip-items .k-item:last-child .k-link{padding-inline-end:0px;}
.gjs-top-sidebar{margin-bottom:10px;}
.gjs-top-sidebar .px-2{padding-right: 0 !important;}
.gjs-top-sidebar .k-button-outline-base,.gjs-right-sidebar .k-button-outline-base,.rightPanelIcons button,.statusbtndv button,.theDotbtn{border: 1px #c1c1c1 solid;color:#393939;font-size: 16px;line-height: 20px;background-color: transparent;box-shadow: none;outline: none !important;height:34px;}
.gjs-top-sidebar .k-button-outline-base.k-active,.gjs-top-sidebar .k-button-outline-base:hover,.gjs-right-sidebar .k-button-outline-base:hover,.rightPanelIcons button:hover,.statusbtndv button:hover,.theDotbtn:hover,.theDotbtn:active{color: #fff !important;background-color: var(--primaryColor) !important;border: 1px solid;border-color: var(--primaryColor);outline-offset: -3px;}
.gjs-right-sidebar .k-active .k-button-outline-base{color: var(--primaryColor) !important;border-color: var(--primaryColor);background-color: #fff !important;}
.rightPanelComp .rPageHeading .closeIcon,.rightPanelComp .rPageHeading .k-button{color:#fff !important;background-color:transparent !important;margin-left:15px;}
.rightPanelComp .rPageHeading .closeIcon:hover,.rightPanelComp .rPageHeading .closeIcon:focus{background-color: #b0f3fa !important;color: #007b87 !important;}
.rightPanelComp .rPageHeading .closeIcon svg{font-size:20px;}
.theDotbtn:focus{outline:none !important;box-shadow:none;}

.rightPanelComp .rPageHeading .form-select{background-color:var(--primaryColor) !important;color: #fff !important;border: 1px rgb(255 255 255 / 40%) solid;box-shadow: none;height: 28px;margin-top: 3px;}

// .form-select:hover,.form-select:focus{color:#333 !important;background-color:transparent !important;}

.devicesDv{box-shadow:none;width:80px !important;border:1px #ccc solid;margin-left:10px;height:34px;font-size:14px;border-radius: 6px;}
.devicesDv:hover,.devicesDv:focus{background-color:var(--primaryColor);border-color:var(--primaryColor);}
.devicesDv button{box-shadow:none !important;border:none !important;display:none !important;}

.minVh{min-height:calc(100vh - 170px) !important;}
.gjs-one-bg {background-color:var(--primaryColor);color:#fff;font-size: 12px;}
.k-reset, .k-animation-container{width:auto !important;}
.gjs-right-sidebar .k-animation-container{width:100% !important;}
.popbtn{line-height:16px;}


/* .gjs-right-sidebar{width:400px;}
.gjs-right-sidebar-data-query{width:500px;} */
.gjs-right-sidebar .k-tabstrip-content{padding-block:0px;padding-inline:0px;}
/* .gjs-right-sidebar .k-tabstrip .k-tabstrip-content{padding-block:0px;padding-inline:0px;border: none;background-color: transparent;} */
.gjs-right-sidebar .k-tabstrip .k-tabstrip-items-wrapper{padding-bottom:10px;}
.gjs-custom-editor-canvas{padding:15px;background-color:#f5f5f5;height:calc(100vh - 220px) !important;
    /* box-shadow: 4px 2px 12px -7px rgb(0 0 0 / 50%); */
}

.k-tabstrip-items-wrapper .k-item,.k-tabstrip-items-wrapper .k-item:active, .k-tabstrip-items-wrapper .k-item.k-active, .k-tabstrip-items-wrapper .k-item.k-selected{border:none;box-shadow:none;}

.k-expander{margin-bottom:10px;}
.k-expander-header{background-color: #f5f5f5;padding-block: 8px;padding-inline: 8px;}
.k-expander-header .k-expander-title{color: #424242;}

.rightPanelComp .rightPanelGap{padding-block:10px;padding-inline:15px;}
.layGap{margin:0px 10px;}
.layGap .bg-sky-900 .bg-sky-500{font-weight:900;color:var(--primaryColor);}
.rightPanelComp .rPageHeading{margin-bottom: 0px;background-color: var(--primaryColor);color: #fff;font-size: 18px;padding: 6px 10px 6px 15px;}
.rightPanelComp .rPageHeading .k-button-outline-base{color: #fff;font-size: 12px;line-height: 12px;padding: 4px;}
.rightPanelComp .rPageHeading .k-button-outline-base svg{font-size:16px;}

.leftPanelCanvasDv{width:94%;float:left;}
.rightPanelIcons{margin-top:30px;float:right;}
.rightPanelIcons button{margin-top:15px;}
[class*="uiBuilder-"] .leftPanelCanvasDv .sPageHeading1,
	.formLists .rdt_TableCell .btn-toolbar {
		flex-wrap: nowrap;
		display: flex;
	}

.nav-tabs .nav-item .nav-link { color: #000}
.nav-tabs .nav-item .nav-link.active { background-color:var(--primaryColor-darker);color:#fff !important;outline: none !important;}
.nav-tabs .nav-item .nav-link.active a{color:#fff !important;outline: none !important;}
.no-resize {resize: none;}

.statusbtndv button{padding:4px 10px;border-radius: 6px;font-size:13px;}

.formfieldv{border:1px #ccc solid;}
.formfieldv .k-input-inner{border:none;padding:6px 12px;}
.tabformdv .nav-tabs .nav-item .nav-link{font-weight:600;}
.form-control::-webkit-input-placeholder{color: #ccc !important;}

.isEdicn{font-size:14px;border:none;box-shadow:none;padding:0px;line-height: 12px;border-radius: 2px;background-color:transparent;color:#333;margin-left: 5px;}
.isEdicn:hover,.isEdicn:focus{color:var(--primaryColor);background-color:transparent;}
.customswal{ z-index: 99999; }

.mytabs .nav-tabs .nav-item .nav-link{border:none;padding:0px;margin:0px 10px 10px 0px;}
.gjs-am-meta .gjs-am-name {     overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-right: 20px; }

.k-split-button.k-active{
    button{
        color: #fff !important;
        background-color: var(--primaryColor) !important;
        border: 1px solid;
        border-color: var(--primaryColor);
        outline-offset: -3px;
    }
    button:first-child{
        border-right: 2px rgb(255 255 255 / 30%) solid !important;
    }
}

.gjs-cell{position: relative;}
.gjs-cell a[title="Show Password"],.gjs-cell a[title="Hide Password"]{position:absolute !important;left:initial !important;right:5px !important;}

[class*="uiBuilder-"]{
	.show{display:block}
	.hide{display:none}
	.fullWd{width:100%;flex:initial !important;overflow: initial !important;}
}

[class*="preview-"],[class*="renderedView-"]{
    .noContainer-fluid{
        .container-fluid{
            padding:0px !important;
        }
    }
}
