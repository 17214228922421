@import "../general/mixin";

.categryListDv {
    padding: 15px 0;
    background: rgba(0, 0, 0, 0.02);

    .flex-column {
        margin: 0px 4px 20px 0px;
    }

    .categrySubMenu & {
        margin-bottom: 0;

        .flex-column {
            margin-bottom: 0px;
        }
    }

    p {
        font-size: 14px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.5);
        text-transform: uppercase;
        padding: 8px 10px 8px 8px;
    }

    .nav-item {
        .nav-link {
            font-size: 15px;
            color: rgba(0, 0, 0, 0.6);
            font-weight: 600;
            padding: 6px 5px 6px 25px;
            position: relative;

            span {
                display: block;
                width: auto;
                margin-right: 15px;
                word-break: break-word;
            }

            &::before {
                content: "";
                width: 8px;
                height: 8px;
                background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 0.65);" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-minus"><path d="M5 12h14"/></svg>');
                @include displayInlineBlock;
                background-repeat: no-repeat;
                position: absolute;
                left: 8px;
                top: 7px;
            }

            &.active,
            &:hover {
                color: var(--anchorColor);
                font-weight: 600;
                @include bgWhite;
            }

            &.subMenuIcn {
                &::after {
                    color: #777;
                    content: "\f107";
                    @include FontAwesomeFamily;
                    font-size: 14px;
                    font-weight: 400;
                    position: absolute;
                    top: 6px;
                    right: 6px;
                }

                &.subMenuIcnRotate::after {
                    transform: rotate(180deg);
                }
            }
        }
    }

    /* Scrollbar Styles */
    @include scrollbar(8px);
}

.categrySubMenu {
    margin: 2px 0px 5px 15px;

    li {
        .nav-link {
            font-size: 13px;
            padding: 4px 5px 4px 20px;
            margin: 1px 0px;

            &::before {
                content: "";
                width: 10px;
                height: 13px;
                background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="13" viewBox="0 0 512 512" style="fill: rgba(0, 0, 0, 0.65);"><path fill="none" stroke="currentColor" stroke-linecap="square" stroke-miterlimit="10" stroke-width="32" d="M400 352l64-64-64-64"/><path fill="none" stroke="currentColor" stroke-linecap="square" stroke-miterlimit="10" stroke-width="32" d="M448 288H48V160"/></svg>');
                @include displayInlineBlock;
                background-repeat: no-repeat;
                position: absolute;
                left: 8px;
                top: 8px;
            }
        }
    }

}

.categryInnerSubMenu {
    margin: 2px 0px 5px 15px;

    li {
        .nav-link {
            padding-left: 37px;

            &::before {
                left: 21px;
            }
        }
    }
}

.viewMoreServiceBtn {
    width: 100%;
    padding: 10px;
    display: block;
    text-align: center;
    color: var(--primaryColor);
    border: 1px solid #ffffff;

    &:hover {
        border: 1px solid;
        border-color: var(--primaryColor);
    }
}

/****** Moblie View ******/

.categryListDropdown {
    width: 85%;
    padding: 0 !important;
    margin-bottom: 15px;
    background: transparent !important;

    .nav-item {
        width: 100%;

        &:first-child {
            @include displayInlineBlock;
        }

        .dropdown-toggle {
            white-space: break-spaces;
            padding: 10px 20px 10px 3px;
            color: var(--primaryColor);
            font-size: 17px;
            font-weight: 600;
            position: relative;
            max-width: 100%;
            border-bottom: 1px solid;

            &::after {
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: 20px;
                display: flex;
                align-items: center;
                padding: 0 5px;
                border: none;
                content: "\f107";
                font-family: "FontAwesome", sans-serif;
            }

            &:focus {
                outline: none !important;
            }
        }

        .nav-link {
            width: 100%;

            &.dropdown-toggle {
                &::before {
                    display: none;
                }

                &::after {
                    width: 30px;
                }
            }
        }
    }

    .nav-pills {
        .nav-link {

            &.active,
            &.show>.nav-link {
                background-color: rgba(0, 0, 0, 0.05);
            }
        }
    }

    .dropdown-menu {
        padding: 10px;

        &.show {
            width: auto;
            max-height: 300px;
            overflow: hidden;
            overflow-y: auto;
            box-shadow: 0px 1px 9px -4px rgba(0, 0, 0, 0.8);
            border: 0;

            .nav-item {
                border: none;
                color: #707070;

                .nav-link {
                    span {
                        width: 200px;
                        padding-right: 15px;
                    }

                    // &:active,
                    // &.active {
                    //     background-color: var(--primaryColor);
                    //     color: #ffffff;
                    // }
                }
            }
        }
    }
}

.dropdown-item {

    &.active,
    &:active {
        background-color: var(--anchorColor) !important;
    }
}