@import "../general/mixin";

.modHamBur {
    padding: 0;
    margin: 8px 5px 0 -8px;
    float: left;

    button {
        background-color: transparent;
        border: none;
        outline: none;
        box-shadow: none;
        padding: 10px;
        margin: 0;
        border-radius: 50%;

        &:focus,
        &:hover {
            opacity: 1;
            background-color: rgba(0, 0, 0, 0.3);
            outline: none !important;
            border: none;
        }
    }

    .dotsMenu {
        color: var(--headerTxtColor);
        font-size: 22px;
    }
}

.modulePopup {
    background-color: #fdfefe;
    height: 100%;
    width: 270px;
    position: fixed;
    top: 0px;
    left: -270px;
    z-index: 9000;
    padding: 20px 0px 0px 10px;
    box-shadow: 0px 0px 9px -4px rgba(0, 0, 0, 0.8);
    -webkit-transition: right 0.3s, left 0.3s;
    -moz-transition: right 0.3s, left 0.3s;
    -ms-transition: right 0.3s, left 0.3s;
    -o-transition: right 0.3s, left 0.3s;
    transition: right 0.3s, left 0.3s;

    &.open {
        left: 0px;
    }

    .popupLogo {
        background: var(--headerBG);
        border-bottom: 1px solid;
        border-bottom-color: var(--headerBG);
        padding: 11px 11px 11px 15px;
        margin: -20px 0px 10px -10px;

        .pLogo {
            background-repeat: no-repeat;
            background-position: left center;
            background-size: contain;
            height: 35px;
            width: 133px;
        }
    }

    &:has(> .othrLnkNav a:focus) {
        left: 0px;
    }
}

.menConFlx {
    display: flex;
    height: calc(100vh - 86px);

    .transAppy {
        &.show {
            width: 310px;
        }

        &.hide {
            width: 43px;

            .iconSidebar {
                .othrLnkNav {
                    .rghArow {
                        >a {
                            &::before {
                                color: transparent;
                                content: "|";
                                position: absolute;
                                top: 1px;
                                left: 0px;
                                border-left: 4px rgba(0, 0, 0, 0.2) solid;
                                height: 39px;
                            }

                            &:hover:before {
                                border-left-color: var(--primaryColor);
                            }
                        }
                    }
                }
            }
        }
    }
}

.iconSidebar {
    background-color: transparent;
    height: 100%;
    width: 100%;
    padding: 0px;
    border-right: 1px solid #e9e9e9;

    >div {
        height: 100%;
        position: relative;
    }

    &.show {
        .othrLnkNav {

            &:hover {
                .rghArow {
                    >a::after {
                        right: 9.5px;
                    }
                }
            }
            
            .rghArow {
                >a::after {
                    right: 15px;
                }
            }
        }
    }

    .collapseBtnDv {
        .btn {
            font-size: 15px;
            position: relative;
            top: 0px;
            left: 0px;

            svg {
                font-size: 15px;
            }

            &:hover {
                background-color: var(--primaryColor) !important;
                color: #fff !important;
            }
        }
    }

    .othrLnkNav {
        .rghArow {
            position: relative;

            &.rghArowRotate>a::after {
                transform: rotate(180deg);
            }

            >a::after {
                color: var(--anchorColor);
                content: "\f107";
                @include FontAwesomeFamily;
                font-size: 14px;
                font-weight: 400;
                position: absolute;
                top: 8px;
                right: -30px;
            }
        }
    }
}

.collapseBtnDv {
    position: absolute;
    right: -10px;
    top: 6px;
    z-index: 1;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    border-radius: 50px;

    .btn {
        padding: 0px 4px;
        display: block;
        background-color: #fff !important;
        color: var(--primaryColor) !important;
        outline-offset: 0px;
        outline: none !important;
        border-radius: 50px;
        box-shadow: 0px 0px 9px -2px rgb(0 0 0 / 60%);

        &.right {
            svg {
                margin-right: 10px;
            }
        }
    }
}

.modulePopupSerch {
    display: block;
    margin: 0px 8px;
    position: relative;
    padding: 8px 0px 10px;
    transition: opacity 1s;

    &.show {
        input {
            border-color: #ccc;
            padding: 6px 6px 6px 25px;

            &:focus {
                box-shadow: none;
                background-color: #ffffff;
                border: 1px solid #cccccc;
            }
        }
    }

    &:has(.visibleTrue.bnmenu) {
        input {
            visibility: hidden;
        }
    }

    .luSrch {
        color: var(--primaryColor);
        font-size: 18px;
        position: absolute;
        top: 17px;
        left: 4px;
        z-index: 1;
    }

    input {
        border-color: #fff;
        transition: border-color 1s ease;
        color: #555 !important;

        &:disabled,
        &:read-only {
            background-color: #fff !important;
            cursor: default;
        }
    }

    button {
        color: #555 !important;
        position: absolute;
        top: 19px;
        right: 4px;
        z-index: 1;
        padding: 4px 6px;
        line-height: 14px;
        background-color: transparent !important;
        border: none;

        &:hover,
        &:focus {
            background-color: #f7f7f7 !important;
            color: var(--primaryColor) !important;
        }

        &.bnmenu {
            color: var(--primaryColor) !important;
            padding: 4px;
            background-color: transparent !important;
            border: none;
            position: relative;
            top: -4px;
            left: -5px;
            outline: none;

            &:hover,
            &:focus {
                background-color: #f7f7f7 !important;
            }
        }
    }
}


.othrLnkNav {
    clear: both;
    overflow: hidden;
    max-height: calc(100vh - 180px) !important;

    li {
        margin: 0px 0px 1px;

        >a {
            font-size: 14px;
            font-weight: 500;
            opacity: 1;
            position: relative;
            padding: 10px;
            margin-left: 0px;
            background-color: transparent;
            display: inline-flex;
            align-items: center;
            width: 100%;
            color: var(--anchorColor);

            &:hover,
            &:focus {
                background-color: rgba(#000000, 0.08);
                outline: none;
                outline-color: var(--primaryColor);

                .appIcon {
                    &:has(.customAppSetIcn) {
                        &::before {
                            background-color: #e9eaea;
                        }
                    }
                }
            }

            .appIcon {
                margin-right: 10px;
                display: inline-flex;
                font-size: 20px;

                svg {
                    &.customIcon {
                        font-size: 23px;
                        margin: 0px -2px;
                    }

                    &.customIcon1 {
                        font-size: 25px;
                        margin: 0px -3px;
                        position: relative;
                        top: 1px;
                    }

                    &.customIcon2 {
                        font-size: 18px;
                    }

                    &.customIcon3 {
                        font-size: 22px;
                        position: relative;
                        left: -1px;
                    }
                    &.customIconStroke {
                        font-size:20px;
                        stroke-width: 2.1;
                        position: relative;
                        left: 1px;
                    }
                    &.customAppSetIcn {
                        font-size: 15px;
                        stroke-width: 2.5px;
                        position: absolute;
                        bottom: 0px;
                        left: -1px;
                    }

                    &.customAppSetIcn2 {
                        polyline {
                            @include displayVisibilityNone;
                        }
                    }
                }

                &:has(.customAppSetIcn) {
                    position: relative;

                    &::before {
                        content: " ";
                        width: 13px;
                        height: 13px;
                        background-color: var(--pageBG);
                        position: absolute;
                        bottom: 0px;
                        left: 1px;
                    }
                }
            }

            &:focus-visible {
                outline-offset: -3px;
            }

            .sublnkIcon {
                a {
                    .appIcon {
                        margin-right: 0;
                    }
                }
            }

            .rghArow {
                position: relative;

                &.rghArowRotate>a::after {
                    transform: rotate(180deg);
                }

                &::after {
                    color: var(--anchorColor);
                    content: "\f107"; // FontAwesome icon
                    @include FontAwesomeFamily; // Ensure the correct FontAwesome font is included
                    font-size: 14px;
                    font-weight: 400;
                    position: absolute;
                    top: 8px;
                    right: 30px;
                }
            }

            .apptransTxt {
                font-size: 14px;
                visibility: hidden;
                opacity: 0;
                // transition: visibility .5s, opacity .5s ease-in;
                @include ovrFlowContent;

                &.show {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        &.active {
            background-color: var(--primaryColor) !important;
            color: #fff !important;

            .appIcon {
                color: #fff !important;

                &:has(.customAppSetIcn) {
                    &::before {
                        background-color: var(--primaryColor);
                    }
                }
            }

            a {
                &:hover, &:focus {
                    .appIcon {
                        &:has(.customAppSetIcn) {
                            &::before {
                                background-color: var(--primaryColor);
                            }
                        }
                    }
                }
            }

            .apptransTxt,
            a {
                color: #fff !important;
            }
        }

        li {
            margin: 0px 0px;

            a {
                padding: 4px 0px 4px 30px !important;
                height: initial;

                &::before {
                    top: 5px;
                }
            }
        }

        .sublnk {
            padding: 0;
            margin: 1px 10px 0px;

            li {
                &.active {
                    a {    
                        &:before {
                            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 512 512" style="fill: rgb(255, 255, 255);"><path fill="none" stroke="rgb(255, 255, 255)" stroke-linecap="square" stroke-miterlimit="10" stroke-width="32" d="M400 352l64-64-64-64"/><path fill="none" stroke="rgb(255, 255, 255)" stroke-linecap="square" stroke-miterlimit="10" stroke-width="32" d="M448 288H48V160"/></svg>');
                        }
                    }
                }
            }

            a {
                font-size: 13px;
                font-weight: 500;
                opacity: 1;
                display: block;
                position: relative;
                padding: 0 0 0 30px;
                margin-left: 0;
                background-color: transparent;
                color: #333;

                &:before {
                    content: " ";
                    width: 15px;
                    height: 15px;
                    fill: rgba(0, 0, 0, 0.65);
                    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 512 512" style="fill: white;"><path fill="none" stroke="currentColor" stroke-linecap="square" stroke-miterlimit="10" stroke-width="32" d="M400 352l64-64-64-64"/><path fill="none" stroke="currentColor" stroke-linecap="square" stroke-miterlimit="10" stroke-width="32" d="M448 288H48V160"/></svg>');
                    display: inline-block;
                    background-repeat: no-repeat;
                    position: absolute;
                    left: 10px;
                    top: 4px;
                }

                &:hover,
                &:focus {
                    background-color: rgba(#000000, 0.06);
                    outline: none;
                    outline-color: var(--primaryColor);

                    .appIcon {
                        &:has(.customAppSetIcn) {
                            &::before {
                                background-color: rgba(0, 0, 0, 0.06);
                            }
                        }
                    }
                }

                span {
                    margin-right: 10px;
                    background-color: transparent;
                    @include ovrFlowContent;
                }
            }

            &.sublnkHvr {
                display: inline-block;
                position: absolute;
                left: -13px !important;
                background: #ffffff;
                width: 230px;
                z-index: 999;
                border: 1px solid rgba(0, 0, 0, 0.3);
                border-radius: 4px;

                li {
                    margin: 2px 0;
                    padding-left: 8px;
                }

                a {
                    padding: 3px 0 3px 30px;

                    &:before {
                        top: 5px;
                    }
                }

                .sublnkHeader {
                    margin: 4px 0;
                    padding: 0;

                    a {
                        padding: 5px 0 5px 10px;
                        font-size: 14px;
                        display: flex;
                        pointer-events: none;
                        color: var(--anchorColor);

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .rghArow {
        a {
            &.active {
                background-color: var(--primaryColor) !important;
                color: #fff !important;

                .appIcon,
                .apptransTxt,
                &::after {
                    color: #fff !important;
                }
            }
        }
    }

    &:hover {
        overflow-y: auto;
    }

    @include scrollbar(6px);

    &+.othrLnkNav {
        border-bottom: none;
    }
}

.appMenuPopup {
    position: absolute;
    top: 58px;
    left: 0px;
    width: 244px;
    z-index: 9999;
    background-color: var(--pageBG);
    box-shadow: 2px 0px 13px -3px rgb(0 0 0 / 50%);
    height: calc(100vh - 86px);

    >div {
        height: 100%;
    }

    .apptransTxt {
        visibility: visible !important;
        opacity: 1 !important;
    }

    .othrLnkNav {
        max-height: calc(100vh - 195px) !important;
        &:hover {
            .rghArow {
                >a::after {
                    right: 9.5px;
                }
            }
        }

        .rghArow {
            >a::after {
                color: var(--anchorColor);
                content: "\f107";
                @include FontAwesomeFamily;
                font-size: 14px;
                font-weight: 400;
                position: absolute;
                top: 8px;
                right: 15px;
            }

            &.rghArowRotate>a::after {
                transform: rotate(180deg);
            }
        }

        li {
            margin: 0px 0px 1px;

            li {
                margin: 0px;

                a {
                    padding: 4px 0px 4px 30px;
                    height: initial;

                    &::before {
                        top: 5px;
                    }
                }

                &.active {
                    background-color: var(--primaryColor) !important;
                    color: #fff !important;

                    .appIcon {
                        color: #fff !important;
                    }
                }
            }

            &.active {
                background-color: var(--primaryColor) !important;
                color: #fff !important;

                .appIcon {
                    color: #fff !important;
                }

                .apptransTxt,
                a {
                    color: #fff !important;
                }
            }
        }
    }

    .collapseBtnDv {
        .btn {
            font-size: 16px;

            &:hover {
                background-color: var(--primaryColor) !important;
                color: #fff !important;
                transform: rotate(360deg);
                transition: transform 0.3s ease-in;
            }
        }
    }

    .modulePopupSerch {
        .luSrch {
            font-size: 18px;
            top: 18px;
            left: 4px;
        }
    }
}

.foucInpt {
    float: left;
    width: 1px !important;
    height: 1px !important;
    border: none;
    @include bgTransparent;
    box-shadow: none;

    &:focus,
    &:focus-visible {
        box-shadow: none;
        border: none;
        @include bgTransparent;
        outline: none !important;
    }
}