@import "../general/mixin";

.wibTabs {
    margin: 0px;
    padding: 2px 0px;
    list-style-type: none;
    @include displayInlineBlock;
    position: relative;
    z-index: 2;

    li {
        @extend .wibTabs;
        margin: 0px 0px 10px 0px;

        a {
            font-size: 18px;
            color: rgba(#000000, 0.6) !important;
            font-weight: 600;
            padding: 5px 10px;
            border-bottom: 1px solid transparent;

            &:hover,
            &:focus {
                color: var(--primaryColor);
            }
        }

        &:first-child a {
            padding: 5px 10px 5px 0px;
        }

        &.active a {
            font-size: 18px;
            font-weight: 600;
            color: var(--primaryColor-darker) !important;
            border-bottom: 1px solid;
            border-bottom-color: var(--primaryColor-darker);
        }
    }

    &.wibTabsLastLi {
        width: 100%;

        .lastli {
            float: right;
        }
    }
}

/************ Filter Tabs ************/

#assFiltr {
    font-size: 13px;

    .shw,
    .filtr {
        position: relative;
        z-index: 2;
    }

    .shw {
        margin-right: 2px;
    }

    .filtr {
        button {
            border: none;
            background-color: #f1f1f1;
            @include colorBlack;
            margin: 0px 4px 5px;
            padding: 3px 8px 2px;
            font-weight: 500;
            line-height: 18px;

            &.active,
            &.active:hover {
                background-color: rgba(#000000, 0.6);
                color: #ffffff;
            }

            &:hover {
                background-color: #ebebeb;
            }

            &:first-child {
                margin-left: 0px;
            }
        }
    }
}

.savQueDropDv {
    display: inline-block;
    margin-left: 5px;
    position: relative;
    //top: -2px;

    button {
        font-size: 13px;
    }

    .dropdown-menu {
        padding: 2px 5px;
        top: 25px;
        left: initial;
        right: 0;
        border-radius: 2px;
        min-width: initial;
        width: 204px;

        .hed {
            padding: 0 2px 5px;
            font-size: 13px;
            color: #a4a4a4;
        }
    }

    .txtvals {
        font-size: 13px;
        padding: 2px;
        margin: 0;
        border-bottom: 1px rgb(0 0 0 / 15%) solid;
        display: flex;
        width: 100%;

        &:last-child {
            border-bottom: none;
        }

        .txt {
            font-size: 12px;
            width: 160px;
            @include ovrFlowContent;
        }

        .txtInp {
            font-size: 12px;
            width: 120px;
            @include displayInlineBlock;
            padding: 0 4px;
            border-radius: 1px;
        }

        button {
            font-size: 12px;
            background: transparent !important;
            background-color: transparent !important;
            padding: 0 !important;
            margin: 0 0 0 auto !important;
            line-height: 10px !important;
            order: 2;

            &:hover,
            &:focus {
                color: var(--primaryColor) !important;
            }
        }
    }
}

/************ Secondary Tabs ************/

.tabStyle {
    .nav-tabs {
        >li {
            >a {
                font-size: 16px;
                color: #707070;
                font-weight: 500;
                padding: 10px;
                background-color: transparent;
                border: none;
                box-shadow: none;
                margin-right: 10px;

                &:before {
                    content: "*";
                    color: #f00;
                    font-size: 13px;
                }

                &.active {
                    color: var(--primaryColor-darkest) !important;
                    font-weight: 500;
                    border: none;
                    border-bottom: 1px solid var(--primaryColor-darkest);
                    background: transparent;
                    background-color: transparent;
                }

                &:focus,
                &:hover,
                &.active:focus,
                &.active:hover {
                    background-color: aliceblue;
                    border: none;
                    border-bottom: 1px solid var(--primaryColor);
                    outline-offset: 0;
                    outline: thin var(--primaryColor) dotted !important;
                }
            }

            .nav-link {
                font-size: 16px;
                color: #707070;
                font-weight: 500;
                padding: 10px;
                background-color: transparent;
                border: none;
                box-shadow: none;
                margin-right: 10px;

                // &:before {
                //     content: "*";
                //     color: #f00;
                //     font-size: 13px;
                // }

                &.active {
                    color: var(--primaryColor-darkest) !important;
                    font-weight: 500;
                    border: none;
                    border-bottom: 1px solid var(--primaryColor-darkest);
                    background: transparent;
                    background-color: transparent;
                }

                &:hover,
                &:focus {
                    background-color: aliceblue;
                    border: none;
                    border-bottom: 1px solid var(--primaryColor);
                    outline-offset: 0;
                    outline: thin var(--primaryColor) dotted !important;
                }
            }
        }
    }

    .tab-content {
        padding-top: 10px;
        background-color: #f9f9f9;
        min-height: 150px;
    }
}

/************ Right Side Tabs ************/

.rBoxTabs {
    .nav-tabs {
        >li {
            padding: 0px;
            margin: 0px 10px !important;

            &:first-child {
                margin-left: 0px !important;
            }

            >a,
            >.nav-link {
                font-size: 13px;
                color: #707070;
                background-color: transparent;
                border: none;
                border-bottom: 1px transparent solid;
                font-weight: 600;
                padding: 4px 0px 6px;
                margin: 0px;
                text-transform: uppercase;

                &:hover,
                &:focus {
                    color: var(--primaryColor);
                }

                &.active {
                    color: var(--primaryColor) !important;
                    background-color: transparent;
                    border: none;
                    border-bottom: 1px solid;
                    border-bottom-color: var(--primaryColor) !important;
                    cursor: default;

                    &:focus {
                        outline: thin var(--primaryColor) dotted !important;
                        outline-color: #000000;
                        outline-offset: 0px;
                        color: var(--primaryColor);
                        background-color: aliceblue;
                    }
                }
            }

            &:last-child {
                margin-right: 0px;
                padding: 0px;
            }
        }
    }

    .tab-content {
        margin-top: 15px;

        &.cntrtTab {
            margin-top: 0px;
        }
    }
}