/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/

@media screen and (max-width:1280px) {
	#react-autowhatever-1 {
		white-space: initial;
	}

	.tabSerhed .catBordDv .rightTrue header {
		width: 120px;
		right: 50px;
	}
}

@media screen and (max-width:991px) {
	.js-mobile-content.visible-mobile {
		background-color: #fff !important;
		border: 1px solid #a1aac9;
		padding: 20px 10px 10px 10px;
	}

	.js-mobile-content.visible-mobile:first-child {
		display: block !important;
	}

	.js-mobile-content .btnClose {
		height: 10px;
		width: 10px;
		color: #000;
	}

	.disnone {
		display: block;
	}

	.toolbarBtnStyle .rgSidrkBtn,
	.toolbarBtnStyle .rgSiLigBtn {
		padding: 5px !important;
		width: 110px;
		margin: 2px 0px 2px 5px;
	}

	#OfferingDetailId .page-header h1 {
		font-size: 24px;
	}

	.rwLeftPart {
		margin-bottom: 10px;
	}

	.inLineLabel .form-group .checkLabel {
		padding-top: 3px;
	}

	.margin-xs-t-15 {
		margin-top: 15px !important;
	}

	.rBoxStyle .btn.ciCrtCatBtn {
		padding: 5px 4px !important;
		margin-right: 1px;
		font-size: 12px;
		margin-left: 1px !important;
	}

	.breakFixHamburger .myLinks {
		right: 0px;
		left: initial;
	}

	.nwthrDotBtn .breakFixHamburger .myLinks {
		right: 0px !important;
		left: initial !important;
	}

	.wibTabs li a,
	.wibTabs li.active a {
		font-size: 16px;
		padding: 5px 8px;
	}

	#offeringsView .prodSlideBox {
		width: 32%;
	}
	.sPageHeading, .sPageHeading1{
		font-size:20px;
	}

}

// @media screen and (max-width:1099px) {
// 	.catBordDv .rightTrue header, .catBordDv .leftTrue header {display:none;}
// }

@media screen and (max-width:1023px) {
	#skipdv {
		margin-left: 0px;
		transition: none;
		padding-bottom: 25px;
	}

	.othrLnkNav {
		max-height: calc(100vh - 140px) !important;
	}

	.leftP-5 {
		padding-left: 15px;
	}

	.marmT-56 {
		margin-top: 0;
	}

	.xs-marB-10 {
		margin-bottom: 10px;
	}

	.newsBoxLine {
		border-right: none;
		padding: 0px;
	}

	.proDetTab .specRg {
		float: none;
		width: 100%;
		padding: 0px;
		margin-bottom: 15px;
	}

	.respondv {
		position: initial;
	}

	//.respondv .tableRgtBor{overflow-x: auto;}
	//.respondv .tableRgtBor .multiSelDv,.respondv .tableRgtBor .multiSelDv .multiSelectRef{position:initial !important;}
	//.respondv .tableRgtBor .colSearDv.multiSelPopPosi button+div{right:90px !important;}
	.rBoxStyle .rgSidrkBtn,
	.rBoxStyle .rgSiLigBtn {
		padding: 5px 5px !important;
		font-size: 13px;
	}

	.rBoxStyle .rgSidrkBtn:focus,
	.rBoxStyle .rgSiLigBtn:focus {
		padding: 5px 4px !important;
	}

	.userInfoPopupParent .popover {
		right: initial !important;
		top: initial !important;
		left: 10px !important;
		bottom: 0px !important;
	}

	.modulePopupSerch input {
		border-color: #ccc;
	}

	.modulePopup .popupLogo {
		margin: -20px 0px 0px -10px;
	}

	.othrLnkNav a {
		padding: 7px 5px 6px 0px;
	}

	.modulePopupSerch {
		margin: 0px 10px 0px 0px;
	}

	.othrLnkNav .rghArow>a::after {
		color: var(--anchorColor);
		content: "\f107";
		font-family: "FontAwesome", sans-serif;
		font-size: 14px;
		font-weight: 400;
		position: absolute;
		top: 8px;
		right: 10px;
	}

	.othrLnkNav .rghArow.rghArowRotate>a::after {
		transform: rotate(180deg);
	}

	.othrLnkNav li.active,
	.othrLnkNav li li.active {
		background-color: var(--primaryColor) !important;

		& .appIcon,
		& a {
			color: #fff !important;
		}
	}

	.othrLnkNav .sublnk li {
		margin: 0px;
	}

	.othrLnkNav .sublnk a {
		padding: 4px 0px 4px 30px;
		height: initial;
	}

	.othrLnkNav .sublnk a:before {
		top: 5px !important;
	}
	
}

/* =================================================================================
smaller than 1024
================================================================================= */
@media screen and (max-width:1024px) {
	.paddingTB {
		padding: 10px 0;
	}

	//.status{border-right:none;}
	.rPageHeading {
		font-size: 18px;
	}

	.react-date-field__calendar-icon {
		margin: 2px 4px 0 -8px;
	}

	.newsBox .hed {
		padding: 6px 150px 6px 0px;
	}

	.sxhometabact ul.nav-tabs>li>a {
		font-size: 18px !important;
	}

	.shortinfo .carousel-control {
		top: 100% !important;
	}

	.prodSlideBox {
		width: 32%;
		margin: 10px 6px !important;
	}

	.newsBox .carousel-control.left {
		left: initial;
		margin-top: 0;
		right: 40px;
	}

	.languageDropDown {
		display: none !important;
	}

	.tabStyle .nav-tabs>li>a,
	.tabStyle .nav-tabs li .nav-link {
		font-size: 15px !important;
		background-color: rgba(0, 0, 0, .05);
		border: 1px #ddd solid;
		margin: 0px 2px 0px;
		padding: 4px 7px;
	}

	.tabStyle .nav-tabs>li,
	.tabStyle .nav-tabs li {
		padding: 3px 0px;
	}

	.tabStyle .nav-tabs {
		border-bottom: none;
	}

	.tabStyle .nav-tabs>li>a.active,
	.tabStyle .nav-tabs li .nav-link.active {
		border: 1px #dddddd solid;
		background-color: transparent;
		border-bottom-color: var(--primaryColor-darkest);
	}
}

@media (min-width:768px) and (max-width:1023px) {

	/* Forms */
	.labelTxt {
		padding: 8px 0 4px 0 !important;
		display: block;
	}

	.circle {
		left: 45%;
	}

	.searchBar {
		right: 5px;
	}

	.padMar {
		margin-top: 15px;
		padding: 10px;
	}

	.newsBoxDv.show {
		height: 55px;
	}

	.newsBoxDv>div {
		height: 50px;
	}

	.newsBoxDv>div.open {
		height: initial;
	}

	.newsBox .hed {
		padding: 6px 160px 6px 0px;
	}

	/* Approval Page */
	.sxBlk {
		clear: both;
		display: block;
	}

	.showListS {
		margin-left: 0;
	}

	.sxhometabact ul.nav-tabs>li>a {
		font-size: 18px !important;
	}

	.shortinfo .carousel-control {
		top: 100% !important;
	}

	.prodSlideBox {
		width: 32%;
		margin: 10px 4px !important;
	}

	.rBoxStyle .rBoxGap .rBoxTabs .nav-tabs>li {
		margin: 0px 3px !important;
	}

	.rBoxStyle .rBoxGap .rBoxTabs .nav-tabs>li>a {
		font-size: 11px;
		font-weight: 700;
	}

	.repListDv .repSec {
		flex: 1 1 30%;
	}
}

/* =================================================================================
smaller than 760
================================================================================= */
@media screen and (max-width:767px) {
	.table-responsive {
		margin-bottom: 0px;
	}

	.table-responsive .table-responsive {
		border: none;
	}

	.table-responsive .table.tableView {
		margin: 0px;
	}

	.table-responsive .table.tableView td {
		white-space: initial !important;
	}

	.table-responsive .nowrapWhright.tableView td {
		white-space: nowrap !important;
	}

	.sxhometabact .col-md-12>.filterBox {
		top: 19px;
	}

	.pindCateBg {
		background-color: rgb(245 247 251 / 6%) !important;
		height: initial;
	}

	.newsBoxDv.show {
		height: initial;
		margin: 10px 0px;
	}

	.newsBoxDv>div {
		position: initial;
	}

	.newsBox .carousel-inner>.item {
		padding-bottom: 20px;
	}

	.newsBox .hed {
		padding: 6px 0px;
	}

	.newsBox .carousel-control {
		top: initial;
		bottom: 5px;
	}

	//.newsBoxDv .buCount{top:initial;bottom:6px;}
	//.newsBoxDv .isPause{top:initial;bottom:6px;}
	//.newsBox .item .itmmore{top: initial;bottom:5px;}
	.newsBox .info {
		height: 100px;
		overflow-y: auto;
	}

	/* .newsBox .carousel-inner{overflow:visible;} */

	.headerNavigation li.profileBox .dropdown-menu::after,
	.headerNavigation li.profileBox .dropdown-menu::before {
		bottom: 100%;
		right: 9px;
	}

	.gloSearch .inpBox {
		border-top: 1px #ccc solid;
		border-bottom: 1px #ccc solid;
	}

	.gloSearch .sericon {
		border-right: 1px #ccc solid;
		border-top: 1px #ccc solid;
		border-bottom: 1px #ccc solid;
	}

	.spinIcn {
		font-size: 10px;
		top: 6px;
	}

	.mobMarTB40 {
		margin: 40px 0px 0px;
	}

	.respondv .respondvContent {
		width: max-content;
	}

	.nBotPagina .pagination>li>a,
	.nBotPagina .pagination>li>span {
		padding: 6px 8px;
	}

	.nBotPagina {
		margin: 20px 0px;
		text-align: right;
	}


	.marT20B767 {
		margin-top: 20px;
	}

	.xsMar-t-0 {
		margin-top: 0 !important;
	}

	.xsLR {
		text-align: left;
	}

	.xst-m-5 {
		margin-top: 5px;
	}

	.xst-m-10 {
		margin-top: 10px;
	}

	.xsb-m-5 {
		margin-bottom: 5px;
	}

	.xs-f-left {
		float: left !important;
	}

	/* Quick Views */
	#nav-close {
		padding: 10px 43px 10px 0;
	}

	/* Quick Views */
	ul.action {
		float: left;
		margin: 5px 0 0 0;
	}

	ul.action li {
		display: inline;
		float: left;
		margin: 0 15px 0 0;
		text-align: center;
	}

	ul.action li i {
		display: inline;
		margin: 0 2px 0 0;
		position: relative;
		top: 3px;
	}

	ul.action li:last-child {
		margin: 0;
	}

	//.status{border-right:none;}

	/* Center Panel */
	.midMargin {
		margin: 10px 15px 0 15px;
	}

	.midMarginR {
		margin: 75px 15px 0 15px;
		padding: 0;
	}

	.activeMenu {
		width: 70px;
	}

	.times,
	.rightNav,
	.navigation,
	.exSearch {
		display: none;
	}

	.mobileMenuResults .rightNav {
		display: block;
	}

	.leftNav {
		left: -70px;
	}

	.leftNav.showNav {
		left: 10px;
		background: #fff;
	}

	#mobileLeftMenuFilter.mobileLeftMenuFilterMove {
		left: 55px;
	}

	/* Forms */
	.labelTxt {
		padding: 8px 0 4px 0 !important;
		display: block;
	}

	/* Track ORder Page */
	.view {
		display: none;
		position: absolute;
		right: 40px;
		top: 13px;
	}

	/* Category Lising IT Hardware Page */
	.listing img {
		border: 1px solid #d4d4d6;
		float: left;
		height: 75px;
		margin: 0 15px 0 0;
		width: 75px;
	}

	/* Offering Details */
	.proImg {
		float: right;
		width: 40%;
	}

	.listMX {
		clear: both;
		width: 100% !important;
	}

	/* ul.half li:nth-child(odd){width:30%;}
ul.half li:nth-child(even){width:30%;} */

	/* Review Feedback Page */
	.textL {
		text-align: left !important;
	}

	/* Profile Page */
	.xs-marB-10 {
		margin-bottom: 10px;
	}

	/* BreadkFix Page */
	.sPageHeading1 h1 {
		font-size: 18px;
		font-weight: 600;
		margin: 0;
	}

	.padMar {
		margin-top: 15px;
		padding: 10px;
	}

	.myStatusGap ul li,
	.myStatusGap ul li:last-child {
		padding: 0 4px;
	}

	/* Approval Page */
	.xs-mar-l-0 {
		margin-left: 0 !important;
	}

	.tableView {
		margin-top: 0;
	}

	.downloadPDFGraph {
		position: initial;
		top: initial;
		right: initial;
		padding: 3px 8px !important;
	}

	.sxhometabact ul.nav-tabs>li>a {
		font-size: 18px !important;
	}

	.sxhometabact.mobMarTB40>[class*="col-"] {
		padding-left: 0px;
		padding-right: 0px;
	}

	.shortinfo .carousel-control {
		top: 100% !important;
	}

	.prodSlideBox {
		width: 32%;
		margin: 10px 4px !important;
	}

	.ratRevDec {
		border-left: none;
		padding-left: 0px;
	}

	#viewMDetails .table-responsive {
		border: none;
	}

	.footerDiv .newsBoxLine {
		display: block;
		width: 100%;
		border-right: none;
		padding: 20px 0px;
		clear: both;
	}

	.footerDiv .selfLnk {
		display: block;
		width: 100%;
		padding-left: 0px;
		clear: both;
	}

	.row-eq-height {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: block !important;
	}

	.panel-collapse .panel-body,
	.addBgRw {
		font-size: 12px !important;
	}

	.mobPadR0 {
		padding-right: 0px;
	}

	.mobPadL0 {
		padding-left: 0px;
	}

	#newOrderNowListFromCart .ordCompoNam.padding-l-20 {
		padding-left: 0px;
	}

	.disFlex {
		display: block;
	}

	.inLineLabDv1 {
		border-left: none;
	}

	.inLineLabDv {
		border-right: none;
	}

	#myProfileLeftSidePanel .nav-tabs>li>a {
		font-size: 15px !important;
		padding: 10px 5px;
	}

	.preClose1 {
		right: 15px;
	}

	.noWhSpace td {
		white-space: initial !important;
	}

	.table-responsive>.table>tbody>tr>td,
	.table-responsive>.table>tbody>tr>th,
	.table-responsive>.table>tfoot>tr>td,
	.table-responsive>.table>tfoot>tr>th,
	.table-responsive>.table>thead>tr>td,
	.table-responsive>.table>thead>tr>th {
		white-space: initial !important;
	}

	.isShowLeftPanel {
		display: none !important;
	}

	.editSelect .showSelect {
		font-size: 13px;
		padding: 3px !important;
	}

	.repListDv .repSec {
		flex: 1 1 48%;
	}

	.wibsrch .catename .nav-item {
		padding: 0px 10px;
	}

	.wibsrch .catename .dropdown>a {
		line-height: 12px;
		white-space: pre-wrap;
		width: 100px;
		font-size: 12px;
		display: block;
		padding: 0;
	}

	.wibsrch .innerdv .catename .dropdown > a {
		padding: 9px 0px 12px;
	}

	.othrLnkNav:hover {
		overflow-y: initial;
	}

	.othrLnkNav {
		overflow-y: auto;
	}

	.myReqApprvalPopup {
		max-width: calc(100vw - 50px);
		left: 20px !important;
	}

	.wibSerhed .catBordDv .rightTrue header {
		top: 175px !important;
		right: initial !important;
		left: 10px !important;
	}

	.serbarPos .catBordDv .rightTrue header {
		right: 0px;
	}

	[class*="uiBuilder-"] .gjs-custom-block-manager.rightPanelComp .k-expander-content .justify-content-center {
		width: 50%;
	}

	.gjs-top-sidebar {
		display: block !important;
	}


	.devicesDv {
		display: inline-block !important;
		margin: 10px 10px 10px 4px;
		font-size: 15px;
		height: initial;
		padding: 4px !important;
		border-radius: 6px;
	}

	.topBtnMobileCls {
		padding: 0px !important;
		display: inline !important;
		width: auto !important;
		margin-left: initial !important;

		& .statusbtndv {
			display: inline-block !important;
		}

		& button {
			font-size: 15px;
			display: inline-block;
			padding: 4px !important;
			margin: 0px 3px 3px 3px !important;
		}
		& .k-split-button.k-button-group{
			& button {
				margin-left: 0px !important;
        		margin-right: 0px !important;
			}
		}
	}
	.rightPanelIcons {
		margin-top:80px !important;
	}

	.quickViewBreakFix:has(.rBoxStyle) {
		.dateRangeLabel {
			display: none;
		}
	}

	.aiInsightsBtn {
		bottom: calc(100% - 25rem);
	}

	.copyCrpdv{
		.copyCrp {		
			.copysym {
				width: 100% !important;
				display: block !important;
				text-align: center !important;
			}

			.copyVer {
				display: block !important;
				width: 100% !important;
				text-align: center !important;
			}

			.cpLnk {
				display: block !important;
				width: 100% !important;
				text-align: center !important;
				padding: 2px 0px;
			}
		}
	}
}

@media (max-width:767px) {
	.formsTable header{
		width:90px;
	}
	.inlinefnd{
		margin-right:100px;
	}
}

@media (max-width:852px) {
	.inlinefnd{
		> label{display:none;}
	}
}

@media (max-width:767px) and (orientation: landscape) {
	.copyCrpdv{
		position: initial;
	}

	.menConFlx {
		height: auto;
	}
}


@media (min-width:768px) and (max-width:1200px) {

	.exSearch,
	.times {
		display: none;
	}

	.navigation ul li {
		margin: 0 8px 0 0;
	}

	.view {
		right: 40px;
		top: 13px;
	}

}

@media (min-width:641px) and (max-width:991px) {
	.sxhometabact.sxLiView ul.nav-tabs>li>a {
		font-size: 14px !important;
		padding: 10px 4px !important;
	}

	.sxhometabact ul.nav-tabs>li:first-child>a {
		padding-left: 0px !important;
	}
}

/* =================================================================================
smaller than 640
================================================================================= */

@media (max-width:640px) {
	.offpriceSec .pbSec {
		font-size: 12px;
		margin-right: 10px;
	}

	.issuesd .row {
		padding: 10px;
	}

	.prodSlideBox {
		width: 47%;
		margin: 10px 5px !important;
	}

	.sxhometabact ul.nav-tabs {
		border: none !important;
	}

	.sxhometabact ul.nav-tabs>li>a {
		font-size: 15px !important;
		background-color: rgb(0 0 0 / 5%);
		border: 1px #ddd solid;
	}

	.sxhometabact ul.nav-tabs>li.active>a {
		background-color: transparent;
	}

	.homeservices .nav-tabs>li {
		float: none;
	}

	.homeservices .nav-tabs>li>a {
		border: 1px #ccc solid;
		border-radius: 0;
		margin: 0px;
	}

	.sxhometabact.sxLiView ul.nav-tabs {
		padding-top: 10px;
		border-bottom: none !important;
	}

	.sxhometabact.sxLiView ul.nav-tabs>li {
		margin-bottom: 2px !important;
	}

	.sxhometabact.sxLiView ul.nav-tabs>li>a {
		padding: 10px;
	}

	.sxhometabact.sxLiView ul.nav-tabs>li.active>a,
	.sxhometabact.sxLiView ul.nav-tabs>li.active>a:focus {
		padding: 10px !important;
		border: 1px #ddd solid;
		border-bottom: 1px #10b5c5 solid;
	}

	.sxhometabact.sxLiView ul.nav-tabs>li:first-child>a {
		padding-left: 10px !important;
	}

	.catListSec .bodyCont {
		width: 100%;
		display: table-row;
	}

	.catListSec .headIcon {
		width: 20%;
		display: inline-block;
	}

	.catListSec .headIcon .gap {
		background-color: #fff;
	}

	.catListSec .footBtn {
		width: initial;
		display: inline-block;
	}

	.rBoxStyle .rgSidrkBtn,
	.rBoxStyle .rgSiLigBtn {
		padding: 5px 5px !important;
		font-size: 13px;
	}

	.rBoxStyle .rgSidrkBtn:focus,
	.rBoxStyle .rgSiLigBtn:focus {
		padding: 5px 4px !important;
	}

	.inLineLabel .form-group .formOpt,
	.inLineLabel .form-group input[type="text"],
	.inLineLabel .form-group label,
	.editSelect .editInput {
		font-size: 12px;
	}

	.inLineLabel {
		padding: 10px;
	}

	.inLineLabel .form-group .checkLabel {
		padding: 5px 0px 0px 5px;
		white-space: nowrap;
	}

	.wibsrch .colSearInp {
		width: 80%;
		padding: 0px 10px;
	}

	.wibsrch .serchmoduVal {
		width: 25px;
	}

	.hTabhed {
		border: none;

		h3.tabActive {
			max-width: 90%;
			border: none;
		}
	}

	.connectors .catBordDv {
		position: relative;
	}

	.connectors .catBordDv .rightTrue.srchPosTop1 header {
		right: 70px;
		top: -42px;
	}

	[class*="connectors-"] .tabformdv .nav-tabs .nav-item .nav-link {
		padding: 6px 0px;
		font-size: 13px;
		font-weight: 500;
	}

	[class*="connectors-"] .w-75.form-control {
		width: 60% !important;
	}

	[class*="renderedView-"] .panelOverflow {
		margin-top: 20px;
	}

	[class*="renderedView-"] .catBordDv .rightTrue header {
		top: 76px;
	}

	#SxHomeCateTab .customScroll {
		border-bottom: initial;
	}

	#SxHomeCateTab .customScroll ul.nav-tabs>li>a.active {
		border-top: 1px #ddd solid;
	}

	#offeringsView {
		& .prodSlideBox {
			width:100%;
		}
	}

}

/* @media (min-width:320px) and (max-width:480px) { */
@media screen and (max-width:480px) {
	.gjs-row{
		display: block !important;
		width: 100% !important;
	}
	[class*="renderedView-667564dc670a6133b9921ee0/"] {
		#ika52j {
			display: flex !important;
			width: 100% !important;
		}
	}
	[class*="walkUpReq"] {
		#i24gf {
			display: none !important;
		}
	}
	.logos .comLogo {
		height: 40px;
		width: 70px;
		left: -12px;
	}

	.searchBar {
		right: 10px;
	}

	.listMX li {
		float: none;
		width: 100%;
	}

	.progressbarDiv .txt div {
		font-size: 8px;
		font-weight: 400;
	}

	.leftNav .innerBigPopover {
		width: 284px;
	}

	.prodSlideBox {
		width: 100%;
		margin: 0px 0px 15px !important;
	}

	.copyr [class*="col-"],
	.cpLnk {
		text-align: center !important;
	}

	.catListSec .bodyCont,
	.catListSec .headIcon,
	.catListSec .footBtn {
		width: 100%;
		display: table-row;
	}

	.catListSec .footBtn>a {
		padding: 10px;
		width: 50%;
	}

	.catListSec .headIcon .gap {
		background-color: #fff;
		margin: 0px 0px 5px 0px !important;
	}

	#viewMDetails .bgNonTab {
		width: 100%;
		display: block;
		padding-right: 0px;
		margin-bottom: 20px;
	}

	#viewMDetails .ratRevDec {
		width: 100%;
		display: block;
	}
	.topRgtNavIcons{
		display: block;
		& > .toprigLnk:first-child{
			position:initial;
			margin-right:0px !important;
		}
	}
	.toprigLnk .riggep{margin:0px;border-right:none;}
}

@media (min-width:320px) and (max-width:359px) {
	.progressbarDiv .txt div {
		font-size: 10px;
		font-weight: 400;
	}

	.progressbarDiv li.mActive .name {
		font-size: 8px;
	}

	.progressbarDiv .head {
		padding: 0 5px 0 0;
	}

}

@media (max-width:375px) {
	.inciSupProviderDv {
		.showGrpDv {
			gap: 10px;
		}
	}

	.savQueDropDv .txtvals .txt {
		width: 100px;
	}
}

@media (max-width:462px) {
	.inciSupProviderDv {
		.showGrpDv {
			width: 100%;
			margin: 4px 0px;
		}
	}

	.qurBtnSec {
		position: initial;
		padding: 6px 10px;
		background-color: #f2f2f2;
	}

	.aiButtonCss button.dropdown-toggle {
		padding: 0px 4px !important;
	}
}

@media (max-width:375px) {
	#myProfileLeftSidePanel .nav-tabs {
		border: 1px #ddd solid !important;
		margin-bottom: 10px;
	}

	#myProfileLeftSidePanel .nav-tabs>li {
		float: none;
		background-color: rgb(0 0 0 / 5%);
	}

	#myProfileLeftSidePanel .nav-tabs>li.active {
		float: none;
		background-color: transparent;
	}

	#myProfileLeftSidePanel .nav-tabs>li>a {
		font-size: 15px !important;
		text-align: center;
	}
}

@media (max-width:319px) {
	.breadcrumb.creDele .breadcrumb-item+.breadcrumb-item::before {
		margin: 0px 0px;
	}

	.breadcrumb.creDele>li+li:before {
		padding: 0 3px;
	}

	.modal.show .modal-dialog {
		position: absolute;
		right: 6px;
		top: 40px;
		width: calc(100vw - 20px);
	}

	.wibsrch .colSearInp {
		width: 67%;
		padding: 0px 4px;
	}
}

@media (min-width: 564px) {
	.daterangepicker .drp-calendar.left {
		clear: initial;
	}
}
@media (max-width: 540px) {
	.rightPanelIcons {
		margin-top: 118px !important;
	}
}

@media (max-width:610px) {
	.genAiPopdv {	
		.genAiHd2 {
			.k-button-group {
				margin-left: auto;
			}
		}
		.genAiList {
			gap: 10px 9px;
	
			a {
				width: 48%;
			}
		}
	}
}

@media(max-width:414px) {
	.OfferingInlineButtons ul {
		border: 1px #d0d0d0 solid !important;
		margin-bottom: 20px;
		display: flex;
		flex-wrap: wrap;
	}

	.OfferingInlineButtons li {
		margin: 0px;
		float: none;
		display: block;
	}

	.OfferingInlineButtons .btn[disabled] {
		background-color: transparent !important;
		font-size: 14px !important;
		padding: 10px 10px !important;
		width: 100%;
	}

	.OfferingInlineButtons .btn {
		background-color: rgb(0 0 0 / 5%) !important;
		border: 1px #ddd solid !important;
		font-size: 14px !important;
		padding: 10px 10px !important;
		width: 100%;
	}

	.copyr .copysym {
		width: 100%;
		display: block;
		text-align: center;
	}

	.copyr .copyVer {
		display: block;
		width: 100%;
		text-align: center;
	}

	.daterangepicker {
		right: 10px !important;
		width: calc(100vw - 20px);
		z-index: 997 !important;

		&.show-ranges.show-calendar {
			.calendar-table th.next {
				top: 132px;
			}
		}
	}

	.daterangepicker .drp-calendar {
		max-width: 100%;
	}

	.rBoxStyle .rBoxGap .rBoxTabs .nav-tabs>li {
		margin: 0px 4px !important;
	}

	.rBoxStyle .rBoxGap .rBoxTabs .nav-tabs>li>a {
		font-size: 11px;
		font-weight: 700;
	}

	.respondv .tableRgtBor {
		border: none !important;
	}

	.table.nowrapWhright {
		margin: 0px;
		display: block !important;
		width: 100% !important;
	}

	.table.nowrapWhright thead,
	.table.nowrapWhright tbody,
	.table.nowrapWhright tr,
	.table.nowrapWhright tr>th,
	.table.nowrapWhright tr>td {
		width: 100% !important;
		display: block;
		min-height: 30px;
	}

	.table.nowrapWhright tr {
		margin-bottom: 20px !important;
		border: 1px #ccc solid !important;
	}

	.table.nowrapWhright .sortParArr {
		width: 50%;
		display: inline-block !important;
		margin: 0px !important;
		word-break: break-word;
		white-space: initial;
		vertical-align: top;
		font-size: 13px;
	}

	.table.nowrapWhright .colSearDv {
		width: 50%;
		display: inline-block;
		text-align: left;
		vertical-align: top;
	}

	.table.nowrapWhright tbody td:nth-child(1) {
		font-size: 14px;
		font-weight: 500;
		color: #fff !important;
		background-color: #10b5c5;
	}

	.table.nowrapWhright tbody td:nth-child(1) .showdv414 {
		line-height: 14px;
	}

	.table.nowrapWhright tbody td:nth-child(1) .valdv414 {
		font-size: 13px;
		padding-left: 0px;
	}

	.table.nowrapWhright tbody td:nth-child(1) .keydv414 {
		color: #fff;
		line-height: 16px;
		font-size: 15px;
	}

	.respondv .nowrapWhright.tableView td {
		white-space: initial !important;
		border-left: none;
		border-right: none;
		border-bottom: none;
	}

	.hiddenBe414 {
		display: none !important;
	}

	.showdv414 {
		display: table;
		width: 100%;
		font-size: 14px;
	}

	.showdv414 .keydv414 {
		display: table-cell;
		visibility: visible;
		width: 45%;
		word-break: break-word;
		color: #09a9b9;
		vertical-align: middle;
	}

	.showdv414 .valdv414 {
		display: table-cell;
		width: 55%;
		word-break: break-word;
		padding-left: 5px;
	}

	.nBotPagina .nShow .form-control {
		width: 50px;
	}

	.offH2 {
		font-size: 16px;
	}

	.tabStyle .nav-tabs>li>a,
	.tabStyle .nav-tabs li .nav-link {
		padding: 10px 8px;
		margin-right: 0;
		font-size: 15px;
	}

	.rBoxStyle .rBoxGap .rBoxTabs .nav-tabs>li>a,
	.rBoxStyle .rBoxGap .rBoxTabs .nav-tabs>li>button {
		padding: 4px 6px 6px 0px;
	}

	//.status ul li {padding: 0 0px;}
	.swal-modal {
		width: calc(100vw - 2rem);
	}

	.leftNav .innerBigPopover {
		width: calc(100vw - 5rem) !important;
	}

	.multiSelectRef>button+div,
	.colSearDv button+div {
		width: auto;
	}

	.queryBuilder .ruleGroup .rule {
		display: inline-flex;
	}

	// .tableView .hyperLinkWIB {
	// 	color: #ffffff !important;
	// }
	.table.nowrapWhright tbody .wibMobCol {
		display: flex;

		.mobReqNum {
			display: inline-block !important;
			margin-left: 10px;

			.hyperLinkWIB {
				color: #ffffff !important;
			}
		}
	}

	.table.nowrapWhright tbody td.deskReqNum {
		display: none;
	}

	.fltr-table {
		thead {
			tr {
				th {
					&:first-child {
						max-width: 100% !important;
					}
				}
			}
		}

		tbody {
			tr {
				td {
					&:first-child {
						max-width: 100% !important;
					}
				}
			}
		}
	}

	.forumDvCol {
		max-width: 100%;
	}

	.btn-jwt {
		button {
			margin-right: 5px;
			padding: 3px 4px;
			font-size: 12px;
		}
	}

	.jwt-geneBtn {
		font-size: 14px;
		padding: 6px 8px;
	}

	.supported-formats {
		padding: 0 1rem !important;
	}

	.cmdbMbHyperLink {
		color: #ffffff !important;
	}

	.genAiPopdv {	
		.genAiHd2 {
			.k-button-group {
				margin-left: auto;
			}
		}
		.genAiList {
			gap: 10px 0px;
	
			a {
				width: 100%;
			}
		}
	}
}

@media (min-width:414px) and (max-width:767px) {
	.table.tableView>thead>tr>th {
		min-width: 115px;
	}
}


@media (min-width:992px) and (max-width:1160px) {
	.breadcrumbHome .welcome span {
		color: #fff;
		display: block;
		font-size: 24px;
		font-weight: 400;
		padding: 0 0 8px;
		line-height: 18px;
	}

	.radioDiv .checkbox-inline+.checkbox-inline,
	.radio-inline+.radio-inline {
		margin-left: 15px;
	}

	.logos .xsmLogo {
		width: 105px;
	}

	.paHedFilter .myBt.fillBtn {
		margin-left: 3px;
	}
}

@media (min-width:767px) and (max-width:991px) {
	.OfferingInlineButtons .btn {
		font-size: 16px !important;
	}

	#mobileLeftMenuFilter {
		display: none;
	}

	.footerDiv .newsBoxLine {
		border-right: none;
		padding: 0px;
	}

	.preClose1 {
		right: -47%;
	}

	.rightSideFields {
		margin-bottom: 5px;
	}

	#viewMDetails .bgNonTab {
		width: 55%;
	}

	#viewMDetails .ratRevDec {
		width: 45%;
	}

	.rightSideFields .myInputWidth,
	.rightSideFields .myInputWidths {
		width: 100%;
		margin: 5px 0px;
	}

	.rightSideFields .mySelectWidth {
		max-width: 100%;
	}

	.rBoxStyle .rightSideFields .myBtn {
		margin: 5px 0px 5px 5px;
	}

	.exterLnkicn {
		top: -118px;
	}

	.exterLnkicn.exterLnkicnBG {
		top: -90px;
	}

	.rBoxGap:has(.alert) .exterLnkicn.exterLnkicnBG {
		top: -180px;
	}

	.rBoxGap:has(.alert.errorMessageSec) .exterLnkicn.exterLnkicnBG {
		top: -88px;
	}

	// .serbdbtn .addbtntr {
	// 	top: 118px;
	// }
}


@media (min-width:992px) and (max-width:1199px) {
	.catListSec .bodyCont {
		width: 65%;
	}

	.catListSec .headIcon {
		width: 10%;
	}

	.catListSec .footBtn {
		width: 25%;
	}
}

@media (min-width:1024px) and (max-width:1279px) {
	.newsBoxDv.show {
		height: 55px;
	}

	.newsBoxDv>div {
		height: 50px;
	}

	.newsBoxDv>div.open {
		height: initial;
	}
}

@media (min-width:1024px) and (max-width:1179px) {
	.prodSlideBox {
		width: 31.4%;
	}

	#offeringsView {
		& .prodSlideBox {
			width: 30.7%;
		}
	}

}

@media (min-width:1180px) and (max-width:1284px) {
	.prodSlideBox {
		width: 18.4%;
	}

	#offeringsView {
		& .prodSlideBox {
			width: 31.2%;
		}
	}
}
@media (max-width:1380px) {
	h1 .order{
		margin-bottom:15px;
	}
}

@media (min-width:1200px) and (max-width:1390px) {
	.categryListDv li .nav-link {
		font-size: 14px;
		padding: 6px 0px 6px 22px;
	}

	[class*="home"] .verticalRw {
		>.col-xl-2 {
			width: 22%;
		}

		>.col-xl-10 {
			width: 78%;
		}
	}
}

@media (min-width:992px) and (max-width:1108px) {
	.gloSearchDiv {
		left: 150px;
		width: 375px;
	}

	.wibsrch {
		left: 150px;
	}
}

@media (min-width:576px) and (max-width:710px) {

	.gloSearchDiv,
	.wibsrch {
		left: 190px;
		width: 395px;
	}
}

@media (max-width:467px) {
	.nBotPagina .nShow {
		float: none;
		width: 100%;
		text-align: center;
		margin-bottom: 10px;
	}

	.nBotPagina nav {
		width: 100%;
	}

	.nBotPagina nav .pagination {
		justify-content: center;
	}
}

@media (max-width:390px) {
	.repListDv .repSec {
		flex: 1 1 100%;
	}
}

@media (max-width:576px) {
	.form-select {
		font-size: 14px;
	}

	.wibTabs,
	.wibTabs li {
		padding: 3px 0px;
	}

	.wibTabs li a,
	.wibTabs li.active a {
		padding: 4px 7px;
		font-size: 16px;
	}

	.wibTabs li a {
		font-size: 14px !important;
		background-color: rgba(0, 0, 0, 0.05);
		border: 1px #ddd solid;
		margin: 0px 2px 0px 0px;
	}

	.wibTabs li.active a {
		background-color: transparent;
	}

	.wibTabs li:first-child a {
		padding: 4px 6px;
	}

	.paHedFilter .myBt {
		font-size: 18px;
		margin-left: 5px;
	}

	#assFiltr .filtr button {
		margin: 0px 2px 8px;
		font-size: 12px;
		padding: 1px 4px;
	}

	.dateRangeLabel {
		font-size: 11px;
		top: 0px;
	}

	.midMargin {
		margin: 16px 15px 0 15px;
	}

	.breakFixHamburger .myLinks {
		right: 0;
		left: initial;
		//width: auto;
	}

	.dropdown-menu {
		min-width: 200px;
	}

	.dropdown-menu-right {
		left: 0;
	}

	.toprigLnk ul.icnlnk:last-child {
		width: auto;
	}

	.toprigLnk .icnlnk li {
		padding: 0 1px !important;
		margin-bottom: 3px;
	}

	.toprigLnk .threeDotul {
		padding: 0px 0px 0px 2px !important;
		margin-right: 2px !important;
	}

	.threeDotul .dropdown-menu {
		min-width: 205px;
	}

	.showwAllGrps,
	.showwAllGrps:focus {
		font-size: 12px !important;
	}

	//.midMargin {margin: 25px 15px 0 15px;}
	.rBoxStyle .rBoxGap .rBoxTabs .nav-tabs>li {
		margin: 4px 0px 0px 4px !important;
	}

	.breadcrumb .breadcrumb-item {
		font-size: 12px;
	}

	.breadcrumb>li+li:before {
		padding: 0 4px;
	}

	.breadcrumb .breadcrumb-item+.breadcrumb-item::before {
		margin: 0px 2px;
	}

	.tabDatacount {
		font-size: 13px;
	}

	//.table.nowrapWhright .colSearDv {float: right;}
	.nowrapWhright th,
	.nowrapWhright td {
		font-size: 12px;
	}

	.rwKey {
		font-size: 12px;
	}

	.rBoxStyle .rBoxGap .rBoxTabs .tab-content {
		margin-top: 10px;
	}

	.rwVal,
	.rwVal1 {
		font-size: 12px;
	}

	.rwTable {
		padding: 5px 0px;
	}

	.rightSideFields {
		margin-bottom: 5px;
	}

	.exterLnkicn {
		top: -80px;
	}

	//.mobleftNav {left: 0px;width: 100%;position: relative;height: 25px;top: 10px;}
	//.mobleftNav .leftMenu {width: 50%;flex-direction: row;}
	//.mobleftNav .leftMenu li {padding: 5px 10px;}

	//.catBordDv .rightTrue header {right: 150px;}
	.myReqst .myControl {
		font-size: 12px;
	}

	.cIcatBordDv {
		margin-bottom: 30px;
	}

	.cIcatBordDv .myOrderDiv .paginationSetNew .react-bs-table-sizePerPage-dropdown {
		top: 0px;
		left: 0px;
	}

	.cIcatBordDv .myOrderDiv .paginationSetNew .pagination {
		top: 0px;
	}

	.cIcatBordDv .paginationSetNew .react-bs-table-pagination {
		margin: 10px 0px !important;
	}

	.cIcatBordDv .dtaTableDv {
		position: relative;
	}

	.cIcatBordDv .dtaTableDv header {
		position: relative;
		width: 100%;
		z-index: 2;
		padding: 0px;
		display: inline-block;
		min-height: initial;
	}

	.cIcatBordDv .rightTrue {
		position: relative;
		top: 45px;
	}

	.cIcatBordDv .rightTrue header {
		top: 0;
		left: 0;
		margin-bottom: 10px;
	}

	.cIcatBordDv .leftTrue header {
		top: -44px;
	}

	.catPanelGroup .panel-collapse,
	.catPanelGroup .accordion-collapse {
		background: none;
		background-color: #edf3f7;
	}

	.rightSideFields .myInputWidth {
		width: 100%;
		margin: 5px 0px;
		border-color: #dee2e6;
	}

	.rightSideFields .mySelectWidth {
		max-width: 100%;
	}

	.rBoxStyle .rightSideFields .sbAttr .myBtn {
		margin: 5px 0px 5px 5px;
	}

	.exterLnkicnBG {
		top: -117px;
	}

	.gloSearchDiv,
	.wibsrch {
		position: initial;
		top: initial;
		left: initial;
		width: auto;
		z-index: initial;
		margin-bottom: 0px;
	}

	.wibsrch {
		margin: 10px;
		border: 1px #ccc solid;
		border-radius: 50px;
	}

	.gloSearch .catList .catBox a {
		padding: 6px 13px;
	}

	.gloSearch .inpBox input {
		height: 30px;
		padding: 0px 15px;
		top: -1px;
	}

	.gloSearch .sericon .btn {
		height: 28px;
	}

	.gloSearchResult {
		top: 34px;
		border-radius: 6px;
	}

	.gloSearchResult .nav-tabs>li>a {
		padding: 5px 0px;
		margin-right: 20px;
	}

	.rBoxStyle .rBoxGap .rBoxTabs .nav-tabs>li>a,
	.rBoxStyle .rBoxGap .rBoxTabs .nav-tabs>li>button {
		font-size: 12px;
		background-color: rgba(0, 0, 0, .05);
		border: 1px solid #dddddd;
		padding: 4px 5px 6px;
	}

	.rBoxStyle .rBoxGap .rBoxTabs .nav-tabs>li a.active,
	.rBoxStyle .rBoxGap .rBoxTabs .nav-tabs>li button.active {
		border: 1px solid #dddddd;
	}

	.catPanelGroup .catPanelGroupBg .botLineWhite label {
		margin: 10px 0px !important;
	}

	.margin-sm-t-5 {
		margin-top: 5px;
	}

	.serbdbtn .addbtntr {
		right: 40px;
	}

	.exterLnkicn.exterLnkicnBG {
		right: 90px;
		top: -90px;
		
		&.extGotoRuleBtn {
			right:28px;
		}
	}

	.rBoxGap:has(.alert) .exterLnkicn.exterLnkicnBG {
		top: -164px;
	}

	.rBoxGap:has(.alert.errorMessageSec) .exterLnkicn.exterLnkicnBG {
		top: -88px;
	}

	.myReqApprvalPopup.myAppAdhocPopup {
		max-width: calc(100vw - 80px);
		left: 2px !important;
	}

	.catBordDv .rightTrue.catBorSrch header,
	.catBordDv .leftTrue.catBorSrch header {
		right: initial;
	}

	.rBoxStyle .rPageHeading .offNam {
		font-size: 16px;
	}

	.catBordDv .rightTrue.fdnPos header,
	.catBordDv .leftTrue.fdnPos header {
		top: 25px !important;
	}

	.catBordDv .rightTrue.srchPosTop.bfncSrchPos header,
	.catBordDv .leftTrue.srchPosTop.bfncSrchPos header {
		right: 80px;
		top: 86px;
	}

	.catBordDv .rightTrue.cmpSrchPos header, .catBordDv .leftTrue.cmpSrchPos header {
		right: 45px;
	}

	.repListDv {
		flex-direction: column;

		.part1,
		.part2 {
			width: 100%;
		}
	}

	.nbConfigHeader {
		top: 86px;
	}

	.pindCateBg {
		margin: 10px 0px;
	}

	.rBoxStyle .rBoxGap .rBoxTabs .nav-tabs.associateCompTabs li {
		max-width: 115px !important;
		overflow: hidden;
		white-space: nowrap;

		button {
			text-overflow: ellipsis;
			overflow: hidden;
			max-width: 100%;
		}
	}

	.aiInsightsBtn {
		bottom: calc(100% - 32rem);
	}

	.rBoxStyle .rightSideFields .myBtn {
		margin: 5px 0px 5px 5px;
	}

	.copyCrpdv{
		position: initial;
	}

	.menConFlx {
		height: auto;
	}

	.daterangepicker {

		.calendar-table th.next {
			position: absolute;
			top: 9px;
			right: 10px;
		}
	
		&.show-ranges.show-calendar {
			.calendar-table th.next {
				top: 132px;
			}
		}
	}
}

@media (max-width:350px) {
	.vCatalogBreadcrumb {
		.breadcrumb {
			.breadcrumb-item {
				max-width: 25%;
			}
		}
	}
}

@media (max-width:380px) {
	.myReqApprvalPopup.myAppAdhocPopup {
		max-width: calc(100vw - 20px);
		left: 0px !important;
	}
}

@media (max-width:240px) {
	.logos .xsmLogo {
		width: 115px;
	}
}

@media (min-width:641px) and (max-width:767px) {
	.sxhometabact .col-md-12>.filterBox {
		top: 7px;
	}
}

@media (max-width:768px) {

	// .userInfoPopup.bs-popover-start {min-width: calc(100vw - 4rem);transform: translate3d(-9px, 600px, 0px) !important;}
	.userInfoPopup.bs-popover-start .popover-arrow {
		display: none;
	}

	.userInfoPopup {
		width: 100%;
	}

	.userInfoPopup .userInfoPopupParent .cardLeftdv {
		width: auto;
	}

	#viewMDetails {
		flex-direction: column;
	}

	#viewMDetails .bgNonTab,
	#viewMDetails .ratRevDec {
		width: 100%;
		padding: 0;
	}

	#viewMDetails .ratRevDec {
		margin-top: 10px;
	}

	//.catBordDv .dtaTableDv header {left: 0px;right: initial;}
	.shopCartloader2 .listLoader {
		display: none;
	}

	[class*="renderedView-665ec115794d529900518fd8"] .gjs-cell {
        width: 100%;
        display: block !important;
    }

	[class*="uiBuilder"] {
        .uiBuildPanelGrp {
			display: block !important;

			.fullWd {
				margin-top: 20px;
			}
		}
    }
}

@media (min-width:577px) and (max-width:767px) {
	.serbdbtn .addbtntr {
		right: 40px;
	}

	.exterLnkicn.exterLnkicnBG {
		right: 90px;
		top: -47px;

		&.extGotoRuleBtn {
			right:28px;
		}
	}
}

@media (min-width:768px) and (max-width:992px) {

	// .userInfoPopup.bs-popover-start {min-width: calc(100vw - 20rem);transform: translate3d(-75px, 1100px, 0px) !important;}
	.userInfoPopup.bs-popover-start .popover-arrow {
		display: none;
	}

	.userInfoPopup .userInfoPopupParent .cardLeftdv {
		width: auto;
	}

	#offeringsView .prodSlideBox {
		width: 31%;
	}
}

@media (max-width:991px) and (max-height:250px) {
	.headerNavigation li.profileBox .dropdown-menu {
		transform: translate3d(-132px, 48px, 0px) !important;
	}
}

@media (max-width:991px) and (max-height:380px) {
	.mbPopupScrol {
		max-height: 200px;
		overflow: hidden;
		overflow-y: scroll;
	}

}

@media (min-width:991px) and (max-width:1208px) {
	.rBoxStyle .ciCrtCatBtn {
		padding: 5px 8px !important;
		font-size: 14px;
	}
}


@media (min-width:992px) and (max-width:1239px) {
	.toprigLnk .icnlnk {
		display: inline-flex;
	}

	.fulfillHeader li {
		padding: 0 1px !important;
	}

	.toprigLnk .threeDotul {
		padding: 0px 0px 0px 1.5px !important;
		margin-right: 1.5px !important;
	}
}

@media (max-width:592px) {
	.savQueDropDv {
		margin-left: 0;
	}
}

/*@media (max-width:992px) and (max-height:467px) {
	.daterangepicker {top: initial !important;bottom: 20px;right: 10px !important;}
}*/
@media (max-height:560px) {
	.multiSelectStatus {
		max-height: 150px !important;
		overflow: hidden;
		overflow-y: scroll;
	}

	.dateTimeSdiv.deledateTimePosTop .rdtOpen .rdtPicker,
	.dateTimeSdiv.addRosterCalender .rdtOpen .rdtPicker {
		top: initial;
		bottom: -60px !important;
	}

	.heightdvTypehd .rbt .rbt-menu.dropdown-menu.show {
		bottom: 64px !important;
		top: initial !important;
	}

	.minHghtdvTypehd .rbt-menu.dropdown-menu.show {
		max-height: 100px !important;
	}
}

@media (max-height:415px) {
	.dateTimeSdiv.deleMBDateTimePos .rdtOpen .rdtPicker {
		bottom: -40px !important;
	}

	.dateTimeSdiv.addRosterCalender .rdtOpen .rdtPicker {
		bottom: -40px !important;
	}
}

@media (max-height:460px) {
	.deleDropdown .react-autosuggest__suggestions-container--open {
		max-height: 160px;
		overflow: hidden;
	}
}
@media (max-width:1399px) {
	.servicedetails .radioInlineBox.float-l{top:initial !important;float:none !important;}
}

@media (max-width:1116px) {
	.topRgtNavIcons{
		& > .toprigLnk:first-child{
			position:initial;
			margin-right:20px;
		}
	}
}

@media (max-width:428px) {

	.catBordDv .rightTrue header,
	.catBordDv .leftTrue header {
		//right: 95px;
		top: 112px;
	}

	.catBordDv .rightTrue.srchPosTop header,
	.catBordDv .leftTrue.srchPosTop header {
		top: 70px;
		right: initial;
		left: 10px;
	}

	.catBordDv .rightTrue.fdnPos header,
	.catBordDv .leftTrue.fdnPos header,
	.catBordDv .rightTrue.fdnPos.cmpSrchPos header,
	.catBordDv .leftTrue.fdnPos.cmpSrchPos header,
	.catBordDv .rightTrue.srchPosTop.bfncSrchPos header,
	.catBordDv .leftTrue.srchPosTop.bfncSrchPos header {
		right: initial;
	}

	.noWrapPieChart {
		white-space: pre-wrap;
	}
}

@media (max-width:576px) {
	.tabSerhed .catBordDv .rightTrue header {
		top: 158px !important;
		left: 10px;
		right: initial !important;
	}

	.wibSerhed {
		.catBordDv .rightTrue header {
		top: 135px !important;
		right: 45px !important;
		left: initial !important;
		width: auto !important;
		}
	}
}

@media (min-width:429px) and (max-width:576px) {

	.catBordDv .rightTrue header,
	.catBordDv .leftTrue header {
		//right: 95px;
		top: 85px;
	}

	.catBordDv .rightTrue.srchPosTop header,
	.catBordDv .leftTrue.srchPosTop header {
		top: 69px;
	}

	.catBordDv .rightTrue.fdnPos header,
	.catBordDv .leftTrue.fdnPos header {
		right: 80px;
	}
}

@media (min-width:540px) and (max-width:720px) {
	.tabSerhed .catBordDv .rightTrue header {
		top: 124px !important;
		left: 10px;
		right: initial !important;
	}

	[class*="renderedView-"] .catBordDv .rightTrue header {
		top: 38px;
		width: 150px;
	}

	#offeringsView .prodSlideBox {
		width: 47%;
	}

}

@media (min-width:360px) and (max-width:740px) and (orientation:landscape) {
	.tabSerhed .catBordDv .rightTrue header {
		top: 82px !important;
		right: initial !important;
		left: 10px !important;
	}

	.wibSerhed .catBordDv .rightTrue header {
		top: 82px !important;
		right: initial !important;
		left: 10px !important;
	}

	#offeringsView .prodSlideBox {
		width: 47%;
	}
}

@media (min-width:577px) and (max-width:992px) {

	.catBordDv .rightTrue header,
	.catBordDv .leftTrue header {
		top: 75px;
	}

	[class*="renderedView-"] .catBordDv .rightTrue header {
		top: 38px;
	}
}

@media (max-width:992px) {
	.headerNavigation li.profileBox>a {
		padding: 4px 13px;
	}

	.headerNavigation li.profileBox {
		top: 11px;
		border-radius: 100%;
		border: 4px solid var(--headerBG);
	}

	.headerNavigation li.profileBox .dropdown-menu {
		transform: translate3d(7px, 26px, 0px) !important;
	}

	.headerNavigation li.profileBox>a:hover,
	.headerNavigation li.profileBox>a:focus {
		background-color: rgba(0, 0, 0, 0);
	}

	.headerNavigation li.profileBox:has(a:hover),
	.headerNavigation li.profileBox:has(a:focus),
	.headerNavigation li.profileBox:has(a.active) {
		border: 4px solid var(--headerBG-darker);
	}

	.partWithKA {
		flex-direction: column;
		gap: 20px;
	}

	.partWithKA .prt1,
	.partWithKA .prt2 {
		width: 100%;
	}

	.variantDv .innervardv {
		gap: 10px;
	}

	.variantDv .nav-item a.dropdown-toggle {
		padding: 5px 8px;
	}

	.datePickerNav {
		.datepicker-prev {
			&:after {
				left: 20px;
			}
		}

		.datepicker-next {
			&:after {
				right: 20px;
			}
		}

		.datepicker-title {
			width: 300px;
		}
	}

	.catBordDv .rightTrue.catBorSrch header,
	.catBordDv .leftTrue.catBorSrch header {
		top: -45px;
	}

	.valurdrpdown {

		.mySel,
		.myControl,
		.myBtn {
			border-radius: 4px;
		}
	}

	.valurdrpdown .myControl {
		border: 1px solid #ccc;
	}

	.leftPanelCanvasDv {
		width: 92%;
	}

	.rightPanelIcons {
		margin-top:30px;
	}

	.supported-formats {
		padding: 0 5rem;
	}
	
	.homeservices .hTabhed h3.tabActive {
		width: 90%;
	}

	.toprigLnk.toprigStatusDv {
        padding-right: 0px;
        padding-left: 0px;
    }
}

@media (min-width:600px) and (max-width:1160px) {
	.dtaTableDv .rdt_Pagination {
		flex-wrap: wrap;
		justify-content: center;
	}
}

@media (min-width:741px) and (max-width:1160px) {
	.wibSerhed .catBordDv .rightTrue header {
		top: 48px !important;
		right: 50px !important;
		left: initial !important;
		width: 100px;
	}
}

@media (max-width:421px) {

	.catBordDv .rightTrue.srchPosTop.reportmbSrchPos header,
	.catBordDv .leftTrue.srchPosTop.reportmbSrchPos header {
		top: 75px;
	}
}
@media (max-width:368px) {

	.catBordDv .rightTrue.srchPosTop.reportmbSrchPos header,
	.catBordDv .leftTrue.srchPosTop.reportmbSrchPos header {
		top: 95px;
	}
}

@media (max-width:485px) {
	.reportBtnGrp {
		.btn {
			margin-top: 5px;
		}
	}

	.leftPanelCanvasDv {
		width: 89%;
	}

	

}

@media (min-width:992px) and (max-width:1284px) {
	.nBotPagina {
		.nShow {
			select {
				padding: 3px 30px 3px 3px;
				font-size: 12px;
			}
		}

		.pagination {

			>li>a,
			>li>span {
				padding: 3px 6px;
				font-size: 13px;
			}
		}
	}
}

@media (min-width:1281px){
	.leftPanelCanvasDv {
		width: 96%;
	}
}

@media (min-width:480px) and (max-width:670px) {
	.breakFixHamburger .myLinks {
        right: initial;
        left: 0;
    }
}

@media (min-width:1037px) and (max-width:1250px) {
	#app:has(.transAppy.show) {
		.wibSerhed {
			margin-top: 20px;
			.catBordDv .rightTrue header {
			top: 85px !important;
			right: 20px !important;
			width: auto !important;
			}
		}
	}
}
@media (min-width:690px) and (max-width:810px) {
	.wibSerhed {
		margin-top: 20px;
		.catBordDv .rightTrue header {
		top: 85px !important;
		right: 20px !important;
		left: initial !important;
		width: auto !important;
		}
	}
}
@media (min-width:1024px) and (max-width:1037px) {
	#app:has(.transAppy.show) {
		.wibSerhed {
			.catBordDv .rightTrue header {
			top: 85px !important;
			width: auto !important;
			}
		}
	}
}
@media (min-width:577px) and (max-width:690px) {
	.wibSerhed {
		.catBordDv .rightTrue header {
		top: 82px !important;
		right: 45px !important;
		left: initial !important;
		width: auto !important;
		}
	}
}
@media (min-width:357px) and (max-width:477px) {
	.wibSerhed {
		margin-top: 20px;
		.catBordDv .rightTrue header {
		top: 172px !important;
		right: 20px !important;
		width: auto !important;
		}
	}
}
@media (max-width:356px) {
	.wibSerhed {
		.catBordDv .rightTrue header {
		top: 170px !important;
		width: 100px !important;
		}
	}
}
@media (max-width:1028px) {
	.toprigLnk .icnlnk li a, .toprigLnk .icnlnk li button {
		font-size: 16px;
	}
}

@media (min-width:767px) and (max-width:930px) {
	.genAiPopdv {	
		.genAiList {
			gap: 10px 5px;
	
			a {
				width: 48%;
			}
		}
	}
}