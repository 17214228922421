@import "./mixin";

.skipdv {
  &:has(.forgot-password) {
    @include display(flex, center, center);
  }
}

.forgot-password {
  >.row {
    align-items: center;
    justify-content: center;
  }

  .progress-line-container {
    width: 100%;
    @include display(inline-flex, center, center);
    text-align: center;
    margin-top: 20px;

    >div {
      @include display(inline-flex, center, space-between);
      width: 75%;
    }

    .progress-line {
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      display: inline-block;
      height: 4px;
      background-color: rgb(0, 131, 143);
      font-size: 4px;
      width: 77%;
      margin: 0px 0px 0px 0px;
    }

    &:has(.step-2.active) {
      .step-1 {
        background-color: rgb(0, 131, 143) !important;
        color: #fff;
        border-color: rgb(0, 131, 143);

        &:after {
          border-color: rgb(0, 131, 143);
        }
      }
    }

    &:has(.step-3.active) {

      .step-1,
      .step-2 {
        background-color: rgb(0, 131, 143) !important;
        color: #fff;
        border-color: rgb(0, 131, 143);

        &:after {
          border-color: rgb(0, 131, 143);
        }
      }
    }

    &:has(.step-4.active) {

      .step-1,
      .step-2,
      .step-3 {
        background-color: rgb(0, 131, 143) !important;
        color: #fff;
        border-color: rgb(0, 131, 143);

        &:after {
          border-color: rgb(0, 131, 143);
        }
      }
    }

    .step {
      position: relative;
      width: 25px;
      height: 25px;
      font-size: 13px;
      font-weight: 700;
      border-radius: 50%;
      color: #737373;
      border: 1px solid #cccccc;
      background-color: rgba(255, 255, 255, 1);

      span {
        @include display(flex, center, center);
        height: 100%;
      }

      &.active {
        background-color: rgb(0, 131, 143) !important;
        color: #fff;
        border-color: rgb(0, 131, 143);
      }

      &:after {
        content: " ";
        position: relative;
        @include display(inline-flex);
        width: 40px;
        top: -26px;
        left: 27px;
        border: 1px solid #cccccc;
      }

      &:last-child {
        &:after {
          content: none;
        }
      }
    }
  }

  .spinner-border {
    height: 1rem !important;
    width: 1rem !important;
  }

  .captcha-container {
    position: relative;
    @include display(flex, center, flex-end);
    width: 100%;

    >.canvasDv {
      width: calc( 100% - 150px );
      border: 1px solid rgb(224, 224, 224);
      height: 30px;
      border-radius: 2px;

      >div {
        @include display(flex, center, space-between);

        #canv {
          width: 125px;
        }

        #reload_href {
          color: rgb(85, 85, 85);
          background-color: rgb(243, 243, 243);
          height: 28px;
          @include display(inline-flex, center);
          padding: 4px 10px;
        }
      }
    }
  }

  .left-side-panel {
    height: auto;
    display: block;
    padding: 20px 40px;
    border: 1px solid rgb(233, 233, 233);
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    width: 400px;
    min-height: 270px;
    z-index: 2;
    margin: 0px 0px 0px 20px;
    order: 2;

    >h2 {
      font-size: 24px;
      font-weight: 300;
      color: rgb(0, 131, 143);
      text-align: left;
      margin-bottom: 10px;
      margin-top: 40px;
    }

    .form-container {
      display: flex;
      width: 100%;
      margin-top: 25px;

      .form-fields {
        width: 100%;

        .k-form-field {
          @include display(table);
          width: 100%;
          border: 1px solid rgb(224, 224, 224);
          border-radius: 2px;

          .k-form-label,
          .k-form-field-wrap {
            @include display(table-cell);
            height: 36px;
          }

          .k-form-label {
            color: rgb(85, 85, 85);
            background-color: rgb(243, 243, 243);
            padding: 4px 10px;

            svg {
              font-size: 16px;
            }
          }

          .k-form-field-wrap {
            width: 100%;
            position: relative;

            .k-input {
              height: 100%;
              border: 0px;
            }

            .k-form-error {
              position: absolute;
              bottom: -17px;
            }
          }

          &.otpDv {
            border: 0px;
            display: block;

            .k-form-label {
              background: transparent;
              color: #000000;
              padding-left: 0px;
            }

            .k-radio-list {
              padding-left: 15px;
            }

            .k-radio-item {
              .k-radio {
                &:checked {
                  border-color: rgb(0, 131, 143);
                  background-color: rgb(0, 131, 143);

                  &:focus {
                    box-shadow: none;
                    outline: none;
                  }
                }
              }
            }

            .k-radio-label {
              color: rgb(85, 85, 85);
            }
          }
        }
      }

      .fpBtnGrp {
        width: 100%;
        margin: 25px 0px 10px 0px;

        .k-button {
          width: 50%;
          padding: 10px;
          border: 0px;
          border-radius: 0px;

          &.cancel-button {
            background-color: rgb(243, 243, 243);

            &:hover {
              border: 0px;
            }
          }

          &.reset-button {
            background-color: rgb(0, 131, 143);
            color: rgba(255, 255, 255, 1);
            font-size: 15px;
            font-weight: 400;
            padding: 2px 10px 2px 10px;
          }
        }
      }

    }
  }

  .right-side-panel {
    height: auto;
    display: block;
    text-align: left;
    padding: 10px;
    border: 0px;

    .text-container {
      font-size: 12px;

      span {
        &.boldTxt {
          color: rgb(0, 131, 143);
          font-weight: 600;
          font-size: 14px;
        }
      }

      .first-line {
        font-size: 14px;
        font-weight: 500;
        margin: 0 0 10px 0;
      }

      .password-attributes {
        padding: 10px 5px;
      }
    }
  }
}

@media screen and (max-width: 940px) {
  .forgot-password {
    flex-direction: column-reverse !important;

    .left-side-panel {
      margin: 0px 0px 20px 0px;
    }
  }
}