/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/

/* ---------------- React Date Time Picker - Shubham -----------------
https://github.com/YouCanBookMe/react-datetime*/

.rdt {
  position: relative;

  &Picker {
    display: none;
    position: absolute;
    width: 250px;
    padding: 4px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
    border: 1px solid #f9f9f9;

    .rdtTimeToggle {
      text-align: center;

      &:hover {
        background: #eeeeee;
        cursor: pointer;
      }
    }

    table {
      width: 100%;
      margin: 0;
    }

    td,
    th {
      text-align: center;
      height: 28px;
    }

    td {
      cursor: pointer;

      &.rdtDay:hover,
      &.rdtHour:hover,
      &.rdtMinute:hover,
      &.rdtSecond:hover {
        background: #eeeeee;
        cursor: pointer;
      }

      &.rdtOld,
      &.rdtNew {
        color: #999999;
      }

      &.rdtToday:before {
        content: '';
        display: inline-block;
        border-left: 7px solid transparent;
        border-bottom: 7px solid #428bca;
        border-top-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        bottom: 4px;
        right: 4px;
      }

      &.rdtActive {
        background-color: #428bca;
        color: #fff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);

        &:hover {
          background-color: #428bca;
          color: #fff;
          text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        }

        &.rdtToday:before {
          border-bottom-color: #fff;
        }
      }

      &.rdtDisabled,
      &.rdtDisabled:hover {
        background: none;
        color: #999999;
        cursor: not-allowed;
      }

      span {
        &.rdtOld {
          color: #999999;
        }

        &.rdtDisabled,
        &.rdtDisabled:hover {
          background: none;
          color: #999999;
          cursor: not-allowed;
        }
      }
    }

    th {
      border-bottom: 1px solid #f9f9f9;

      &.rdtSwitch {
        width: 100px;
      }

      &.rdtNext,
      &.rdtPrev {
        font-size: 21px;
        vertical-align: top;
      }

      &.rdtDisabled,
      &.rdtDisabled:hover {
        background: none;
        color: #999999;
        cursor: not-allowed;
      }
    }

    thead {
      tr {
        &:first-child th {
          cursor: pointer;

          &:hover {
            background: #eeeeee;
          }
        }
      }

      button {
        width: 100%;
        height: 100%;
      }
    }

    tfoot {
      border-top: 1px solid #f9f9f9;
    }

    button {
      border: none;
      background: none;
      cursor: pointer;


      &:hover {
        background-color: #eee;
      }
    }

    .dow {
      width: 14.2857%;
      border-bottom: none;
      cursor: default;
    }
  }

  &Counters {
    display: inline-block;

    &>div {
      float: left;
    }

  }

  &Counter {
    height: 100px;
    width: 40px;

    &Separator {
      line-height: 100px;
    }

    .rdtBtn {
      height: 40%;
      line-height: 40px;
      cursor: pointer;
      display: block;

      -webkit-touch-callout: none;
      /* iOS Safari */
      -webkit-user-select: none;
      /* Chrome/Safari/Opera */
      -khtml-user-select: none;
      /* Konqueror */
      -moz-user-select: none;
      /* Firefox */
      -ms-user-select: none;
      /* Internet Explorer/Edge */
      user-select: none;

      &:hover {
        background: #eee;
      }

    }

    .rdtCount {
      height: 20%;
      font-size: 1.2em;
    }
  }

  &Milli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px;

    input {
      width: 100%;
      font-size: 1.2em;
      margin-top: 37px;
    }
  }


  &Time td {
    cursor: default;
  }
}

.rdtOpen .rdtPicker {
  display: block;
}

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;

  &:hover {
    background: #eee;
  }
}