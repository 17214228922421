@import "../general/mixin";

/******* Consumer Global Search *******/

.gloSearchDiv {
  position: fixed;
  top: 9px;
  left: 215px;
  width: 445px;
  z-index: 1000;
  border-radius: 50px;

  .gloSearch {
    background-color: transparent;
    border-radius: 50px;
    display: table;
    width: 100%;

    .catList {
      display: table-cell;
      width: 25%;
      background-color: var(--secondaryColor);
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      position: relative;
      z-index: 100;

      .catBox {
        white-space: nowrap;
        text-align: center;

        a {
          padding: 10px 13px;
          display: block;
          font-size: 14px;
          color: var(--headerTxtColor-lighter);

          &:hover,
          &:focus {
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
          }
        }

        .dropdown-menu {
          width: 210px;
          overflow-y: auto;
          max-height: calc(100vh - 70px) !important;
          box-shadow: 0px 4px 9px -4px rgba(0, 0, 0, 0.8);
          border: none;
          top: 41px !important;
          transform: none !important;
          border-radius: 6px;

          a {
            color: rgba(#000000, 0.8);
            padding: 6px 10px;
            white-space: initial;
            border-bottom: 1px solid;
            border-bottom-color: rgba(#000000, 0.1);
            margin: 0px 10px;
            word-break: break-word;

            &:hover,
            &:focus {
              background-color: rgba(#000000, 0.04);
              border-radius: 0;
              box-shadow: none;
            }
          }
        }
      }

      a:focus {
        outline: none !important;
      }
    }

    .inpBox {
      display: table-cell;
      width: 70%;
      background-color: var(--headerBG-lightest);
      position: relative;
      border: 1px solid #e9e9e9;
      border-width: 1px 0px;

      &.inpBoxBorder {
        border-left: 35px solid;
        border-left-color: var(--secondaryColor);
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
      }

      input {
        height: 38px;
        padding: 0px 15px;
        line-height: normal;
        font-size: 14px;
        text-transform: capitalize;
        background-color: transparent;
        border: none;
        width: 100%;
        position: relative;
        top: -1px;

        &:focus {
          -webkit-box-shadow: inset 5px 0px 6px -4px #66afe9;
          box-shadow: inset 5px 0px 6px -4px #66afe9;
          outline: none;
        }

        &::placeholder {
          color: #888 !important;
        }
      }

      &.hasGap .react-autosuggest__suggestions-list {
        padding: 30px 0px 0px 0px !important;
        z-index: 1;
      }
    }

    .sericon {
      display: table-cell;
      width: 5%;
      background-color: var(--headerBG-lightest);
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      position: relative;
      border: 1px solid #e9e9e9;
      border-left-width: 0px;

      .dropdown-menu {
        border-radius: 6px;
        top: 10px !important;
        right: 0px !important;
        left: initial !important;
        word-break: break-word;
        width: 225px;
        min-width: 120px;
      }

      .dropdown-item {
        white-space: normal;
      }

      a {
        svg {
          font-size: 17px;
          color: #888;

          &:hover,
          &:focus {
            color: #333;
          }
        }

        .btn {
          font-size: 13px;
          color: #bfbfbf;
          border: none;
          box-shadow: none;
          background: transparent;
          background-color: transparent;
          float: none;
          width: 38px;
          height: 38px;
          padding: 4px 6px !important;
          display: block;
          border-top-right-radius: 50px !important;
          border-bottom-right-radius: 50px !important;

          &:hover,
          &:focus {
            color: #000000 !important;
          }

          &:focus {
            color: #853a00 !important;
            background-color: #f2f2f2;
          }
        }
      }

      .dropdown-toggle::after {
        border: none;
      }
    }

    .react-autosuggest__suggestions-list {
      padding: 0px !important;
      max-height: initial;
      overflow-y: initial;

      a {
        padding: 0px 20px 0px 5px;

        span {
          margin-right: 3px;
        }

        &:hover {
          color: #333;
        }
      }
    }
  }
}

/******* Global Search *******/

.wibsrch {
  position: fixed;
  top: 10px;
  left: 215px;
  z-index: 1000;
  width: 445px;

  .innerdv {
    position: relative;
    display: table;
    width: 100%;
    background-color: transparent;
    border-radius: 50px;

    .colSearInp {
      height: 36px;
      padding: 0px 15px;
      font-size: 14px;
      text-transform: capitalize;
      background-color: transparent;
      border: none;
      width: 82%;
      display: inline;
      line-height: normal;
      position: relative;
      top: 0px;

      &:focus {
        outline: 0 !important;
        box-shadow: none !important;
        background-color: transparent !important;
      }

      &::placeholder {
        color: #aaa !important;
        font-size: 13px;
      }
    }

    .faicn {
      font-size: 15px;
      color: #bfbfbf;
      padding: 8px 12px 4px 6px;
      display: table-cell;
      width: 5%;
      background-color: var(--headerBG-lightest);
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      border: 1px solid #e9e9e9;
      border-left-width: 0px;


      svg {
        stroke-width: 10;
      }

      &:focus,
      &:hover {
        outline: none;
        @include colorBlack;
      }

      &:focus {
        background-color: #f2f2f2;
      }
    }

    .catename {
      display: table-cell;
      width: 25%;
      background-color: var(--secondaryColor);
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      position: relative;
      z-index: 100;
      vertical-align: middle;

      .nav-item {
        text-align: center;
        white-space: pre-wrap;
        display: inline-block;
      }

      .dropdown {
        >a {
          padding: 9px 10px 12px;
          display: block;
          color: var(--headerTxtColor-lighter);
          font-size: 14px;

          &::after {
            font-size: 14px;
            position: relative;
            top: 1px;
            margin-left: 5px;
          }
        }

        .dropdown-menu {
          a {

            &:active,
            &:focus {
              color: var(--pageTxtColor);
              background-color: #e9ecef;
            }
          }

          &.open {
            >.dropdown-menu {
              border-top: none;
              top: 29px;
              min-width: initial;
            }
          }
        }
      }

      &:focus,
      a:focus,
      a:active {
        color: var(--headerTxtColor);
        outline: none !important;
        text-decoration: none;
      }
    }

    .innerIpt {
      position: relative;
      display: table-cell;
      width: 70%;
      background-color: var(--headerBG-lightest);
      border: 1px solid #e9e9e9;
      border-width: 1px 0px;
    }

    .serchmodu {
      position: absolute;
      box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
      min-width: 80px;
      text-transform: capitalize;
      z-index: 995;

      .list-group-item {
        cursor: pointer;
        outline: 0px solid;
        outline-offset: 3px;

        &:hover,
        &:focus {
          color: #555;
          text-decoration: none;
          background-color: #f5f5f5;
        }
      }
    }

    .serchmoduVal {
      position: relative;
      font-size: 13px;
      color: #666 !important;
      cursor: pointer;
      margin-left: 5px;
      box-shadow: none;
      border: none;
      background-color: transparent !important;
      padding: 0px 0px 0px 8px;
      display: inline;
      width: 30px;

      &::before {
        content: "/";
        color: #444;
        @include FontAwesomeFamily;
        font-size: 14px;
        position: absolute;
        top: 0px;
        left: 0px;
      }

      &:hover::before,
      &:focus::before {
        content: "x";
        color: #666;
        font-size: 14px;
        font-weight: 500;
        position: absolute;
        top: -2px;
        left: -2px;
      }
    }
  }
}

.pindCateBg {
  background-color: #525051;
  margin-bottom: 10px;
  height: 0px;

  .pindCateBox {
    @include displayNone; // Ensure this mixin is defined elsewhere

    .pdLi {
      background-color: transparent;
      border: none;
      @include displayInlineBlock; // Ensure this mixin is defined elsewhere
      padding: 0px 20px;

      &:first-child {
        padding-left: 0px;
      }

      a {
        color: #d8d8d8;

        &:hover,
        &:focus {
          color: #ffffff;
        }
      }
    }
  }

  .hamiconLine {
    @include displayNone; // Ensure this mixin is defined elsewhere
  }

  .allCateBtn {
    color: #d8d8d8;
    font-size: 14px;
    padding: 0px 0px 3px;
    margin: 0px;
    border: none;
    background-color: transparent;

    .fa {
      @include displayInlineBlock; // Ensure this mixin is defined elsewhere
      width: 20px;
      position: relative;
      top: 2px;
    }
  }
}

.categoryListingTab {
  border: 1px solid #e1e1e1;

  .card {
    clear: both;
    border-bottom: 1px solid #e1e1e1;
    padding: 6px 10px;
    margin-top: 5px;
    display: table;
    width: 100%;

    &.active,
    &:hover {
      @include bgWhite;
    }

    .myView {
      display: none;

      &.active {
        font-size: 13px;
        display: block;
        position: absolute;
        right: 0;
        z-index: 100;
      }
    }

    a {
      i {
        font-size: 18px;
        margin-right: 5px;
      }
    }

    .card-left,
    .card-right,
    .card-body {
      display: table-cell;
      vertical-align: top;
    }

    .card-body {
      padding: 0 0 0 10px;
      width: 10000px;
      overflow: hidden;
      zoom: 1;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  p {
    color: #4f4e53;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin: 0 0 5px 0;
  }

  .card-header {
    color: #007b87;
    font-size: 19px;
    font-weight: 400;
    line-height: 20px;
    margin: 2px 0;
    text-transform: capitalize;
    padding: initial;
    background-color: transparent;
    border-bottom: none;

    a {
      margin-right: 5px;
      word-break: break-word;
    }
  }

  .Listprice {
    color: #121116;
    font-size: 16px;
    font-weight: 400;
    margin: 0 0 5px 0;
  }

  .starRtn {
    color: #464749;
    font-size: 14px;
    font-weight: 400;
    margin: 0;

    a {
      color: #bf780e;
      font-size: 14px;
      font-weight: 600;
      text-decoration: none;

      &.active {
        @include colorBlack;
        font-weight: 600;
      }

      &:hover {
        @include colorBlack;
      }
    }

    .icons {
      i {
        font-size: 13px;
      }
    }
  }

  .myView {
    a {
      margin-left: 10px;
    }
  }

  .starRtn1 {
    a {
      color: var(--primaryColor);
      margin-right: 15px;

      &.active {
        @include colorBlack;
        font-weight: 600;
      }

      &:hover {
        @include colorBlack;
      }
    }
  }

  .vDet {
    text-align: left;
    font-size: 11px;
    color: #555;

    a {
      display: inline-block;

      i {
        float: right;
        margin: 3px 0 0 4px;
        font-size: 12px;
      }
    }
  }

  &.gridTab {
    a img {
      border: 1px solid #ccc;
      height: 145px;
      display: inline-block;
    }

    .card {

      &.active,
      &:hover {
        background-color: transparent;
      }

      .starRtn {
        display: block;
      }

      .card-header {
        color: #c98400;
        text-align: center;
        margin: 0;
      }

      padding: 0 10px 10px;
      border-bottom: none;
    }

    .myMedia {
      min-height: 360px;
      border-bottom: 1px #e1e1e1 solid;

      &.active,
      &:hover {
        background-color: #eaeaea;
        cursor: pointer;
      }
    }

    .imgCon {
      text-align: left;
      padding: 10px 10px 0;

      img {
        border: 1px solid #d4d4d6;
      }
    }

    .starRtn {
      a {
        font-size: 11px;
        display: inline-block;

        i {
          float: right;
          margin: 3px 0 0 4px;
        }
      }

      .icons {
        i {
          font-size: 11px;
        }
      }
    }

    .vDet {
      text-align: center;
    }
  }

  &.cateList {
    border: none;

    .card {
      border: none !important;
      padding: 5px;

      +.card {
        margin-top: 15px;
      }

      &:nth-child(odd) {
        background-color: #f7f7f7;
      }
    }

    .imgSec {
      border-radius: 50px;
      border: 1px solid var(--primaryColor);
      width: 45px;
      height: 45px;
      display: inline-block;
      padding: 10px 0;
      text-align: center;

      &:focus {
        outline-offset: 4px;
      }

      .proimg {
        height: 30px;
        width: 30px;
        max-width: initial;
        min-width: initial;
        min-height: auto;
        max-height: initial;
        position: relative;
        top: calc(50% - 14.5px);
        text-indent: -999px;
        overflow: hidden;
        border-radius: 0 !important;
      }
    }
  }
}

.starRtn {
  color: #464749;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 5px 0;

  a {
    color: #bf780e;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
  }
}

.gloSearchResult {
  background-color: #fdfefe;
  width: 100%;
  position: absolute;
  top: 41px;
  left: 0;
  z-index: 10;
  padding: 10px 15px;
  box-shadow: 0px 4px 9px -4px rgba(0, 0, 0, 0.8);
  border-radius: 18px;
  text-align: left;

  .nav-tabs {
    >li {
      >a {
        font-size: 14px;
        font-weight: 600;
        padding: 10px 8px;
        margin-right: 15px;
        @include bgTransparent;
        border: none;

        &:hover,
        &:focus {
          outline-color: #000000;
          @include colorBlack;
          background-color: aliceblue;
          border-bottom-color: #000000;
        }
      }

      &.active {
        >a {
          font-weight: 600;
          color: var(--primaryColor);
          border: none;
          border-bottom: 1px solid var(--primaryColor);
          @include bgTransparent;
        }
      }
    }
  }

  .list-group {
    margin: 0;
    overflow-x: auto;
    max-height: calc(100vh - 135px) !important;

    >li {
      border: none;
      padding: 0;
    }

    a {

      &:hover,
      &:focus {
        background-color: #f1f0f0;
        text-decoration: underline;
        outline: none;
      }

      .codeNu {
        font-size: 12px;
        color: #336699;
        font-weight: 400;
      }

      .byTxt {
        font-size: 12px;
        color: #9e9e9e;
      }
    }

    >a.moreLnk {
      font-weight: 600;
      font-size: 12px;
      text-align: center;
      border-bottom: none;
      border-top: 1px #d2d2d2 solid;
      padding: 8px 10px;
      display: block;
    }

    >li>a,
    >a {
      font-size: 14px;
      font-weight: 500;
      color: #404040;
      display: block;
      padding: 6px 0px;
      margin: 0px;
      border: none;
      border-bottom: 1px #d2d2d2 solid;
      @include bgTransparent;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: none !important;
      }

      .suggCat,
      .suggsum {
        font-size: 13px;
        font-weight: 400;
        @include ovrFlowContent;
      }

      .suggCat {
        color: #888;
        text-transform: uppercase;
        font-size: 12px;
      }

      .suggAnk {
        font-size: 15px;
        color: #404040;
        text-transform: capitalize;
      }

      .suggsum {
        color: #888;
      }
    }
  }

  .myRequest-lg-item {
    cursor: default;
  }
}

.dataShow {
  height: 55px;

  .pagination {
    margin-top: 22px;
  }

  &.serviceBoard {
    height: 40px;
    margin-left: 5px;

    .pagination {
      margin-top: 0px;
      margin-left: 10px;
    }
  }
}