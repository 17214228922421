@import "../general/mixin";

.logout {
    bottom: 20px;
    position: fixed;
    right: 9px;
    text-align: center;

    a {
        color: #686868;

        span {
            display: block;
            background-color: #d2d2d2;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
            border-radius: 50%;
            height: 34px;
            width: 34px;

            i {
                font-size: 25px;
                font-weight: 400;
                margin: 5px 0 0 0;
            }

        }
    }
}

#footerDiv {
    font-size: 13px;
    padding: 10px;
    margin: 30px 0px 10px;
    text-align: center;
    border: none;

    a {
        margin: 0px 5px;
        @include displayInlineBlock;
    }
}

.xsmLogout {
    text-align: center;
    margin: 40px 0px 20px;
    min-height: calc(100vh - 230px);
    a {
        color: var(--primaryColor);
    }
}