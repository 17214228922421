@import "../general/mixin";

.homeservices {
  clear: both;
  position: relative;

  >.filterBox {
    position: absolute;
    top: 9px;
    right: 0px;
    z-index: 1;

    .pageviews {
      display: inline-block;

      .btn {
        color: var(--primaryColor);
        font-size: 22px;
        //cursor: default !important;
        padding: 2px 4px;
        margin: 0px;
        @include bgWhite;
        box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 25%);
        line-height: normal;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        svg {
          stroke-width: 2;
        }

        &:hover,
        &.active:focus {
          box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 15%);
          color: #b5b5b5;
          outline-color: #000000;
        }
      }
    }
  }

  .tabouter-border {
    border-bottom: none;
    border-left: none;
    border-right: none;
    padding: 20px 0 10px 0;
  }

  .hTabhed {
    border-bottom: 1px solid rgba(208, 208, 208, 0.6);
    margin-bottom: 20px;

    h3 {
      font-size: 19px;
      color: #707070;
      font-weight: 600;

      &.tabActive {
        color: var(--primaryColor);
        border-bottom: 1px solid;
        border-bottom-color: var(--primaryColor);
        display: inline-block;
        padding: 10px 0px !important;
        margin: 0px 0px !important;
        min-height: 40px;
      }
    }
  }

  #brand,
  #bestSell,
  #bestFeat,
  #bestCom,
  #bestFree,
  #bestFav,
  #bestCate {
    @include margin-padding-0;

    #pro-info {
      @include displayNone;
    }

    .shortinfo {
      display: block;
      padding: 0px;
    }
  }
}

#offeringsView {
  display: flex;
  justify-content: flex-start;
  flex-flow: wrap;

  .prodSlideBox {
    margin-bottom: 25px !important;
    width: 23.2%;
  }
}

.prodSlideBox {
  width: 18.5%;
  @include bgWhite;
  border: 1px #e7e7e7 solid;
  border-bottom: none;
  display: inline-block;
  margin: 4px 7px;
  vertical-align: top;
  text-align: left;
  position: relative;

  &:hover,
  &:focus {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  }

  >.gap {
    padding: 10px;
    height: calc(100% - 40px);

    a {
      font-weight: 500;
    }

    >.head {
      display: table;
      width: 100%;
      height: 45px;

      >.headImg {
        width: 20%;
        display: table-cell;
        text-align: center;
        vertical-align: middle;

        a {
          border-radius: 50px;
          border: 1px solid;
          border-color: #d8d8d8;
          width: 45px;
          height: 45px;
          display: inline-block;
          padding: 0px;
        }

        img,
        svg.icon {
          height: 30px;
          width: 30px;
          min-height: initial;
          max-height: initial;
          min-width: initial;
          max-width: initial;
          position: relative;
          top: calc(50% - 14.5px);
          text-indent: -999px;
          overflow: hidden;
          border-radius: 0px !important;
        }
      }

      >.headTxt {
        font-size: 15px;
        color: #707070;
        font-weight: 500;
        line-height: 18px;
        display: table-cell;
        width: 80%;
        vertical-align: middle;
        padding: 0px;

        a {
          font-size: 14px;
          margin-left: 10px;
          font-weight: 600;
          line-height: 16px;
          word-break: break-word;
          display: inline-block;
        }
      }

      >.headIcon {
        font-size: 14px;
        color: #d8d8d8;
        margin: 0px 5px;
        display: table-cell;
        width: 18%;
        vertical-align: top;
        padding-top: 10px;

        .active {
          color: var(--primaryColor);
        }

        >a {
          padding: 0px 3px;

          &:focus {
            outline-color: #000000;
            opacity: 1;
          }
        }
      }
    }

    >.proStar {
      font-size: 11px;
      display: inline-block;

      span[data-forhalf="★"] {
        font-size: 14px !important;
      }
    }

    >.proPricBill {
      font-size: 11px;
      color: #585858;
      display: block;
      clear: both;
      min-height: 18px;
      font-weight: 300;
    }

    >.txtDec {
      font-size: 12px;
      color: #757575;
      line-height: 16px;
      margin: 2px 0px 4px;
      word-break: break-word;
      min-height: 32px;
    }

    .headIcon {
      float: right;
      margin-top: 0px;

      >a {
        padding: 0px 3px;
        color: #d4d4d4;
        margin-left: 5px;
        display: inline-block;
        font-size: 18px;

        &:first-child {
          @include displayVisibilityNone;
        }

        &.active {
          color: var(--secondaryColor);
        }

        &:focus {
          outline-color: #000000;
          opacity: 1;
        }
      }
    }
  }

  >.footBtn {
    display: table;
    width: 100%;
    border: 1px #e7e7e7 solid;
    // margin-top: 0px;
    // position: absolute;
    // bottom: 1px;

    >a,
    >.disSpan {
      display: table-cell;
      width: 50%;
      padding: 5px;
      text-align: center;
      vertical-align: middle;
      font-size: 13px;
      font-weight: 500;
      line-height: 14px;
      height: 40px;
    }

    .disSpan {
      color: #747474;
      background-color: #fbfbfb;
      pointer-events: none;
      cursor: default;
      text-decoration: none;
    }

    >.cartBtn {
      @include colorBlack;
      background-color: rgba(#000000, 0.1);

      &:hover,
      &:focus {
        background-color: rgba(#000000, 0.2);
        outline-offset: -3px;
      }
    }

    >.orderBtn {
      background-color: var(--primaryColor);
      color: #ffffff;

      &:hover,
      &:focus {
        @include colorBlack;
        background-color: rgba(#000000, 0.2);
        outline-offset: -3px;
      }
    }
  }
}

.newsBoxDv {
  position: relative;
  z-index: 2;
  margin-top: -10px;

  &.show {
    height: 35px;
    margin: 20px 10px 0px 10px;
  }

  >div {
    position: absolute;
    background-color: #eeeeee;
    top: 0px;
    width: 100%;

    &.open {
      box-shadow: 0px 6px 8px -10px #000000;
    }
  }

  .isPause {
    font-size: 13px;
    position: absolute;
    top: 7px;
    right: 32px;
    opacity: 0.5;
    text-shadow: none;
    padding: 0px 4px;

    &:hover,
    &:focus {
      color: #ffffff !important;
      opacity: 1;
      background-color: #9f9f9f;
      outline-color: #000000 !important;
      outline-offset: 3px;
    }
  }

  .buCount {
    font-size: 13px;
    position: absolute;
    top: 7px;
    right: 82px;
  }

  .open .newsBox .hed,
  .open .newsBox .hed a {
    font-weight: 600;
  }
}

.newsBox {
  color: #ffffff;
  margin: 0px;

  .hed {
    padding: 6px 110px 6px 0px;

    &,
    a {
      font-size: 13px;
      @include colorBlack;
      font-weight: 400;
    }

    a:hover,
    a:focus {
      color: #909090;
    }

    a:focus {
      color: var(--primaryColor);
      outline-color: #000000;
      font-weight: 600;
      outline-offset: 2px;
      @include bgWhite;
    }
  }

  .carousel-inner>.active,
  .carousel-inner>.carousel-item {
    text-align: left !important;
  }

  .txt {
    color: #b3b3b3;
    margin: 5px 0px 0px;
    line-height: 18px;
    text-align: justify;
  }

  .info,
  .info span,
  .info p {
    color: #000000 !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    padding: 0px 0px 2px !important;
    margin: 0px !important;
    word-break: break-word;
  }

  .info {
    margin-top: 0px;
    padding: 5px 0px 10px !important;
    border-top: 1px #c3c3c3 solid;

    a,
    li a {
      display: inline-block;
      color: #76a9ff !important;
    }

    table {
      width: 100% !important;
      height: auto !important;
      border: 1px #777777 solid !important;
      margin: 0px 0px 10px 0px !important;

      td,
      th {
        color: #b3b3b3 !important;
        font-size: 13px !important;
        word-break: break-word;
        border: 1px #777777 solid !important;
        padding: 0px;
        margin: 0px;
        vertical-align: top;
      }
    }

    h3 {
      color: #b3b3b3 !important;
      font-size: 13px !important;
      word-break: break-word;
      padding: 0px !important;
      margin: 0px !important;
      border-bottom: none !important;
    }

    ul,
    ol {
      margin-left: 10px;

      ul {
        list-style-type: disc;
        padding: 10px 0px 10px 14px;
      }

      ol {
        list-style-type: decimal;
        padding: 10px 0px 10px 10px;
      }

      li {
        margin-left: 0px !important;
        list-style-type: inherit !important;
      }
    }

    img {
      width: 100% !important;
      height: 20px !important;
    }
  }

  .carousel-item {
    .itmmore {
      font-size: 12px;
      @include colorBlack;
      position: absolute;
      right: 120px;
      z-index: 10;
      top: 7px;
      background-color: transparent;
      padding: 0px 10px;
      opacity: 0.5;
      border-right: 1px #808080 solid;

      &.oneItm {
        right: 0px;
        border-right: none;
      }

      &:hover,
      &:focus {
        color: #ffffff;
        background-color: #9f9f9f;
        opacity: 1;
        outline-color: #000000;
        outline-offset: 4px;
      }
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    margin: 0px;
    width: 20px;
    height: 20px;
    top: 6px;
    background: transparent;
    text-shadow: none;

    &.prev {
      top: 7px;
      right: 42px;
      left: initial;
      opacity: 1;
    }

    &.next {
      top: 7px;
      right: 0px;
      opacity: 1;
    }
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    -webkit-filter: invert(1) grayscale(100);
    filter: invert(1) grayscale(100);
  }

  .scrollarea {
    .scrollbar-container.vertical {
      background: #9a9a9a;

      .scrollbar {
        background: #ffffff;
      }
    }
  }
}

.sxhometabact {
  &.sxLiView {
    clear: both;
    position: relative;
    margin-top: 10px;

    .tabouter-border {
      border-bottom: none;
      border-left: none;
      border-right: none;
      padding: 20px 0 10px 0;
    }
  }

  ul.nav-tabs {
    border-bottom: 1px #d0d0d0 solid !important;

    >li {
      width: auto;
      text-align: center;
      margin: 0px !important;
      padding: 11px 0px;

      &:first-child>a,
      &.sxLiView>li:first-child>a {
        padding-left: 0px !important;
      }

      >a {
        font-size: 19px !important;
        color: #707070;
        border: none;
        font-weight: 600;
        background-color: transparent;
        padding: 10px;

        &.active {
          color: var(--primaryColor);
          border: none;
          border-bottom: 1px solid;
          border-bottom-color: var(--primaryColor);

          &:focus {
            outline: thin dotted;
            outline-color: #000000 !important;
            outline-offset: -1px;
            padding-left: 10px !important;
          }
        }
      }
    }
  }

  .catNaSld {
    ul.nav-tabs {
      >li:first-child.active>a:focus {
        border-left: 1px dotted;
      }

      >li.active>a:focus {
        border-top: 1px dotted;
      }
    }
  }

  .col-md-12 {
    >.filterBox {
      position: absolute;
      top: 5px;
      right: 10px;
      z-index: 1;
    }
  }

  .customScroll {
    border-bottom: 1px #ddd solid;

    >div:first-child {
      position: initial !important;
      overflow-x: scroll;
      padding-bottom: 0px;
    }

    >div:nth-child(2) {
      height: 16px !important;

      >div {
        top: 15px;
        height: 3px !important;
      }
    }

    ul {
      width: 100%;

      &.nav-tabs {
        border-bottom: none !important;
      }

      li,
      li a {
        display: table-cell;
        white-space: nowrap;
        float: initial;
      }

      li {
        padding: 0px !important;

        a {
          padding: 15px 10px !important;
        }
      }
    }
  }
}

#SxHomeCateTab {
  .customScroll {
    height: 50px !important;
  }
}

.catListSec .headIcon .gap>a:first-child,
#proShar {
  display: none;
  visibility: hidden;
}

.topAdvtimt {
  background-color: #f9f9f9;
  text-align: left;
  border-radius: 2px;
  overflow: hidden;

  .slick-dots {
    bottom: 0 !important;

    ul {
      @include displayInlineBlock;
      margin: 0 auto;
      background-color: transparent !important;
      padding: 0 20px;
    }

    li {
      width: initial;
      height: initial;
      margin: 0 5px;
      padding: 0 0 5px;

      &.slick-active button::before,
      button:hover::before {
        color: var(--secondaryColor) !important;
        font-size: 10px;
      }

      button:before {
        @include colorBlack;
        font-family: "slick", sans-serif;
        font-size: 10px;
        line-height: 20px;
        position: absolute;
        top: 3px !important;
        left: 0;
        width: 15px;
        height: 15px;
        content: "•";
        text-align: center;
        opacity: 1;
      }
    }

    .fa.fa-pause-circle:before {
      content: "\f04c";
    }

    .adbtn,
    button {
      @include bgTransparent;
      border: none;
      box-shadow: none;
      font-size: 12px;
      padding: 0;
      margin: 0;
      @include displayInlineBlock;
      width: 15px;
      height: 15px;

      &.adbtn {
        margin: 0 6px;
        position: relative;
        top: 0;

        &:focus,
        &:hover {
          color: var(--secondaryColor);
        }
      }
    }
  }

  .picture {
    text-align: left;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 120px;
  }

  .slick-initialized {
    .slick-slide {
      div {
        height: 120px;
      }

      a {
        outline: none;
      }
    }
  }

  .slick-prev {
    left: 0;

    &:before {
      content: "\f104";
    }
  }

  .slick-next {
    right: 0;

    &:before {
      content: "\f105";
    }
  }

  .slick-arrow {
    z-index: 1;
    width: 55px;
    height: 65px;
    color: transparent !important;
    background: rgba(0, 0, 0, 0.2);

    &:before,
    &:focus {
      color: rgba(255, 255, 255, 0.9);
      @include FontAwesomeFamily;
      font-size: 50px;
      opacity: 0.4;
    }

    &:hover {
      opacity: 1;
    }
  }
}

.tabouter-border {
  border-left: 1px #ccc solid;
  border-right: 1px #ccc solid;
  border-bottom: 1px #ccc solid;
  padding: 15px;
}

.tabFormDv {
  label {
    @include ovrFlowContent;
  }
}

#appendDots {
  li {
    position: relative;
    @include displayInlineBlock;
    width: 10px !important;
    height: 10px !important;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;

    button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 10px !important;
      height: 10px !important;
      padding: 2px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;

      &:before {
        font-family: "slick", sans-serif;
        font-size: 10px !important;
        line-height: 20px;
        position: absolute;
        top: 3px !important;
        left: 0;
        width: 10px;
        height: 10px;
        content: "•";
        text-align: center;
        opacity: 0.25;
        @include colorBlack;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    &.slick-active button:before {
      opacity: 0.75;
      color: var(--secondaryColor) !important;
      font-size: 10px !important;
    }
  }

  .btn {
    border: none;
    border-radius: 0;
    color: inherit;
    font-size: 18px !important;
    margin-top: 0;
    position: relative !important;
    top: 10px !important;
  }

  .fa.fa-pause-circle:before {
    content: "\f04c";
    font-size: 18px;
  }
}