@import "../general/mixin";

.colSearDv {
    position: relative;
    margin-bottom: 3px;

    .customMultiSelect {
        &__indicator {
            padding: 4px !important;
            @include widthHeight(auto !important, auto !important);

            svg {
                width: 11px !important;
                stroke-width: 0.6;
            }
        }
    }

    &.colInputDv {
        position: initial !important;
    }

    .colSearInp,
    .form-control {
        font-size: 12px;
        font-weight: 300;
        color: #333;
        height: auto;
        padding: 2px 20px 2px 6px;

        &::placeholder {
            color: #666 !important;
            opacity: 1;
            font-weight: 400;
        }
    }

    .colSearInp {
        &.colSrchInpWidth {
            min-width: 150px;
        }
    }

    select {
        &.form-control {
            color: #bdbdbd;
            padding: 1px 6px 1px 6px;
            position: relative;
            top: 1px;
        }
    }

    .form-control[readonly] {
        @include bgWhite;
        cursor: pointer;
    }

    .faicn {
        position: absolute;
        top: 1px;
        right: 2px;
        font-size: 12px;
        color: #d8d8d8;
        @include bgWhite;
        padding: 1px 5px;
        z-index: 1;

        &:hover,
        &:focus,
        &:active {
            color: var(--primaryColor);
        }
    }

    &.hideCheckbox input[type="checkbox"] {
        @include displayNone;
    }

    &Gap {
        padding-bottom: 30px;
    }

    .multiSelectRef button>span {
        color: #666 !important;
        opacity: 1;
        font-weight: 400;
    }
}

.multiSelectRef>button,
.colSearDv button {
    font-size: 12px;
    font-weight: 400;
    color: #333;
    height: 23px;
    width: auto;
    min-width: 100%;
    padding: 2px 6px;
    @include bgWhite;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    line-height: 17px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);

    &:focus {
        border: 1px solid #66afe9;
        -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%),
            0 0 8px rgb(102 175 233 / 60%);
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
    }
}

.colSearStatus .multiSelectRef>button+div,
.colSearDv.colSearStatus button+div {
    right: 20px;
    left: initial;
}

.multiSelectRef>button .css-1gpjby2,
.colSearDv button .css-1gpjby2 {
    margin: 0;
    height: 17px;
}

.multiSelectRef>button .css-1gpjby2 svg,
.colSearDv button .css-1gpjby2 svg {
    width: 20px;
    height: 20px;
    position: relative;
    top: -1px;
}

.multiSelectRef>button+div,
.colSearDv button+div {
    box-shadow: none;
    border: 1px solid #c7c7c7;
    margin-top: 0 !important;
}

.multiSelectRef .css-1jllj6i-control,
.colSearDv .css-1jllj6i-control {
    min-height: 28px;
    @include displayNone;
}

.multiSelectRef .css-1wy0on6>div,
.colSearDv .css-1wy0on6>div {
    height: 20px;
    width: 24px;
}

.multiSelectRef .css-1wy0on6>div svg,
.colSearDv .css-1wy0on6>div svg {
    width: 16px;
}

.multiSelectRef .css-1vr111p-option,
.multiSelectRef .css-1qprcsu-option,
.colSearDv .css-1vr111p-option,
.colSearDvf .css-1qprcsu-option,
.colSearDv .css-1qprcsu-option {
    font-size: 12px;
    color: #333;
    font-weight: 400;
    //min-width: 120px;
    min-width: auto;
    padding: 4px 6px;
    @include bgTransparent;
    display: block;
}

.multiSelectRef .css-1vr111p-option input[type="checkbox"],
.multiSelectRef .css-1qprcsu-option input[type="checkbox"],
.colSearDv .css-1vr111p-option input[type="checkbox"],
.colSearDv .css-1qprcsu-option input[type="checkbox"] {
    position: relative;
    top: -2px;
}

.multiSelectRef .css-1qprcsu-option:active,
.colSearDv .css-1qprcsu-option:active {
    @include bgTransparent;
}

.multiSelectPopup {
    background-color: white;
    border-radius: 4px;
    margin-top: 8px;
    position: absolute;
    z-index: 3 !important;
    min-width: 100%;
    max-width: 200px;
    right: 0px;

    >div>div>div {
        max-height: 250px;
    }

}

.customReactSelectMenu {
    >div {
        @include displayNone;
    }

    .btnhed {
        display: block;
        font-size: 11px;
        margin: 0 6px;

        >div {
            padding: 2px 0 5px;
            position: relative;
            border-bottom: 1px #dcdcdc solid;
            display: inline-flex;
            width: 100%;
            align-items: center;

            >a {
                display: inline-block;
                max-width: 65px;
                @include ovrFlowContent;
            }
        }

        a {
            padding: 2px 0;
        }

        .okbtnmulti {
            position: absolute;
            right: 0;
            top: 2px;
            display: inline-flex;
            align-items: center;

            >a {
                &:first-child {
                    display: inline-block;
                    max-width: 35px;
                    @include ovrFlowContent;
                }
            }

            .clos {
                margin-left: 7px;
                font-size: 14px;
                display: inline-flex;

                i {
                    font-size: 12px;
                    position: relative;
                    top: 1px;
                    font-weight: 300;
                }
            }
        }
    }

    +.css-ik6y5r {
        box-shadow: none;
        min-width: 150px;
    }
}

.customReactSelectMenu .btnhed a:hover,
.colSearDv .btnhed a:focus {
    color: #222;
}

.rdtDiv {
    .rdtOpen {
        .rdtPicker {
            display: block;
            width: auto;

            td {
                padding: 4px 10px !important;
            }
        }
    }

    .colSearDv {
        margin-bottom: 0px !important;

        button {
            width: 100%;
            height: 34px;
            padding: 5px 12px 5px 16px;
            font-size: 14px;
            color: #555555;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-weight: 400;

            svg {
                width: 24px !important;
                height: 24px !important;
                position: relative;
                top: -4px !important;
                margin-right: -15px !important;
            }

            +div {
                width: 100%;
            }

            >span {
                white-space: initial;
            }
        }
    }
}

.customMultiSelect {
    &__control {
        height: 23px;
        border: 1px solid #cccccc !important;
        box-shadow: none !important;
        flex-wrap: initial !important;
        cursor: pointer !important;

        &--is-focused {
            border: 1px solid #cccccc !important;
            box-shadow: none !important;
        }
    }

    &__value-container {
        padding: 0px 0px 0px 4px !important;
    }

    &__menu {
        box-shadow: none;
        border: 1px solid #c7c7c7;
        margin: 0 !important;
        min-width: 150px;
    }

    &__menu-list {
        padding: 0px !important;

        >div {
            @include display(flex);

            &.btnhed {
                display: block;
                font-size: 11px;
                margin: 0;
                padding: 2px 6px;

                >div {
                    .btn {
                        color: var(--anchorColor) !important;
                        padding: 0px;
                        background: transparent !important;
                        font-size: 11px;
                    }
                }
            }
        }

        .customOption {
            padding: 0px 6px;

            .form-check {
                width: 100%;
                cursor: pointer;

                .form-check-label {
                    font-size: 12px;
                    color: #333333;
                    font-weight: 400;
                    width: 100%;
                    padding: 4px 0px;
                    cursor: pointer;
                }

                .form-check-input {
                    border-color: #767676;
                    width: 12px;
                    height: 12px;
                    border-radius: 2.5px;
                    margin-top: 0.5em;
                    cursor: pointer;

                    &:checked {
                        background-color: var(--primaryColor);
                        border-color: var(--primaryColor);
                    }
                }
            }
        }
    }

    &__placeholder,
    &__value-container {
        font-size: 12px;
    }

    &__placeholder,
    &__indicator svg,
    &__value-container {
        color: #666666 !important;
        font-weight: 400;
    }

    &__indicator {
        padding: 4px !important;

        svg {
            width: 13px;
        }
    }
}