@import "./mixin";

/************ Chat Bot ************/

.chatbotIcon {
    position: fixed;
    z-index: 1001;
    bottom: 25px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: var(--primaryColor);
    cursor: pointer;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);
    padding: 0 !important;
    margin: 0 !important;
    border: none;
    transition: all 1s ease;
    transform: scale(1);

    &:focus {
        outline-color: #000000;
        outline-offset: 4px;
        transform: scale(1.1);
    }

    .inner {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 28px;
            height: 32px;

            path {
                fill: white;
            }
        }

        &.arrow {
            svg {
                width: 22px;
                height: 22px;
                margin-top: 4px;
            }
        }
    }
}

.chatbotPopup {
    @include displayInlineBlock;
    position: fixed;
    bottom: 90px;
    right: 20px;
    width: 410px;
    height: 485px;
    z-index: 8888;
    background-size: cover;
    @include bgWhite;
    border-radius: 16px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
}

.lucyiFrame {
    border: none;
    border-radius: 5px;
}

/************ Carousel ************/

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.carousel-indicators {
    bottom: -14px;
    right: 0;
    width: initial;
    margin: 0;
    top: initial;
    @include bgTransparent;
    border-bottom: none;
    left: initial;
    align-items: center;

    li,
    button {
        margin: 0;
        width: 10px !important;
        height: 10px !important;
        border-radius: 50%;
        background-color: #c5c5c5;
        border: none !important;

        &.active {
            background-color: #2e8cbe;
        }
    }

    button:last-child {
        margin-right: 0;
    }

    [data-bs-target] {
        border: 1px solid #2e8cbe !important;
        margin: auto 4px;
    }
}

.carousel-control-next,
.carousel-control-prev {
    width: 20px;
    height: 20px;
    opacity: 1;
    background-color: rgb(0 0 0 / 5%);
    bottom: -25px;

    &:hover {
        background-color: rgb(0 0 0 / 20%);
        outline-color: #000000 !important;
    }
}

.carousel-control-prev {
    right: 42px;
    left: initial;
    top: initial;
    bottom: -25px;
}

.carousel-control-next {
    top: initial;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    width: 12px;
    height: 12px;
    -webkit-filter: invert(1) grayscale(100);
    filter: invert(1) grayscale(100);
    opacity: 1;
}

.home .homeservices .carousel .carousel-item {
    display: flex;
    justify-content: flex-start;
    flex-flow: wrap;
}

/************ Swal ************/

.swal-footer {
    text-align: center !important;
}

.swal-title {
    font-size: 16px;
}

.swal2-title,
.swal2-html-container {
    font-size: 17px !important;
    margin: 30px 0 20px !important;
}

.swal2-icon {
    margin: 2.5em auto 0.6em;
}

.swal2-styled {
    border-radius: 0;
    padding: 10px 24px !important;
    font-size: 14px;
    font-weight: 500;
    box-shadow: none;
    color: #000000 !important;
    background: rgba(#000000, 0.1) !important;
    background-color: rgba(#000000, 0.1) !important;
    border: none !important;

    &.swal2-confirm {
        color: #ffffff !important;
        background-color: var(--primaryColor) !important;
    }

    &:focus {
        box-shadow: none !important;
    }
}

.swal2-actions {
    margin-bottom: 10px;
}

.swal-modal {
    width: 400px;
}

.swal-button,
.swal-button:hover {
    background-color: var(--primaryColor) !important;
}

div:where(.swal2-container) h2:where(.swal2-title) {
    font-weight: 400 !important;
}

.themePop {
    .themePopdv {
        .swal2-image {
            margin: 0;
        }
    }
}

/************ Back to Top ************/

#backtotop {
    font-size: 12px;
    color: var(--primaryColor);
    @include bgWhite;
    font-weight: 600;
    position: fixed;
    left: calc(50% - 45px);
    top: 65px;
    padding: 4px 12px 6px;
    z-index: 100;
    border: none;
    border-radius: 4px;
    box-shadow: 0px 1px 6px -1px rgb(0 0 0 / 30%);

    &:hover,
    &:focus {
        outline-offset: 2px;
        @include colorBlack;
    }

    i {
        font-size: 18px;
        position: relative;
        top: 1px;
        margin-right: 3px;
    }
}

/************ Button ************/

.floatBtn {
    position: fixed;
    top: 40%;
    right: -33px;
    z-index: 1;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);

    li {
        display: initial;
        padding: 0;
        @include bgTransparent;
        border: none;
        list-style-type: none;

        a {
            font-size: 13px;
            color: #ffffff;
            font-weight: 500;
            padding: 0 10px 1px 6px;
            @include displayInlineBlock;
            text-align: center;
            border-radius: 2px;

            i {
                margin-right: 0;
                font-size: 9px;
                position: relative;
                top: -1px;
            }

            &:hover,
            &:focus {
                color: #ffffff;
            }
        }

        &.feed {
            background-color: #bcc147;
        }

        &.feed1 {
            background-color: #53bff9;
        }

        &.noti {
            background-color: #f8931d;
        }

        &.survey {
            background-color: #3caea3;
        }
    }
}

.toolbarBtnStyle {
    .btn {
        font-size: 13px;
        color: #000000 !important;
        background: rgba(#000000, 0.1) !important;
        background-color: rgba(#000000, 0.1) !important;
        border: none !important;
        font-weight: 500;
        box-shadow: none;
        padding: 8px 14px !important;
        float: initial;
        border-radius: 2px;

        &:hover,
        &:focus {
            color: #000000 !important;
            background-color: rgba(#000000, 0.15) !important;
            text-decoration: none;
        }
    }

    .rgSidrkBtn,
    .rgSiLigBtn {
        padding: 10px 16px !important;

        &.ctBrdUploadBtn {
            padding: 5px 10px !important;
        }
    }

    .rgSidrkBtn {
        color: #ffffff !important;
        background-color: var(--primaryColor) !important;
    }

    a {
        @include displayInlineBlock;
    }

    .btn-xs {
        font-size: 11px !important;
        padding: 3px 6px !important;
    }
}

.stickyArea {
    .dropFileBtn {
        button {
            float: right;
            position: relative;
            right: 0px !important;
            top: calc(50% - 11px);
            font-size: 13px;
            border: 1px #cccccc solid;

            &:hover,
            &:focus {
                outline-offset: 4px;
                @include colorBlack;
                background-color: lightcyan;
            }
        }
    }
}

.exportBtnDv {
    display: inline-flex;
    vertical-align: top;
    margin-left: 10px;

    button {
        font-size: 15px;
        color: #555;
        padding: 2px 6px;
        outline-offset: -1px;
        border-radius: 3px !important;
        border: 1px #a8a8a8 solid;
        background-color: #fff;

        &:hover,
        &:active,
        &:focus {
            outline-offset: -1px !important;
            background-color: #efefef;
        }

        span {
            @include displayInlineBlock;
            padding: 0;
            margin: 0;
            vertical-align: top;
            position: relative;
            top: -3px;
            font-size: 15px;
        }
    }

    .dropdown-menu {
        padding: 0;
        width: initial;
        min-width: 100px;
        z-index: 996;

        a {
            display: inline-flex;
            padding: 4px 10px;
            color: #333;
            font-size: 13px;

            svg {
                position: relative;
                top: 2px;
                margin-right: 5px;
            }
        }
    }
}

.copyTxtbtn {
    position: absolute;
    top: 4px;
    right: 4px;
    font-size: 15px;
    border: none !important;
    padding: 4px !important;
    line-height: 14px;
    border-radius: 2px;
    z-index: 1;

    &:hover,
    &:focus {
        @include bgTransparent;
    }
}

/************ Range Slider ************/

.input-range {
    height: 1rem;
    position: relative;
    width: 100%;

    &__slider {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: #FFD461;
        border: 1px solid #dab041;
        border-radius: 100%;
        cursor: pointer;
        display: block;
        height: 1rem;
        margin-left: -0.5rem;
        margin-top: -0.65rem;
        outline: none;
        position: absolute;
        top: 50%;
        width: 1rem;
        transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;

        &:active {
            -webkit-transform: scale(1.3);
            transform: scale(1.3);
        }

        &:focus {
            box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
        }

        &-container {
            transition: left 0.3s ease-out;
        }
    }

    &--disabled {
        .input-range__slider {
            background: #cccccc;
            border: 1px solid #cccccc;
            box-shadow: none;
            -webkit-transform: none;
            transform: none;
        }
    }

    &__label {
        color: #fff;
        font-size: 0.7rem;
        transform: translateZ(0);
        white-space: nowrap;

        &--min,
        &--max {
            bottom: -0.5rem;
            position: absolute;
        }

        &--min {
            left: 0;
        }

        &--max {
            right: 0;

            .input-range__label-container {
                left: 0%;
            }
        }

        &--value {
            position: absolute;
            top: -1.8rem;
            background: #bf8b00;
            padding: 2px 6px;
            border-radius: 5px;
            margin-left: 0;
        }

        &-container {
            left: 0%;
            position: relative;
        }
    }

    &__track {
        background: #eeeeee;
        border-radius: 0.3rem;
        cursor: pointer;
        display: block;
        height: 0.3rem;
        position: relative;
        transition: left 0.3s ease-out, width 0.3s ease-out;

        &--background {
            left: 0;
            margin-top: -0.15rem;
            position: absolute;
            right: 0;
            top: 50%;
        }

        &--active {
            background: #ffdc7d;
        }
    }

    &--disabled {
        .input-range__track {
            background: #eeeeee;
        }
    }
}

/************ Time Picker ************/

.rc-time-picker {
    box-sizing: border-box;
    display: block;
    position: relative;

    &:after {
        content: "\f017";
        color: #666;
        font-family: 'FontAwesome', sans-serif;
        font-weight: normal;
        font-size: 18px;
        position: absolute;
        top: 8px;
        right: 10px;
    }

    * {
        box-sizing: border-box;
    }

    &-input {
        width: 100%;
        position: relative;
        display: inline-block;
        padding: 6px 12px;
        height: 34px;
        cursor: text;
        font-size: 12px;
        line-height: 1.5;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
            background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
            box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

        &[disabled] {
            color: #ccc;
            background: #f7f7f7;
            cursor: not-allowed;
        }
    }

    &-panel {
        z-index: 1070;
        width: 225px;
        position: absolute;

        * {
            box-sizing: border-box;
        }

        &-inner {
            display: inline-block;
            position: relative;
            outline: none;
            list-style: none;
            font-size: 12px;
            text-align: left;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 0 1px 5px #ccc;
            background-clip: padding-box;
            border: 1px solid #ccc;
            line-height: 1.5;
        }

        &-narrow {
            max-width: 113px;
        }

        &-input {
            margin: 0;
            padding: 0;
            width: 100%;
            cursor: auto;
            line-height: 1.5;
            outline: 0;
            border: 1px solid transparent;
        }

        &-input-wrap {
            box-sizing: border-box;
            position: relative;
            padding: 6px;
            border-bottom: 1px solid #e9e9e9;
        }

        &-input-invalid {
            border-color: red;
        }

        &-clear-btn {
            position: absolute;
            right: 6px;
            cursor: pointer;
            overflow: hidden;
            width: 20px;
            height: 20px;
            text-align: center;
            line-height: 20px;
            top: 6px;
            margin: 0;

            &:after {
                content: "x";
                font-size: 12px;
                color: #aaa;
                display: inline-block;
                line-height: 1;
                width: 20px;
                transition: color 0.3s ease;
            }

            &:hover:after {
                color: #666;
            }
        }

        &-select {
            float: left;
            font-size: 12px;
            border: 1px solid #e9e9e9;
            border-width: 0 1px;
            margin-left: -1px;
            box-sizing: border-box;
            width: 56px;
            max-height: 144px;
            overflow-y: auto;
            position: relative;

            &-active {
                overflow-y: auto;
            }

            &:first-child {
                border-left: 0;
                margin-left: 0;
            }

            &:last-child {
                border-right: 0;
            }

            ul {
                list-style: none;
                box-sizing: border-box;
                margin: 0;
                padding: 0;
                width: 100%;
            }

            li {
                list-style: none;
                box-sizing: content-box;
                margin: 0;
                padding: 0;
                width: 100%;
                height: 24px;
                line-height: 24px;
                text-align: center;
                cursor: pointer;
                user-select: none;

                &:hover {
                    background: #edfaff;
                }
            }
        }
    }
}

li {
    &.rc-time-picker-panel-select-option-selected {
        background: #f7f7f7;
        font-weight: bold;
    }

    &.rc-time-picker-panel-select-option-disabled {
        color: #ccc;

        &:hover {
            background: transparent;
            cursor: not-allowed;
        }
    }
}

/************ Disclaimer ************/

.editSelect {
    &:has(.disclaimerIcn) {
        .showSelect {
            width: calc(100% - 30px);
        }
    }
}

.disclaimerIcn {
    position: absolute;
    inset: 0px 10px auto auto;
    color: var(--anchorColor);
    font-size: 16px;
    cursor: pointer;
    width: auto;
    height: 100%;
}

.disclaimerPopup {
    z-index: 991;
    padding: 10px;
    span {
        font-weight: 600;
        color: var(--secondaryColor);
    }
}

.gtx-body{
    word-break: break-all;
}

/************ Simplebar ************/

[data-simplebar] {
    position: relative;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
  }
  
  .simplebar-wrapper {
    overflow: hidden;
    width: inherit;
    height: inherit;
    max-width: inherit;
    max-height: inherit;
  }
  
  .simplebar-mask {
    direction: inherit;
    position: absolute;
    overflow: hidden;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: auto !important;
    height: auto !important;
    z-index: 0;
  }
  
  .simplebar-offset {
    direction: inherit !important;
    box-sizing: inherit !important;
    resize: none !important;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
    -webkit-overflow-scrolling: touch;
  }
  
  .simplebar-content-wrapper {
    direction: inherit;
    box-sizing: border-box !important;
    position: relative;
    display: block;
    height: 100%;
    width: auto;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .simplebar-content-wrapper::-webkit-scrollbar, .simplebar-hide-scrollbar::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
  
  .simplebar-content:after, .simplebar-content:before {
    content: " ";
    display: table;
  }
  
  .simplebar-placeholder {
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    pointer-events: none;
  }
  
  .simplebar-height-auto-observer-wrapper {
    box-sizing: inherit !important;
    height: 100%;
    width: 100%;
    max-width: 1px;
    position: relative;
    float: left;
    max-height: 1px;
    overflow: hidden;
    z-index: -1;
    padding: 0;
    margin: 0;
    pointer-events: none;
    flex-grow: inherit;
    flex-shrink: 0;
    flex-basis: 0;
  }
  
  .simplebar-height-auto-observer {
    box-sizing: inherit;
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 1000%;
    width: 1000%;
    min-height: 1px;
    min-width: 1px;
    overflow: hidden;
    pointer-events: none;
    z-index: -1;
  }
  
  .simplebar-track {
    z-index: 1;
    position: absolute;
    right: 0;
    bottom: 0;
    pointer-events: none;
    overflow: hidden;
  }
  
  [data-simplebar].simplebar-dragging {
    pointer-events: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  [data-simplebar].simplebar-dragging .simplebar-content {
    pointer-events: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  [data-simplebar].simplebar-dragging .simplebar-track {
    pointer-events: all;
  }
  
  .simplebar-scrollbar {
    position: absolute;
    left: 0;
    right: 0;
    min-height: 10px;
  }
  
  .simplebar-scrollbar:before {
    position: absolute;
    content: "";
    background: #000;
    border-radius: 7px;
    left: 2px;
    right: 2px;
    opacity: 0;
    transition: opacity 0.2s 0.5s linear;
  }
  
  .simplebar-scrollbar.simplebar-visible:before {
    opacity: 0.5;
    transition-delay: 0s;
    transition-duration: 0s;
  }
  
  .simplebar-track.simplebar-vertical {
    top: 0;
    width: 11px;
  }
  
  .simplebar-scrollbar:before {
    top: 2px;
    bottom: 2px;
    left: 2px;
    right: 2px;
  }
  
  .simplebar-track.simplebar-horizontal {
    left: 0;
    height: 11px;
  }
  
  .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    right: auto;
    left: 0;
    top: 0;
    bottom: 0;
    min-height: 0;
    min-width: 10px;
    width: auto;
  }
  
  [data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
    right: auto;
    left: 0;
  }
  
  .simplebar-dummy-scrollbar-size {
    direction: rtl;
    position: fixed;
    opacity: 0;
    visibility: hidden;
    height: 500px;
    width: 500px;
    overflow-y: hidden;
    overflow-x: scroll;
    -ms-overflow-style: scrollbar !important;
  }
  
  .simplebar-dummy-scrollbar-size > div {
    width: 200%;
    height: 200%;
    margin: 10px 0;
  }
  
  .simplebar-hide-scrollbar {
    position: fixed;
    left: 0;
    visibility: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }