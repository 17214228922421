@import "../general/mixin";

/****** Edit Form Filter ******/

@keyframes spin-animation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.toprigLnk {
    margin-top: 5px;
    text-align: right;

    .thrlnebtn {
        @include displayInlineBlock;
        position: relative;

        .btnDropMenu {
            float: left;
            padding: 2px 8px;
            border: 1px solid transparent;
            border-radius: 2px;
            line-height: 22px;

            i {
                margin-left: 5px;
            }

            &.bgtran {
                background-color: transparent !important;
                color: #a1a1a1;
                border-color: #d1d1d1 !important;
                padding: 3px 0px;
                font-size: 20px;
                display: flex;
                align-items: center;

                i {
                    margin-left: 0px;
                }
            }

            &:hover,
            &:focus {
                color: #ffffff !important;
                background-color: var(--primaryColor) !important;
                border: 1px solid;
                border-color: var(--primaryColor);
                outline-offset: -3px;

                svg {
                    color: #ffffff;
                }
            }
        }

        .breakFixHamburger {
            top: 20px;

            &.statusHamburger {
                top: 30px;
            }

            .list-group-item {
                padding: 0;
                border: none;
            }

            .nav-item {
                cursor: pointer;
                width: 100%;
                padding: 4px 10px;
                display: inline-flex;
                align-items: flex-start;
                word-break: break-word;
                white-space: normal;

                &:hover,
                &:focus {
                    color: #333 !important;
                    background-color: #deebff !important;
                    border-radius: 4px;
                }

                i, svg { 
                    position: relative;
                    top: 2px;
                    margin-right: 5px;
                }
            }
        }
    }

    .icnlnk {
        margin: 0;
        padding: 0;
        @include displayInlineBlock;

        li {
            display: inline;
            float: left;
            padding: 0 5px;
            text-align: center;
            position: relative;

            &:last-child {
                padding: 0;
            }

            a,
            button {
                color: #393939;
                font-size: 17px;
                padding: 4px;
                font-weight: 400;
                text-align: center;
                display: block;
                border: 1px #d3d3d3 solid;
                border-radius: 4px;
                outline: none;

                &.myBt.plus,
                .anchbtn {
                    color: var(--primaryColor) !important;
                    border-color: var(--primaryColor);

                    svg {
                        color: var(--primaryColor);
                    }
                }

                &.active svg {
                    color: rgb(255, 255, 255);
                }

                &:hover,
                &:focus,
                &.active {
                    color: #ffffff !important;
                    background-color: var(--primaryColor) !important;
                    border: 1px solid;
                    border-color: var(--primaryColor);
                    outline-offset: -3px;

                    svg {
                        color: #ffffff;
                    }
                }

                &:focus {
                    opacity: 0.8;
                }

                span {
                    display: block;
                    border-radius: 5px;
                    background-color: transparent;
                    border: none;

                    i {
                        color: #ffffff;
                        font-size: 15px;
                        margin-top: 8px;
                    }
                }

                svg {
                    color: rgb(0, 0, 0, 60%);

                    &.icn-spinner {
                        animation: spin-animation 1.6s infinite;
                        animation-timing-function: linear;
                    }

                    &.hourGlsWidth {
                        stroke-width: 0.6;
                    }

                    &.svgRotate90 {
                        transform: rotate(90deg);
                    }

                    &.cmdbAttrPencil {
                        position: absolute;
                        font-size: 16px;
                        bottom: 7px;
                        right: 6px;
                        fill: rgb(255 255 255 / 40%);
                    }

                    &:hover,
                    &:focus,
                    .anchbtn:hover svg,
                    .anchbtn:focus svg {
                        color: #ffffff;
                    }
                }
            }

            .dropdown-menu {
                display: block;
                width: 100%;
                text-align: left;

                li {
                    padding: 0 6px;

                    >a {
                        border: 1px transparent solid;
                        text-align: left;
                        padding: 3px 5px;

                        &:active,
                        &.active {
                            background-color: transparent;
                        }

                        i {
                            margin-right: 5px;
                            @include displayInlineBlock;
                        }
                    }
                }
            }

            .watcher-count {
                position: absolute;
                color: rgba(0, 0, 0, 0.4); // Adjusted for SCSS syntax
                top: -2px;
                font-size: 10px;
                font-weight: 600;
                right: 7px;

                &.prblmCount {
                    top: -3px;
                    right: 6px;
                }
            }

            a {
                &:hover {
                    .watcher-count {
                        color: #ffffff;

                        &.prblmCount {
                            top: -8px;
                            right: -3px;
                        }
                    }
                }

                &.active {
                    .watcher-count {
                        color: #ffffff;

                        &.prblmCount {
                            top: -8px;
                            right: -3px;
                        }
                    }
                }
            }

            .saveSplitBtn {
                button {
                    display: flex;
                }

                .k-button-outline-base {
                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }

        .riggep {
            margin-right: 5px;
            padding-right: 5px;
            border-right: 1px #ccc solid;
        }

        .navdrp {
            span {
                width: 18px !important;
            }

            .dropdown-menu {
                li {
                    a {
                        padding: 0px;
                    }
                }
            }
        }
    }

    .open>.dropdown-menu {
        z-index: 1;
    }

    .threeDotul {
        @include displayInlineBlock;
        padding: 0px 0px 0px 5px !important;
        margin-right: 5px !important;

        >a {
            font-weight: bold;
            font-size: 20px;
            color: #a1a1a1;
            border: 1px #d3d3d3 solid;
            padding: 3px 0px !important;
            border-radius: 3px;

            span {
                width: 12px;
                display: block;

                svg {
                    position: relative;
                    right: 4px;
                }
            }

            &:focus,
            &:hover {
                color: #ffffff !important;
                background-color: var(--primaryColor) !important;
                border: 1px transparent solid;
                outline-offset: -3px;
                outline: none !important;
            }
        }

        .dropdown-menu {
            padding: 5px 6px !important;
            z-index: 1;
            min-width: 200px;
            text-align: left;

            li {
                display: block;
                float: none;
                padding: 0px;
                text-align: left;
                margin-bottom: 1px;
                width: 100%;

                &:last-child {
                    border-bottom: none;
                    margin-bottom: 0px;
                }

                a {
                    text-align: left;
                    border: none;
                    padding: 3px 0px;
                    margin-bottom: 0px;
                    font-size: 13px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;

                    &:hover,
                    &:focus {
                        border: none;
                    }

                    svg {
                        margin-right: 4px;
                        //color: #393939;
                        font-size: 14px;
                    }
                }
            }
        }

        .dropdown-toggle::after {
            @include displayNone;
        }
    }
    
    &.toprigStatusDv {
        padding-right: 10px;
    }
}

.saveRedirectPopup {
    border-radius: 0.375rem;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.175);
    margin-top: 2px;

    .k-menu-group {
        .k-item {
            &.k-menu-item {
                font-family: var(--FontFamily);
                font-size: 13px;
            }

            &:focus {
                .k-link {
                    box-shadow: none;
                }
            }

            >.k-link {
                &.k-menu-link {
                    padding: 7px 9px;
                }

                &:active {
                    background-color: var(--primaryColor);
                }

                &:hover {
                    color: #ffffff;
                    background-color: var(--primaryColor);
                }
            }
        }
    }
}

.breakFixHamburger {
    position: relative;

    .myLinks {
        position: absolute;
        top: 2px;
        left: 0;
        min-width: auto;
        width: max-content;
        max-width: 220px;
        padding: 0;
        z-index: 100;
        -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.26);
        -moz-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.26);
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.26);
        border-radius: 4px;
        border: 1px #d3d3d3 solid;
        background: #ffffff;
        text-align: left;
        font-size: 13px;

        &.empty {
            box-shadow: none;
            border: none;
        }

        li {
            i {
                margin-right: 7px;
            }

            a {
                font-size: 13px;
                color: inherit;
                @include bgWhite;
                padding: 4px 10px;
                display: block;
                margin-bottom: 2px;
                outline: none;

                &:hover,
                &:focus {
                    background-color: #deebff;
                }

                &[title="blank"] {
                    padding: 0;
                    height: 1px;
                    margin-bottom: 0;
                    @include displayNone;
                }
            }
        }
    }
}

/************ JWT ************/

.btn-jwt {
    button {
        border: 1px solid #ddd;
        margin-right: 10px;
        padding: 4px 6px;
        text-transform: capitalize;
        background-color: #f1f1f1 !important;
        color: #000000 !important;
        line-height: 20px;
        font-size: 14px;

        &:hover,
        &:focus {
            border-color: rgba(0, 0, 0, .6);
        }
    }

    .jwt-btn.selected {
        background-color: rgba(0, 0, 0, .6) !important;
        color: #ffffff !important;
    }
}

.jwtContent {
    .myJwt {
        word-break: break-all;
        border: 1px #ccc solid;
        padding: 10px;
        margin: 10px 0px;
        font-size: 13px;
        color: #333;
        max-height: 200px;
        overflow: hidden;
        overflow-y: auto;
    }
}