@import "../general/mixin";

.datePickerBox {
    @include displayInlineBlock;
    position: relative;

    .datePickerPopup {
        @include displayInlineBlock;
        position: absolute;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
        background: #ffffff;
        z-index: 9999;
        top: 29px;
        right: 2px;
        width: 572px;

        .closeFunBtn {
            z-index: 1;
            @include displayInlineBlock;
            position: absolute;
            left: -5px;
            top: -8px;
            background: #007eff;
            width: 18px;
            border-radius: 47%;
            color: #ffffff;
            height: 18px;
            text-align: center;
            font-size: 12px;
        }
    }

    .txtInpt {
        font-size: 12px;
        cursor: pointer;
        width: 240px;
        background: transparent;
        border: none;
        box-shadow: none;

        &Box {
            cursor: pointer;
            background: #ffffff;
            border: 1px solid #ccc;
            border-radius: 4px;
            margin: 0;
            -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
            -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
            -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
            transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

            &:before {
                color: #666;
                content: "\f073";
                @include FontAwesomeFamily;
                position: relative;
                top: 1px;
                left: 4px;
            }
        }
    }
}

.dateTimePickerField {
    padding: 0 !important;

    input {
        height: 100% !important;
        border: 0 !important;
    }
}

.dateTimeSdiv {
    >.react-date-field--theme-default {
        .react-date-field__input {
            height: 20px !important;
        }
    }

    .react-date-field__input {
        @include bgTransparent;
    }

    input::placeholder {
        color: #333 !important;
        font-size: 13px;
    }

    .react-date-field--theme-default {
        .react-date-field__calendar-icon {
            border: 2px solid #c7c8cc;

            &-inner,
            &:after,
            &:before {
                background: #c7c8cc;
            }
        }

        .react-date-field__picker {
            position: absolute;
            top: initial !important;
            bottom: 36px !important;
        }

        .react-date-field__clear-icon {
            display: none !important;
        }
    }

    &.dateTimePosTop {
        .rdtOpen .rdtPicker {
            bottom: 36px;
            box-shadow: 0 0 0 1px rgb(0 0 0 / 30%);
            border-radius: 2px;
        }
    }

    &.dateTimeBot {
        .rdtOpen .rdtPicker {
            bottom: 4px;
        }
    }

    &.dateTimeRightPos {
        .rdtOpen .rdtPicker {
            right: 0;
        }
    }

    &.deledateTimePosTop {
        .rdtOpen .rdtPicker {
            top: -64px;
            box-shadow: 0 0 0 1px rgb(0 0 0 / 30%);
            border-radius: 2px;
        }
    }

    .rdtOpen .rdtPicker {
        z-index: 995 !important;
    }
}

.dateInfo {
    font-size: 12px;
    position: relative;
    top: 5px;
    left: 3px;

    .form-control {
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        background: none;
        border-radius: 0 !important;
        height: 23px !important;
        margin-right: 3px;
        padding: 6px 10px;
    }
}

.daterangepicker {
    margin-top: 0;
    box-shadow: 0 2px 4px -2px #000000;
    z-index: 990 !important;

    &:before,
    &:after {
        @include displayNone;
    }

    .drp-calendar {

        &.right,
        &.left {
            padding: 0;
        }

        .calendar-table {

            th,
            td {
                min-width: 25px;
                width: 25px;
                height: 14px;
                line-height: 14px;
                border-radius: 2px;
                padding: 5px;

                &.active,
                &:hover {
                    color: #ffffff;
                    background-color: var(--primaryColor);
                }
            }
        }
    }
}

.dateRangeLabel {
    float: right;
    position: relative;
    top: 3px;
    color: var(--anchorColor);
    font-size: 13px;
    cursor: pointer;

    a {
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        svg {
            font-size: 1.1rem;
            margin-right: 4px;
        }
    }
}

.tagDateTimePos {
    position: initial;

    .rdt {
        position: initial;  
    }

    .rdtPicker {
        right: 120px;

        &.tagDateTimePosTop {
            top: initial;
            right: 0;
            bottom: 30px;
        }
    }

    &.tagDateTimePosTop {
        position: relative;

        .rdtPicker {
                top: initial;
                right: 0;
                bottom: 30px;
        }
    }      
}

.dateTimePosLeft {
    .rdtPicker {
        right: 0;
    }
}

.calendrFilters {
    background-color: rgba(0, 0, 0, 0.07);
    padding: 10px;
    align-items: center;

    .myBt {
        border: 0;
        background: #ffffff;
        margin: 0 3px;
        padding: 4px 8px;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.12);
        font-size: 14px;
        display: flex;

        &.smCalBtn {
            padding: 3px 4px;
            margin: 0 2px;
        }

        svg {
            font-size: 18px;
        }
    }

    .smBtnCls.btn {
        font-size: 12px;
        padding: 2px 6px !important;
        border-radius: 4px;
    }

    span {
        font-weight: 600;
    }
}

.timeslot-header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.onCallCalendar {
    .rbc-time-view-resources {
        .rbc-row-resource {
            .rbc-header {
                font-size: 13px;
                padding: 6px 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 50px;

                span {
                    word-wrap: break-word;
                    white-space: initial;
                }
            }

            .rbc-timeslot-group {
                .rbc-time-slot {
                    &:last-child {
                        @include displayNone;
                    }

                    flex: 1 0 0;
                    display: flex;
                    align-items: center;
                    padding: 10px 0;

                    &.rbc-today {
                        background: #ffffff;
                    }
                }
            }
        }
    }
}