@import "../general/mixin";

.copyCrpdv {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 998;
    border-top: 1px solid #e9e9e9;

    .copyr,
    .copyCrp {
        font-size: 12px;
        color: var(--headerTxtColor);
        background: var(--headerBG);
        padding: 5px 0px;

        a {
            color: var(--headerTxtColor) !important;
        }
    }

    .copyr {
        .copysym {
            width: 65%;
            @include displayInlineBlock;
        }

        .copyVer {
            @include displayInlineBlock;
            width: 35%;
            text-align: right;
        }
    }

    .copyCrp {
        .copysym {
            width: 45%;
            @include displayInlineBlock;

            span {
                white-space: nowrap;
            }
        }

        .copyVer {
            @include displayInlineBlock;
            width: 20%;
            text-align: right;
        }

        .cpLnk {
            @include displayInlineBlock;
            width: 35%;

            li {
                @include displayInlineBlock;

                &:first-child {
                    margin-right: 10px;
                }
            }

            a {
                font-size: 12px;
                color: #dadada;

                &:hover {
                    color: #82ebf5;
                }

                &:focus {
                    color: #82ebf5;
                    outline-color: #ffffff;
                }
            }
        }
    }
}

/************ Resize Panel ************/

.resizeHandle {
    background-color: transparent;
    position: relative;
    padding-left: 16px;

    .outlne {
        border-left: 1px solid transparent;
        width: 16px;
        height: 100%;
    }

    .handIcn {
        color: var(--primaryColor);
        position: absolute;
        top: 0;
        left: 7px;
        z-index: 1;
        @include displayNone;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: var(--pageBG);
        font-size: 10px;
        width: 16px;
        height: 16px;
        border-radius: 50%;

    }

    &:hover {
        .outlne {
            border-left-color: var(--primaryColor);
        }

        .handIcn {
            display: flex;
        }
    }

    &:focus {
        .outlne {
            border-left-color: var(--primaryColor);
        }

        .handIcn {
            display: flex;
        }
    }
}