
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
.rdp {

  margin: 0px;
}

.rdp-table {
  max-width: 100%;
  width: 100%; 
  text-align: center;
  margin-top: 10px;
}

.rdp-months {
  flex-direction: column;
}

.rdp-head_row th.rdp-head_cell {
  padding: 7px;  
  text-transform: capitalize; 
  background-color: rgb(235, 235, 235);
  font-size:14px;

  span {
    color: #fff;
  }
}
.rdp-cell {
  padding: 10px 10px;
  height: 28px;
  width: 28px;
  border-bottom: 0px !important;
}

.rdp-weeknumber,
.rdp-day {
  display: inline-flex;
  height: 28px;
  width: 28px;
}

.rdp-row td.rdp-cell:nth-child(6),
.rdp-row td.rdp-cell:nth-child(7) {
  background-color: #e6f5f6;
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: #e8effb;
}

.cal-today {
  background-color: #f0f1f1 !important;
  border-radius: 0px;
  border: solid 1px #a2a2a2;
  color: #262626 !important;;
}

.datepicker-prev:after {
  content: "\f104";
  font-family: 'FontAwesome', sans-serif;
  font-size: 22px;
  margin-left: 0px;
  cursor: pointer;
  position: absolute;
  left: calc(100% - 70%);
  width: 20px;
  height: 23px;
  line-height: 20px;
  text-align: center; 
  color: #11b5c5;
  background-color: #fff;
  border: 1px solid #dbdbdb;
}

.datepicker-next:after {
  content: "\f105";
  font-family: 'FontAwesome', sans-serif;
  font-size: 22px;
  position: absolute;
  right: calc(100% - 70%);
  width: 20px;
  border: 1px solid #dbdbdb;
  height: 23px;
  line-height: 20px;
  cursor: pointer;
  color: #11b5c5;
}

.date-picker-header {
  color: #11b5c5;
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  padding: 2px;
  position: relative;
}

.datepicker-title {
  display: inline-block;
  width: 150px;
  padding: 2px 5px;
  font-size: 18px;
}

.simplebar-content {
  font-size: 12px;
}

.datepicker-title {
  padding: 0px 5px;
}

.filters {
  padding: 7px;  
  text-transform: capitalize; 
  background-color: rgb(235, 235, 235);
  label {
    font-weight: 600;white-space: nowrap;
  }
}

.meeting-name {
  border: 1px solid #ebebeb;
  background-color: #ebebeb;

  h1 {
    font-size: 14px;
    font-weight: 600;
    color:#777;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 5px;
  }
}

.recrring-name {
  background-color: #11b5c5;
  border: 2px solid #11b5c5;
  display: inline-block;
  border-radius: 100px;
  width: 12px;
  height: 12px;
  text-align: center;
  line-height: 9px;
  font-size: 10px;
  color: #fff;
}

.recrring-icon {
  font-size: 12px;
  margin-top: 10px;
  color:rgb(119, 119, 119);
  font-style: italic;
}

.filter .form-control {
  font-size: 12px;
  font-weight: 300;
  color: #333;
  height: auto;
  padding: 2px 20px 2px 6px;
}
.filter .form-control::placeholder {
  color: #666 !important;
  opacity: 1;
  font-weight: 400;
}

.cal-icon {
  color: #bbb6b6;
  font-size: 16px;
}
#custom-switch{
  width: 30px;
  height: 18px;
}
#custom-switch:checked{
  background-color: #f8993b;
  outline: thin dotted #000;
  outline-offset: 2px;
}

.dispIn {
  display: inline-block;
  position: relative;

  span {
    margin-right: 5px;
    border-right: 1px solid #aeaeae;
    padding-right: 5px;
  }
}

.icon-btn.selected {
  background-color: #7dbc26;
  color: #fff !important;
}

.meeting-name {
  border: 1px solid #ebebeb;
  margin-top: 10px;
  background-color: #ebebeb;

  h1 {
    font-size: 13px;
    font-weight: normal;
    color: #777;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 5px;
  }
}

.custom-select {
  border-bottom: 1px solid #fff;
  font-size: 10px;
  padding: 1px;
  padding-left: 0px;
  color: #fff;
}

.meeting-details {
  border: 1px solid #dddddd;

  .recrring-name {
    background-color: #11b5c5;
    border: 2px solid #11b5c5;
    display: inline-block;
    border-radius: 100px;
    width: 12px;
    height: 12px;
    text-align: center;
    line-height: 9px;
    font-size: 10px;
    color: #fff;
  }

  .p2 {
    padding: 2px 5px 5px 5px;
  }

  .timings {
    font-size: 12px;
    background-color: #ffffff;
    display: block;
    box-shadow: 0px 2px 3px 0px rgb(0 0 0 / 30%);
    color: #000;
    padding: 4px 3px;
  }
  
  ul {
    margin: 0px;
    padding: 0px;
    width: 100%;

    li {
      list-style: none;
      margin-bottom: 10px;

      a {
        display: block;
        border-bottom: 1px solid #ddd;
        font-size: 11px;
        color: inherit;
        background-color: #f5f5f5;
      }

      a:hover {
        color: inherit;
      }
    }

    li.active a{
      border-left: 6px solid #11b5c5;
      
    }
    li.active a:hover {
      border-left: 6px solid #e4ae3b;
    }
  }
}

.participant-email {
  display: block;
  color: #959595;
  font-size: 11px;
  line-height: 12px;
}

 .participants-table td {
  padding: 5px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.console-filter .colSearDv select.form-control{
    font-size: 12px;
    font-weight: 400;
    color: #333;
    height: auto;
    padding: 2px 20px 2px 6px;
}
.datepicker-action .rdtPicker {
  right:0px
}

.custom-attachment-table .whopublished {
  background-color: #f7f7f7;
  border-top: 1px solid #ddd;
  color: #6b6a6a;
  display: block;
  font-size: 11px;
  font-weight: 400;
  padding: 7px;
}
.custom-attachment-table .document-body span {
  color: #000;
  display: block;
  white-space: pre-wrap;
  word-break: break-word;
}

.base {
  font-size: 11px;
  background-color: rgb(235, 235, 235) !important;
}
