
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/

.rte {
  
  .ql-toolbar.ql-snow {
    font-family: "Roboto Condensed", sans-serif !important;
  }
  .ql-editor p {
    font-family: "Roboto Condensed", sans-serif !important;
    font-size: 12px;
    color: #000000;
  }
  label {
    padding: 5px 0;
    display: block;
    color: #313131;
    font-weight: 400;
    font-size: 14px !important;
    
  }
  .wordCount {
    float: right;
    font-size: 11px;
    color: inherit;
    font-weight: normal;
    margin-top: 5px;
  }
}
.text-editor {
  width: 100%;
  margin: 0 auto;
  background: #fff;
  position: relative;
}
.ql-editor {
  min-width: 100px;
  padding: 8px 10px;
}

.labelAsterisk{color: #f44336;display: inline-block;padding-left: 5px;}

.quill.in-valid{
  border: solid 1px #dc3545;
  border-radius: 4px;
}