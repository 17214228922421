@import "../general/mixin";
@import "./pagination";
@import "./tableFilters";
@import "../rightside/main";

/************ Custom table ************/

.respondv {
    .tableRgtBor {
        border-right: 1px #ddd solid;
        border-left: 1px #ddd solid;

        .table {
            >tbody {
                >tr:has(> td > button.rowFocus:focus) {
                    background-color: #fcf0e4 !important;
                    outline: thin #ff7f00 dashed;
                    outline-offset: -3px;
                }
            }
        }
    }

    &.responDvMinH {
        min-height: 350px;
    }
}

.nowrapWhright {
    margin: 0px !important;
    tr.wordBreak{
        td{
            padding:8px 10px !important;
            white-space: initial !important;
            word-break: break-word !important;
        }
    }

    th,
    td {
        font-size: 13.8px;
        white-space: nowrap;
        padding: 8px 10px !important;
        border: 1px solid #ddd;
    }

    th {
        vertical-align: top;
    }

    td {
        cursor: pointer;

        &.noPointer {
            cursor: default;
        }
    }

    tbody,
    thead,
    tfoot {
        tr {

            td,
            th {

                &:last-child,
                &:first-child {
                    border-right: none;
                    border-left: none;
                }
            }
        }
    }

    .trAnchor {
        color: #333;
        text-decoration: underline;

        &:hover,
        &:focus {
            @include colorBlack;
            text-decoration: none;
        }
    }
}

.tableView {
    margin: 10px 0px 10px 0px;

    a {
        color: var(--anchorColor);

        &:hover {
            color: var(--anchorColor-darker);
        }
    }

    td {
        padding: 7px 5px !important;
        word-break: break-word;
    }

    thead {
        background-color: rgba(0, 0, 0, 0.02);

        >tr {
            >th {
                font-weight: 600;
                min-width: 90px;
                background: var(--tableHeader);
            }

            td,
            >th {
                border-bottom-width: 1px;
            }
        }
    }

    tbody {
        >tr {
            &.active {
                >td {
                    background-color: rgb(16 181 197 / 13%) !important;
                }
            }
        }
    }

    .hyperLinkWIB {
        color: var(--primaryColor) !important;
        text-underline-offset: 2px;

        &:hover {
            text-decoration: underline;
        }
    }

    .myActive,
    .myActive:hover,
    .myActive:focus {
        td {
            background-color: var(--primaryColor-lighter) !important;
        }
    }

    .tableViewMin {
        >thead {
            >tr {
                >th {
                    min-width: initial;
                }
            }
        }
    }
}

.table-striped {
    >tbody {
        >tr {
            &:nth-of-type(odd) {
                background-color: rgba(0, 0, 0, 0.001);

                td {
                    background-color: var(--tableColumn);
                }
            }
        }
    }
}

.table-hover {
    >tbody {
        >tr {
            &:hover {
                background-color: rgba(0, 0, 0, 0.003);
            }
        }
    }
}

.fltr-table {
    thead {
        tr {
            th {
                &:first-child {
                    max-width: 250px !important;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                &:first-child {
                    @include ovrFlowContent;
                    max-width: 250px !important;
                }
            }
        }
    }
}

.circle8 {
    height: 8px;
    width: 8px;
    @include displayInlineBlock;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    position: relative;
    top: 0px;
    left: 0px;
    margin-right: 5px;
}

.sortParArr {
    cursor: pointer;
}

.sortArr {
    @include displayInlineBlock;
    margin-left: 5px;

    .customArrow {
        color: rgba(204, 204, 204, 0.2);

        &.isActive {
            @include colorBlack;
        }
    }

    .caret {
        border-width: 3px;
        @include displayInlineBlock;
        width: 0;
        height: 0;
        margin-left: 2px;
        vertical-align: middle;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
    }

    .dropup {
        top: 1px;
        left: 0;

        .caret {
            content: "";
            border-top: 0;
            border-bottom: 4px dashed;
        }
    }

    .dropdown {
        top: -1px;
        left: 0;

        .caret {
            @include displayInlineBlock;
            width: 0;
            height: 0;
            margin-left: 2px;
            vertical-align: middle;
            border-top: 4px dashed;
            border-right: 4px solid transparent;
            border-left: 4px solid transparent;
        }
    }
}


/************ Bootstrap table ************/

.sortImg {
    th.sort {
        &::after {
            content: "\F0DC";
            color: #9c9c9c;
            @include FontAwesomeFamily;
            font-weight: normal;
            font-size: 11px;
            margin-left: 5px;
        }

        &.down::after {
            content: "\F0DD";
            color: #007eff;
        }

        &.up::after {
            content: "\F0DE";
            color: #007eff;
        }
    }
}

.tablesl {
    border-collapse: collapse;
    table-layout: fixed;
    margin-bottom: 0px;

    td {
        border: solid 1px #ddd;
        width: 100px;
        word-wrap: break-word;
    }
}

.xtFilterBar {
    margin-bottom: 20px;

    label {
        color: rgba(#000000, 0.8);
    }

    .form-control {
        color: rgba(#000000, 0.8);
        border-color: rgba(#000000, 0.3);
        border-radius: 0;
        height: 34px;
        padding: 4px 8px !important;

        &:hover,
        &:focus {
            color: rgba(#000000, 0.8);
            border-color: rgba(#000000, 0.3);
        }
    }

    .myFields {
        .btn-default {
            padding: 6px 10px !important;
            font-size: 14px;
        }
    }

    .popFormBtn {
        padding: 4px 10px !important;
    }
}

.serviceDetailsHeadColor {
    color: var(--primaryColor);
}

.nerept {
    a {
        font-size: 15px;
        color: var(--primaryColor-darker) !important;
        font-weight: 400;
        background-color: transparent !important;

        &:focus {
            outline: none !important;
        }
    }

    .dropdown-menu {
        a {
            display: block !important;
            font-size: 14px !important;
            font-weight: 400 !important;
            margin-bottom: 5px;
            padding-bottom: 5px;

            &:hover {
                background-color: var(--primaryColor-lighter) !important;
                color: #212529 !important;
            }
        }
    }
}

.repoparDv {
    .nerept {
        a {
            font-size: 16px;
            font-weight: 500;
        }

        .dropdown-menu {
            min-width: 230px;

            .aTagdv {
                max-height: calc(100vh - 240px);
                overflow: auto;

                @include scrollbar(8px);
            }

            .form-control {
                margin: 0px 10px 10px;
                width: 90%;
                color: #333;

                &::-webkit-input-placeholder {
                    color: #ccc !important;
                }
            }
        }
    }
}

.inputSerDv {
    position: relative;

    svg {
        position: absolute;
        top: 10px;
        right: 18px;
        z-index: 1;
        cursor: pointer;
        color: #888;

        &.viewicn {
            right: 10px;
        }
    }
}

.searchRslt {
    color: #51789e;
    font-size: 13px;
    display: table;
    width: 100%;
    min-height: 32px;

    &box {
        color: #399bff;
        display: table-cell;
        vertical-align: middle;

        strong {
            color: #3e3e3e;
            font-weight: bold;
            padding-right: 5px;
        }

        span {
            color: #555;
        }

        b {
            font-weight: 600;
        }
    }
}

.GenCriteria {
    .form-control {
        height: initial;
        padding: 4px;
    }

    .btn {
        font-size: 13px;
    }

    th {
        font-size: 14px;
        color: #313131;
        font-weight: 600;
    }
}

.mybtnToolbar {
    .btn-group {
        float: none;
    }

    .dropdown-menu {
        >li>a {
            padding: 2px 10px;
        }

        a::before {
            color: #4da5ff;
            @include FontAwesomeFamily;
            float: left;
            font-weight: normal;
            font-size: 14px;
            margin-right: 5px;
        }
    }

    .pdf {
        a::before {
            content: "\f1c1";
            color: red;
        }
    }

    .excel {
        a::before {
            content: "\f1c3";
            color: var(--primaryColor);
        }
    }
}

.frobj {
    span:hover {
        color: #ffffff !important;
        background-color: var(--primaryColor) !important;
        border: 1px solid;
        border-color: var(--primaryColor);
        outline-offset: -3px;
        border-radius: 4px;
        padding: 1px;
    }
}

.tree-node {
    svg {
        fill: #ffffff;
    }

    &:hover {
        fill: #ffcc6d;

        svg {
            fill: #ffffff;
        }

        .outer-circle {
            stroke: #ffcc6d !important;
        }
    }
}

#dashboard {
    clear: both;

    iframe {
        width: 100%;
        height: calc(100vh - 200px) !important;
        padding: 0 15px !important;
        overflow-y: hidden;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            background: #484848;
        }

        &::-webkit-scrollbar-thumb {
            background: #000;

            &:hover {
                background: #2a55a0;
            }
        }
    }
}

.Escalation-level-list {
    position: relative;
    counter-reset: section;
    padding: 20px;

    &::after {
        border-left: 1px solid #ddd;
        margin-left: -11px;
        content: "";
        position: absolute;
        height: 100%;
        top: 0;
    }

    li {
        list-style: none;
        position: relative;

        &::before {
            counter-increment: section;
            content: counter(section);
            color: #383838;
            background: #fff;
            border: 1px solid #ddd;
            padding: 4px 11px;
            margin-right: 0;
            border-radius: 100px;
            margin-left: -26px;
            width: 28px;
            z-index: 1;
            height: 28px;
            @include display(flex, center, center);
            position: absolute;
            top: 23px;
        }
    }

    &-data {
        margin-left: 20px;
        position: relative;

        &::before {
            top: 25px;
            border-color: transparent #d9d9d9 transparent transparent;
            border-width: 11px;
            content: '';
            display: block;
            position: absolute;
            right: 100%;
            width: 0;
            height: 0;
            border-style: solid;
        }

        &::after {
            top: 26px;
            border-color: transparent #fff transparent transparent;
            border-width: 10px;
            content: '';
            display: block;
            position: absolute;
            right: 99.9%;
            width: 0;
            height: 0;
            border-style: solid;
        }

        table {
            th {
                padding: 3px 5px;
                background-color: #f2f2f2;
            }

            td {
                padding: 3px 5px;
            }
        }
    }
}

.escalation-level-form {
    .modal-dialog {
        position: relative !important;
        width: auto !important;
    }

    .modal-header {
        display: block !important;
    }
}

.repListDv {
    .repSec {
        border: 1px solid var(--primaryColor);
        border-radius: 2px;
        width: 29%;
        padding: 10px;
        min-height: 60px;
        text-align: left;
        background-color: var(--primaryColor-lighter);
        opacity: 0.8;

        a {
            font-size: 16px;
            color: #000;
            display: block;
            font-weight: 500;
            @include ovrFlowContent;

            svg {
                margin-right: 4px;
                position: relative;
                top: -2px;
            }
        }

        &:hover,
        &:focus {
            opacity: 1;
        }
    }

    .hrd {
        font-size: 18px;
        color: var(--primaryColor);
        margin-bottom: 15px;
        font-weight: 500;
    }

    .secrepdv {
        display: flex;
        gap: 29px;
        flex-wrap: wrap;
    }

    .flxDv {
        display: flex;
        justify-content: space-between;
        clear: both;
        width: 100%;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 20px;

        .repSec {
            border: 1px solid #ccc;
            border-radius: 2px;
            flex: 1 1 22%;
        }
    }
}

[class*="renderedView-66b5b94f59e99e31cffa17f2"] {
    .Escalation-level-list {
        td {
            &#icowsr, &#iod0no {
                word-break: break-word;
            }
        }
    }
}


.smallBtn2{
    padding: 2px 6px !important;
    border:none !important;
    &:focus {
      background-color: var(--primaryColor-darker) !important;
    }
  }