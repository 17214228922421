@mixin FontAwesomeFamily {
    font-family: "FontAwesome", sans-serif;
}

@mixin margin-padding-0 {
    margin: 0;
    padding: 0;
}

@mixin displayNone {
    display: none;
}

@mixin displayInlineBlock {
    display: inline-block;
}

@mixin displayVisibilityNone {
    display: none;
    visibility: hidden;
}

@mixin ovrFlowContent {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

@mixin bgWhite {
    background-color: #ffffff;
}

@mixin colorBlack {
    color : #000000;
}

@mixin bgTransparent {
    background-color: transparent;
}

@mixin widthHeight($width, $height) {
    width: $width;
    height: $height;
}

@mixin scrollbar($width) {
    &::-webkit-scrollbar {
        width: $width;
    }

    &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 10%);
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 20%);
        border-radius: 5px;

        &:hover {
            background: rgba(0, 0, 0, 8%);
        }
    }
}

@mixin display($display, $align: null, $justify: null) {
    display: $display;
    
    @if $display == flex or $display == inline-flex {
        @if $align != null {
            align-items: $align;
        }
        @if $justify != null {
            justify-content: $justify;
        }
    }
}