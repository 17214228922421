@import "./mixin";

@function shade($color, $percentage) {
    @return mix(black, $color, $percentage);
}

@function tint($color, $percentage) {
    @return mix(white, $color, $percentage);
}

:root {
  --FontFamily:"Open Sans";
  --pageBG: #fcfdff;
  --pageTxtColor: #000000;
  --pageTxtColor-light: color-mix(in srgb, var(--pageTxtColor), #ffffff 30%);
  --pageTxtColor-lighter: color-mix(in srgb, var(--pageTxtColor), #ffffff 85%);
  --anchorColor: #0a89a7;
  --anchorColor-darker: color-mix(in srgb, var(--anchorColor), #000000 20%);
  --anchorColor-light: color-mix(in srgb, var(--anchorColor), #ffffff 50%);
  --anchorColor-lighter: color-mix(in srgb, var(--anchorColor), #ffffff 80%);
  --anchorColor-lightest: color-mix(in srgb, var(--anchorColor), #ffffff 85%);
  --aiButtonBG: #f8993b;
  --aiButtonBG-light: color-mix(in srgb, var(--aiButtonBG), #ffffff 50%);
  --headerBG: #717272;
  --headerBG-darker: color-mix(in srgb, var(--headerBG), #000000 30%);
  --headerBG-lighter: color-mix(in srgb, var(--headerBG), #ffffff 75%);
  --headerBG-lightest: color-mix(in srgb, var(--headerBG), #ffffff 95%);
  --headerTxtColor: #d0d0d0;
  --headerTxtColor-lighter: color-mix(in srgb, var(--headerTxtColor), #ffffff 85%);
  --primaryColor: #29b6cc;
  --primaryColor-darker: color-mix(in srgb, var(--primaryColor), #000000 15%);
  --primaryColor-darkest: color-mix(in srgb, var(--primaryColor), #000000 30%);
  --primaryColor-lighter: color-mix(in srgb, var(--primaryColor), #ffffff 85%);
  --primaryColor-lightest: color-mix(in srgb, var(--primaryColor), #ffffff 95%);
  --secondaryColor: #f8993b;
  --secondaryColor-lighter: color-mix(in srgb, var(--secondaryColor), #ffffff 40%);
  --tableHeader: #eaeaea;
  --tableColumn: #ffffff;
  --modalBackDrop:0;
}