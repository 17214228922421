/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/

@use "././font-family.scss";
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css");
// @import "react-big-calendar/lib/sass/styles";
// @import "react-big-calendar/lib/addons/dragAndDrop/styles";

@import "./common/header";
@import "./common/footer";
@import "./common/sideMenu";
@import "./common/tabs"; // main tabs, filter tabs, secondary tabs
@import "./common/aiButton"; // ai button
@import "./common/logout";
@import "./common/qBuilder";

@import "./governance";
@import "./meeting-calendar";

@import "./reactDateTime";

@import "./general/theme";
@import "./general/mixin";
@import "./general/mainBody";
@import "./general/utility";
@import "./general/customStyle";
@import "./general/typography";
@import "./responsive";
@import "./general/forgotPassword"