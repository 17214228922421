@import "./mixin";

.keydv414 {
    @include displayVisibilityNone;
}

#notification_body table,
#notification_body table td {
    @include bgTransparent;
    border: none !important;
    word-break: break-word;
}

.rc-input-number {
    border: 1px #ccc solid;
}

.rc-input-number-handler-wrap {
    border-left: none;
    background-color: #f1f1f1;
}

.rc-input-number-handler-up {
    border-bottom: none;
}

.rc-input-number-handler-up,
.rc-input-number-handler-down {
    line-height: 11px;
    height: 12px;
    font-size: 8px;
}

.rc-input-number-handler-up-inner:after {
    content: "\25B2";
}

.rc-input-number-handler-down-inner:after {
    content: "\25BC";
}

/*sxStyle.css*/
.disabled {
    background-color: #f5f5f5 !important;
    color: #adadad !important;
    cursor: default !important;
}

.bg-white .btn-default {
    color: #333;
    @include bgWhite;
    border-color: #ccc;
}

.circle {
    height: 10px;
    width: 10px;
    @include displayInlineBlock;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    position: relative;
    top: 1px;
    left: 5px;
}

.edit {
    cursor: pointer;
}

/* Error Page */

.spCommon {
    background: url("../images/sprite-common.png") no-repeat;
}

.verBorder {
    background: url("../images/ver-border.png") repeat-y center top;
    overflow: auto;
}

.sAction {
    background: url("../images/sprite-action.png") no-repeat;
}

.sDetails {
    background: url("../images/sprite-serviceDetails.png") no-repeat;
    background-position: 14px 13px;
}

.times {
    border-right: 1px solid #d1d1d1;
    color: #535c69;
    font-weight: 300;
    float: right;
    margin: 11px 15px 0 0;
    padding: 0;
    position: relative;
    width: 108px;
}

.hours {
    @include colorBlack;
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
}

.date {
    @include colorBlack;
    font-size: 8px;
    clear: both;
    display: block;
    padding: 0 0 0 2px;
    font-weight: 400;
}

.navbar-inverse {
    background: none;
    border: 0;
}

.nav-expanded nav {
    right: 0;
}

body.nav-expanded {
    margin-left: 0em;
    -webkit-transition: right 0.4s ease-in-out 0s;
    -moz-transition: right 0.4s ease-in-out 0s;
    -o-transition: right 0.4s ease-in-out 0s;
    transition: right 0.4s ease-in-out 0s;
}

#nav-close {
    font-size: 24px;
    padding-right: 10px;
}



#mobileLeftMenuFilter {
    color: #ffffff;
    font-size: 16px;
    background: #41607a;
    position: fixed;
    left: 0;
    top: 59px;
    z-index: 9999;
    padding: 0px 6px 2px 4px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    font-weight: bold;
    -webkit-transition: right 0.3s, left 0.3s;
    -moz-transition: right 0.3s, left 0.3s;
    -ms-transition: right 0.3s, left 0.3s;
    -o-transition: right 0.3s, left 0.3s;
    transition: right 0.3s, left 0.3s;
}

ul.inline li {
    display: inline;
    padding: 0 10px;
}

ul.block li {
    display: block;
    padding-bottom: 10px;
}

ul.action {
    float: right;
    margin: 0;
    padding: 0;
}

ul.action li {
    display: inline;
    float: left;
    margin: 0 15px;
    text-align: center;
}

ul.action li:last-child {
    margin: 0 0 0 15px;
}

ul.action li i {
    display: block;
    margin: 0 0 1px 0;
}

.listMX {
    clear: both;
    overflow: hidden;
}

.listMX li {
    float: left;
    padding: 0 0 5px 0;
    width: 50%;
}


.xsLR {
    text-align: right;
}

.boxshadow-lb {
    -webkit-box-shadow: -7px 10px 8px -5px rgba(211, 211, 211, 1);
    -moz-box-shadow: -7px 10px 8px -5px rgba(211, 211, 211, 1);
    box-shadow: -7px 10px 8px -5px rgba(211, 211, 211, 1);
}

.width30 {
    width: 30px;
}

.width70 {
    width: 70px;
}

.txtDec-line {
    text-decoration: underline;
}

.userImg {
    height: 35px;
    width: 35px;
}

h1 {
    color: #1d1d1d;
    font-size: 30px;
    font-weight: 300;
    margin: 0;
    display: table-cell;
    vertical-align: middle;
}

h2 {
    color: #1d1d1d;
    font-size: 22px;
    font-weight: 300;
    margin: 0;
    padding: 0;
}

h3 {
    color: #555;
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 15px 0;
    padding: 0;
}

.barLineTxt {
    position: absolute;
    right: 32px;
    top: 5px;
    width: 50px;
    text-align: left;
    font-size: 12px;
    line-height: 11px;
}

.spCircle {
    width: 16px;
    height: 16px;
    border-radius: 50px;
    position: absolute;
    left: 50%;
    margin-left: -8px;
}

.posBox {
    position: relative;
    top: -8px;
}

.posArrowr {
    position: absolute;
    left: -6px;
}

.posArrowl {
    position: absolute;
    right: -6px;
}

.btnClose {
    cursor: pointer;
}

.view {
    @include displayNone;
    position: absolute;
    right: 10px;
}

.view a {
    float: left;
    width: 25px;
    height: 25px;
    margin-left: 5px;
}

.pCanel {
    background-position: 0 0;
}

.Padd {
    background-position: 0 -28px;
}

.js-tabs-list.c-active .view,
.OrderT:hover .view {
    display: block;
}

.noHandCur span {
    cursor: default !important;
}

.ordCompoNamPos {
    color: #707070;
    margin-left: 25px;
}

.js-mobile-content {
    @include displayNone;
}

.showCon .wrapper {
    @include displayNone;
}

.js-mobile-content .btnClose {
    @include displayNone;
}

.nodisplay,
.none,
.disnone {
    @include displayNone;
}

.lineH-22 {
    line-height: 22px;
}

.typhdRig #react-autowhatever-1 {
    right: 0;
    left: initial;
}

#react-autowhatever-1 {
    position: absolute;
    font-size: 13px;
    @include colorBlack;
    background: #ffffff;
    width: auto;
    z-index: 100;
    box-shadow: 0px 1px 4px -1px #000000;
    word-break: break-word;
    white-space: nowrap;
}

#react-autowhatever-1 ul {
    padding: 0px 10px;
}


#mainApp .loginFooter {
    margin-top: 30px;
    font-size: 12px;
    border-top: 1px #dee7ee solid;
    padding: 20px 0px;
    background: #ffffff;
}

.ordCompoNam {
    color: #707070;
    word-break: break-word;
}

.clickInfo {
    background: url("../images/textBox.png") no-repeat;
    cursor: pointer;
    padding: 5px;
    position: absolute;
    left: 15px;
    top: 0px;
}

.panel-group .panel+.panel {
    margin-top: 20px;
    box-shadow: none;
}

.btnGapDiv .btn {
    margin: 5px 5px 0px 0px;
    padding: 4px 10px;
    font-size: 14px !important;
}

.w55 {
    height: 55px;
    width: 55px;
    max-width: initial;
}


.closeDiv {
    cursor: pointer;
}

.arrowTitle::after {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #e8e8e8;
    content: "";
    clear: both;
    height: 0;
    left: 5%;
    position: absolute;
    top: 36px;
    width: 0;
}

.width48 {
    width: 48px;
}

.width55 {
    width: 55px;
}

.width75 {
    width: 75px;
}

.width60 {
    width: 60px;
}

.colPad0>div.colPadadjust {
    padding: 0px 5px;
}

.rStar {
    &:after {
        content: "*";
        color: #f00;
    }

    &.rStarPos {
        position: absolute;
        top: 0px;
        left: 5px;
    }
}

.mandtryPadding {
    padding-top: 13px !important;
}

.rotate90 {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}


.shead {
    font-size: 16px;
    color: #336699;
    margin: 5px 10px;
    border-bottom: 1px #dedbdb solid;
    font-weight: bold;
}

.searchListR {
    max-height: 200px;
    overflow: auto;
}



.HamburgerGap {
    height: 0px;
    border-bottom: 1px #d8d8d8 solid;
    display: block;
}

.fileAttach {
    width: 100%;
    padding: 6px;
    font-size: 14px;
}

.sc-cJPUEC {
    font-size: 8px;
    color: #646464 !important;
}

.rSubhdTxt {
    font-size: 18px;
    color: #369;
    font-weight: 400;
    margin: -4px 0px 10px 0px;
}


.widthH30 {
    width: 30px;
    height: 30px;
}

.fSbtn {
    font-size: 22px;
    color: #399bff;
}

.fStnNew {
    text-align: right;
    padding-bottom: 5px;
}

.imgObjBox {
    width: 90px;
    text-align: center;
    margin-right: 10px;
}

.srchnp {
    padding: 3px 6px !important;
    height: initial;
}


.upfiles {
    float: right;
    font-size: 11px;
    color: var(--primaryColor);
    cursor: pointer;
}

.logoutIcon {
    color: #545454;
    font-size: 50px;
}

.logMsg {
    color: #5a5a5a;
    font-size: 22px;
    font-weight: 600;
}

.thnkMsg {
    color: #989898;
    font-size: 14px;
    font-weight: 400;
    font-style: italic;
    padding: 0px 30px;
}

.slaTabe.table>tbody>tr>td {
    border-top: none;
}

.conBoard {
    background-position: -7px -1366px;
}

.conItem {
    background-position: -7px -1412px;
}

.inAvai {
    background-position: -7px -512px;
}

.appTabClas.table>tbody>tr>td,
.appTabClas.table>tbody>tr>th,
.appTabClas.table>tfoot>tr>td,
.appTabClas.table>tfoot>tr>th,
.appTabClas.table>thead>tr>td,
.appTabClas.table>thead>tr>th {
    padding: 4px;
    font-size: 13px;
}

.rctLoader {
    // height: 40px;
    // left: 50%;
    // margin-left: -20px;
    // margin-top: -20px;
    // position: absolute;
    // top: 40%;
    // width: 40px;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: absolute;
}

.seqCheckBox label {
    font-weight: 600;
    padding-left: 0px;
    height: initial;
    margin-bottom: 3px;
}

.labelInline label {
    display: inline;
}

.widthAuto {
    width: auto;
}


.radioInlineBox label.radio-inline {
    height: 21px;
    line-height: initial;
}

.radioInlineBox .radio-inline input[type="radio"] {
    margin-right: 5px;
    top: -1px;
    position: relative;
}

.media-heading {
    color: var(--primaryColor);
    font-size: 13px;
    margin-bottom: 2px;
}

.newPad {
    padding: 6px 12px !important;
}

.myChkBox.checkbox {
    padding: 0px;
    margin: -3px 0px 0px 0px;
}

.myChkBox.checkbox label {
    margin: 0px;
    font-size: 10px;
    padding: 0;
    min-height: 15px;
}

.checkbox-inline input[type="checkbox"] {
    width: 12px;
    margin: 1px 0px 0px -16px;
}

.mySerBgBtn {
    float: right;
    margin: 2px 20px 0px 0px;
    padding: 5px 12px !important;
}

.notAvai {
    font-size: 10px;
    margin-left: 5px;
    position: relative;
    top: -4px;
}

.black .fa.fa-angle-down.fa_custom,
.black .fa.fa-angle-up.fa_custom {
    font-weight: 800;
}

.padMar {
    padding: 10px 10px 0 10px;
}

.cngRow {
    height: 30px;
    line-height: 20px;
}

.cngRow input[type="radio"] {
    margin: 0 3px !important;
}

.txtArea {
    height: 34px;
}

.iu-container {
    position: relative;
}

.iu-container .iu-label {
    bottom: 0;
    display: block;
    cursor: pointer;
    font-size: 16px;
    padding-bottom: 5px;
    position: absolute;
}

.lheits {
    height: 24px;
    line-height: 24px;
}

.lheits input[type="checkbox"] {
    margin-right: 5px;
}

.wid300 {
    width: 300px;
}




.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
    pointer-events: initial;
    cursor: not-allowed;
}

.myFilterInput .form-control,
.myFilterInput .pagination>li>a,
.myFilterInput .pagination>li>span {
    padding: 4px 8px;
    height: initial;
}

#downloadAs.btn {
    padding: 2px 6px !important;
    color: #ffffff;
}

.greenMark {
    position: absolute;
    left: 50%;
    margin-left: -9%;
    color: #81c109;
    font-size: 14px;
    font-weight: bold;
    margin-top: 30px;
}

.divToPrint {
    position: relative;
}


div[data-role="change"] {
    color: #04d000 !important;
}

div[data-role="breakFix"] {
    color: #ff4141 !important;
    text-align: center;
}

div[data-role="investigation"] {
    color: #deba00 !important;
    text-align: center;
}

.staticPage p {
    line-height: 22px;
    margin-bottom: 10px;
    text-align: justify;
}

.staticPage h2 {
    margin-top: 20px;
}

.hindiNum {
    margin: 0px 30px 10px 35px;
    list-style-type: disc;
}

.hindiNum li {
    margin-bottom: 8px;
    line-height: 20px;
}

.myServs {
    float: right;
    margin: -5px 0 0 10px;
}

.myServs label {
    font-weight: bold;
}


.reptDv .react-bs-table-pagination {
    margin: 0px 0px 10px;
}

.reptDv .tableView {
    margin: 0px;
}

.reptDv .btn-success {
    color: #ffffff;
}

.zindx1 .react-bs-table-tool-bar .col-xs-6.col-sm-6.col-md-6.col-lg-4 {
    z-index: 1;
}

.zindx1 .react-bs-table-search-form {
    margin-top: 0px;
}

.article-table-td-align-center {
    text-align: center !important;
}

.wordWrap {
    text-overflow: ellipsis;
    word-wrap: break-word;
    word-break: break-all;
}

.pageContainerListCss {
    padding: 2px;
    border: 1px solid #ffffff;
}

.bullets {
    margin-left: 1px;
    margin-right: 7px;
}

.fulPlans-listing .table-responsive {
    max-height: 450px;
}

.fulPlans-listing .tableView {
    margin-top: 0px;
}

.selectFulPlan {
    margin-bottom: 15px;
}

.seqCheckBox input[type="checkbox"] {
    margin: 3px 0 0 7px !important;
    cursor: pointer;
    float: right;
    position: initial;
}

.manReport {
    background-position: -8px -1555px;
}

.serDesk {
    background-position: -9px -1601px;
}

.showPreview {
    margin-top: 20px;
    box-shadow: 0px 0px 0px 0px #000000;
    position: relative;
}

.preClose {
    position: absolute;
    top: 0px;
    right: 0px;
    color: inherit;
    padding: 0px 8px !important;
}

.preClose1 {
    position: absolute;
    top: -40px;
    right: -48%;
}

.preSubBtn {
    margin: 0;
    font-size: 9px !important;
    padding: 1px 10px !important;
}

.clLeftAlign .row>.col-md-6:nth-child(2n-1) {
    clear: left;
}

.rad-check-align .checkbox-inline+.checkbox-inline,
.rad-check-align .radio-inline+.radio-inline {
    margin-left: 0px;
}

.rad-check-align .checkbox-inline,
.rad-check-align .radio-inline {
    margin-right: 10px;
}

.rad-check-align .form-group label.control-label:first-child {
    display: block;
    height: auto;
    width: 275px;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.flLeft {
    float: left;
}

.flRight {
    float: right;
    padding-right: 25px;
    padding-top: 3px;
}

.workitemFloatRight {
    float: right;
    padding-right: 25px;
}

.workitemTaskFloatRight {
    float: right;
    padding-right: 25px;
    margin-top: -2px;
}

.floatRight {
    float: right;
}

.tablesla {
    border-collapse: collapse;
    table-layout: fixed;
    width: 390px;
}

.tablesla td {
    border: solid 1px #dee2e6;
    width: 100px;
    word-wrap: break-word;
}

.pointerDisabled {
    pointer-events: none;
    opacity: 0.7;
}

.attLoader-container {
    position: relative;
    top: 5px;
    margin-right: 5px;
    float: left;
}

.servLoader-container>div {
    float: left;
    position: relative;
    top: 5px;
    margin-right: 10px;
}

.listLoader {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    clear: both;
    text-align: center;
}

.listLoader>div {
    @include displayInlineBlock;
    position: relative;
    top: 1px;
    margin-left: 5px;
}

.srvListLoader-container>div,
.atbtstLoader-container>div {
    float: right;
    position: relative;
    top: 2px;
    margin-left: 5px;
}

.atbtstLoader-container {
    float: left;
    padding: 10px;
    font-size: 13px;
}

.tableWithOutPointer tr:hover {
    cursor: inherit;
}

.labelBlock div label {
    display: block;
}

.readOnlyInputheight {
    height: 27px !important;
}

.popFormBtn {
    padding: 4px 8px !important;
    display: flex;
    align-items: center;
}

.ql-container.ql-snow {
    color: #555;
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.ql-toolbar.ql-snow {
    border-radius: 4px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 4px;
    background-color: #e4e4e4;
}

.ql-editor {
    padding: 8px 12px;
}

.disabled_showAll {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: black;
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
    font-family: inherit;
}

.reactQuillEditor-area p,
.reactQuillEditor p {
    margin: 0px 0px 10px 0px;
}

.reactQuillEditor ul,
.reactQuillEditor ol {
    margin: 10px 0px 10px 30px !important;
    padding: 0px !important;
}

.reactQuillEditor ul {
    list-style-type: disc !important;
}

.reactQuillEditor ol {
    list-style-type: decimal !important;
}

.fa:not(.fa-spin):not(.fa-pulse) {
    animation: none;
}


.checkbox,
.radio {
    margin: 0px;
}

.helpTextInfo {
    font-size: 10px;
    position: relative;
    top: -5px;
    font-style: italic;
}

.btnStatus {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    @include displayInlineBlock;
}

.statusCheckBoxTxt {
    position: absolute;
    top: 2px;
    left: 30px;
    white-space: nowrap;
}

.alert {
    font-size: 15px;
    padding: 10px 20px;
    margin-bottom: 5px;
    border-radius: 2px;
}

.alert.alertNoti {
    padding: 5px 8px;
    margin-bottom: 5px;
}

.alert.alertNoti .alertMess {
    margin-bottom: 5px !important;
}

.alert ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.alert li {
    margin-bottom: 8px;
}

.alert ul .alHd {
    color: #2e8cbe;
    font-size: 14px;
}

.alert ul li ul li {
    margin-bottom: 0px;
}

.alert ul p {
    font-size: 12px;
    color: #f00;
    display: inline;
}

.alertCatNa {
    font-size: 12px;
    @include colorBlack;
    font-weight: 600;
    margin-bottom: 4px;
}

.position-re {
    position: relative;
}

.cursorPoint {
    cursor: pointer;
}

.btninfo {
    background-color: #399bff;
    border: 1px #399bff solid;
    color: #ffffff;
    padding: 0px 6px !important;
    font-size: 9px !important;
    margin-left: 4px;
    font-weight: bold;
}

.rct-widget-multiselect .rw-multiselect-wrapper>.rw-input {
    padding: 0;
    height: auto;
}

.checkContainer {
    transition: 0.6s ease-in-out;
    position: relative;
    top: -5px;
}

.isVisible .checkContainer {
    right: 0;
}

.isHidden .checkContainer {
    right: -100%;
}

.showChecksArr {
    width: 34px;
    height: 34px;
    background: #dc9000;
    padding: 5px;
    float: right;
    margin-left: 15px;
    cursor: pointer;
    position: relative;
    line-height: 24px;
    color: #ffffff;
    text-align: center;
    border-radius: 50%;
    font-size: 17px;
    margin-right: 15px;
}

.showChecksArr .fa-angle-double-left {
    font-size: 20px;
}

.checkboxesC {
    overflow: hidden;
    margin: 0;
    position: relative;
    top: 3px;
}

.isVisible .showChecksArr {
    background: #ff4e00;
}

.radioDiv-inp .radio-inline input {
    margin-top: 2px;
}

.readOnlyClass {
    cursor: not-allowed;
}

.readOnlyClass * {
    background: #eee;
    pointer-events: none;
}

.custmChkLbl {
    position: absolute;
    top: 1px;
    left: 30px;
    white-space: nowrap;
    cursor: pointer;
    word-break: break-word;
}

.formSelectionRadioBox {
    border-bottom: 1px solid #e4e4e4;
    width: 100%;
    float: left;
    padding-bottom: 10px;
}

.customArrow {
    color: rgb(204, 204, 204);
}

.customArrow.isActive {
    @include colorBlack;
}

.sDetails.p12 {
    background-position: 5px -423px;
}

.cartCount {
    position: absolute;
    top: 6px;
    right: 6px;
    font-size: 9px;
    font-weight: bold;
    color: #ffffff;
    background: var(--secondaryColor);
    width: 14px;
    height: 14px;
    border-radius: 50%;
    text-align: center;
    line-height: 15px;
}

.assetsAnswers {
    margin-top: -5px;
}

.assetsAnswers p {
    padding-bottom: 5px;
}

.approvalCount {
    position: absolute;
    top: -1px;
    right: 2px;
    font-size: 8px;
    font-weight: bold;
    color: #ffffff;
    background: #ff4b00;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    text-align: center;
    line-height: 17px;
}

.radioMyreqst input[type="radio"] {
    margin: 4px 0px 0px -20px;
}

.radioFeedback input[type="radio"] {
    margin: 3px 0px 0px -20px;
}

.welcome {
    position: relative;
    width: auto;
}

.welcome .msg {
    @include displayInlineBlock;
    padding: 20px 10px 13px 10px;
    font-size: 14px;
    color: #ffffff;
    font-weight: 400;
    z-index: 9999999;
}

.typeahead.error .form-control {
    border-color: red !important;
}

.errorMessage {
    color: red;
    font-size: 13px;
}

.orderingInfo-form-container span.error {
    @include displayNone;
}



.border-top {
    border-top: 1px solid #eee;
}

.checkoutbutton {
    margin-top: 4px;
}

.checkbox.readOnlyChk label {
    cursor: default;
}

.btnChkbox.cursorChk label {
    cursor: pointer;
}

.multipleInlineRadios .radio-inline {
    margin: 0;
    margin-right: 10px;
}

.myReqst .mySearchControl {
    height: initial;
    height: 28px !important;
    padding: 3px;
}

.dateFieldFilter {
    border-radius: 3px;
    border: 1px solid #ccc;
    height: 34px;
}

.serviceLbl {
    font-size: 14px;
    padding-right: 10px;
    position: relative;
    top: 2px;
}

.criteria-table td,
.criteria-table th {
    padding: 6px 4px !important;
}

.addMinusButton.btn {
    padding: 3px 6px !important;
    margin-top: 5px;
    font-size: 10px;
}

.rBoxStyle .criteria-table .addMinusButton.btn {
    padding: 8px 6px !important;
    margin-top: 0px;
    font-size: 10px;
}

.addMinusButton:first-child {
    margin-right: 2px;
}

.radioInlineRightMarg.radioInlineBox label.radio-inline,
.rBoxGap .radioInlineBox label.radio-inline {
    height: auto;
    margin: 0;
    margin-right: 10px;
}

.navigation li a,
.navigation li button {
    text-indent: -9999px;
}

.multiselect option {
    max-width: 100%;
    overflow: hidden;
    word-wrap: normal !important;
    white-space: normal;
    padding-bottom: 10px;
}

.shiftRight .rdtPicker {
    right: 0px;
}

.myOrderDiv.bootstrapTable .paginationSetNew .react-bs-table-search-form {
    left: -235px !important;
}

.myOrderDiv .react-bs-table-container .react-bs-table-tool-bar {
    display: block;
    margin-top: -42px;
    margin-bottom: 10px;
}

#serviceListPanel .panel+.panel {
    margin-top: 15px;
    box-shadow: none;
}

.tranClose {
    color: red;
    cursor: pointer;
    position: absolute;
    right: -8px;
    top: -12px;
    font-size: 21px;
    @include displayInlineBlock;
}

.tranHead {
    font-size: 18px;
    color: #336699;
    margin-bottom: 10px;
    padding: 0px;
    font-weight: 400;
}


.translationPartial {
    background-color: var(--secondaryColor);
    color: #ffffff;
}

.translationNill {
    background-color: #ff0000;
    color: #ffffff;
}

.translationAll {
    background-color: #7bc230;
    color: #ffffff;
}

.selectedItem {
    background-color: #e9e9e9;
    color: #000;
    padding: 3px;
    margin-bottom: 5px;
}

.justifyText {
    text-align: justify;
}

.tabDatacount {
    color: #505050;
    float: right;
}

.srResulcount {
    font-size: 15px;
    color: #767676;
    margin-bottom: 15px;
}

.srResulcount .keywrd {
    font-weight: 600;
}

.btn-toolbar>.btn,
.btn-toolbar>.btn-group,
.btn-toolbar>.input-group {
    margin-right: 5px;
}

.btn-toolbar>.btn:last-child,
.btn-toolbar>.btn-group:last-child,
.btn-toolbar>.input-group:last-child {
    margin-right: 0px;
}

.react-bs-table-sizePerPage-dropdown .btn-default {
    font-size: 14px;
    color: #555555;
    background-color: #ffffff !important;
    background-image: none;
    border: 1px solid #ccc !important;
    border-radius: 4px;
    height: initial;
    padding: 5px 10px;
    @include displayInlineBlock;
    text-align: left;
    width: auto;
}

.react-bs-table-bordered {
    border-radius: 0px;
}

.react-bs-table .table-bordered>tbody>tr>td {
    padding: 8px 10px !important;
}

.react-bs-table .table-bordered tr>th {
    padding: 5px 10px !important;
}

.shortinfo {
    display: block;
    padding: 0px;
}

.catcarousel ul.nav-tabs>li>a {
    font-size: 15px !important;
    font-weight: 400 !important;
}

.btn-default {
    background-color: var(--primaryColor) !important;
    border: 1px solid !important;
    border-color: var(--primaryColor);
    color: #ffffff;
}

.auditBox.lbox {
    border: 1px solid #dedede;
    border-left: 3px #c7c7ca solid;
    /* border-bottom-left-radius: 3px; */
    /* background: #f1f2f7; */
    padding: 0px;
    min-height: auto;
    border-radius: 4px;
}


.backtolst.btn {
    padding: 4px 8px !important;
}

.minItemLeftMargin .form-group>div>div {
    width: auto !important;
    position: relative !important;
    bottom: 0px !important;
    left: 0px !important;
    right: initial !important;
}

.col-center {
    float: none;
    margin: 0 auto;
}

.colTransit {
    -webkit-transition: right 5s, left 5s;
    -moz-transition: right 5s, left 5s;
    -ms-transition: right 5s, left 5s;
    -o-transition: right 5s, left 5s;
    transition: right 5s, left 5s;
}

.table-responsive {
    min-height: 0.01%;
    overflow-x: auto;
}

.overflow-clip {
    overflow: clip !important;
}

.overFlowClp {
    overflow-x: clip;
}

.txtLowerCa {
    text-transform: lowercase;
}

.proDetTab .specRg {
    float: right;
    padding: 0px 20px 0px 10px;
    width: 40%;
}

.errorBox {
    padding: 5px;
    border: 1px solid #f00;
    width: 100%;
    float: left;
}


.r-nav ul li .notificationTemp {
    background-position: -7px -306px;
}

.notificationcss {
    float: right;
}

.positionInitial {
    position: initial !important;
}

.bor4sGrey {
    border: 1px #e1e1e1 solid;
}

.minmaxdiv {
    display: inline-flex;
}

.minmaxdiv .form-control {
    padding: inherit;
    /* width: auto; */
    height: initial !important;
    margin: auto;
    position: initial;
}

.minmaxdiv .form-group:last-child {
    margin-left: 10px;
}

.lfFieldCheckbox>div {
    float: left;
    margin-right: 10px;
}

.lfFieldCheckbox label {
    line-height: 22px;
}

.shotSpinSer_categoryBoard {
    @include displayInlineBlock;
    padding: 4px 2px;
    width: 65px;
    margin-left: 149px;
    height: 26px !important;
    border-radius: 0 !important;
    position: absolute;
    top: 164px;
}



/* .dvTypehd>span.icn{top: 4px;right: 4px;} */

#popoverAuditLog .popover-content {
    min-height: calc(100vh - 270px) !important;
}

.KA-error-msg {
    margin-bottom: 10px;
}

.spinIcn {
    @include displayInlineBlock;
    position: absolute;
    top: 10px;
    right: 10px;
}



.save {
    background-position: -3px 1px;
}

.tag {
    background-position: -3px -118px;
}

.bclose {
    background-position: -8px -478px;
}







.myPopBtn {
    padding: 2px 6px !important;
    font-size: 13px;
}








.keyValQAns p {
    font-size: 12px;
    word-break: break-word;
}

.wd100 {
    width: 100px;
}

.vipIcn {
    font-size: 12px;
    color: red;
    font-weight: 700;
    position: relative;
    top: -3px;
    left: 5px;
}

.datePckBot .rdtPicker {
    bottom: 36px;
}

.react-bs-table-pagination .react-bs-table-sizePerPage-dropdown .dropdown-menu {
    bottom: 35px;
    top: initial;
    min-width: auto;
}

.pagiPosBot .react-bs-table-pagination .react-bs-table-sizePerPage-dropdown .dropdown-menu {
    bottom: initial;
    top: initial;
    min-width: auto;
}

.msts_theme_example .msts__heading .msts__side_available,
.msts_theme_example .msts__heading .msts__side_selected {
    background-color: #dcf0f2 !important;
    font-weight: 600;
}



.whiteSpacePre {
    white-space: pre-line;
}

.hideROInput {
    @include displayNone;
}


.wrpAttTab {
    word-break: break-word;
}

.addWhiteSpc td {
    white-space: pre-wrap;
    word-break: break-word;
}

.addbtntr {
    float: right;
    background-color: transparent !important;
    color: #ffffff !important;
    padding: 0px 0px !important;
    border: none !important;
}

.rBoxStyle .btn.addbtntr:focus {
    background-color: #ffffff !important;
}

.serbdbtn .addbtntr {
    padding: 2px 6px !important;
    margin: 0;
    position: absolute;
    right: 10px;
    top: 8px;
    background: #ffffff !important;
    width: 50px;
    text-align: center;
    @include ovrFlowContent;
}



a[title="Ascending"],
a[title="ascending"],
a[title="Descending"],
a[title="descending"] {
    padding: 2px 4px;
    @include displayInlineBlock;
    height: 18px;
    border: 1px transparent solid;
    clear: both;
    vertical-align: text-bottom;
    text-align: center;
    line-height: 14px;
}

a[title="Ascending"]:hover,
a[title="ascending"]:hover,
a[title="Descending"]:hover,
a[title="descending"]:hover,
a[title="Ascending"]:focus,
a[title="ascending"]:focus,
a[title="Descending"]:focus,
a[title="descending"]:focus {
    color: #ffffff;
    border: 1px #000000 solid;
    background-color: var(--primaryColor);
}

a[title="Ascending"] i,
a[title="ascending"] i,
a[title="Descending"] i,
a[title="descending"] i {
    position: relative;
    top: -2px;
}

.infoicon {
    font-size: 14px;
    color: #0055b9;
    position: relative;
    top: -3px;
    left: 2px;
    @include displayInlineBlock;
    text-align: initial;
}

.rowFocus {
    border: 0;
    padding: 0;
    margin: 0;
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    white-space: nowrap;
    @include bgTransparent;
    box-shadow: none;
}

.drpdwntop .rbt-menu.dropdown-menu.show {
    top: auto !important;
    bottom: 70px !important;
    max-height: 200px !important;
}

.grayGradi {
    background: #eeeeee;
    background: -moz-linear-gradient(top, #eeeeee 0%, #cccccc 100%);
    background: -webkit-linear-gradient(top, #eeeeee 0%, #cccccc 100%);
    background: linear-gradient(to bottom, #eeeeee 0%, #cccccc 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#cccccc', GradientType=0);
}

#allcomments {
    outline: none;
}

.dropdown-menu.show {
    display: block;
    z-index: 900;
}



.btn-close {
    width: 10px;
    height: 10px;
    padding: 0px;
    line-height: 1;
}

.btn-close .rbt-close-content {
    @include displayNone;
}

.rbt.error input,
.rdt.calIcon.error .form-control {
    border-color: red !important;
    border-radius: 6px;
}

.form-control {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555555;
    @include bgWhite;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
}

.form-control:focus,
.form-select:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(102, 175, 233, 0.6);
}

.kendoDropDownList {
    display: inline-flex;
    width: 100%;
    padding: 1px 1px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555555;
    @include bgWhite;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;

    &:focus {
        border-color: #66afe9;
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
            0 0 8px rgba(102, 175, 233, 0.6);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
            0 0 8px rgba(102, 175, 233, 0.6);
    }

    &:hover {
        @include bgWhite;
    }

    .k-input-value-text {
        max-width: 135px;
    }
}

.kendoDropdownPopup {

    .k-list-item,
    .k-list-optionlabel {
        &.k-selected {
            background-color: var(--primaryColor);

            &.k-focus {
                box-shadow: none;
            }
        }
    }
}

.kendoTextBox {
    border-color: #cccccc;
}

.rd3t-node,
.rd3t-leaf-node {
    fill: var(--primaryColor) !important;
    stroke: #ffffff !important;
    stroke-width: 1 !important;
}

.rd3t-label__title,
.rd3t-label__attributes {
    font-weight: normal !important;
}

.hide36 {
    height: 36px !important;
}

.height34 {
    height: 34px !important;
}


#autoSuggest-w-100 {
    width: 100% !important;
}

.fulfillHeader li {
    padding: 0 3px !important;
}

.modal-backdrop.show {
    opacity: var(--modalBackDrop) !important;
}

.onBehalfModal.modal.show .modal-dialog {
    position: absolute;
    right: 6px;
    top: 34px;
    width: 300px;
}



.circleNaIcn {
    height: 25px;
    width: 25px;
    background-color: #156be9;
    border-radius: 50%;
    margin-right: 0px;
    text-align: center;
    font-size: 12px !important;
    color: #ffffff !important;
    float: left;
    padding-top: 4px;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
}

.circleNa {
    cursor: pointer;
    margin-right: 20px;
}

.tableWordBreak tbody td {
    word-break: break-word;
}

.eyeicn {
    position: absolute;
    top: 6px;
    right: 0px;
    z-index: 1;
    font-size: 11px;
    padding: 2px 10px;
}

.workdayScheTimeZone hr {
    @include displayNone;
}

.sdeskTable thead tr {
    background-color: #eaeaea;
}

.sdeskTable thead tr th {
    font-weight: 600;
}

.rBoxStyle input::-ms-reveal,
.rBoxStyle input::-ms-clear {
    @include displayNone;
}

.tooltip-cmdb {
    position: absolute;
    background-color: #f9f9f9;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    pointer-events: none;
}

.slaPlusBtn {
    position: relative;
    top: 3px;
}


.adSrch svg {
    stroke-width: 0.5;
}


.closbtn {
    border: none !important;
    width: 11px;
    height: 11px;
}

.rd3t-link {
    stroke: var(--primaryColor) !important;
    stroke-width: 2;
}

.related:hover {
    font-weight: 700;
}

#treeWrapper {
    height: 90vh;
}

.outer-circle {
    stroke: var(--primaryColor) !important;
}

.wrap-node-text {
    word-wrap: break-word;
    width: 90%;
    color: var(--primaryColor);
    font-weight: 600;
}

.processHiClsBtn {
    background: var(--primaryColor);
    border: 0;
    color: #ffffff;
    padding: 1px 2px;
    font-size: 20px;
    position: relative;
    top: -3px;
}

tbody:has(.compositeBrdrBotm) tr {
    border-bottom-color: #ffffff;
}

.slasta {
    width: 8px;
    height: 8px;
    @include displayInlineBlock;
    text-indent: -200px;
    border-radius: 50%;
    overflow: hidden;
    cursor: auto;
    margin-left: 10px;
}

.forCloicn .dvTypehd .icn {
    top: 5px;
    right: 5px;
}

.forClosIcn1.dvTypehd .icn {
    top: 5px;
    right: 5px;
}

.tag-btn-container .btn-toolbar {
    flex-wrap: nowrap;
}

.questionSvg {
    margin-left: 5px;
    font-size: 16px;
    position: relative;
    top: -1px;
    stroke-width: 6;
}

.attrBtn.table tr .btn {
    padding: 4px 10px !important;
}

.searchIcon {
    input::after {
        position: absolute;
        right: 2px;
        bottom: 10px;
        content: "\f002";
        font: normal normal normal 12px/1 "FontAwesome", sans-serif;
        color: #666;
    }
}

.rBoxRightSide .rBoxGap {
    min-height: 400px;
}

.playlist-name span {
    margin-left: 6px;
}


.member-add-head {
    @include colorBlack;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
}

.deleteFilter {
    background-color: #f7f7f7;
    padding: 5px 10px;
    margin-bottom: 4px;
    box-shadow: 0px 1px 1px 0px rgb(0 0 0 / 20%);
}


span.custom-swap-separator {
    position: absolute;
    min-height: 130px;
    top: 0px;
}

span.custom-swap-separator::before {
    position: absolute;
    height: 130px;
    width: 1px;
    border: 1px dotted #ddd;
    content: "";
    right: 0px;
    left: -10px;
}

span.custom-swap-separator svg {
    position: absolute;
    left: -16px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    height: 30px;
    margin-bottom: auto;
    @include bgWhite;
    color: #ddd;
    padding-top: 4px;
    padding-bottom: 4px;
}

.innerCatgryDrpdwn.nav-item {
    border: none;
}

.innerCatgryDrpdwn.nav-item .nav-link {
    color: #212529;
    padding: 0.25rem 1rem;
    border-radius: 0px;
}

.taskInfoicn {
    margin-left: 5px;
    position: relative;
    top: -2px;
}

//******* Governacne start *******//

.occurnance .accordion-header .accordion-button.collapsed::after {
    color: #828282;
    content: "\f067";
    @include FontAwesomeFamily;
    font-size: 11px;
    font-weight: 300;
    position: absolute;
    right: 4px;
    top: calc(50% - 6px);
    background-image: none;
    transition: none;
}

.occurnance .accordion-header .accordion-button:not(.collapsed)::after {
    color: #828282;
    content: "\f068";
    @include FontAwesomeFamily;
    font-size: 11px;
    font-weight: 300;
    position: absolute;
    right: 12px;
    top: calc(50% - 16px);
    background-image: none;
    transition: none;
}

.occurnance .accordion-header .accordion-button {
    padding: 8px;
    font-size: 14px;
    position: relative;
    @include colorBlack;
    background: #f5f5f5 !important;
    border-radius: 1px;
    outline-offset: 0px;
    border: none;
    box-shadow: none;
}

.occurnance .accordion-item:not(:first-of-type) {
    border-top: 1px solid #dee2e6;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.accordion-item:last-of-type {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

/* ******* Governacne END ******* */

.btn-week button {
    border: 1px solid #ddd;
    height: 30px;
    margin-right: 10px;
    padding: 3px;
    text-transform: capitalize;
    width: 36px;
    @include bgWhite;
    line-height: 20px;
    font-size: 14px;
}

.week-btn.selected {
    background-color: #7dbc26 !important;
    color: #ffffff;
}

.disAnchor {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
}

/*Admin Preferences*/
.custom-grey-bg .nav-link {
    font-size: 14px;
}

.custom-grey-bg .nav-link.active {
    background-color: var(--primaryColor-lighter);
    color: var(--anchorColor-darker);
}

.custom-grey-bg {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background: #f0f0f0;
    min-height: 545px;
}

.bg-lightBlue {
    // background-color: #edf3f7;
    border-right: 1px solid #ddd;
}

.bor-b-1 {
    border-bottom: 1px solid #ddd;
    width: 100%;
}

.dark-blue-grey {
    // background-color: #e4eaee;
    border-right: 1px solid #ddd;
}

.pos-r {
    position: relative;
    overflow-y: auto;
}

.pos-a {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    max-height: 545px;
    overflow-y: auto;
}

.p-10 {
    padding: 15px;
}

.w-80 {
    width: 100%;
    position: relative;
}

.changes-reset-btn {
    position: relative;
    right: -15%;
    top: 6px;
}

.changes-reset-btn a {
    background-color: var(--primaryColor);
    color: #fff;
    padding: 7px 10px;
}

.no-bor {
    border: none;
}

.pos-a label {
    color: #000;
    font-size: 14px;
}

.inst-text {
    font-size: 12px;
    color: #444;
    display: block;
    font-style: italic;
}

.custom-grey-bg .flex-column {
    margin-top: 7px;
}

.custom-grey-bg .flex-column li a {
    color: #444;
    font-size: 13px;
}

.top-setting {
    background-color: #ddd;
    padding: 3px;
}

.overridden-chk label {
    vertical-align: top;
    padding-left: 4px;
}

.overridden-chk input {
    padding-right: 5px;
    vertical-align: middle;
}

.custom-comp-select {
    padding: 0.375rem 0.75rem 0.375rem 0.25rem;
}

#defaultTab {
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#defaultTab .nav-item .nav-link.active {
    background-color: var(--primaryColor);
    color: #fff;
}

#defaultTab .nav-item .nav-link {
    padding: 5px 15px;
    border-radius: 0px;
    background-color: #fff;
}

#defaultTab .nav-item .nav-link:focus {
    outline: none;
}

.min-h-545 {
    min-height: 545px;
    border-bottom: 1px solid #ddd;
}

.pt-7 {
    padding-top: 7px;
}

/*Admin Preferences*/

.noWrapName,
.noWrapPieChart {
    white-space: nowrap;
}

.noWrapPieChart .piec {
    @include displayInlineBlock;
    margin-right: 10px;
    vertical-align: text-bottom;
}

.dotLineHeight {
    line-height: 10px !important;
}

.greyDot {
    height: 10px;
    width: 10px;
    @include displayInlineBlock;
    background-color: #9e9e9e !important;
    border-radius: 50% !important;
    box-shadow: none;
    border: none !important;
    padding: initial !important;
}



//////////////////////////////////////////////////////////////////////////////////


.tbSerialTxt {
    font-size: 11px;
    color: rgb(0, 0, 0, 70%);
}



// this is for analytics iframe



.custom-border-1 {
    border: 1px solid #ddd;
}


.edit-level {
    display: block;
    margin-bottom: 3px;
    text-align: center;
}

.trash-level {
    display: block;
    text-align: center;
}

.form-switch.form-check-reverse .form-check-input {
    transform: scale(1);
    position: relative;
    top: -2px;
    font-size: 16px;
    background-color: #f0f0f0;
    border: 1px #c9c9c9 solid;

    &:checked {
        background-color: #00875a;
        border: 1px #006644 solid;
    }
}

.centertxt {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
}


// 5








.rule-query .queryBuilder .rule-operators {
    width: 100%;
}



.fullViewLoadDv {
    width: 100%;
    height: 125%;
    position: absolute;
    z-index: 99999;
    background: rgb(255 255 255 / 40%);
    margin-top: -70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reportGrpfield .btn {
    padding: 8px 8px;
    font-size: 14px;
    background: rgba(0, 0, 0, .1) !important;
    color: #000000 !important;
    display: inline-flex;

    &.ciCrtCatBtn {
        padding: 8px 6px;
        font-size: 16px;
        margin-right: 3px;

        &:last-child {
            margin-right: 0px;
        }
    }
}

.condition-b #react-autowhatever-1 {
    right: 0px;
    width: 300px;
}

.eyepassdv .form-control {
    padding-right: 40px;
}

.hyperLinkCntrct {
    color: var(--primaryColor) !important;
    text-underline-offset: 2px;

    &:hover {
        text-decoration: underline;
    }
}

.group-dd {
    z-index: 2;
    border-color: #ccc;
    white-space: nowrap;
    vertical-align: middle;
    display: table-cell;
    height: 34px;
    padding: 4px 10px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background: linear-gradient(to bottom, #eeeeee 0%, #cccccc 100%);
}

.rule-sg label {
    background-color: #eee;
    width: 100%;
    padding: 5px;
    margin-bottom: 5px !important;
}

.slaAttrTable {
    width: 100%;

    tbody {
        tr {
            td {
                width: 75%;
                border: none;

                &:first-child {
                    width: 25%;
                    padding: 1rem 1px;
                }
            }
        }
    }
}

.partWithKA {
    display: flex;
    gap: 30px;

    .prt1 {
        width: 70%;
    }

    .fullprt {
        width: 100%;
    }

    .prt2 {
        margin-left: auto;
        order: 2;
        width: 30%;
    }

    .accordion-button {
        color: #000;
        font-size: 14px;
        font-weight: 500;
        padding: 8px 15px 8px 15px;
        box-shadow: none;
        outline-offset: -2px;
    }

    .accordion-button:not(.collapsed) {
        background-color: #f5f5f5;
    }
}

.forumDvCol {
    max-width: 220px;
}

.notiChkBtn {
    #custom-switch {
        position: relative;
        top: -2px;
        outline: none !important;
        box-shadow: none;
        height: 18px;
        width: 36px;
        border: 1px #999 solid;
        cursor: pointer;

        &:focus {
            transform: scale(1);
        }
    }

    .form-check-label {
        cursor: pointer;
        padding-left: 10px;
        display: inline !important;
    }
}

.wboxTip {
    border: none;
    padding: 6px;
    box-shadow: 0px 0px 0px 2px #ccc;
    border-radius: 5px;
    opacity: 1;
    background-color: #fff;
    width: 200px;
    font-size: 12px;
    color: #333;
    z-index: 100;
    margin: 5px;
}

.dropzoneBox {
    height: 50px !important;
    display: flex;
    align-items: center;
    margin-top: 5px;
    justify-content: center;
    font-size: 14px !important;
    cursor: pointer;
}

.dropZicon {
    border: 1px #000 dashed;
    color: red;
    width: 20px;
    height: 20px;
    font-size: 11px;
    text-align: center;
    border-radius: 50%;
    margin-right: 10px;
}

.dropZFle {
    @include ovrFlowContent;
}

.dropZFleDv {
    display: flex;
    margin-bottom: 2px;
    font-size: 13px;
}

.dropZRemov {
    margin-left: auto;
    order: 2;
}

svg.rotateStack {
    transform: rotate(-90deg);
}

.k-animation-container {
    width: 100%;
}

.transition-colors:hover {
    background-color: #eee;
}

.ck-quil {
    .ql-editor {
        height: 120px;
    }
}

.walkbtn {
    border-radius: 4px;
    font-size: 12px;
    padding: 2px 10px;
    position: relative;
    top: -7px;
    margin-right: 15px;
}

.walkbtn:hover,
.walkbtn:focus {
    color: #fff !important;
    outline: 1px #000 solid;
    outline-offset: 1px;
}

.formBuilBtn {
    border: 1px #bbbbbb solid;
    color: #666;
    padding: 0px 4px 4px;
    @include bgTransparent;
    border-radius: 2px;
}

.formBuilBtn:hover,
.formBuilBtn:focus {
    background-color: var(--primaryColor);
    color: #fff;
}

.backtolist {
    float: right;
    margin-right: 10px;
}

.gjs-cell .tab-pane .container-fluid {
    padding: 0px;
}

.inlinefnd>div {
    @include displayInlineBlock;
}

.inlinefnd {
    margin-right: 210px;

    .dvTypehd {
        width: auto;

        >.icn {
            top: 4px;
        }

        .rbt .rbt-input {
            padding: 3px 25px 3px 12px !important;
        }
    }
}


// .tabSerhed .catBordDv .rightTrue header {
//     top: 44px;
// }

[class*="renderedView-"] .catBordDv .rightTrue header {
    top: 40px;
}

[class*="editcmdb-"] .catBordDv.bsTableRelate.leftSeardv.hdprt header {
    position: initial !important;
}

[class*="categoryBoard"] .rwLeftPart {
    .sPageHeading1.display-inline-block {
        margin: 20px 0px 10px;
        position: initial !important;
    }
}

[class*="categoryBoard"] .cIcatBordDv {
    position: relative !important;

    .myOrderDiv {
        .dtaTableDv.integrateHubTable {
            header {
                top: -36px;
            }
        }
    }
}

.surveyAttchmnt {
    display: flex;
    gap: 10px;

    div {
        word-break: break-all;
    }
}

.placeholderActive input::placeholder {
    color: #555555 !important;
}

.form-control .k-input-inner {
    outline: 0px;
    padding: 0px;
}

.form-control:has(> .k-input-inner:focus) {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.mybtImprt .dropdown-menu {
    top: 6px !important;
    width: 250px;
    padding: 0px;
    right: -6px !important;
    border-radius: 4px;
}

.dropzoneAreacss {
    padding: 10px;
    background-color: #f4f4f4;
    border-radius: 6px;
}

.dropzoneAreacss .form-control {
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    margin-bottom: 5px;
    padding: 20px 10px;
}

.dropzoneAreacssSiz {
    display: flex;
    font-size: 11px;
    padding: 0px 2px;
    color: #777;
}

.dropzoneAreacssSiz .chld2 {
    margin-left: auto;
    order: 2;
}

.dropzoneAreacss button {
    font-size: 12px;
    border: none;
    margin: 5px 0px;
    padding: 4px 20px !important;
    color: #ffffff !important;
    background-color: var(--primaryColor) !important;
    border-radius: 2px;
}

.dropzoneAreacss button.cancel {
    background-color: rgba(0, 0, 0, 0.1) !important;
    color: #000000 !important;
    margin-left: 10px;
}

.aiInsightsBtn {
    position: absolute;
    bottom: calc(100% - 20rem);
    right: 0px;
    z-index: 998;

    a,
    Button {
        width: 22px;
        height: 115px;
        display: flex;
        font-size: 14px;
        color: #fff;
        background-color: var(--aiButtonBG);
        border: none;
        border-radius: 2px;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 2px -1px #000;
        text-orientation: mixed;
        writing-mode: tb-rl;
    }
}

.aiTxt {
    font-size: 14px;
    line-height: 18px;
    color: #333;
}

.aiSecBox {
    display: flex;
    align-items: center;

    .aiSec1 {
        background-color: var(--primaryColor);
        color: #fff;
        padding: 10px 20px;
        border-radius: 4px;
        margin-right: 25px;
        text-align: center;
    }
}

.predictionList {
    tbody {
        tr {
            td {
                vertical-align: middle;
                min-width: 200px;

                &:first-child {
                    position: relative;
                    border-right: 1px solid #ddd;
                    height: 45px;
                    min-width: 135px;
                    color: var(--pageTxtColor);

                    p {
                        max-width: 110px;
                        min-width: 90%;
                        @include ovrFlowContent;
                    }

                    a{
                        @include display(inline-flex);
                        position: absolute;
                        top: 15px;
                        right: 5px;
                    }
                }

                &:last-child {
                    text-align: right;
                    min-width: 30px;
                }

                .form-control {
                    border: #f5f5f5;
                    padding: 3px 5px !important;
                    background-color: #f5f5f5;
                }
        
                .form-check-input {
                    border-color: #b6b6b6;
                }
            }
        }        
    }
}

.inactive {
    cursor: default;
    opacity: 0.5;
    background-color: #eee;
}

.inciSupProviderDv {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .showGrpDv {
        display: flex;
        flex-wrap: wrap;
        width: auto;
        gap: 10px;
        flex-direction: row-reverse;
        align-items: center;
    }
}

.bg-none {
    background-image: none !important;
}

.validateErrorMessage {
    font-size: 13px;
    color: red;
    padding-top: 2px;
}

.validateCi {
    margin-bottom: 10px;

    .form-group {
        margin-bottom: 0px !important;
    }
}

.serviceReviewRatingColor {
    color: var(--secondaryColor) !important;
}

.ck-quil * {
    outline: none;
}

.visibleTrue {
    display: block;
}

.visibleFalse {
    display: none;
}

.display-contents {
    display: contents;
}

.tskSupportDropdown option {
    font-size: 13px;
}

[class*="editDashboardConfiguration-"] .reportGrpfield.row {
    .d-inline-flex.align-items-center {
        padding-left: 0px;
    }

    .col-md-4 {
        width: 31%;
    }
}

button.ruleGrpBtn {
    background-color: #ff0000 !important;
    padding: 3px 4px;
    border-radius: 0px;
    display: flex;
    border-color: #ff0000 !important;
}

.add_rule {
    font-size: 12px;
    padding: 2px 4px;
}

.queryBuilder {
    &:has(.aiRuleInptWidth) {
        font-size: 14px;
    }
}

.aiRuleInptWidth {
    width: 25%;

    &:has(.form-check) {
        width: auto;
    }

    .typeaheadInput {
        margin-bottom: 0px;

        .rbt-input {
            min-height: 22px;
            padding: 0px 6px !important;

            &.rbt-input-multi {
                .rbt-input-wrapper {
                    margin-bottom: 0px;
                    margin-top: 0px;
                }

                .rbt-input-main {
                    margin: -1px 0px 0px;
                }
            }
        }
    }

    textarea.form-control {
        padding: 4px 6px;
    }

    .aiMultiSelectDropdown,
    .colSearDv {
        margin-bottom: 0px;
    }

    .form-control,
    .aiMultiSelectDropdown button {
        border-color: #767676 !important;
        border-radius: 0;
        font-size: 14px;
    }

    .form-check {
        input {
            height: 13px;
        }

        &:last-child {
            margin-left: 15px;
        }
    }
}

.onbehalfpop .form-control {
    padding-right: 25px;
}

.supported-formats {
    text-align: center;
    margin: 1rem auto 0;
    padding: 0 10rem;
    font-size: .75rem;
    display: inline-flex;
    align-items: center;
    width: 100%;
    flex-direction: column;

    span {
        font-weight: 600;
    }
}

custom-td,
custom-th {
    display: table-cell;
    border: 1px #ccc solid !important;
}

.formsTable header {
    right: 11px !important;
    top: 88px !important;
    z-index: 0 !important;

    .tabFiltabDv {
        .form-control {
            padding: 3px 25px 3px 12px !important;
        }
    }
}

.k-dialog-wrapper {
    z-index: 1044 !important;
}

.btnthreeDot {
    float: right;
    margin-top: 3px;

    >a {
        display: block;
        padding: 2px 4px;
        margin: 0px 2px 0px 10px;
        background-color: #f7f7f7;
        color: #000000;
        border: 1px solid #ccc;
        border-radius: 4px;

        &:focus {
            outline-offset: 0px;
        }
    }

    .dropdown-menu.show {
        padding: 5px 12px;
        min-width: 170px;
        right: -2px !important;
    }

    .dropdown-toggle::after {
        display: none;
    }
}

[class*="connectors"] .catBordDv.hasRole .rightTrue.srchPosTop1 header {
    right: 192px;
    top: 45px;
}

.infoTyRow {
    margin: 15px 0px 0px;
    padding: 10px 0px 0px;
    background-color: rgba(0, 0, 0, .04);
}

.infoTyRowCh {
    background-color: rgba(0, 0, 0, .04);
    margin: 0px 0px 15px;
    padding: 0px 5px;

    .ql-toolbar.ql-snow .ql-formats {
        margin-right: 10px;
    }
}

.k-disabled {
    background-color: #e9ecef;
}

.ui-builder-orderedlist {
    list-style-type: decimal !important;
}

.ui-builder-unorderedlist {
    list-style-type: disc !important;
}

.dvTableError {
    border: red 1px solid;
}