@import "../general/mixin";

.aiButtonCss {
    & button {
        color: #fff !important;
        background-color: var(--aiButtonBG) !important;
        margin: 0px 0px 5px !important;
    }

    & button.dropdown-toggle {
        padding: 4px 8px 4px !important;
        outline-offset: -1px !important;
        border-left: 1px rgb(255 255 255 / 40%) solid !important;
        margin-left: 4px !important;
        position: relative;
        top: 0px;
    }

    svg {
        position: relative;
    }
}

.aiMultiSelectDropdown {
    .multiSelectRef {
        >button {
            background-color: #ffffff !important;
        }
    }

    .multiSelectPopup {
        >div>div>div {
            max-height: 200px;
        }
    }
}

.genAiIcon {
    float: right;
    border: none;
    background-color: var(--aiButtonBG);
    padding: 2px;
    margin-bottom: 5px;

    &:hover {
        background-color: var(--aiButtonBG) !important;

    }

    &.k-disabled {
        background-color: var(--aiButtonBG-light)
    }

    svg {
        @include widthHeight(15px, 15px);
    }

    span {
        @include display(flex);
    }
}

.genAiPopdv {
    width: 100% !important;
    top: auto !important;
    left: auto !important;

    .k-popup {
        //box-shadow: none;
        border-color: rgba(0, 0, 0, 20%);
    }

    .genAiSearch {
        margin: 0px 5px 5px;
        @include display(flex, center);
        position: relative;

        .k-input {
            border: 0px;
            background-color: rgba(0, 0, 0, 5%);
            padding: 5px 25px 5px 10px;
            font-size: 13px;


            &:focus-visible {
                outline: none;
            }

            &::placeholder {
                color: rgba(0, 0, 0, 45%) !important;
            }
        }

        >a {
            position: absolute;
            right: 5px;
            top: 3px;
            color: rgba(0, 0, 0, 60%);

            &:hover {
                color: var(--anchorColor);
            }
        }
    }

    .genAiHd {
        @include display(flex, center, space-between);
        flex-wrap: wrap;
        width: 100%;

        h4 {
            @include display(flex, center);
            font-size: 14px;
            font-weight: 600;
            color: rgba(0, 0, 0, 60%);
            gap: 7px;

            svg {
                background-color: var(--aiButtonBG);
                padding: 2px;
                border-radius: 4px;
                @include widthHeight(18px, 18px);
            }
        }

        .k-button {
            border: 1px solid #ccc !important;
            padding: 4px;

            span {
                @include display(flex);
            }
        }
    }

    .genAiHd2 {
        @include display(flex, center, space-between);
        flex-wrap: wrap;
        width: 100%;
        margin-top: 10px;
        gap: 10px;

        h2 {
            @include display(flex, center);
            font-size: 22px;
            font-weight: 500;
            color: var(--primaryColor-darker);
            gap: 6px;

            >a {
                // &:first-child {
                //     background-color: var(--anchorColor-lighter);
                //     border-radius: 4px;
                //     @include display(inline-flex);
                //     padding: 2px;
                //     font-size: 14px;
                // }

                &:last-child {
                    color: var(--aiButtonBG);
                    font-size: 18px;
                }
            }
        }

        .k-button-group {
            .k-selected {
                background-color: rgba(0,0,0, 50%);
                border: 0px;
                font-size: 16px;
            }
        }

        .k-dropdownlist {
            align-items: center;
            padding-left: 5px;
            border-radius: 0px 4px 4px 0px;
            width: auto;
        }
    }

    .k-popover-body {
        padding-block: 12px;
        padding-inline: 16px;

        &:has(.aiCntntDvCntent) {
            .genAiList {
                margin-top: 20px;
            }   
        }
    }

    .genAiList {
        @include display(flex, center, space-between);
        flex-direction: row;
        margin-top: 15px;
        width: 100%;
        flex-wrap: wrap;
        gap: 20px 10px;
        position: relative;

        a {
            width: 32%;
            @include display(flex, center, space-between);
            flex-wrap: wrap;
            color: var(--pageTxtColor-light);
            font-size: 14px;
            border-radius: 4px;
            padding: 6px 4px;
            background: rgba(0, 0, 0, 4%);

            >span {
                width: 100%;
                @include display(flex, center);

                .genAiListHd {
                    margin-left: 5px;
                    width: 100%;
                }

                svg {
                    font-size: 16px;

                    &:last-child {
                        float: right;
                    }
                }
            }

            &:hover {
                background-color: rgba(0, 0, 0, 5%);
            }
        }
    }

    .aiCntntDvCntent {
        min-height: auto;
        max-height: 200px;
        margin: 10px 0px;
        word-break: break-all;
        overflow: hidden;
        overflow-y: auto;

        @include scrollbar(6px);

        pre {
            font-size: inherit !important;
            font-family: inherit !important;
        }
    }

    .aiCntntDvFootr {
        @include display(flex, center, space-between);

        .ftrLeft,
        .ftrRight {
            @include display(flex, center);
            gap: 15px;
        }

        svg {
            color: var(--pageTxtColor-light);
            font-size: 20px;
        }

        .ftrLeft {
            .genAiListBack {
                @include display(flex, center);
                background: none;
                width: auto;
                font-size: 12px;
                font-weight: 500;
                padding-left: 0px;
                color: var(--anchorColor-darker);

                svg {
                    font-size: 12px;
                    color: var(--anchorColor-darker);
                }

                &:hover {
                    color: var(--aiButtonBG);

                    svg {
                        color: var(--aiButtonBG);
                    }
                }
            }

            >p {
                color: rgba(0, 0, 0, 60%);
                font-size: 13px;
                font-weight: 500;

                >svg {
                    font-size: 16px;
                    margin-right: 5px !important;
                    color: var(--secondaryColor);
                }
            }
        }

        .ftrRight {
            .ftrBtn {
                background-color: var(--anchorColor-lighter);
                background-image: none;
                border: none;
                margin-right: 10px;

                &.dsBtn {
                    background-color: #dbdbdb;
                    color: #424242;
                }

                &:last-child {
                    margin-right: 0px;
                    background-color: var(--anchorColor);
                    color: #ffffff;
                    font-weight: 500;
                }

                .k-button-text {

                    span {
                        padding: 2px 4px;
                        font-size: 12px;
                        font-weight: 500;
                        background-color: rgba(0, 0, 0, 30%);
                        border-radius: 4px;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}