@import "../general/mixin";
@import "../rightside/knowledgeArticle";

.rBoxStyle {
    .ordBgBtn {
        margin: 0;
        padding: 0;

        a,
        button {
            display: block;
            width: 100%;
        }

        .btn.btn-primary {
            font-size: 15px;
            padding: 10px !important;
            width: 100%;
            border: 1px var(--secondaryColor) solid !important;
            color: #ffffff !important;
            background: var(--secondaryColor) !important;
            background-color: var(--secondaryColor) !important;

            &:hover,
            &:focus {
                background: var(--secondaryColor-lighter) !important;
                background-color: var(--secondaryColor-lighter) !important;
                border: 1px var(--secondaryColor-lighter) solid !important;
            }
        }
    }

    .ordBgtoolbar {
        margin: 0;
        text-align: center;

        .btn-link {
            font-size: 15px;
            color: #707070 !important;
            background: transparent !important;
            background-color: transparent !important;
            float: initial;
            border: none !important;
            text-decoration: underline;
            font-weight: 400;

            &:hover,
            &:focus {
                text-decoration: none;
                color: initial !important;
            }
        }
    }
}

#viewMDetails {
    display: flex;
    align-items: flex-start;
    width: 100%;
    word-break: break-word;

    .declg {
        line-height: 22px !important;
        width: 60%;

        p {
            margin-bottom: 10px;
        }
    }

    .bgNonTab {
        width: 45%;
        padding-right: 20px;
        vertical-align: top;

        th,
        td,
        tr {
            border: none;
            background: transparent !important;
            background-color: transparent !important;
        }

        th {
            font-weight: 600;
            font-size: 13px;
            white-space: pre;
        }
    }

    .ratRevDec {
        width: 55%;
        vertical-align: top;
    }

    .hed {
        font-size: 14px;
    }

    ul,
    ol {
        margin: 5px 15px 15px;
        padding: 0 10px;

        li {
            &:nth-child(odd) {
                background: #ededed;
            }
        }
    }

    ul {
        li {
            list-style-type: disc;
        }
    }

    ol {
        li {
            list-style-type: decimal;
        }
    }

    table {
        width: 100%;
        border: 1px #e1e1e1 solid;
        margin: 0;
        padding: 0;

        th,
        td {
            border: 1px #e1e1e1 solid;
            padding: 6px;
        }

        tr {
            &:nth-child(odd) {
                background: #ededed;
            }
        }
    }

    .progress {
        margin-bottom: 0;
        background-color: #efefef;
    }

    ul.revewLis {
        margin: 0;
        padding: 0;

        li {
            font-size: 13px;
            list-style-type: none;
            border-bottom: 1px #dcdcdc solid;
            margin-bottom: 10px;
            word-break: break-word;
            padding: 5px 10px 0px 5px;

            &:last-child {
                border-bottom: none;
            }

            .stDtdv {
                font-size: 12px;
                color: #c3c3c3;
                position: relative;
                top: -5px;
                margin-right: 10px;
            }
        }
    }
}

.offH2 {
    font-size: 18px;
    color: var(--primaryColor);
    margin: 30px 0px 10px;
    text-transform: uppercase;
    font-weight: 500;
}

.offpriceSec {
    .pbSec {
        font-size: 14px;
        margin-right: 30px;
        @include colorBlack;
        @include displayInlineBlock;

        span {
            font-size: 15px;
            color: var(--primaryColor);
            font-weight: 600;
            @include displayInlineBlock;
        }
    }
}

.reqTxtDv {
    border-right: 1px #d8d7d7 solid;
    @include displayInlineBlock;
    padding-right: 15px;
    margin-right: 10px;
}

.reqTxt {
    font-size: 16px;
    font-weight: 100;
    color: var(--primaryColor);
    margin: 0;
    padding: 0;
    position: relative;
    top: -1px;
    @include displayInlineBlock;
    border: none;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    text-align: center;

    >.fa:first-child {
        position: relative;
        left: -5px;
        top: 3px;
    }

    .infoIcn {
        position: absolute;
        top: 0;
        left: 12px;
        font-size: 12px;
    }
}

.simpCheckbox {
    @include displayInlineBlock;
    margin: 0 5px;
    position: relative;
    top: -2px;

    .label {
        line-height: normal;
        padding: 0;
        margin: 0;
    }

    input[type="checkbox"] {
        height: 16px;
        width: 16px;
        opacity: 1;
        border-color: rgb(0 0 0 / 40%);

        &:checked {
            border-color: var(--primaryColor);
            background-color: var(--primaryColor);
        }
    }
}

.proStarView {
    margin-bottom: 10px;

    >div {
        @include displayInlineBlock;
        vertical-align: bottom;
        margin-right: 15px;
        bottom: -1px;
    }

    span {
        font-size: 18px !important;
        top: -1px;
        height: 22px;
    }

    a {
        color: var(--anchorColor);
        text-decoration: underline;
        display: inline-block;

        &:hover,
        &:focus {
            outline-offset: 2px;
        }
    }
}

.proIconSet {
    @include displayInlineBlock;
    margin: 0 10px 0 0;
    vertical-align: middle;

    a {
        @include displayInlineBlock;
        font-size: 18px;
        font-weight: 100;
        color: #d8d8d8;
        margin: 0 5px;
        border: none;
        border-radius: 50%;
        height: 26px;
        width: 26px;
        padding: 3px;
        text-align: center;

        &.active,
        &:hover,
        &:focus {
            color: var(--secondaryColor);
            outline-offset: 2px;
        }

        &#proShar {
            padding: 3px 3px 3px 0;
        }
    }
}

.comInsd {
    background: url("../../images/sprite-common-new.png") no-repeat 0px 0px;
    width: 24px;
    height: 24px;
    @include displayInlineBlock;
    vertical-align: text-bottom;

    &.interLnk {
        background-position: 1px -2px;
    }

    &.exterLnk {
        background-position: 1px -63px;
    }

    &.community {
        background-position: 1px -30px;
    }

    &.offering {
        background-position: 1px -96px;
    }
}

.blue1Anchor {
    a {
        color: var(--anchorColor);
        text-decoration: underline;

        &:hover,
        &:focus {
            outline-offset: 2px;
        }
    }
}

.knledbox {
    a {
        position: relative;
        padding: 6px 0px 6px 10px;
        display: block;
        font-size: 13px;
        border-bottom: 1px #e7e7e7 solid;

        span {
            left: 0px;
            top: 6px;
            width: 20px;
            height: 20px;
            background-size: 82px;
        }

        .exterLnk {
            background-position: 1px -52px;
        }

        .community {
            background-position: 1px -112px;
        }
    }
}

.piPosArrow {
    position: relative;

    &::after {
        color: #333;
        content: "\f107";
        @include FontAwesomeFamily;
        font-size: 16px;
        font-weight: 400;
        position: absolute;
        top: 8px;
        right: 6px;
    }

    .form-control {
        background-color: #ffffff !important;
        padding: 0px 10px;
        color: #333;

        &::placeholder {
            color: #333 !important;
        }
    }
}

.mySearchInput {
    position: absolute;
    top: 2px;
    right: 18px;

    &.btn-toolbar>.btn {
        margin: 0px;
        min-height: 31px;
    }
}

.accordPart {
    .panel {
        box-shadow: none;
        border: none;
        padding: 5px;
        background: rgba(245, 245, 245, 1);
    }

    .panel-default>.panel-heading {
        background: transparent;
        padding: 0;
    }

    .panel-collapse {
        .panel-body {
            padding: 0 0 0 36px;
        }
    }

    .panelParent {
        border-left: 2px #ffbf60 solid;
        padding: 10px 0 10px 10px;
    }
}

.panelPart {
    a {
        display: table;
        clear: both;

        &:after {
            @include displayNone;
        }
    }

    .leftPart,
    .rightPart {
        display: table-cell;
        vertical-align: top;
    }

    .leftPart {
        padding-right: 5px;

        img {
            border: 1px #b7b7b7 solid;
            width: 30px;
            height: 30px;
        }
    }

    .rightPart {
        font-size: 11px;
        @include colorBlack;
        width: 100%;

        .txt {
            font-size: 15px;
            color: #035eb9;
        }

        .dateTim {
            color: grey;
        }

        .addComm {
            font-size: 12px;
            border: none;
            background: transparent;
            color: #ff5400;
            padding: 0 !important;
            margin-right: 10px;
        }

        .plusComm {
            @include displayInlineBlock;
            font-size: 11px;
            border: none;
            background: transparent;
            color: #035eb9;
            padding: 0 !important;
            margin-right: 10px;
        }
    }

    .collapsed .rightPart .plusComm {
        @include displayNone;
    }

    &.ansPart {
        margin-bottom: 5px;
        font-size: 12px;
        display: table;
        width: 100%;

        >.btn {
            color: #035eb9;
            font-size: 11px;
            padding: 0 !important;
            margin-bottom: 10px;
        }

        .rightPart {
            font-size: 11px;
            @include colorBlack;
            width: 100%;

            .txt {
                font-size: 13px;
                @include colorBlack;
                line-height: 15px;
            }
        }
    }

    .commAddInput {
        font-size: 12px;
        height: 30px;
        padding: 2px 6px;
    }

    .commAddTextArea {
        font-size: 12px;
        min-height: 30px;
        padding: 2px 6px;
    }

    &.textareaPart {
        display: table;
        clear: both;
        width: 100%;

        .leftPart {
            width: 100%;
        }

        .rightPart {
            width: auto;
        }

        .addNComm {
            width: 30px;
            height: 30px;
            padding: 0 3px 0 0 !important;
            margin: 0;
            background: rgba(3, 94, 185, 0.2);
            font-size: 16px;
            color: #ffffff;

            &:hover,
            &:focus {
                background: rgba(3, 94, 185, 0.8);
            }
        }
    }
}

.blueAnchor {
    color: #fd9906 !important;
    cursor: pointer;
    text-transform: capitalize;
    font-weight: 500;
}

.askCommScrollbar>.panel-group {
    padding-right: 10px;
}

#consumerSection {
    .row-eq-height {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin: 0px;

        >[class*="col-"] {
            border: none;

            &:first-child {
                -moz-box-shadow: none;
                -webkit-box-shadow: none;
                -webkit-border-radius: 0px;
                -moz-border-radius: 0px;
                border-radius: 0px;
            }
        }

        >.colRightHeight {
            margin-left: inherit;
        }
    }
}

.OfferingInlineButtons {
    margin: 0px;

    li {
        @include displayInlineBlock;
        margin-right: 20px;
    }

    .btn {
        color: #000000 !important;
        font-size: 18px !important;
        text-transform: uppercase;
        border-radius: 0px;
        font-weight: 500;
        margin-bottom: 0px;
        padding: 2px 0px !important;
        background-color: transparent !important;
        box-shadow: none;
        border-width: 1px !important;
        border-color: transparent !important;

        &:hover,
        &:focus {
            color: var(--primaryColor);
            border-bottom-color: transparent !important;
            outline-offset: 2px;
            background-color: aliceblue !important;
        }

        &.disabled,
        &[disabled] {
            color: var(--primaryColor) !important;
            font-size: 18px !important;
            border-color: transparent transparent var(--primaryColor) transparent !important;
            opacity: 1;
        }
    }

    fieldset[disabled] .btn {
        color: var(--primaryColor) !important;
        font-size: 18px !important;
        border-color: transparent transparent var(--primaryColor) transparent !important;
        opacity: 1;
    }
}

.compareIcon {
    background-position: -2px -101px;
    padding: 8px 15px;

    &:hover,
    &:focus {
        background-position: -56px -101px;
    }
}


.icons {
    font-size: 16px;

    i {
        font-size: 14px;
        font-weight: 600;
        margin: 0 5px 0 0;
    }
}

.heartIcon {
    background-position: 0 -135px;
    padding: 8px 15px;
    margin-left: 10px;

    &.highlight {
        background-position: -55px -135px;
    }
}

.ratRevDec {
    .hd {
        font-weight: 600;
        border-bottom: 2px #dcdcdc solid;
        padding: 0px 0px 5px;
        margin-bottom: 10px;
    }

    .selDrpDn {
        font-size: 11px;
        float: right;
        padding: 0px 4px;
        margin-top: 5px;
        height: initial;
        width: auto;
    }
}

.pricOffer {
    color: #585858;
    font-size: 14px;
    @include displayInlineBlock;
}

.totalDv {
    margin: 16px 0px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    background-color: #f1f1f1;
    padding: 4px;
}

.delBtn {
    color: var(--primaryColor);
    font-size: 14px;
    padding: 2px 6px;
    border-radius: 2px;

    &:hover,
    &:focus {
        background-color: #717272;
        color: #ffffff;
        outline-offset: 4px;
    }
}

#OfferingDetailId {
    .page-header {
        margin: 0px 0 10px;
        border-bottom: none;

        h1 {
            color: var(--primaryColor);
            font-weight: 400;
            word-break: break-word;
        }
    }
}

.rating {
    border: none;
    float: left;

    >input {
        @include displayNone;
    }

    >label {
        color: var(--pageTxtColor);
        float: right;

        &:before {
            content: "\f005";
            @include displayInlineBlock;
            font-size: 1.25em;
            @include FontAwesomeFamily;
            margin: 5px;
        }
    }

    >.half:before {
        content: "\f089";
        position: absolute;
    }

    >input:checked~label,
    &:not(:checked)>label:hover,
    &:not(:checked)>label:hover~label {
        color: var(--secondaryColor);
    }

    >input:checked+label:hover,
    >input:checked~label:hover,
    >label:hover~input:checked~label,
    >input:checked~label:hover~label {
        color: var(--secondaryColor);
    }
}

.dv-star-rating-star {

    &:hover,
    &:focus {
        color: var(--secondaryColor-lighter) !important;
    }

    &:hover~.dv-star-rating-empty-star {
        color: var(--secondaryColor-lighter) !important;
    }
}

.closeSpan {
    cursor: pointer;
    @include displayInlineBlock;
    font-size: 12px;
    color: #ffffff;
    background-color: var(--secondaryColor);
    font-weight: 800;
    position: absolute;
    top: -8px;
    right: -8px;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 50%;
    line-height: 20px;

    &Shadow {
        .rPageHeading {
            font-size: 18px;
            margin-bottom: 5px;
        }
    }
}

.offgsBorHad {
    padding: 10px;
    border: 1px #e2dfdf solid;
    background-color: #f9f9f9;
    word-break: break-word;
}

.offHeDv {
    margin: 4px 0px 2px;

    .hd {
        font-size: 15px;
        color: #353535;
    }

    .lnk {
        a {
            color: #8b8c90;
            font-size: 13px;

            &:hover,
            &:focus {
                @include colorBlack;
                outline-offset: 2px;
            }
        }
    }
}

.offDetHed {
    font-size: 15px;
    color: var(--primaryColor);
    @include bgTransparent;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: 500;
    padding: 0px;
}

.greyDethad {
    font-size: 14px;
    color: #ababab;
}

.relatedOfferDv {
    display: table;
    width: 100%;

    .penTd {
        display: table-cell;
        vertical-align: top;

        a {
            color: var(--anchorColor);
            text-decoration: underline;
            @include displayInlineBlock;

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }

        .txtdes {
            color: #575757;
            font-size: 12.5px;
        }
    }

    .imbx {
        width: 17%;

        a {
            width: 45px;
            height: 45px;
            border: 1px solid var(--primaryColor);
            border-radius: 50%;
            padding: 0;
            margin: 0;
            @include displayInlineBlock;
            text-align: center;

            img {
                height: 30px;
                width: 30px;
                position: relative;
                top: calc(50% - 14.5px);
                text-indent: -999px;
                overflow: hidden;
                border-radius: 0;
                margin: 0 auto;
                padding: 0;
            }
        }
    }

    .txbx {
        width: 83%;
        padding: 0px 0px 0px 5px;
        word-break: break-all;
    }
}

.nowraptxt {
    @include ovrFlowContent;
}

.variantDv {
    margin: 15px 0 10px;

    .innervardv {
        display: flex;
        gap: 20px;
    }

    .nav-item {
        a {
            &.dropdown-toggle {
                display: inline-block;
                color: var(--headerTxtColor-lighter);
                background-color: var(--secondaryColor);
                border: 1px solid var(--secondaryColor);
                border-radius: 4px;
                padding: 5px 10px;
            }

            &::after {
                margin-left: 6px;
            }
        }

        &.active {
            a.dropdown-toggle {
                background-color: #fff;
                color: var(--secondaryColor);
            }
        }

    }

    .dropdown-menu {
        &.show {
            padding: 0;

            a {
                outline-offset: 0;
                border-bottom: 1px #eee solid;
                padding: 6px 10px;
            }
        }
    }

    .dropdown-item {

        &:focus,
        &:hover,
        &.active,
        &.active {
            color: #212529;
            background-color: #eee;
        }
    }
}

[class*="cart-orderNow/"] {
    .tableView {
        a {
            color: #000000;

            &.customIconBtn:hover {
                color: rgb(0 0 0 / 0.9);
            }
        }
    }

    .k-input {
        &.k-datepicker {
            border: 1px solid #cccccc;
        }
    }
}