@import "../general/mixin";

.offTxt {
    font-size: 14px;
    color: #4a4a4a;
    line-height: 20px;
    margin: 0;
    word-break: break-word;

    ul,
    ol {
        margin: 2px 0 5px 15px;
        padding: 0 10px;
    }

    ul li {
        list-style-type: disc;
    }

    ol li {
        list-style-type: decimal;
    }

    table {
        width: 100% !important;

        td {
            width: auto !important;
        }
    }

    table,
    table td,
    table td p,
    table td div {
        word-break: break-word;
    }
}

.catPanelGroup {
    margin-bottom: 0;

    .panel {
        border: none;

        &-default>.panel-heading {
            background: none;
            padding: 0;
            margin-bottom: 10px;
        }

        .panel-heading {
            a {
                &.collapsed {
                    @include bgWhite;
                    border-bottom: 1px #bdcfe0 solid;
                    border-right: 1px #bdcfe0 solid;
                }

                &:hover,
                &:focus {
                    border-color: #92b2d0;
                }

                font-size: 14px !important;
                padding: 6px !important;
                text-transform: none;
                background-color: #f3f3f3;
                border: 1px #e6e6e6 solid !important;

                &.collapsed:after {
                    content: "\F055" !important;
                    font-size: 15px !important;
                }

                &:after {
                    color: #3f8def !important;
                    content: "\f056" !important;
                    font-size: 15px !important;
                }
            }

            &.fieldRequired a.collapsed {
                border-bottom: 1px solid red;
            }
        }

        .panel-body {
            padding: 0 15px;

            label {
                color: var(--pageTxtColor);
                margin-bottom: 3px;
                line-height: 17px;
                font-size: 13px;
                font-weight: 400;
                display: block;
                text-transform: none;
                word-break: break-word;
            }
        }
    }

    .panel-collapse,
    .accordion-collapse {
        // background: url("../../images/catPanelGroupBg.png") #edf3f7 repeat-y left top;
        background-size: 50%;
    }

    .botLineWhite {
        border-bottom: 2px #ffffff solid;
        // padding-bottom: 6px;
        // margin-top: 6px;

        label {
            margin-right: 10px;
        }

        input[type="checkbox"],
        input[type="radio"] {
            margin-right: 5px;
            position: relative;
            top: -1px;
        }

        >div {
            padding-bottom: 6px;
            padding-top: 6px;
            background-color: #ffffff;            

            &:first-child {
                background-color: var(--primaryColor-lightest)
            }
        }
    }

    .catPanelGroupBg {
        &.accordion-body {
            margin-top: 5px;
            padding: 2px 10px 0;
        }

        .botLineWhite {
            label {
                margin: 10px !important;
                //display: flex;
                white-space: pre-line;
                word-break: break-word;
            }

            input[type="checkbox"],
            input[type="radio"] {
                margin-right: 5px;
                position: relative;
                top: 0;
            }
        }
    }

    .form-group {
        margin-bottom: 0;
    }

    .form-control {
        height: 32px;
        padding: 4px 8px;
        font-size: 13px;

        &.dropFileBtn {
            padding: 3px 85px 3px 10px !important;
            margin-top: 4px;
            position: relative;
            height: initial;

            button {
                float: right;
                position: absolute;
                right: 5px !important;
                top: calc(50% - 11px) !important;
                border: 1px #bbb solid;

                &:hover,
                &:focus {
                    outline-offset: 4px;
                    @include colorBlack;
                    background-color: lightcyan;
                }
            }
        }
    }

    select[multiple].form-control,
    select[size].form-control {
        height: auto;
    }
}


.btnChkbox {
    text-align: right;

    label,
    span {
        @include displayInlineBlock;
        padding: 0;
        margin: 0;
        position: relative;
        top: -3px;
    }

    input[type="checkbox"] {
        height: 16px;
        width: 16px;

        +input+span {
            background: url("../../images/sprite-common.png") no-repeat 0px 0px;
        }

        &:checked+input+span {
            background-position: -7px -301px;
        }

        +span {
            background: url("../../images/sprite-common.png") no-repeat -7px -281px;
        }

        &:checked+span {
            background-position: -7px -301px;
        }
    }
}

.btnChkboxPos {
    position: absolute;

    label {
        @include displayInlineBlock;
        padding: 0;
        margin: 0;
        position: relative;
        top: -3px;
    }

    input[type="checkbox"] {
        height: 16px;
        width: 16px;
        position: relative;
        top: 2px;
    }
}

.accordion-collapse.collapse {
    .btnChkboxPos {
        label {
            top: 0;
        }

        input[type="checkbox"],
        .collapsing input[type="checkbox"] {
            @include displayNone;
            top: 1px;
        }

        .collapse.show input[type="checkbox"] {
            display: block;
        }
    }
}

.addPenTit {
    display: table;
    width: 100%;

    .penTd {
        display: table-cell;
        vertical-align: middle;
    }

    .imbx {
        text-align: center;

        .addCatIm {
            width: 45px;
            height: 45px;
            border: 1px solid var(--primaryColor);
            border-radius: 50%;
            padding: 0;
            margin: 0 auto;
            @include displayInlineBlock;
            text-align: center;
        }

        img {
            height: 30px;
            width: 30px;
            position: relative;
            top: calc(50% - 14.5px);
            text-indent: -999px;
            overflow: hidden;
            border-radius: 0;
            margin: 0 auto;
            padding: 0;
            text-align: center;
        }
    }

    .txbx {
        padding: 0 30px 0 10px;
        word-break: break-word;
    }

    .txbxDes {
        color: #333;
        word-break: break-word;
    }
}

.oNPanGroSty {
    .panel-default {
        >.panel-heading {
            a {
                color: #3a3a3a;
                font-weight: 500;
                display: block;
                position: relative;
                padding: 8px 28px 8px 10px !important;
                word-break: break-word;

                &:after {
                    color: #565a6d;
                    content: "\f068";
                    @include FontAwesomeFamily;
                    font-size: 14px;
                    font-weight: 400;
                    float: right;
                }

                &.collapsed:after {
                    content: "\f067";
                }
            }
        }

        .panel-body {
            .panel-title {
                a {
                    font-size: 14px;
                }
            }
        }
    }
}

#orderNowOrd {
    .panel-default {
        border-color: #eaeaea;

        >.panel-heading {
            padding: 6px 10px 10px;
            border-bottom: none;
            position: relative;

            a:after {
                color: #808080;
                content: "\f068";
                @include FontAwesomeFamily;
                font-size: 12px;
                font-weight: 300;
                position: absolute;
                top: 20px;
                right: 15px;
            }

            a.collapsed:after {
                content: "\f067";
            }
        }

        .panel-body {
            position: relative;

            .panel-group {
                margin-bottom: 0px;

                .panel+.panel {
                    margin-top: 10px;
                }
            }

            .panel-heading a:after {
                top: 8px;
                font-size: 13px !important;
            }

            .form-group {
                margin-bottom: 0px;
            }

            .collapsed.panel {
                .panel-heading {
                    padding: 0px;
                }

                input[type="radio"] {
                    margin: 0px 5px 0px 0px;
                    position: relative;
                    top: -1px;
                }
            }
        }

        .panel-title {
            >a {
                display: block;

                &:hover,
                &:not(.collapsed),
                &.theme1 {
                    color: var(--primaryColor);
                }

                &:focus {
                    outline-offset: 2px;
                }
            }
        }
    }

    .quantNum {
        margin: 0px 0px 10px 0px;

        .form-group {
            margin: 0px;
            color: var(--primaryColor);
            text-transform: capitalize;
        }
    }
}

.attachErpIcn {
    padding: 0;
    clear: both;
    margin-bottom: 15px;
    color: var(--primaryColor);

    ul {
        li {
            margin-right: 10px;
            @include displayInlineBlock;
        }
    }

    li {
        i {
            margin-left: 5px;
        }
    }
}

.parWantToOrderDv {
    margin-top: 15px !important;
    display: table !important;
}

.wantToOrderDv {
    margin: 0 !important;
    max-width: 15px;
    display: table-cell;
    vertical-align: top;

    .errorBox {
        padding: 0 !important;
        border: 1px solid red;
        width: 15px;
        float: left;
        line-height: 13px;
    }

    >div,
    >.errorBox {
        padding: 0;
    }

    input {
        position: relative;
        top: 0;
        // float: left; // Uncomment if needed
    }

    .custmChkLbl {
        font-size: 13px;
        color: #7d7d7d;
        margin-left: 20px;
        position: initial;
        white-space: initial;
        font-style: italic;
    }

    +.control-label,
    +.form-label {
        word-break: break-word;
        display: table-cell;
        vertical-align: top;

        &.form-label {
            padding-left: 5px;
        }
    }
}

.botLineWhite .dvTypehd .rbt .rbt-input-multi {
    height: initial !important;
}

.piSectionLoader {
    position: absolute;
    display: flex;
    z-index: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    inset: 20px auto auto auto;
}

.k_dateTimePopup {
    .k-popup {
        border: 1px solid #c7c7c7;
        box-shadow: none;
        border-radius: 4px;

        .k-button-solid-base.k-selected,
        .k-button-solid-primary {
            border-color: var(--primaryColor);
            background-color: var(--primaryColor);
        }

        .k-date-tab {
            .k-datetime-selector {
                max-height: 240px;
                &:has(.k-calendar-monthview) {
                    max-height: 230px;
                }

                .k-calendar {
                    .k-calendar-view {
                        .k-calendar-nav {
                            .k-button {
                                color: var(--primaryColor);
                            }
                        }

                        .k-today {
                            color: var(--secondaryColor);
                        }
                    }

                    .k-calendar-table {
                        margin: 0px auto;
                        width: 100%;

                        .k-calendar-thead {
                            .k-calendar-th {
                                color: rgba(0, 0, 0, 80%);
                                font-weight: 600;
                            }
                        }

                        .k-calendar-td {
                            inline-size: var(--INTERNAL--kendo-calendar-cell-size, 37px);

                            &.k-selected {

                                .k-calendar-cell-inner,
                                .k-link {
                                    background-color: var(--primaryColor);
                                }
                            }
                        }
                    }
                }

            }
        }

        .k-time-tab {
            .k-datetime-selector {
                max-height: 140px;
            }

            .k-time-header .k-time-now {
                color: var(--primaryColor);
            }

            .k-time-list-wrapper {
                height: 100px;

                .k-time-list {

                    &::before,
                    &::after {
                        box-shadow: 0 0 3em 1em #ffffff;
                    }

                    .k-item {
                        &:hover {
                            color: var(--primaryColor);
                        }
                    }

                    .k-time-container {
                        ul.k-reset {
                            transform: translateY(27px) !important;
                        }
                    }
                }
            }
        }
    }
}

.templateDrpdwn {
    .form-select {
        background-color: var(--primaryColor);
        color: #ffffff;
        padding: 3px 5px;
        border-radius: 2px;

        option {
            background-color: initial !important;
            color: initial !important;
        }
    }
}

.chkSubmitBtn {
    &.k-split-button {
        width: 100%;

        &:focus,
        &.k-focus,
        .k-button-solid-base:focus,
        .k-button-solid-base.k-focus {
            box-shadow: none;
        }

        .k-button {
            background-color: var(--secondaryColor);
            color: #ffffff;
            border: 0px;

            &:last-child {
                border-left: 1px solid var(--secondaryColor-lighter);
            }

            &:hover {
                background-color: var(--secondaryColor-lighter);
            }

            &.k-icon-button {
                width: 15% !important;
            }
        }
    }
}

.chkSubmitPopup {
    &.k-popup {
        box-shadow: 0 1px 4px -1px #000;
        border-radius: 4px;
        min-width: auto;

        .k-group {
            .k-item {
                font-size: 14px;
                border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);

                &:last-child {
                    border-bottom: 0px;
                }

                &:focus,
                &.k-focus {
                    .k-link {
                        box-shadow: none;
                    }
                }

                .k-link {
                    &:active {
                        background-color: var(--anchorColor-lighter);
                    }

                    .k-menu-link-text {
                        color: var(--anchorColor);
                    }
                }
            }
        }
    }
}

.tempPopup {
    &Hd {
        .form-select {
            border: 0px;
            font-size: 18px;
            color: var(--primaryColor);
            padding: 0px 15px 0px 0px;

            &:focus {
                outline: 0;
                box-shadow: none;
            }

            option {
                font-size: 13px;
            }
        }
    }

    .modal-body {
        padding-top: 0px;
        padding-right: 0px;

        .userInfoPopupParent {
            .areaPopup {
                .infoPopupSD {
                    padding: 0 25px 15px 5px;
                }
            }
        }
    }

    .modal-footer {
        .myBt {
            padding: 6px 10px;
            font-size: 14px;
            border: 0px;
            border-radius: 2px;

            &.tempPopupCancelBtn {
                background-color: rgba(0, 0, 0, 0.1);
                color: #000000;
            }

            &.tempPopupApplyBtn {
                background-color: var(--primaryColor);
                color: #ffffff;
            }
        }
    }

}