@import "../general/mixin";
@import "../form/formRightHeader";

/****** Home Page Filter ******/

.serviceHedRFltrMargn,
.serviceHedRFltrMargn .paHedFilter>div {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-wrap: nowrap;
}

.switchPosi {
    position: absolute;
    width: 80px;
    top: 0px;
    right: 80px;
    display: inline-block;

    div {
        display: inline-block;
    }

    .form-check-input {
        cursor: pointer;
    }

    .cal-icon {
        position: relative;
        top: 1px;
    }
}

.reqSwitdv {
    font-size: 13px;
    color: #595959;
    line-height: 16px;
    @include displayInlineBlock;
}

.reqSwitch {
    @include displayInlineBlock;
    width: 30px;
    height: 18px;
    position: relative;
    border: none;
    box-shadow: none;
    margin-left: 5px;
    vertical-align: top;

    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked+.slider {
            background-color: var(--primaryColor);

            &:before {
                -webkit-transform: translateX(12px);
                -ms-transform: translateX(12px);
                transform: translateX(12px);
            }
        }

        &:focus+.slider {
            background-color: var(--secondaryColor);
            outline: thin dotted #000000;
            outline-offset: 2px;
        }
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 10px;

        &:before {
            position: absolute;
            content: "";
            height: 10px;
            width: 10px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            border-radius: 50%;
        }
    }
}


/****** PIR Log ******/

.main-menu {
    margin-bottom: 100px;

    .input-group {
        padding: 10px 0;

        input {
            border: 1px solid #c8cee6;
            border-radius: 0;
        }

        .input-group-addon {
            background: #ffffff !important;
            border: 1px solid #c8cee6;
            border-left: none;
            border-radius: 0;
        }

        .form-control {
            border-right: 0;
            box-shadow: 0 0 0;
            border-color: #c9d1e8;
        }

        button {
            border: 0;
            background: transparent;
        }
    }

    li {
        border-bottom: 1px solid #e7e7f3;
        padding: 0 15px;

        .caret {
            display: none;
        }

        a {
            color: #3f3f3f;
            display: block;
            font-size: 18px;
            font-weight: 400;
            min-height: 55px;
            line-height: 40px;
            padding: 8px 0 0 0;
            text-decoration: none;

            &:hover {
                color: var(--anchorColor);
                text-decoration: none;
            }

            span {
                border-radius: 50%;
                float: left;
                height: 38px;
                width: 38px;
                margin: 0 10px 0 0;
            }

            .cart {
                background-color: #eee;
                background-position: -5px 0 !important;
                padding: 20px;
            }

            .myorder {
                background-color: #eee;
                background-position: -5px -49px !important;
                padding: 20px;
            }
        }

        .mngservice {
            background-color: #eee;
            background-position: -5px -99px !important;
            padding: 20px;
        }

        .getasitn {
            background-color: #eee;
            background-position: -4px -149px !important;
            padding: 20px;
        }

        .profile {
            background-color: #eee;
            background-position: -4px -199px !important;
            padding: 20px;
        }

        .report {
            background-color: #eee;
            background-position: -5px -250px !important;
            padding: 20px;
        }

        .workplace {
            background-color: #eee;
            background-position: -4px -299px !important;
            padding: 20px;
        }

        .contact {
            background-color: #eee;
            background-position: -4px -349px !important;
            padding: 20px;
        }

        .groups {
            background-color: #eee;
            background-position: -5px -398px !important;
            padding: 20px;
        }

        .mlogout {
            background-color: #eee;
            border-radius: 50%;
            float: left;
            height: 38px;
            width: 38px;
            margin: 0 10px 0 0;

            i {
                font-size: 25px;
                font-weight: 400;
                left: 8px;
                position: relative;
                top: 1px;
            }
        }

        .sub-nav {
            border-bottom: 0;
            padding: 4px;
        }

        ul {
            a {
                color: #686868;
                font-size: 16px;
                font-weight: 300;
                display: block;
                min-height: 25px;
                line-height: 25px;
                margin: 0;
                padding: 0 !important;

                i {
                    color: #565656;
                    font-size: 14px;
                    font-weight: 300;
                    position: relative;
                    padding: 0 3px 0 0;
                    top: -1px;
                }
            }

            li {
                border-bottom: none;
                padding: 0 0 5px 53px;

                ul {
                    padding: 0;
                    margin: 0;

                    li {
                        border-bottom: none;
                        padding: 0 0 5px 16px;

                        a {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}