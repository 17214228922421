@import "./mixin";

.skp_popup {
    .modal-body {
        max-height: 450px;
        overflow: hidden;
        overflow-y: auto;
    }

    .skp_close {
        right: -15px;
        border: none !important;
        font-size: 20px !important;
        padding: 2px 3px !important;
    }

    .skp_keyDv {
        .skp_keyRow {
            @include display(inline-flex, center, space-between);
            width: 100%;
            margin-bottom: 15px;
            color: #363636;

            p {
                @include display(inline-flex, center);

                svg {
                    margin-right: 5px;
                    font-size: 13px;
                    @include display(inline-flex);
                }
            }

            span {
                @include display(inline-flex, center, center);
                background-color: #eeeaea;
                padding: 2px 4px;
                border-radius: 4px;

                &.skp_keyPlus {
                    background: transparent;
                    padding: 0px 4px;
                    font-size: 16px;
                }

                &:last-child {
                    width: 17px;
                }
            }

            &:last-child {
                margin-bottom: 0px;
            }
        }
    }

    .skp_footer {
        color: #7c7c7c;
        font-size: 13px;

        span {
            @include display(inline-flex, center, center);
            background-color: #eeeaea;
            padding: 2px 4px;
            border-radius: 4px;

            &.skp_plus {
                background: transparent;
                padding: 0px 4px;
                font-size: 16px;
            }

            &:last-child {
                width: 17px;
            }
        }
    }
}

.skStrip {
    text-align: right;
    color: #7c7c7c;
    margin-top: 10px;
    font-size: 12px;

    span {
        @include display(inline-flex, center, center);
        background-color: #eeeaea;
        padding: 2px 4px;
        border-radius: 4px;

        &.skPlus {
            background: transparent;
            padding: 0px 4px;
            font-size: 16px;
        }

        &:last-child {
            width: 17px;
        }
    } 
}