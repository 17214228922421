@import "../general/mixin";

/************ Breadcrumb ************/

.breadcrumb {
    background-color: transparent;
    padding: 0;
    margin-bottom: 0px;
    display: inline-flex;
    align-items: center;
    position: relative;
    z-index: 2;
    @include widthHeight(100%, auto);

    .breadcrumb-item {
        @include ovrFlowContent;
        font-size: 13px;
        color: var(--primaryColor);
        list-style: none;
        display: inline-block;
        white-space: nowrap;
        position: relative;
        min-width: auto;
        max-width: 100%;

        a {
            color: var(--anchorColor);
            font-size: 12px;

            &:focus {
                outline-offset: 2px;
            }
        }

        &.active {
            color: #868686;
        }

        +.breadcrumb-item::before {
            color: #bfbfbf;
            font-style: normal;
            @include FontAwesomeFamily;
            content: "\f105";
            font-weight: 600;
            position: relative;
            top: 2px;
            margin: 0px;
        }
    }
}

.vCatalogBreadcrumb {
    .breadcrumb {
        display: inline-flex;
        white-space: nowrap;

        .breadcrumb-item {
            max-width: 30%;
        }
    }
}

/************ Page Heading ************/

.sPageHeading {
    padding-left: 0px;

    &.bored-l {
        padding-left: 10px;
    }
}

.sPageHeading,
.sPageHeading1 {
    font-size: 23px;
    margin: 0px;
    color: var(--primaryColor-darker);
    font-weight: 500;

    & .nowap {
        display: inline-block;
    }

    .order {
        color: var(--anchorColor);
        display: block;
        font-size: 14px;
        font-weight: 400;
        padding-top: 5px;
        text-transform: uppercase;

        & a,
        & .lnk {
            position: absolute;
            display: block;
            width: auto;
            z-index: 10;
            white-space: nowrap;
            top: 0px;
        }
    }
}

.sPageHeading22 {
    h1 {
        font-size: 22px;
    }
}

.tinelineicon {
    color: #555;
    font-size: 15px;
    border-radius: 3px !important;
    padding: 1px 6px !important;
    border: 1px #a8a8a8 solid !important;
    background-color: #fff;
    margin: 0px 0px 0px 6px;

    svg {
        font-size: 16px;
        vertical-align: middle;
        position: relative;
        top: -2px;
    }

    &:hover,
    &:focus {
        color: #555;
        background-color: #efefef;
        outline-offset: -1px;
    }
}

.barDropMenu {
    border-radius: 4px;
    padding: 2px 10px;
    font-size: 20px;
    color: #939393;
    border: 1px #d3d3d3 solid;
    background: transparent;

    :hover,
    :focus {
        color: #ffffff;
        background: var(--primaryColor);
        border: 1px solid;
        border-color: var(--primaryColor);
    }
}

.rPageHeading {
    font-size: 24px;
    color: var(--primaryColor);
    font-weight: 400;
    font-style: normal;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 15px;
    padding-bottom: 5px;
    word-break: break-word;
    display: block;

    a:hover {
        color: #bf780e;
    }
}

.rPageSubHeading {
    font-size: 15px;
    color: var(--primaryColor);
    font-weight: 400;
    font-style: normal;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 15px;
    padding-bottom: 5px;
}


.stickyArea .rPageHeading .float-r a {
    margin-top: 8px;
    @include displayInlineBlock;
}

/************ Right side icons ************/

.topRgtNavIcons {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    white-space: nowrap;
    position: relative;

    &.stusGap .btnDropMenu {
        padding: 3px 8px;
        line-height: 23px;
    }

    &>.toprigLnk:first-child {
        position: sticky;
        right: 34.7%;
        top: 107px;
        z-index: 1;
        margin-right: 20px;
    }
}

.paHedFilterSecDv {
    text-align: right;

    .paHedFilter {
        display: inline-flex;
    }
}

.paHedFilter {
    text-align: right;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;

    .myBt {
        font-size: 18px;
        color: #999;
        @include bgWhite;
        border: 1px #c1c1c1 solid;
        box-shadow: none;
        padding: 4px 5px;
        margin-left: 10px;
        border-radius: 3px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        &:first-child {
            margin-left: 0px;
        }

        &.fillBtn {
            position: relative;
            padding: 4px 5px;
        }

        &.noArrow {
            .dropdown-toggle {
                &::after {
                    display: none;
                }
            }
        }

        &.plus {
            color: var(--primaryColor) !important;
            border-color: var(--primaryColor);
            background-color: #ffffff !important;

            &.scanIcn{
                svg {
                    stroke-width: 0px;
                }
            }

            span {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }

            svg {
                stroke-width: 1px;
            }

            &:hover {
                color: var(--primaryColor);
            }
        }

        svg {
            &.refreshFlip {
                transform: scaleX(-1);
            }
        }

        &.rclose {
            color: #ffffff;
            border-color: #e53f01;
            background-color: #ff4500;

            svg {
                color: #fff;
            }

            &:hover {
                svg {
                    color: red !important;
                }
            }

            &:hover,
            &:focus,
            &:focus svg {
                background-color: #fff !important;
                border-color: #e53f01;
                color: #e53f01 !important;
            }
        }

        &.active {
            //box-shadow: 0px 0px 4px -1px var(--primaryColor);
            background-color: var(--primaryColor);
            color: #ffffff;
        }

        &:focus {
            outline-color: #000000;
            outline-offset: 1px;
            color: #ffffff !important;
            border-color: var(--primaryColor);
            background-color: var(--primaryColor) !important;
        }

        .posLay1 {
            position: absolute;
            top: 10px;
            z-index: 1;
            right: 7px;
            font-size: 10px;
        }

        &:hover,
        &.active {
            color: var(--primaryColor);
            border-color: var(--primaryColor);
            @include bgWhite;
        }
    }

    &.govrHedFilter {
        .myBt {
            &:hover,
            &.active {
                color: #fff !important;
                background-color: var(--primaryColor) !important;
                border: 1px solid;
                border-color: var(--primaryColor);
                outline-offset: -3px;
            }
        }
    }

    .searDv {
        .searInp {
            font-size: 13px;
            font-weight: 300;
            color: #333;
            height: auto;
            min-width: 230px;
            padding: 3px 22px 3px 6px;
            position: relative;
            top: 1px;
            border-radius: 3px;
        }

        .faicn {
            font-size: 12px;
            color: #d8d8d8;
            position: absolute;
            top: 5px;
            right: 10px;

            &:hover,
            &:focus,
            &:active {
                color: var(--primaryColor);
            }
        }
    }

    .overlayDv {
        .myBt {
            &.clrbtn {
                margin-left: 10px;
            }
        }

        .clrFilt {
            position: absolute;
            bottom: 4px;
            right: 4px;
            font-size: 9px;
            font-weight: 300;
        }
    }

    .searDv,
    .overlayDv {
        position: relative;
    }

    .deleteOutBound {
        display: inline;
        color: #ffffff;
        border-color: var(--primaryColor);
        background-color: var(--primaryColor);
        padding: 3px 9px;
    }

    &.nbConfigHeader {
        position: absolute;
        top: 44px;
        right: 14px;
    }
}

.customSaveBtn {
    &:focus {
        background-color: var(--primaryColor);
        border-radius: 2px;

        i {
            color: #ffffff !important;
        }
    }
}


/************ Header ************/

.hfixed {
    background: var(--headerBG);
    position: fixed;
    width: 100%;
    height: 58px !important;
    top: 0px;
    z-index: 999;
    border-bottom: 1px solid #e9e9e9;
}

.logos {
    width: auto;
    height: 58px;
    float: left;
    padding: 8px 0 3px 0;

    .xsmLogo {
        background-repeat: no-repeat;
        background-position: left center;
        background-size: contain;
        float: left;
        height: 35px;
        margin: 3px 0px 0 0;
        text-indent: -9999px;
        width: 130px;

        &:focus {
            outline-color: #ffffff;
        }
    }

    .comLogo {
        height: 40px;
        position: relative;
        top: 2px;
        margin-top: 10px;
    }
}

.languageDropDown {
    color: var(--headerTxtColor);
    margin: 20px 10px 0 0;
    border-right: 1px solid;
    padding: 0px 10px;
    @include displayInlineBlock;
    line-height: 17px;

    label {
        font-size: 11px;
        margin-bottom: 3px;
        line-height: normal;
    }

    select {
        font-size: 14px;
        height: initial;
        padding: 0px 2px;
        border: none;
        box-shadow: none;
        cursor: pointer;
    }
}

.headerNavSec {
    margin: 0px;
    padding: 0;
    float: right;
    position: relative;
}

.headerNavigation {
    margin: 0px;
    padding: 0;

    li {
        float: left;
        margin: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 12px;

        &.shortcutLink {
            p {
                color: #434343;
                font-size: 10px;
                font-weight: 500;

                span {
                    @include display(inline-flex, center, center);
                    background-color: #ededed;
                    padding: 0px 4px;
                    border-radius: 4px;
                    font-style: initial !important;
            
                    &.skPlus {
                        background: transparent;
                        padding: 0px 4px;
                        font-size: 12px;
                    }
            
                    &:last-child {
                        width: 17px;
                    }
                } 
            }
        }

        >a {
            color: var(--headerTxtColor);
            display: block;
            padding: 8px;
            margin: 10px 1px;
            position: relative;
            border-radius: 50%;

            &:hover,
            &:focus,
            &.active,
            .active>& {
                color: var(--headerTxtColor) !important;
                background-color: var(--headerBG-darker);
                outline: none !important;
                box-shadow: none !important;
                border-radius: 50%;
            }

            svg.knwldgIcn {
                position: absolute !important;
                font-size: 16px;
                right: 11px;
                top: 1px !important;
            }
        }

        &.menuKledicn a svg {
            position: relative;
            top: 2px;
        }

        &.menuOrdIcn a svg {
            font-size: 19px;
        }

        &.menuNotiIcn {
            .dropdown-menu {
                transform: none !important;
                inset: initial !important;
                right: -12px !important;
                top: 56px !important;
                min-width: 440px;
                color: #333;

                a {
                    border: none;
                    padding: 0px;

                    svg {
                        font-size: 20px;
                    }
                }
            }

            a {
                svg {
                    font-size: 23px;
                }
            }
        }

        &.menuCrtIcn a svg {
            position: relative;
            top: 1px;
        }

        &.knwledg {
            .dropdown-menu {
                padding: 10px;
                min-width: 290px;
            }

            &:after {
                border-bottom-color: var(--primaryColor);
            }

            &:before {
                border-bottom-color: var(--primaryColor);
            }
        }

        &.menuViewIcn {
            .dropdown-menu {
                right: -4px !important;
                left: initial !important;
                min-width: 150px;
                top: 6px !important;
                padding: 0px;

                &:before,
                &:after {
                    right: 10px;
                }
            }
        }

        &.extLink {
            .dropdown-menu {
                transform: none !important;
                inset: initial !important;
                right: -12px !important;
                top: 56px !important;

                a {
                    white-space: nowrap;
                    width: auto;
                    height: initial;
                }
            }
        }

        &.profileBox {
            margin-left: 2px;

            &.profileBoxMob {
                background-color: #fff;
            }

            .accimg {
                width: 30px;
                height: 30px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                border-radius: 50%;
                background-color: #fff;
            }

            >a {
                padding: 5px 5px 4px;
            }

            .dropdown-menu {
                min-width: 175px;
                transform: translate3d(7px, 43px, 0px) !important;
                top: 12px !important;
                right: 6px !important;

                &:after {
                    border-bottom-color: var(--headerBG-lighter) !important;
                }

                &::after,
                &::before {
                    border-bottom-color: var(--headerBG-lighter);
                    bottom: 100%;
                    right: 6px;
                    left: initial;
                }

                .lnkdv {
                    overflow-x: auto;
                    max-height: calc(100vh - 120px) !important;
                }
            }

            .dropdown-toggle .caret {
                top: 16px;
                right: -10px;
            }

            .userNa {
                margin: 0px;
                padding: 5px 10px;
                background-color: var(--headerBG-lighter);
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                white-space: nowrap;

                .welTxt {
                    font-size: 11px;
                    color: rgba(#000000, 0.4);
                    margin-top: -2px;
                }

                .userInfo {
                    font-size: 13px;
                    color: var(--pageTxtColor);
                    margin-top: -3px;
                    border: none;
                    background-color: transparent;
                }
            }

            &.isOnbehalf {
                background-image: none !important;
                background-color: red !important;

                &::after {
                    color: #ffffff;
                    content: "\f234";
                    @include FontAwesomeFamily;
                    font-size: 17px;
                    font-weight: 400;
                    position: absolute;
                    top: 6px;
                    right: 4px;
                }
            }
        }

        &.knwledg .dropdown-toggle .caret,
        &.notfy .dropdown-toggle .caret {
            @include displayNone;
        }

        .dropdown-menu {
            right: -15px;
            left: initial;
            min-width: 150px;
            top: 39px;
            padding: 0px;

            li {
                float: none;
                margin: 0px;
                padding: 0px;
                border-radius: 4px;

                a {
                    &:focus {
                        outline: thin #000000 dashed !important;
                        outline-offset: -3px;
                    }
                }

                &:last-child a {
                    border-bottom: none;
                }
            }

            a,
            li.active a {
                color: var(--anchorColor);
                padding: 6px 15px;
                font-size: 13px;
                width: initial;
                height: initial;
                border-bottom: 1px solid;
                border-bottom-color: rgba(#000000, 10%);
                display: block;
                margin: 0px;
                border-radius: 4px;
            }

            a {
                >img {
                    width: 14px;
                    height: 14px;
                    margin-right: 10px;
                    position: relative;
                    top: -1px;
                }

                .bCount {
                    @include displayInlineBlock;
                    position: relative;
                    top: -2px;
                    color: var(--secondaryColor);
                    font-size: 85%;
                    font-weight: 600;
                    font-style: initial;
                }

                &.lnkRead {
                    font-size: 12px !important;
                    color: #333 !important;

                    &:hover,
                    &:focus {
                        color: initial !important;
                        background-color: transparent !important;
                        text-decoration: underline;
                    }
                }

                &.active {
                    color: var(--anchorColor) !important;
                    background-color: transparent !important;
                }
            }

            >.active>a .bCount {
                font-style: initial;
            }

            a div,
            >.active>a div {
                color: #808080;
                font-size: 12px;
                font-weight: 400;
            }

            a span,
            >.active>a span {
                font-style: italic;
            }

            li.active,
            li:active,
            >li>a:focus,
            a:hover,
            li.active a {
                color: var(--anchorColor) !important;
                background-color: rgba(#000000, 0.04) !important;
                border-radius: 4px;

                &.shortcutLink {
                    p {    
                        span {
                            background-color: #dddddd;

                            &.skPlus {
                                background: transparent;
                            }
                        } 
                    }
                }
            }

            .saptag {
                @include displayInlineBlock;
                margin-right: 5px;

                &.cale i {
                    @include colorBlack;
                    font-size: 11px;
                    position: relative;
                    top: -1px;
                }
            }

            &:after,
            &:before {
                bottom: 100%;
                left: initial;
                right: 18px;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
            }

            &:after {
                border-color: rgba(255, 255, 255, 0);
                border-bottom-color: #ffffff;
                border-width: 10px;
                margin-left: -10px;
            }

            &:before {
                border-color: rgba(157, 183, 199, 0);
                border-bottom-color: #dedede;
                border-width: 11px;
                margin-left: -12px;
            }
        }

        .dropdown-toggle {
            .caret {
                @include displayNone;
                color: #ffffff;
                position: absolute;
                top: 14px;
                left: initial;
                right: -1px;
            }

            &::after {
                @include displayNone;
            }
        }
    }

    .topNavIcn {
        font-size: 20px;
        margin: 0;
    }

    .topNavIcn.extLink a,
    .tIcextLik a {
        width: 36px;
        height: 37px;
    }

    .tIcextLik a .tIcextLikIcn {
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
        @include displayInlineBlock;
        width: 20px;
        height: 20px;
        border-radius: 4px;
    }
}

.rwLeftPart {
    .popover-content {
        padding: 0px;

        .poplnk {
            margin-bottom: 10px;

            .checkbox {
                margin-bottom: 0px;
            }

            label {
                margin-left: 0px;
            }

        }
    }
}

.onbehalfpop {
    .hrd {
        font-size: 15px;
        margin-bottom: 10px;
        border-bottom: 1px #dee2e6 solid;
        padding-bottom: 5px;
        font-weight: 500;
        color: var(--primaryColor-darkest);
    }

    #react-autowhatever-1 {
        width: 100%;
        top: 0px;
    }
}

.catePopup {
    @include bgWhite;
    width: 250px;
    position: absolute;
    top: 10px;
    right: -15px;
    z-index: 9000;
    box-shadow: 0px 0px 9px -4px rgba(0, 0, 0, 0.8);

    &:after,
    &:before {
        bottom: 100%;
        right: 55px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    &:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #ffffff;
        border-width: 10px;
        margin-left: -10px;
    }

    &:before {
        border-color: rgba(157, 183, 199, 0);
        border-bottom-color: #d2d2d2;
        border-width: 11px;
        margin-left: -12px;
    }

    .ulBox {
        padding: 10px;

        .catdiv {
            margin-bottom: 10px;
            position: relative;

            .catName {
                text-align: left;
                width: 100%;
                position: relative;
                font-size: 14px;
                font-weight: 500;
                color: #009dab;
                display: block;
                padding: 4px 20px 4px 28px;
                margin: 1px 0;
                border: none;
                @include bgTransparent;

                img {
                    margin-right: 10px;
                    width: 14px;
                    height: 14px;
                    position: absolute;
                    top: 8px;
                    left: 5px;
                }

                &:hover,
                &:focus {
                    background-color: #f1f0f0 !important;
                    @include colorBlack;
                }
            }

            &::before {
                content: "\f067";
                color: #93c0d8;
                @include FontAwesomeFamily;
                font-weight: normal;
                font-size: 10px;
                position: absolute;
                top: 10px;
                right: 8px;
                z-index: 1;
            }

            &.active {
                &::before {
                    content: "\f068";
                }

                >.catName {
                    background-color: #f1f0f0 !important;
                    @include colorBlack;
                }
            }

            .subCategory {
                background: transparent;
                margin-left: 20px;

                button {
                    border: none;
                    @include bgTransparent;
                    text-align: left;
                    font-size: 13px;
                    padding: 0;
                }
            }
        }
    }

    .popupLogo {
        margin-top: 4px;

        .pLogo {
            height: 45px;
        }
    }
}

/************ Notification Popup ************/

.twoSecObj {
    display: flex;
    width: 100%;
    z-index: 1;
    margin: 10px 0px;
    // border-bottom: 1px #e1e1e1 solid;
    padding: 5px 2px 10px;

    &:nth-child(odd) {
        background-color: #f6f6f6;
    }

    &:nth-child(even) {
        background-color: rgb(246 246 246 / 60%);
    }

    // &:hover {
    //   background-color:rgb(246 246 246 / 60%);
    // }
    .imgSec {
        width: 50px;
        height: 45px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
        border: 1px #ccc solid;
        margin-right: 10px;
    }

    .secObj {
        width: 100%;
        font-size: 15px;
        color: #000;
        font-weight: 600;

        p {
            @include displayInlineBlock;
            margin-right: 5px;
        }

        span,
        .dSpan {
            color: #999;
            font-weight: 500;
        }

        .objName {
            font-size: 14px;
            color: #535353 !important;
            @include displayInlineBlock;
            font-weight: 500;

            &:hover {
                color: #535353 !important;
                background-color: transparent !important;
                text-decoration: underline;
            }
        }
    }

    .objLnk a.lnkDot {
        color: var(--primaryColor) !important;
        border: 1px #eee solid !important;
        border-radius: 50% !important;
        padding: 0px !important;
        margin: 0px !important;
        line-height: 15px;
        outline: none;

        svg {
            font-size: 22px !important;
        }

        &:hover {
            color: var(--primaryColor) !important;
            border: 1px var(--primaryColor) solid !important;
            outline: none;
            background-color: #eee !important;
        }
    }
}

.endObjDate {
    text-align: center;
    font-size: 14px;
    margin-top: 20px;

    .cenPart {
        width: 50%;
        text-align: center;
        @include displayInlineBlock;
    }

    svg {
        color: var(--primaryColor);
    }
}

.notiPopupScroll {
    height: calc(100vh - 150px);
    min-height: auto !important;

    .areaScroll {
        height: calc(100vh - 300px) !important;
    }
}

/************ Left Top Header ************/

.status {
    float: right;

    ul {
        margin: 0;
        padding: 0;

        li {
            display: inline;
            float: left;
            padding: 0 4px;
            text-align: center;
            position: relative;

            &:last-child {
                padding: 0 0 0 6px;
            }

            a {
                color: #393939;
                font-size: 18px;
                padding: 4px 5px;
                font-weight: 400;
                text-align: center;
                display: flex;
                border: 1px #d3d3d3 solid;
                border-radius: 4px;
                outline: none;

                svg {
                    color: rgba(0, 0, 0, 0.4);

                    &.processBarIcn {
                        position: absolute;
                        top: 10px;
                        left: 13px;
                        font-size: 10px;
                        stroke-width: 4px;
                    }

                    &.slaAttrPencil {
                        position: absolute;
                        font-size: 16px;
                        bottom: 7px;
                        right: 6px;
                        fill: rgba(255, 255, 255, 0.4);
                    }
                }

                &:hover,
                &:focus,
                &.active {
                    color: #ffffff !important;
                    background-color: var(--primaryColor);
                    border: 1px solid var(--primaryColor);

                    span {
                        filter: invert(0) opacity(0.9);
                    }

                    svg {
                        color: #ffffff;
                    }
                }

                strong {
                    clear: both;
                    display: block;
                    color: #393939;
                    font-size: 13px;
                    font-weight: 400;
                }

                span {
                    cursor: pointer;
                    display: block;
                    border-radius: 5px;
                    background-color: transparent;
                    border: none;
                }
            }

            .saveBtnStyle {
                color: rgba(0, 0, 0, 0.4) !important;
                font-size: 18px;
                padding: 4px 5px !important;
                font-weight: 400;
                text-align: center;
                display: flex;
                border: 1px #d3d3d3 solid;
                border-radius: 4px !important;
                outline: none;
            }

            &.navdrp {
                .dropdown-menu {
                    padding: 5px 10px;
                    z-index: 1;
                    min-width: 210px;

                    i {
                        margin-right: 5px;
                    }
                }

                a span {
                    width: 18px !important;
                    height: 30px;

                    i {
                        color: #ffffff;
                        font-size: 15px;
                        margin-top: 8px;
                    }
                }

                li {
                    display: block;
                    width: 100%;
                    text-align: left;
                    padding: 0;
                    margin-bottom: 4px;
                    cursor: pointer;

                    &:last-child {
                        padding: 0;
                    }

                    a {
                        border: 1px transparent solid;
                        text-align: left;
                        padding: 3px 5px;

                        i {
                            margin-right: 5px;
                            @include displayInlineBlock;
                        }
                    }
                }
            }

            .flowChart {
                background-position: -10px -612px;
            }

            .exSysAttr {
                background-position: -8px -969px;
            }

            .sequence {
                background-position: -8px -1436px;
            }

            .language {
                background-position: -5px -1391px;
            }

            .knowledgeLib {
                background-position: -9px -1531px;
            }
        }
    }

    &.myStatusGap {
        ul {
            li {
                padding: 0 4px;

                &.lst {
                    padding: 0px;
                }

                &:last-child {
                    padding: 0 0 0 4px;
                }
            }
        }
    }

    &.myStatus {
        margin-top: 0 !important;

        ul {
            li {
                &:last-child {
                    padding: 0 0 0 4px;
                }

                a {

                    span,
                    span {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                    }

                    button {
                        border: none;
                        font-size: 18px;
                        border-radius: 0 !important;
                        padding: 0 !important;
                        background: transparent !important;
                        background-color: transparent !important;

                        i {
                            font-size: 16px;
                            color: #333;
                        }

                        &.active,
                        &:hover,
                        &:focus {
                            color: #ffffff !important;
                            background: var(--primaryColor) !important;
                            background-color: var(--primaryColor) !important;

                            i {
                                color: #ffffff !important;
                            }
                        }
                    }
                }
            }
        }

        &.marginT10 {
            margin-top: 10px !important;
        }
    }

    .dropdown-menu {
        padding: 5px;

        a {
            border: none;
            text-align: left;
            font-size: 14px;
            display: flex;
            align-items: center;

            &:hover,
            &:focus {
                color: #ffffff !important;
                border: none;
                background-color: var(--primaryColor) !important;
            }

            .fa {
                margin-right: 10px;
            }

            svg {
                margin-right: 4px;
                color: #393939;
            }
        }
    }

    .dropdown-toggle {
        &::after {
            display: none !important;
        }

        &.nav-link {
            padding: 5px 0;
        }
    }
}

.innerStatus {
    float: right;
    margin-right: 10px;
    padding-right: 10px;

    .status {
        ul {
            li {
                span {
                    height: 27px;
                    width: 27px;
                }

                a {
                    span {
                        height: 27px;
                        width: 27px;
                    }

                    .artAdd {
                        background-position: -9px -392px;
                    }

                    .refresh {
                        background-position: -4px -29px;
                    }
                }

                &:last-child {
                    padding: 0 6px;
                }
            }
        }
    }
}

.inputGap12{padding:6px 12px !important;}
.disableDiv{background-color: #eeeeee !important;opacity: 1;cursor: not-allowed;}