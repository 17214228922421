@import "../general/mixin";

.userInfo {
    border: 1px solid #e2dfdf;
    background-color: #f9f9f9;
    height: 100%;

    .userPImg {
        margin: 35px 0 0;
        @include displayInlineBlock; // Ensure this mixin is defined elsewhere

        img {
            width: 140px;
            height: 140px;
            border-radius: 50%;
            border: 1px solid #b3b3b3;
        }
    }

    .userNa {
        font-size: 16px;
        font-weight: 600;
        color: #585858;
        word-break: break-word;
        padding: 0 10px;
    }

    .UserDesi {
        font-size: 13px;
        margin-top: 5px;
        word-break: break-word;
    }

    h4 {
        font-size: 15px;
        margin: 0 0 15px;
        color: #717171;
    }
}

.inLineLabel {
    padding: 10px 20px;

    .form-group {
        display: table;
        vertical-align: top;
        width: 100%;
        margin-bottom: 10px;

        label {
            color: var(--primaryColor-darker);
            font-weight: 500;
            display: table-cell;
            width: 40%;
            vertical-align: top;
            word-break: break-word;

            a {
                color: var(--secondaryColor);
                font-size: 16px;
                display: inline-flex;
                position: relative;
                top: 2.5px;

                svg {
                    stroke-width: 1.8;
                }

                &:hover,
                &:focus {
                    color: var(--pageTxtColor);
                    outline-offset: 2px;
                }
            }
        }

        .fa-pencil-square-o.orange {
            position: relative;
            top: 1px;
        }

        .formOpt,
        input[type="text"] {
            color: var(--pageTxtColor);
            font-weight: 500;
            display: table-cell;
            width: 100%;
            vertical-align: top;
            word-break: break-word;
        }

        .showUpdatebtn,
        .showSelect {
            padding: 2px 8px;
            height: auto;
        }

        .dCheckbox {
            width: 20%;
        }

        .checkLabel {
            width: 85%;
            color: #000000;
        }
    }

    &.inLineLabDv {
        border-right: 1px solid;
        border-right-color: var(--primaryColor);
    }

    &.inLineLabDv1 {
        border-left: 1px #c7cbce solid;
    }
}

.proPtag {
    padding: 10px 20px;

    p {
        width: 300px;
        float: left;
        line-height: 16px;
        margin: 0px 0px 10px;
        display: inline-flex;
        word-break: break-word;
    }

    p:before {
        content: "-";
        color: var(--primaryColor);
        font-size: 22px;
        text-align: center;
        margin-right: 10px;
    }
}