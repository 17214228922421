@import "./mixin";

html,
body,
.popover {
    font-family: var(--FontFamily), sans-serif;
}

html,
body {
    background-color: var(--pageBG);
    color: var(--pageTxtColor);
    font-size: 14px;
    line-height: normal;
    margin: 0;
    width: 100%;
}

body #app {
    margin-top: 58px;
}

#skiptoContent {
    transform: translateY(-120%);
    transition: transform 0.3s;
    position: fixed;
    margin: 4px 0px 0px 4px;
    top: 0px;
    left: 0px;
    font-size: 14px;
    @include colorBlack;
    z-index: 1000;
    @include bgWhite;
    padding: 4px 10px;
    border: 1px #969999 solid;

    &:focus {
        transform: translateY(0%);
        background-color: #89dbe2;
        outline-color: #000000;
        outline-offset: -4px;
    }
}

#skipdv {
    min-height: calc(100vh - 120px);
    position: relative;
    margin-left: 15px;
    overflow-x: auto;
    transition: width .1s linear .1s;
    padding-bottom: 50px;
    width: calc(100vw);
    // height:calc(100vh);
}

.skipdv {
    min-height: calc(100vh - 116px);
    position: relative;
    //margin-left: 45px;
}

button:focus,
.btn:focus,
input[type="checkbox"]:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
.dropdown-toggle:focus,
.carousel-control:focus,
.carousel-control-prev:focus,
.carousel-control-next:focus {
    outline: thin dotted #000000 !important;
    outline-offset: 2px;
}

:focus-visible {
    outline: 3px solid;
    outline-color: var(--primaryColor);
    outline-offset: 3px;
}

#mainApp {
    height: 100%;
}

.loginBox {
    background: #ffffff;
    height: 100%;
}

a {
    cursor: pointer;
    color: var(--anchorColor);
    font-weight: 400;
    line-height: 18px;
    outline: none;
    text-decoration: none;
    &:hover {
        color: rgba(#000000, 0.9);
        outline: none;
        text-decoration: none;
    }
}

input[type="radio"]:focus,
input[type="checkbox"]:focus {
    transform: scale(1.2);
}

.btn {
    border: none;
    border-radius: 3px;
    color: inherit;
    font-size: 15px;
    padding: 8px 10px;
}

.btn:hover,
.btn:active:hover,
.btn:active:focus,
button:active:hover,
button:active:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn-xs {
    font-size: 11px !important;
    padding: 3px 6px;
}

.btn-primary {
    color: #ffffff !important;
    background-color: var(--primaryColor) !important;
    border-radius: 1px;
}

.btn-primary+.btn-primary {
    background-color: rgba(#000000, 0.1);
    @include colorBlack;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    background: #dbdbdb;
    color: #000000 !important;
}

@keyframes spin-animation {
    0% {
        transform: rotate(0deg);
    }
 
    100% {
        transform: rotate(360deg);
    }
}

svg {
    &.icn-spinner {
        animation: spin-animation 1.6s infinite;
        animation-timing-function: linear;
    }
}