@import "../general/mixin";

.knowledgeDiv {
    .myStatusGap {
        position: absolute;
        top: 12px;
        right: 16px;
    }
}

.knSearchDiv {
    position: relative;

    input.form-control {
        padding: 0 45px 0 10px;
    }

    button {
        font-size: 13px;
        position: absolute;
        top: 2px;
        right: 3px;
        padding: 5px 10px;

        &:hover,
        &:focus {
            outline-color: #ffffff;
            outline-offset: -2px;
        }
    }
}

.knSearchDvdropdown {
    display: flex;
    align-items: center;

    select {
        width: 33%;
        border-right: 0;
        border-radius: 4px 0 0 4px;
        padding: 6px 4px;
    }

    input {
        border-radius: 0 4px 4px 0;
    }
}

.KA-icon {
    background: url("../../images/sprite-common-new.png") no-repeat 0px 0px;
    width: 24px;
    height: 24px;
    @include displayInlineBlock;
    vertical-align: text-bottom;

    &.interLnk {
        background-position: 4px 5px;
        background-size: 75px;
    }

    &.interLnk1 {
        background-position: 4px -3px;
    }

    &.exterLnk {
        background-position: 4px -41px;
        background-size: 75px;
    }

    &.exterLnk1 {
        background-position: 4px -65px;
    }

    &.community {
        background-position: 3px 5px;
        background-size: 75px;
    }

    &.community1 {
        background-position: 0px 1px;
    }

    &.communityWh {
        background-position: -1px 1px;
        filter: brightness(0.1) invert(1) opacity(1);
    }
}

.knowledge-search-section {
    margin-bottom: 10px;
}

.knowledge-search-section .table .kn-title,
.knowledge-description-section .KA-Desc-title {
    word-break: break-word;
}

.knowledge-description-section {
    .KA-title {
        font-size: 20px;
        font-weight: bold;
    }

    .KA-description {
        margin: 0px 0px 15px 0px;
        padding: 0px;
        word-break: break-word;
        overflow: auto;
        max-height: 300px;

        p {
            margin-bottom: 5px;
        }

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .KA-download-btn {
        margin-left: 10px;
    }
}

.knTySelect {
    padding-right: 0px;

    .form-control {
        padding: 0px 5px;
    }
}

.kaArticles {
    border: 1px solid #e7e7e7;

    .kaArticlesList {
        font-size: 14px;
        display: inline-flex;
        gap: 5px;
        width: 100%;
    }
}

.kaArticlesDetails {
    .KA-icon {
        &.communityWh {
            filter: none;
        }
    }

    .myBtn {
        font-size: 13px;
        font-weight: 500;
        padding: 6px 12px !important;

        &.rgSiLigBtn {
            &.smallBtn {
                color: #000 !important;
                background: rgba(0, 0, 0, .1) !important;
                background-color: rgba(0, 0, 0, .1) !important;
            }
        }
    }

    .servKaArticles {
        margin: 20px 0px 10px 0px;
        display: inline-flex;
        gap: 5px;
        width: 100%;
    }
}
