@import "../general/mixin";
@import "./accordion";
@import "./calendar";
@import "./typehead";
@import "../rightside/main";

input[type="checkbox"].cusCbox {
    -ms-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -o-transform: scale(1.3);
    line-height: normal;
    margin: 5px 15px 0 0;
    padding: 5px;
    display: block;
    float: left;

    &R {
        line-height: normal;
        margin: 5px 2px 0 15px;
        padding: 5px;
        display: block;
        float: right;
    }
}

.scale1,
.radioDiv .radio-inline input[type="radio"] {
    -ms-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -o-transform: scale(1.3);
}

.spcm-service-form .radio-inline input[type="radio"],
.spcm-service-form .radio-inline input[type="checkbox"] {
    margin: 0 5px 0px 0px;
}

.form-group {
    margin-bottom: 10px;
}

label {
    color: var(--pageTxtColor);
    @include displayInlineBlock;
    max-width: 100%;
    height: initial;
    margin-bottom: 2px !important;
    font-weight: 400;
}

.bgWhite .form-group {
    margin-bottom: 10px;
}

.errorMessageSec {
    margin-bottom: 15px !important;
    border-radius: 3px !important;
}

.has-error .form-control,
.error.dvTypehd .form-control {
    border-color: red !important;
}

.error {
    border-color: red !important;

    &-form {
        border-color: red !important;
        color: red;
    }

    strong {
        font-weight: 600;
        font-size: 13px;
    }
}

.alert-danger {
    color: red;
    background-color: #fff8f8;
    border-color: #ff8e8e;
}

.editInput {
    font-size: 14px !important;
    outline: none !important;
    border: none !important;
    padding: 0px !important;
    height: initial;
    box-shadow: none !important;
    background-color: transparent !important;
    resize: none;
    width: 100% !important;
    cursor: default !important;
}

.formGroupB10 .form-group {
    margin-bottom: 10px;
}

.fulfillmepln input[type="radio"] {
    margin-right: 5px;
    position: relative;
    top: -2px;
}

.myProfileLabel label {
    color: var(--pageTxtColor);
}

.form-select[disabled],
.form-select:disabled,
.form-control[disabled],
.form-control[readonly],
select:disabled,
fieldset[disabled] .form-control {
    background-color: #eeeeee;
    opacity: 1;
    cursor: not-allowed;
}

.form-select {
    border-color: #ccc;
}

.calIcon.rdt .form-control[readonly] {
    background-color: #ffffff !important;
    cursor: auto !important;
}


.borderForm {
    [class*="col-"] {
        margin-bottom: 10px;

        .form-group {
            margin-bottom: 0;
        }
    }

    .radioDiv {
        text-align: left;
    }

    .newFbg {
        [class*="col-"] {
            margin-bottom: 0;
        }
    }

    .worksch {
        [class*="col-"] {
            margin-bottom: 5px;
        }
    }

    .bgGray.padding-5 {
        margin-bottom: 5px;

        input[type="radio"],
        input[type="checkbox"] {
            margin: 0 4px 0 10px;
            position: relative;
            top: -1px;
        }
    }

    .incigap {
        .mgdv {
            .form-control {
                margin-bottom: 5px;
            }
        }

        .errspn {
            .form-control.error~div {
                @include displayNone;
            }
        }
    }
}

.bFormDiv {
    .form-control {
        padding: 6px 6px;
    }

    textarea.form-control {
        min-height: 34px;
    }

    input[type="checkbox"] {
        margin: 0;
    }

    .react-date-field {
        input {
            padding: 6px 2px;
            font-size: 13px;
            width: 100%;
            height: 32px;
        }
    }
}

.infoBtnDiv {
    position: relative;

    .infoicn {
        color: #6b6b6b;
        font-size: 13px;
        cursor: pointer;
        position: absolute;
        border: none;
        background-color: transparent;
        height: 20px;
        right: 6px;
        top: 7px;
        padding: 0 4px 2px 6px;
        border-left: 1px #c4c4c4 solid;

        &:focus,
        &:hover {
            @include colorBlack;
            outline-offset: -2px;
        }
    }

    .form-control {
        padding-right: 30px;
    }

    &.typeHeadicn {
        .infoicn {
            top: 28px;
            right: 5px;
        }

        .dvTypehd {
            .icn {
                top: 7px;
                right: 30px;
            }

            input[type="text"] {
                padding-right: 60px;
            }
        }

        &.prblmBtnDv {
            .dvTypehd {
                input[type="text"] {
                    padding-right: 85px;
                }
            }
        }

        &.chngBtnDv {
            .dvTypehd {
                input[type="text"] {
                    padding-right: 75px;
                }
            }
        }

        &.indiBtnDv {
            .dvTypehd {
                .icn {
                    right: 88px;
                }
            }
        }
    }

    &.withCloseIcn {
        .dvTypehd {
            .icn {
                top: 6px;
                right: 40px;
            }
        }
    }

    &.prblmBtnDv.typeHeadicn {
        .dvTypehd {
            input[type="text"] {
                padding-right: 85px;
            }

            .icn {
                right: 62px;
            }
        }
    }

    &.chngBtnDv.typeHeadicn {
        .dvTypehd {
            input[type="text"] {
                padding-right: 75px;
            }

            .icn {
                right: 32px;
            }
        }
    }
}

.checkboxes {
    label {
        display: block;
        height: 25px;
        margin-bottom: 0;
        position: relative;

        i {
            @include displayInlineBlock; // Ensure this mixin is defined
            height: 28px;
            position: relative;
            top: 6px;
            font-style: normal;
            color: var(--pageTxtColor);
        }

        span {
            color: var(--pageTxtColor);
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            margin-left: 10px;
        }
    }

    input[type="radio"],
    input[type="checkbox"] {
        @include displayNone; // Ensure this mixin is defined

        +i:before {
            @include FontAwesomeFamily; // Ensure this mixin is defined
            font-size: 14px;
            height: 16px;
            width: 16px;
            margin-top: 7px;
            display: table;
            background-color: #4b6d88;
        }

        &:checked {
            +i {
                position: relative;
                animation: icon-beat 0.1s ease; // Vendor prefixes recommended
            }

            +i+span {
                transition: all 0.1s ease;
            }

            +i+span,
            +i+label:before {
                color: var(--pageTxtColor);
            }
        }

        // Add hover and focus styles
        &:hover+i {
            background-color: #6b7d9d; // Example hover color
        }

        &:focus+i {
            outline: 2px solid #60fe6b; // Example focus outline
        }
    }

    input[type="checkbox"] {
        +i:before {
            content: "\f10c"; // Font Awesome checkbox icon
        }

        &:checked {
            +i:before {
                content: "\2713"; // Checkmark icon
                color: #60fe6b;
                font-size: 12px;
                text-align: center;
            }

            +i+span,
            +i:before {
                color: #61ff6a;
                font-weight: 700;
            }
        }
    }
}

.commonForm {
    clear: both;

    .form-group {
        margin-bottom: 10px;
    }

    .form-control {
        padding: 4px;
        height: inherit;
    }
}

.editSelect {
    overflow: hidden;
    position: relative;

    .editInput {
        font-size: 15px;
        @include colorBlack;
        font-weight: 500;
        opacity: 1;
        border-radius: 0px;
        padding: 0px;
        margin-left: -3px;
        width: 115% !important;
    }

    .showSelect {
        font-size: 15px;
        @include colorBlack;
        margin-left: 0px;
        width: 100%;

        &:focus {
            outline-offset: -2px;
            outline: thin dotted #000000;
        }

        &TextArea {
            font-size: 15px;
            @include colorBlack;
            margin-left: 0px;
            width: 100%;
        }
    }
}

.myFields {
    .myControl {
        padding: 3px;
        height: initial;
    }

    .myInput {
        border-radius: 2px;
        margin-right: 10px;
    }

    .mySel {
        padding-top: 2px;
        margin-right: 10px;
        border-radius: 2px;
    }

    .myBtn {
        padding: 3px 10px !important;
        font-size: 14px;
    }

    .mySort {
        width: 100px;
        @include displayInlineBlock;
        margin-left: 10px;
    }

    .myMedim {
        @include displayInlineBlock;
        margin-left: 10px;
        width: 150px;
    }
}

.inlineFields {
    label {
        @include displayInlineBlock;
        height: initial;
        margin-right: 10px;
    }

    select {
        @include displayInlineBlock;
        height: initial;
        padding: 3px;
        width: 145px;
    }

    &.form-inline {
        .form-control {
            @include displayInlineBlock;
            width: auto;
            vertical-align: middle;
            height: initial;
            padding: 3px;
        }

        .btn {
            font-size: 13px;
        }
    }

    &.myfeedbackSort {
        select {
            width: auto;
        }
    }
}

.inputGrpInlne {
    display: table;
    width: 100%;

    &.input-group {
        >div {
            width: 80%;
            display: table-cell;
            vertical-align: top;

            .form-control {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    .input-group-text {
        display: table-cell;
        vertical-align: top;
        padding: 0;

        .form-label {
            margin-bottom: 0 !important;
            padding: 3px 0;
        }
    }
}

.inputGrp2Inlne {
    display: table;
    width: 100%;

    &.input-group {
        >div {
            width: 48%;
            display: table-cell;
            vertical-align: top;

            .form-control {
                border-radius: 0;
            }
        }
    }

    .input-group-text {
        display: table-cell;
        vertical-align: top;
        padding: 0;
        width: 26%;

        .form-label {
            margin-bottom: 0 !important;
            padding: 3px 0;
        }

        .form-control,
        .form-select {
            border: none;
            box-shadow: none;
            padding: 4px;
            height: 28px !important;
            @include bgWhite;
            text-align: left;
            appearance: auto;
        }
    }
}

.rightPanelGap {
    label {
        height: 22px;
        margin-bottom: 0px;
    }

    .form-control {
        height: initial;
        padding: 4px 10px;
    }

    .form-group {
        margin-bottom: 10px;
    }
}

.priceForm-group {
    .input-group {
        width: 100%;

        &-addon {
            width: 100px;
            padding: 0;
            background: transparent;

            .form-control {
                padding: 2px 6px;
                border: none;
                box-shadow: none;
                height: initial;
            }
        }
    }

    &.withImg {
        .input-group-addon {
            width: 32px;

            img {
                height: 32px;
            }

            svg.icon {
                width: 32px;
                height: 30px;
            }
        }

        label {
            display: block;
            font-weight: initial;
            height: 29px;
            margin: 0;
        }
    }

    &.withImgbox {
        .input-group {
            display: table;

            >div:not([class]) {
                width: 90%;
                display: table-cell;
                vertical-align: middle;

                label {
                    width: 100%;
                    margin-bottom: 0 !important;

                    input {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                        outline-offset: -3px;
                    }
                }
            }

            .input-group-text {
                display: table-cell;
                width: 10%;
                padding: 0 4px;
                vertical-align: middle;

                img {
                    height: 24px;
                    width: initial !important;
                    max-width: 150px;
                }
            }
        }
    }
}

.radInpCs {
    input {
        position: relative;
        top: -3px;
        margin-right: 5px;

        +input {
            margin-left: 15px;
        }
    }
}


.retrodv {
    input[type="radio"] {
        position: relative;
        top: -1px;
        left: 0px;
        margin-right: 5px;
        vertical-align: text-bottom;

        label {
            display: inline !important;
        }

        .fieldgap {
            margin-right: 15px;
        }
    }
}

.inpbtnBox {
    position: relative;

    .form-control {
        padding: 0px 40px 0px 10px;
    }

    .myBtn {
        position: absolute;
        top: 2px;
        right: 2px;
        padding: 2px 6px !important;
        font-size: 13px;
        color: #ccc !important;
        background-color: transparent !important;

        &:hover {
            color: #333 !important;
        }
    }
}

.radioDiv {
    text-align: right;

    .radio-inline {
        height: initial;

        input[type="radio"] {
            +span {
                text-transform: uppercase;
                font-size: 14px !important;
            }

            &:checked {
                +span {
                    color: #177ed0;
                }
            }
        }
    }

    .checkbox-inline+.checkbox-inline {
        margin-top: 0;
        margin-left: 20px;
    }
}

.radio-inline+.radio-inline {
    margin-top: 0;
    margin-left: 20px;
}

.searchFawicon {
    position: relative;

    &:after {
        @include FontAwesomeFamily;
        content: "\f002";
        color: #a7a7a7;
        font-size: 14px;
        position: absolute;
        top: 10px;
        right: 10px;
    }
}

.notiPre {
    .secdv {
        border-bottom: 1px #b3d4e6 solid;
        font-size: 14px;
        padding: 6px 0px;

        .tag {
            border: 1px #ddd solid;
            display: table-cell;
            padding: 2px 10px;
            width: 70px;
            margin-right: 10px;
            text-align: center;
            vertical-align: middle;
        }

        .tagDes {
            display: table-cell;
            padding-left: 10px;
            vertical-align: middle;
            word-break: break-word;
        }

        .secdvLogo{
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            display: inline-block;
            width: 130px;
            height: 32px;
            margin: 0px;
            text-indent: -9999px;
        }
    }

    .notiBody {
        text-align: center;
        padding: 15px 0px;

        .notiBodyPre {
            line-height: 20px;

            td:has(> #bodyPart) {
                padding: 0px !important;
                min-height: 30px;
            }
        }
    }

    .notbg {
        font-size: 10px;
        padding: 4px 8px;
        background-color: #336699;
        color: #ffffff;
    }
}

.rwDynaCls {
    word-break: break-word;

    table {
        width: 100% !important;
    }
}

.rPlusIcn {
    color: #ffffff;
    @include bgTransparent;
    border: none;
    box-shadow: none;
    float: right;
    cursor: pointer;
    /* margin-top: 10px; */
    margin-right: 10px;
    font-size: 18px;

    &1 {
        color: #ffffff;
        @include bgTransparent;
        border: none;
        box-shadow: none;
        float: right;
        cursor: pointer;
        /* margin-top: 5px; */
        margin-right: 0px;
        font-size: 18px;
    }
}

.attrvaldv {
    .dropdown-menu {
        max-height: 200px !important;
    }

    .rbt-input.form-control,
    .typeaheadInput .rbt-input.form-control {
        height: auto;
    }

    .rbt-input.form-control {
        .rbt-token {
            align-items: center;

            .rbt-token-remove-button {
                padding: 0rem 0.5rem 0.15rem !important;
            }
        }
    }
}

.slectInp {
    border-collapse: separate;
    display: table;
    width: 100%;
    position: relative;

    .inpt {
        margin-bottom: 0;
        position: relative;
        width: 100%;
        display: table-cell;
        vertical-align: middle;

        input {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .sleinput {
        position: relative;
        z-index: 2;
        border-color: #ccc;
        white-space: nowrap;
        vertical-align: middle;
        display: table-cell;
        height: 34px;
        padding: 4px 10px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        &:focus {
            @include colorBlack;
            outline: thin dotted #000000 !important;
            outline-offset: -3px;
        }
    }
}

#editableTextArea, #editableTextArea2 {
    padding: 0;

    table,
    td {
        border: none !important;
    }

    #bodyPart {
        padding: 0;
        min-height: 30px;
        word-break: break-word;

        &:focus-visible {
            outline: none;
        }
    }

    .varModify {
        padding: 0;
        border-radius: 2px;
        white-space: pre-wrap;
        -moz-user-modify: read-only;
        -webkit-user-modify: read-only;
        position: relative;
        border: none;
        box-shadow: none;
        @include bgTransparent;
        cursor: pointer;

        &:hover,
        &:focus {
            border: none;
            box-shadow: none;
            @include bgTransparent;
            outline: none !important;
        }

        &:focus {
            color: var(--primaryColor);
        }

        select {
            font-size: 13px;
            @include colorBlack;
            position: absolute;
            top: 18px;
            right: 0;
            z-index: 10;
            font-weight: normal;
            box-shadow: 8px 4px 15px -6px #000;
            border: 1px #555555 solid;
            min-width: 60px;
            max-width: 100%;
            background-color: #fff;
            outline: 4px #fff solid;
        }
    }
}

#bodyPart {
    word-break: break-word !important;
}

.watcher-container {
    .radio-inline {
        line-height: 25px !important;
        font-size: 12px;

        input[type="checkbox"]:focus {
            outline: none !important;
        }
    }

    .radioInlineBox {
        .dvTypehd {
            width: 100% !important;

            .rbt-input-main {
                margin: 0 0 2px;
                height: 29px;
            }
        }
    }
}

.myReqst {
    display: inline-flex;

    .myControl {
        height: initial;
        height: 23px;
        padding: 3px;
    }

    select {
        height: initial;
        height: 23px;
        padding: 0px !important;
        border-radius: 2px;
    }
}

span:has(.showwAllGrps) {
    text-align: right;
}

.showwAllGrps,
.showwAllGrps:focus {
    font-size: 11px;
    // float: right;
    color: var(--anchorColor);
    text-decoration: none;
    line-height: 13px;
    display: inline-block;
}

.showAlIcn {
    background-color: var(--aiButtonBG);
    color: #fff;
    padding: 2px 3px 2px 3px;
    position: relative;
    // top: -5px;
    border-radius: 2px;
    line-height: 16px;
    border: none;

    .icn {
        margin-right: 2px;
        position: relative;
        top: -1px;
    }

    svg {
        //margin-right: 5px;
    }

    &:hover,
    &:focus {
        color: #e8e8e8;
    }
}

.compositeDataField {
    border: 1px solid rgb(0, 0, 0, 15%);
    border-radius: 3px;
    position: relative;
    margin-left: 15px;
    width: 98%;

    &:before,
    &:after {
        content: "-";
        position: absolute;
        left: -11px;
        top: -15px;
        color: #ffffff;
        height: 70%;
    }

    &:before {
        border-left: 1px dashed rgb(0, 0, 0, 20%);
    }

    &:after {
        border-bottom: 1px dashed rgba(0, 0, 0, 20%);
        width: 10px;
    }

    &.compositeSubField {
        position: relative;
        width: calc(100% - 15px);
    }
}

.userInGrp {
    .dropdown-toggle.btn {
        border: 1px solid #ccc;
        border-right: none;
        height: 34px;
        display: flex;
        align-items: center;

        &:hover {
            background: #e9ecef;
            color: #212529;
        }
    }

    select {
        width: 22%;
        border-radius: 0.375rem 0 0 0.375rem;
        border-right: 0;
        padding: 6px 8px;
    }

    input {
        border-radius: 0 0.375rem 0.375rem 0;
    }

    >div:has(.form-control) {
        width: 100%;
    }
}


.indiIcnDv {
    position: absolute;
    @include bgTransparent;
    height: 20px;
    padding: 0 4px 2px 2px;
    top: 28px;
    right: 3px;
    border-left: 1px #c4c4c4 solid;
    display: flex;
    align-items: center;
}

.asignicn {
    color: #6b6b6b;
    font-size: 13px;
    border: none;
    @include bgTransparent;
}

.chngBtnDv {
    .indiIcnDv {
        padding: 0 4px 2px 4px;
    }

    .asignicn {
        padding: 0 3px;
    }
}

.controlInputdv {
    border: 1px solid #ccc;
    border-radius: 4px;

    .input-group-text {
        width: 40%;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        padding: 2px 4px;

        .cls {
            height: 100%;
            width: 100%;
            border-radius: 4px;
        }
    }

    .form-control {
        border: none;
        border-left: 1px #ccc solid;
        box-shadow: none;
        padding: 4px;
    }
}

.controlInputPop {
    padding: 10px;
    border-radius: 6px;
    margin: 0px 5px;

    .react-colorful {
        width: 180px;
        height: 180px;

        &__saturation {
            border-radius: 2px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }

        &__last-control {
            border-radius: 2px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
        }

        &__pointer {
            width: 20px;
            height: 20px;
        }

        &__hue {
            height: 15px;
        }
    }
}

.dCheckbox.chkboxpow,
.servicedetails .form-label {
    display: flex;
}

.servicedetails .rightSideFields .form-label {
    display: inline-block;
}

.form-select[disabled]::placeholder,
.form-control[disabled]::placeholder {
    color: #909090 !important;
}

.appntRecurDv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    input {
        width: auto;
    }
}

.atRemIm {
    display: table;
    width: 100%;
    margin-top: 0px;

    .txt {
        margin-top: 0px;
        display: table-cell;
        width: 100%;
        font-size: 12px;
        padding-left: 5px;
    }

    .xBtn {
        border: none;
        box-shadow: none;
        display: table-cell;
        width: 25px;
        color: #898989;
        font-size: 12px;
        text-align: right;
        background-color: transparent;

        &:hover {
            color: #000000;
        }
    }
}

    .cabAppField {
        @include display(flex, baseline);
        flex-direction: column;
        gap: 10px;
        >input{
            margin-left: 5px !important;
        }
    }