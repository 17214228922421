@import "../general/mixin";

.serviceListBox {
    .hTxt {
        padding: 0px 20px;
        margin: 10px 0px 20px;
    }
}

.servicePlist {
    .lbox {
        padding-left: 46px;
        margin-bottom: 15px;
        min-height: 36px;
        clear: both;
        cursor: pointer;

        &:hover {
            .hd {
                color: var(--anchorColor-darker);
            }

            .serIcon {
                background-color: var(--primaryColor);
            }

            .sDetails {
                background-color: var(--primaryColor) !important;
            }

            .txtBox,
            &:focus .txtBox {
                font-size: 11px;
                display: block;
            }
        }

        .serIcon {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            position: absolute;
            left: -45px;
            background-color: #919191;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(255, 255, 255, 0.7);
            font-size: 22px;

            svg.knwldgIcn {
                position: absolute;
                font-size: 15px;
                top: 3px;
                right: 2px;
            }
        }

        .sumTxt {
            font-size: 13px;
            @include colorBlack;
            margin-bottom: 2px;
            margin-top: -2px;
        }

        .txt {
            font-size: 11px;
            color: #717171;
            line-height: 14px;
        }
    }

    .hd,
    .hd a {
        font-size: 14px;
        color: var(--anchorColor);
        font-weight: 600;
        line-height: 18px;
        position: relative;
    }

    .hd.gapn {
        padding: 10px;
        background-color: #fafafa;

        &:hover,
        &:focus {
            background-color: var(--primaryColor-lighter);
        }
    }

    .hdCount {
        @include displayInlineBlock;
        margin-left: 5px;
    }
}

.lanActBox {
    display: table;
    width: 100%;
    margin-bottom: 10px;
    background-color: #f7f7f7;
    padding: 4px 8px 8px;
    padding-bottom: 10px;

    .lanChild {
        display: table-cell;
        vertical-align: top;
    }

    p,
    h2 {
        margin: 0;
        padding: 0;
    }

    h2 {
        font-size: 18px;

        // First letter uppercase
        &::first-letter {
            text-transform: uppercase;
        }

        text-transform: lowercase;
    }

    p {
        font-size: 11px;
        padding: 0 2px;
    }

    .lanInput {
        width: 6%;
    }

    .lanName {
        width: 40%;

        h2 {
            text-transform: lowercase;
        }
    }

    .lanStat {
        width: 30%;
        font-size: 11px;
        text-align: center;
        vertical-align: bottom;
    }

    .lanBtns {
        width: 24%;
        text-align: right;
        vertical-align: bottom;

        .btn {
            margin-left: 4px;
            border: none;
            padding: 0 6px;
            cursor: pointer;

            i {
                font-size: 12px;
            }
        }
    }
}

.lanName-head {
    span {
        text-transform: lowercase;
        @include displayInlineBlock;

        &::first-letter {
            text-transform: uppercase;
        }
    }
}

.langTable {
    &.table-bordered {
        margin: 0;
    }

    thead {
        background-color: #e8e8ea;
    }

    .bgCell {
        background-color: #ececec;
    }
}

.radioVarBox {
    margin: 15px 0px 10px;

    label {
        position: relative;
        margin-right: 15px;
        @include displayInlineBlock;
        padding-left: 20px;
    }

    input {
        position: absolute;
        top: 4px;
        left: 0px;
    }
}

.checkBoxInput {
    margin: 10px 0px;

    label {
        position: relative;
        margin-right: 5px;
        @include displayInlineBlock;
        padding-left: 20px;
    }

    input {
        position: absolute;
        top: 2px;
        left: 0px;
    }
}

.keyVal {
    display: table;
    width: 100%;
    margin-bottom: 10px;

    span {
        font-size: 14px;
        display: table-cell;
        vertical-align: top;
    }

    .key {
        width: 40%;
        color: #336699;
    }

    .val {
        width: 60%;
    }
}

.rePanelGp {
    .panel-heading {
        a {
            font-size: 15px;
            display: block;
            outline: none;

            &::after {
                color: #565a6d;
                content: "\f068";
                @include FontAwesomeFamily;
                font-size: 14px;
                font-weight: 400;
                float: right;
            }

            &.collapsed::after {
                content: "\f067";
            }
        }
    }
}

.exterLnkicn {
    @include displayInlineBlock;
    position: absolute;
    top: -48px;
    right: 64px;

    &.exterLnkicnBG {
        top: -47px;

        a {
            padding: 2px 6px;
            font-size: 17px;
            height: auto;
            @include colorBlack;
            @include bgWhite;

            &:hover {
                background-color: #dbdbdb;
            }

            &:focus {
                @include bgWhite;
            }
        }
    }

    &.extGotoRuleBtn {
        right: 10px;
    }

    a {
        padding: 0 6px;
        font-size: 14px;
        color: var(--primaryColor);
        @include bgTransparent;
        height: 20px;
        border: none;
        text-align: center;
        display: block;

        &:hover,
        &:focus {
            @include colorBlack;
            outline-offset: -2px;
        }

        &.gotoRuleBtnIcn {
            margin-left: 3px;
            font-size: 18px;
            height: auto;
            position: relative;
            top: -1px;
        }
    }

    i {
        margin: 5px 0 0 0;
    }
}

.rBoxGap {
    &:has(.alert) {
        .exterLnkicn {
            &.exterLnkicnBG {
                top: -94px;
            }
        }
    }

    &:has(.alert.errorMessageSec) {
        .exterLnkicn {
            &.exterLnkicnBG {
                top: -47px;
            }
        }
    }
}

.servicedetails {
    .rBoxGap {
        .alert {
            font-size: 12px !important;
            padding: 8px !important;
        }
    }
}