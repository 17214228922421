@import "../general/mixin";
@import "../rightside/main";

.catBordDv {
    margin-bottom: 30px;

    .rightTrue,
    .leftTrue {
        header {
            right: 100px;
            top: 32px;
        }

        &.catBorSrch {
            header {
                top: -55px;
            }
        }

        &.srchPosTop {
            header {
                right: 92px;
                top: 44px;
            }
        }

        &.srchPosTop1 {
            header {
                right: 92px;
                top: 44px;
            }
        }

        &.fdnPos {
            header {
                top: 0px;
            }
        }

        &.cmpSrchPos {
            header {
                right: 45px;
            }
        }

        &.bfncSrchPos {
            header {
                right: 90px;
                top: 45px;
            }
        }
    }
}

.tabSerhed {
    .catBordDv {

        .rightTrue,
        .leftTrue {
            header {
                right: 60px;
                top: 44px;
            }
        }
    }
}

.wibSerhed {
    .catBordDv {

        .rightTrue,
        .leftTrue {
            header {
                right: 50px;
                top: 48px;
            }
        }
    }
}

.dtaTableDv {
    position: initial;

    header {
        left: initial;
        right: 0px;
        position: absolute;
        @include widthHeight(auto, auto);
        z-index: 2;
        padding: 0px;
        @include displayInlineBlock;
        min-height: initial;
        padding-right: 0px !important;

        .tabFiltabDv {
            .form-control {
                padding: 3px 25px 3px 12px;
            }
        }
    }

    .rdt_Pagination {
        word-break: break-word;
        padding: 0px !important;

        span:nth-child(1) {
            margin: 0 !important;
        }

        span:nth-child(3) {
            margin: 0px 10px !important;
        }

        button {
            @include displayInlineBlock;
            padding: 0px;
            @include widthHeight(auto, auto);
        }

        select:focus-visible {
            outline-offset: -4px;
        }
    }

    >div {
        >.rdt_Table {
            border-right: 1px solid rgba(0, 0, 0, 12%);
        }
    }
}

.rdt_Table {
    .rdt_TableRow {
        &:nth-child(odd) {
            background-color: var(--tableColumn);
        }
    }

    .rdt_TableHeadRow {
        background-color: var(--tableHeader) !important;
    }
    
    .rdt_TableRow,
    .rdt_TableHeadRow {
        min-height: initial !important;
    }
    
    .rdt_TableCol,
    .rdt_TableCell {
        border-left: 1px solid #ddd;
        font-size: 13.8px !important;
        padding: 8px 10px !important;
    
        &:first-child {
            min-width: 145px;
        }
    
        &:last-child {
            min-width: 70px;
            border-right: 1px solid #ddd;
        }
    }
    
    .rdt_TableCol {
        font-weight: 600;
        @include colorBlack;
    }
}

.integrateHubTable {
    .rdt_Pagination {
        span {
            margin: 0 auto;

            &.fAiwtk {
                margin: 0px;
            }
        }
    }
}

.bsTableRelate {
    .rdt_Table {
        .rdt_TableHead {
            .rdt_TableCol {
                &:last-child {
                    min-width: 70px;
                    border-right: 1px solid #ddd;
                }

                &:first-child {
                    min-width: 145px;
                }
            }
        }

        .rdt_TableBody {
            .rdt_TableCell {
                &:last-child {
                    min-width: 70px;
                    border-right: 1px solid #ddd;
                }

                &:first-child {
                    white-space: nowrap !important;
                    min-width: 145px;
                }
            }
        }
    }
}

.cnTableCol {
    .rdt_Table {
        .rdt_TableHead {
            .rdt_TableCol {
                &:first-child {
                    min-width: 155px;
                }
            }
        }

        .rdt_TableBody {
            .rdt_TableCell {
                &:first-child {
                    white-space: nowrap !important;
                    min-width: 155px;
                }
            }
        }
    }
}

.rdt_TableRow .groupMangButton {
    display: table !important;

    .form-group {
        width: 58px;
        display: table-cell;
    }

    .paHedFilter {
        display: table-cell;

        button {
            @include displayInlineBlock;
            padding: 1px 0px;
            margin: 0px 0px 0px 2px;
            position: relative;
            top: -1px;
        }
    }
}


.rdt_Table {
    .rdt_TableHead {
        .rdt_TableCol {
            .rdt_TableCol_Sortable {
                span.__rdt_custom_sort_icon__ {
                    position: relative;
                    bottom: -2px;
                    margin-left: 3px;

                    svg {
                        width: 16px;
                    }
                }
            }
        }
    }

    .rdt_TableBody {
        .rdt_TableRow {
            &:nth-child(odd) {
                background-color: rgb(0, 0, 0, 5%);
            }

            .rdt_TableCell {
                @include ovrFlowContent;

                >div {
                    @include ovrFlowContent;
                }
            }

            &:hover {
                border-bottom-color: rgba(0, 0, 0, 0.12);
                outline-color: rgba(0, 0, 0, 0.1);

                .rdt_TableCell {
                    background-color: rgba(0, 0, 0, .003);
                }
            }
        }

        .rdt_TableRow.activeRow {
            background-color: var(--primaryColor-lighter) !important;

            &:hover {
                .rdt_TableCell {
                    background-color: var(--primaryColor-lighter) !important;
                }
            }
        }
    }
}

.datConTab {
    header {
        text-align: left;
        margin: 5px 0px -10px;
        padding: 0px;
        display: block;
    }

    .btn-success {
        font-size: 13px;
        padding: 6px 12px;
        @include displayInlineBlock;
    }

    .tabFiltabDv {
        float: right;
    }
}

.tabFiltabDv {
    position: relative;

    input {
        &:focus {
            outline-offset: 0px;
            box-shadow: none;
            border-color: #ddd;
        }
    }

    button {
        position: absolute;
        top: 2px;
        right: 3px;
        border: none;
        box-shadow: none;
        background: transparent;
        @include bgTransparent;
        color: #ddd;
        font-weight: 300;

        &:hover,
        &:focus {
            color: #a8a8a8;
        }
    }
}

.serbarPos,
.serbarPos1 {
    position: relative;

    .catBordDv {
        .rightTrue {
            header {
                input {
                    padding: 2px 4px;
                    font-size: 12px;
                }
            }
        }
    }

    .tabFiltabDv {
        button {
            padding: 0px;
            top: 6px;
            margin: 0px;
            line-height: 10px;
            font-size: 13px;
        }
    }
}

.serbarPos {
    .catBordDv {
        .rightTrue {
            header {
                right: 20px !important;
                top: -4px !important;
            }
        }
    }
}

.serbarPos1 {
    .catBordDv {
        .rightTrue {
            header {
                right: 0px !important;
                top: -4px !important;
            }
        }
    }
}