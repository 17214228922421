@import "../general/mixin";

.nBotPagina {
    text-align: center;
    margin: 20px 0 0;

    nav {
        position: relative;
        z-index: 2;
    }

    .nShow {
        float: left;

        .form-control {
            height: initial;
            padding: 5px;
        }

        >div,
        .form-label {
            font-size: 13px;
        }

        select {
            padding: 6.5px 30px 6.5px 5px;
        }

    }

    div,
    select {
        @include displayInlineBlock;
        text-align: left;
        width: auto;
        position: relative;
        z-index: 2;
    }

    .pagination {

        >li>a,
        >li>span {
            color: var(--primaryColor);
            padding: 6px 9px;
            font-size: 16px;
        }

        >a {
            color: var(--primaryColor);
            padding: 5px 9px;

            &:hover {
                z-index: 2;
                color: var(--primaryColor);
                background-color: #eeeeee !important;
                border-color: #ddd !important;
            }
        }

        .active {

            >a,
            >a:focus,
            >a:hover,
            >span,
            >span:focus,
            >span:hover {
                @include colorBlack;
                background-color: #ffffff !important;
                border-color: var(--primaryColor) !important;
            }

            >a {
                z-index: 1;
            }
        }

        >li.disabled {
            display: none !important;

            >a {
                opacity: 0.7;
                pointer-events: none;
                background-color: #f5f5f5 !important;
                color: #adadad !important;
                cursor: default !important;
            }
        }
    }
}

.react-bootstrap-table-page-btns-ul {
    &.pagination {
        a {
            font-size: 16px;
            color: var(--primaryColor);
            padding: 6px 9px;
        }

        .active {

            a,
            a:hover {
                @include colorBlack;
                background-color: #ffffff !important;
                border-color: var(--primaryColor) !important;
            }

            >a {
                z-index: 1;
            }
        }
    }
}

.pagination {
    margin: 0px 0px;

    >li {

        &.active>a,
        &.active>span {
            @include colorBlack;
            background-color: #ffffff !important;
            border-color: var(--primaryColor) !important;
        }
    }

    >li>a,
    >li>span {
        position: relative;
        float: left;
        padding: 5px 9px;
        margin-left: -1px;
        line-height: 1.42857143;
        color: #08a4b3;
        text-decoration: none;
        @include bgWhite;
        border: 1px solid #ddd;
    }

    .glyphicon {
        color: var(--primaryColor);
        @include FontAwesomeFamily;
        font-size: 11px;
        font-weight: 300;
        @include displayInlineBlock;
        transform: skewX(10deg);

        &-menu-left:before {
            content: "\f053";
        }

        &-menu-right:before {
            content: "\f054";
        }
    }

    >.active {

        >a,
        >a:focus,
        >a:hover,
        >span,
        >span:focus,
        >span:hover {
            background-color: var(--primaryColor);
            border-color: #09adbd;
        }
    }

    .disabled {
        @include displayVisibilityNone;
    }
}

.toSmall .rdt_Pagination {
    justify-content: center;

    &>span,
    &>div {
        display: none;

        &:last-child {
            display: flex !important;
        }
    }
}