@import "../general/mixin";

/************ Info Table ************/

.rwTable {
    display: table !important;
    width: 100%;
    clear: both;
    font-size: 14px;
    border-top: 1px #b3d4e6 solid;
    padding: 8px 0 !important;

    &:last-child {
        border-bottom: 1px #b3d4e6 solid;
    }

    .rwKey {
        display: table-cell;
        width: 50%;
        font-size: 14px;
        color: var(--primaryColor-darkest);
        vertical-align: middle;
    }

    .rwVal,
    .rwVal1 {
        display: table-cell;
        width: 50%;
        color: #222;
        font-weight: 400;
        vertical-align: middle;
        word-break: break-word;

        ol,
        ul {
            list-style: disc;
            margin: 0 0 0 20px;

            &.noDisc {
                list-style: none;
                margin: 0;

                li {
                    clear: left;
                }
            }
        }
    }
}

.brdrTopcolor .rwTable {
    border-color: #b3d4e6;
    border-bottom-width: 1px !important;
}

.readMoreText {
    padding: 0 4px;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 600;
    box-shadow: none;
}

.lessMoreBtn {
    font-size: 12px;
    color: var(--anchorColor);
    border: none;
    box-shadow: none;
    @include bgTransparent;
    position: absolute;
    bottom: -18px;
    right: 0px;

    i {
        vertical-align: middle;
    }

    &Inline {
        font-size: 13px;
        color: var(--anchorColor);
        border: none;
        box-shadow: none;
        @include bgTransparent;
        padding: 0px;
    }
}

.rwTableGap {
    display: table;
    width: 100%;
    clear: both;
    font-size: 13px;
    padding: 4px 0px;

    .rwKey {
        display: table-cell;
        width: 45%;
        font-size: 13px;
        color: #333;
        vertical-align: top;
    }

    .rwGap {
        display: table-cell;
        width: 10%;
        text-align: center;
    }

    .rwVal {
        display: table-cell;
        width: 45%;
        font-size: 13px;
        color: #222;
        font-weight: 400;
        vertical-align: top;
        word-break: break-word;
    }
}

.notifAccrTable {
    .rwTable {
        .rwKey {
            width: 20%;
        }

        .rwVal,
        .rwVal1 {
            width: 80%;
        }
    }
}

/************ Timeline ************/

.timeline {
    clear: both;
    padding-top: 10px;

    ul {
        margin: 0;

        li {
            display: inline;
            float: left;
            padding: 0 0 15px 0;
            width: 44%;

            &:first-child {
                margin: 0 6% 0 0;
                position: relative;
            }

            &:last-child {
                margin: 0 0 0 6%;
            }
        }
    }
}

/************ Audit Log ************/

.actCommts {
    border-bottom: 1px #f2f2f2 solid;
    margin-bottom: 10px;
    padding-bottom: 10px;
    clear: both;

    &.actCommtsTsk {
        padding: 0 5px 15px 0 !important;
    }

    &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .hed {
        color: var(--primaryColor);
        font-weight: 600;
        font-size: 14px;
    }

    .subhed.tskSubhed {
        span {
            &.intTag {
                position: relative;
                top: -1px;
                font-size: 11px;
                font-weight: 400;
            }
        }
    }

    .subhed,
    .subhed span.timeao {
        font-weight: 300;
        font-size: 12px;
    }

    .subhed span {
        font-weight: 600;
        font-size: 13px;
        color: #535353;
        word-break: break-all;
    }

    .media {
        margin-top: 10px;
        color: #333;

        &:last-child {
            .desFld {
                border-bottom: none;
                padding-bottom: 0;
            }

            .des {
                margin-bottom: 0;
            }
        }
    }

    .des {
        font-size: 13px;
        @include colorBlack;
        font-weight: 500;
        margin-top: 2px;
        margin-bottom: 12px;
        white-space: pre-wrap;
        word-break: break-word;
    }

    .moreArrow,
    .lessArrow {
        font-size: 15px;
        color: var(--primaryColor);

        &:hover {
            @include colorBlack;
        }
    }

    .table {
        margin-bottom: 0;
    }

    .desFld {
        font-size: 13px;
        font-weight: 600;
        border-bottom: 1px #eee solid;
        padding-bottom: 5px;
        margin-bottom: 5px;
    }

    .fenme {
        font-size: 13px;
        @include colorBlack;
        font-weight: 500;
        margin: 4px 0;
        background-color: #fbfbfb;
        text-transform: uppercase;
    }

    .oldnme {
        color: #888888;
        margin-bottom: 4px;
        font-weight: 500;
        word-break: break-word;

        table {
            color: #888 !important;
        }
    }

    .nwnme {
        @include colorBlack;
        font-weight: 500;
        white-space: pre-wrap;
        word-break: break-word;
    }
}

/************ Progress Bar ************/

.progressbarDiv {
    padding-bottom: 66px;
    padding-top: 56px;

    &:after {
        content: "";
        display: block;
        margin-top: -16px;
        border-bottom: 2px dotted #b38b0d;
    }

    ul {
        width: 100%;
        height: 30px;
    }

    li {
        display: table-cell;
        width: 1%;
        position: relative;
        vertical-align: top;

        &:first-child.mActive .box {
            left: initial;
            background-color: #ffffff;
        }

        &:last-child:after {
            content: "";
        }

        &:nth-child(even) .txt {
            top: -44px;
        }

        a {
            display: block;
        }

        .box {
            padding: 0px;
            margin-right: 30px;
            height: 26px;
            width: 26px;
            border-radius: 50%;
            display: inline-block;
        }

        &.mdisabled {
            .box {
                background-color: #ffffff;
                border: 1px #a0a0a0 solid;
            }

            .box .step1 {
                border: 1px #de972f solid;
                height: 16px;
                width: 16px;
                border-radius: 50%;
                margin-top: 4px;
                margin-left: 4px;
                float: left;

                .step2 {
                    width: 10px;
                    height: 10px;
                    background-color: #de972f;
                    border: 2px #ffffff solid;
                    border-radius: 50%;
                    text-align: center;
                    margin-top: 2px;
                    margin-left: 2px;
                    float: left;
                }
            }

            .head {
                background: #de972f;
                color: #ffffff;

                span {
                    background-color: #de972f;
                    display: none;
                }
            }

            .name,
            .time {
                color: grey;
            }
        }

        &.mActive {
            .box {
                background-color: #ffffff;
                border: 1px #a0a0a0 solid;
            }

            .box .step1 {
                border: 1px #50b245 solid;
                height: 16px;
                width: 16px;
                border-radius: 50%;
                margin-top: 4px;
                margin-left: 4px;
                float: left;

                .step2 {
                    width: 10px;
                    height: 10px;
                    background-color: #50b245;
                    border: 2px #ffffff solid;
                    border-radius: 50%;
                    text-align: center;
                    margin-top: 2px;
                    margin-left: 2px;
                    float: left;
                }
            }

            .txt {
                border-left-color: #50b245;
            }

            .head {
                background: #50b245;

                span {
                    background-color: #50b245;
                    display: none;
                }
            }
        }

        &.notActive {
            .box {
                background-color: #ffffff;
                border: 7px #177ad2 solid;
            }

            .head {
                background: #69869e;

                span {
                    background-color: #177ad2;
                    display: none;
                }
            }
        }
    }

    .time {
        font-size: 8px !important;
    }

    .txt {
        position: absolute;
        font-size: 11px;
        top: 27px;
        left: 0px;
        z-index: 1;
        border-left: 1px #de972f solid;
        padding-left: 10px;
        margin-left: 11px;

        div {
            font-size: 11px;
        }
    }

    .head {
        color: #ffffff;
        padding: 1px 10px 1px 6px;
        font-size: 12px;

        span {
            font-size: 12px;
            display: inline-block;
            padding: 3px 7px;
            margin-right: 4px;
        }
    }

    #timelinePopup {
        z-index: 2;
        position: absolute;
        top: 58px;
        left: 0px;
        min-width: 120px;
        background-color: #dae4ed;
        white-space: nowrap;
        box-shadow: 2px 2px 4px -1px #c0d0de;
        border-left: 4px #de972f solid;

        ul {
            height: 100%;
        }

        li {
            display: table;
            border-bottom: 1px #b3c5d6 solid;
            width: 100%;
            padding: 5px 6px;

            &:hover {
                background-color: #cad5de;
            }

            .head {
                background: transparent;
            }

            &:last-child {
                border-bottom: none;
            }

            &:after {
                content: "";
            }
        }
    }
}


.progressbarDivOuter {
    position: absolute;
    top: 1px;
    left: 0;
    @include bgWhite;
    z-index: 899;
    border-radius: 2px;
    padding: 10px;
    right: 0;
    border: 1px #d9d9d9 solid;
    box-shadow: 0 0 12px -4px #000000;

    .carousel {
        background-color: #ebecef;
        border: 1px #cdd6ec solid;
    }

    .carousel-indicators {
        bottom: -11px;
        right: 4px;
        width: initial;
        margin-right: initial;
        margin-bottom: 1rem;
        margin-left: initial;
        top: initial;
        background-color: rgba(0, 0, 0, 0);
        border-bottom: none;
        left: initial;
        align-items: center;
    }
}

.progressClose {
    background-color: red;
    color: #ffffff;
    @include displayInlineBlock;
    padding-top: 3px;
    border-radius: 4px;
    font-size: 10px;
    width: 20px;
    height: 20px;
    text-align: center;
    position: absolute;
    top: 10px;
    right: 10px;
    font-weight: bold;
    z-index: 1;
}

/************ Color Dots ************/

.redDot,
.redDot.btn-default,
.redDot.btn,
.rBoxStyle .redDot.btn-default,
.rBoxStyle .redDot.btn,
.rBoxStyle .redDot:focus,
.rBoxStyle .redDot.btn:focus,
.redDot.btn-default:focus,
.redDot.btn:focus {
    height: 10px;
    width: 10px;
    @include displayInlineBlock;
    background-color: #e60000 !important;
    border-radius: 50% !important;
    box-shadow: none;
    border: none !important;
    padding: initial !important;
}

.redDot:hover,
.rBoxStyle .redDot.btn:hover,
.redDot.btn-default:hover,
.redDot.btn:hover {
    background-color: #ca0303 !important;
}

.greenDot,
.greenDot.btn-default,
.greenDot.btn,
.rBoxStyle .greenDot.btn-default,
.rBoxStyle .greenDot.btn,
.rBoxStyle .greenDot:focus,
.rBoxStyle .greenDot.btn:focus,
.greenDot.btn-default:focus,
.greenDot.btn:focus {
    height: 10px;
    width: 10px;
    @include displayInlineBlock;
    background-color: #00ff00 !important;
    border-radius: 50% !important;
    box-shadow: none;
    border: none !important;
    padding: initial !important;
}

.greenDot:hover,
.rBoxStyle .greenDot.btn:hover,
.greenDot.btn-default:hover,
.greenDot.btn:hover {
    background-color: #04cc04 !important;
}

.blueDot,
.blueDot.btn-default,
.blueDot.btn,
.rBoxStyle .blueDot.btn-default,
.rBoxStyle .blueDot.btn,
.rBoxStyle .blueDot:focus,
.rBoxStyle .blueDot.btn:focus,
.blueDot.btn-default:focus,
.blueDot.btn:focus {
    height: 10px;
    width: 10px;
    @include displayInlineBlock;
    background-color: #ffd400 !important;
    border-radius: 50% !important;
    box-shadow: none;
    border: none !important;
    padding: initial !important;
}

.blueDot:hover,
.rBoxStyle .blueDot.btn:hover,
.blueDot.btn-default:hover,
.blueDot.btn:hover {
    background-color: #eb9f00 !important;
}

.orangeDot,
.orangeDot.btn-default,
.orangeDot.btn,
.rBoxStyle .orangeDot.btn-default,
.rBoxStyle .orangeDot.btn,
.rBoxStyle .orangeDot:focus,
.rBoxStyle .orangeDot.btn:focus,
.orangeDot.btn-default:focus,
.orangeDot.btn:focus {
    height: 10px;
    width: 10px;
    @include displayInlineBlock;
    background-color: #ff7300 !important;
    border-radius: 50% !important;
    box-shadow: none;
    border: none !important;
    padding: initial !important;
}

.orangeDot:hover,
.rBoxStyle .orangeDot.btn:hover,
.orangeDot.btn-default:hover,
.orangeDot.btn:hover {
    background-color: #c85d04 !important;
}

/************ Charts ************/

.rgChartNo {
    margin: 0 0 35px;
    clear: both;
    background-color: #eaeaea;
    border-bottom-right-radius: 20px;
    height: 52px;

    .shDesChart {
        width: 100%;
        display: table;
        position: relative;
    }

    .num {
        cursor: pointer;
        font-size: 24px;
        position: relative;
        top: -8px;
        border-radius: 4px;
        padding: 25px 0;
        color: #ffffff;
        font-weight: bold;
        width: 30%;
        display: table-cell;
        text-align: center;
        line-height: 20px;
        vertical-align: middle;
    }

    .numTxt {
        line-height: normal;
        display: table-cell;
        width: 70%;
        padding: 0 10px 15px;
        text-align: left;
        vertical-align: middle;

        .secLine {
            color: #757575;
            font-size: 12px;
            display: block;
        }
    }

    .numCoun {
        font-size: 11px;
        font-weight: normal;
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 60px;
        margin-left: -30px;
        color: #b2deea;
    }
}

.dashBox {
    border: 1px #dfdfdf solid;
    border-bottom: 2px #ffbb28 solid;
    padding: 0;
    margin: 15px 0;

    &:first-child {
        margin: 0 0 15px;
    }

    h2 {
        font-size: 14px;
        font-weight: 400;
        margin: 0;
        background-color: #ffc74d;
        padding: 4px 10px;
    }

    .chartBox {
        padding: 5px 10px;
    }

    .recharts-text,
    .recharts-layer text {
        font-size: 11px;
    }

    .recharts-label-list .recharts-label {
        font-size: 9px;
    }

    .recharts-legend-wrapper {
        width: 100% !important;
        left: -6px !important;

        .recharts-default-legend {
            line-height: 14px;
        }

        .recharts-legend-item {
            margin-right: 5px !important;
            line-height: 12px;

            .recharts-legend-item-text {
                font-size: 9px;
            }
        }
    }

    svg:not(:root) {
        overflow: visible;
    }
}

.chartArrow {
    font-size: 10px;
    color: #278bc7;
    display: block;
    margin: 4px 5px 0 0;
    cursor: pointer;
    text-align: center;
    background: #efefef;

    &:after {
        content: "\f0d7";
        color: #278bc7;
        @include FontAwesomeFamily;
        font-weight: normal;
        padding-left: 3px;
    }

    &:active::after,
    &:focus::after,
    &.active:after {
        content: "\f0d8";
    }
}

.chartTxt {
    &Num {
        font-size: 30px;
        font-weight: bold;
        margin: 0px auto;
        text-align: center;
        line-height: 28px;
    }

    &lab {
        font-size: 12px;
        margin: 0px auto;
        text-align: center;
        line-height: 14px;
    }
}

.borderChartTD {
    min-height: 60px;

    &1 {
        border-right: 1px #cccccc solid;
        border-left: 1px #cccccc solid;
        background: #f1f1f1;
    }
}

path.recharts-sector:hover,
path.recharts-rectangle:hover {
    opacity: 0.65;
    cursor: pointer;
}

.downloadPDFGraph {
    position: absolute;
    top: -38px;
    right: 170px;
    padding: 3px 8px !important;
}

.borderBoxLine {
    padding: 10px 0px;
}


.editImicon {
    font-size: 10px;

    a {
        font-size: 12px;
        color: var(--primaryColor);
        margin-right: 5px;

        &:after {
            content: "\f040";
            color: var(--primaryColor);
            font-size: 14px;
            @include FontAwesomeFamily;
            margin-left: 5px;
        }

    }
}

.tagListData,
.tagListDataValue {
    li {
        min-width: 100%;
        max-width: 150px;
        @include ovrFlowContent;
        margin-bottom: 5px;
        padding: 0px 7px 5px 5px;
        border-bottom: 1px solid #ccc;

        &:first-child {
            margin-top: 10px;
        }

        &:last-child {
            margin-bottom: 15px;
            border-bottom: none;
            padding-bottom: 0px;
        }
    }
}

.tagListDataValue {
    li {
        max-width: 120px;
    }
}

tr:has(.tagListData, .tagListDataValue) {
    vertical-align: middle;
}

.notifAccrdHead {
    width: 96%;
    @include ovrFlowContent;
}

.smartFormIcn {
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    svg {
        font-size: 13px;

        &:first-child {
            margin-right: 10px;
            font-size: 16px;
        }

        &.smrtIcnOn {
            color: var(--anchorColor)
        }

        &.smrtIcnOff {
            color: var(--anchorColor-light)
        }
    }
}

.rulesDropdown {
    .k-treeview {
        max-height: 200px;
    }

    .k-treeview-leaf {
        &.k-selected {
            background-color: #ffffff;
            color: #424242;

            &:hover {
                background-color: #f0f0f0;
            }

            &.k-focus {
                box-shadow: none;
            }
        }

        &:focus,
        &.k-focus {
            box-shadow: none;
        }
    }
}

.ck-quil {
    .ql-container {
        .ql-tooltip {
            left: -1px !important;

            &.ql-editing {
                display: inline-flex;
                align-items: flex-start;
                flex-direction: column;

                &.ql-hidden {
                    display: none;
                }

                .ql-action {
                    width: 100%;
                    text-align: right;
                }
            }

            .ql-preview {
                max-width: 50px;
            }

            .ql-action {
                &::after {
                    margin-left: 10px;
                }
            }
        }
    }
}

.vendorRow {
    display: inline-flex;
    align-items: center;
    width: 100%;

    .vendor {
        font-weight: bold;
        position: relative;
        width: 45%;
        padding-left: 12px;
    }

    .ticket {
        font-weight: bold;
        position: relative;
        width: 35%;
    }

    .actionCss {
        font-weight: bold;
        position: relative;
        width: 20%;
    }
}

.borderBottom {
    border-bottom: 2px solid #e4e4e4;
}

.noCriteriaField {
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
}

.slaStatusIcn {
    background-color: var(--aiButtonBG);
    border-radius: 4px;
    padding: 2px;
    @include display(inline-flex, center, center);
    margin-left: 5px;

    svg {
        @include widthHeight(12px, 12px);
    }
}

.bs-popover-bottom { 
    &.slaStatusPopup {
        min-width: auto;
        padding: 2px 5px;
        font-size: 16px;
        font-weight: 600;
        display: inline-flex;
        align-items: center;
        background-color: var(--aiButtonBG);
        color: #ffffff;
        border: 0px;
        border-radius: 4px;
        box-shadow: 0 0 5px 0px #6b6b6b;
    
        span {
            width: 65px;
            display: block;
            font-size: 10px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0.4px;
        }

        .popover-arrow {
            &::before, &::after {
                top: 2px;
                border-bottom-color: var(--aiButtonBG);
            }
        }
    }
}
