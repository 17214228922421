
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
#GovernanceMeeting .week-btn.selected, #meeting-document .week-btn.selected, .custom-attachment-table .week-btn.selected {
  background-color: #7dbc26!important;
  color: #fff;
}
#GovernanceMeeting .week-btn, #meeting-document .week-btn, .custom-attachment-table .week-btn {
  border: 1px solid #ddd;
  height: 30px;
  margin-right: 18px;
  padding: 3px;
  text-transform: capitalize;
  width: 36px;
}

.occurrene-table th {
  font-size: 13px;
  color: #333;
}

.occurrene-table th:first-child,
.occurrene-table tbody td:first-child {
  background-color: rgb(235, 235, 235);
  width: 40%;
  
  a {
   color: var(--anchorColor);
  }
}

.document-body {
  border: 1px solid #ddd;
  font-size: 12px;
}

.default-Tracking {
  border: 1px solid #2f4a7c;
  td,
  th {
    border: 1px solid #2f4a7c;
  }
}

.tracking {
  .heading-attached-file h4 {
    font-size: 14px;
  }

  .default-Tracking {
    border: 1px solid #ddd;

    td,
    th {
      border: 1px solid #ddd;
    }
  }

  tr th:first-child {
    background-color: #f5f5f5;
  }

  tr td {
    padding: 2px 5px;
  }

  tr td:first-child {
    background-color: #f5f5f5;
  }

  .delegate {
    align-items: center;

    .radio-1 {
      flex-grow: 1;
      margin-left: 0;
      margin-right: 0;
    }

    .radio-2 {
      flex-grow: 1;
      margin-left: 0;
      margin-right: 0;
    }
    .ant-select {
      font-size: 11px !important;
    }

    .flabel {
      top: -10px !important;
    }
  }

  .delegate-select label + .MuiInput-formControl {
    margin-top: 10px;
  }

  .delegate-select .MuiFormControl-root {
    margin-top: 0px;
  }

  .radio-2 {
    flex-grow: 1;
    margin-left: 0;
    margin-right: 0;
  }
  .ant-select {
    font-size: 11px !important;
  }

  .flabel {
    top: -10px !important;
  }

  .radiobtn:after {
    content: "";
    position: absolute;
    display: none;
  }

  .default-radio-btn {
    display: block;
    position: relative;
    padding-left: 55px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 17px;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
  }

  .next-block {
    display: block;
  }

  .radiobtn {
    position: absolute;
    top: 0;
    left: 0;
    height: 12px;
    width: 12px;
    background-color: #fff;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #c8c8c8;
  }

  .radiobtn.red-check:after {
    border-color: #e32f11;
  }

  .radiobtn:after {
    left: 3px;
    top: -3px;
    width: 6px;
    height: 12px;
    border-style: solid;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  input:checked ~ .radiobtn {
    background-color: #fff;
  }

  input:checked ~ .radiobtn:after {
    display: block;
  }

  .radiobtn.green-check:after {
    border-color: #7dbc26;
  }

  .radiobtn:after {
    left: 3px;
    top: -3px;
    width: 6px;
    height: 12px;
    border-style: solid;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.user-email-custom {
  color: #626262;
  display: block;
  font-size: 11px;
}

#scrollableDiv_ {
  // border: 1px solid #ddd;
  margin-bottom: 6px;
  margin-top: 6px;
  overflow: auto;
  position: relative;
  border-bottom: 0px;
}

#scrollableDiv_ table {
  border-collapse: collapse!important;
  font-size: 12px;
  margin: 0!important;
  white-space: nowrap;
  width: 100%;
}

.is-invalid input,.is-invalid .rbt-input-multi {
  border:solid 1px #dc3545;
}

.disabledallField {
  pointer-events: none;
  opacity: 0.9;
}
.pointer-event-enable{
  pointer-events: visible;
}

.close-btn-multiselect{
  align-items: center;
  padding-top: 6px;
  padding-left: 3px;
}

#GovernanceMeeting {
  .form-control {
    padding: 6px 6px;
  }
}


.styled-table {
  padding: 5px 0px;

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

.table {
  border: 1px solid #ddd;

  .tr {
    :last-child {
      .td {
        border-bottom: 0;
      }
    }
  }

  .th,
  .td {
    padding: 5px;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    background-color: #fff;
    overflow: hidden;

    :last-child {
     border-radius: 0;
    }
  }

  &.sticky {
   overflow: scroll; 
   .header{
     position: sticky;
     z-index: 1;
     width: fit-content;
   }

    .header {
      top: 0;
      
    }

    .header .tr .th {
        background-color: #e2e3e3;
        font-weight: 600;
        font-size: 13px;
    }    
    .body {
      position: relative;
      z-index: 0;
      font-size: 13px;
    }

    .body .tr:nth-child(odd) div {
      background-color: #f2f2f2;
  }

  .btn{
    font-size: 18px;
    color: #999;
    background-color: transparent;
    border: 1px #c1c1c1 solid;
    box-shadow: none;
    padding: 4px 5px;
    margin-left: 10px;
    border-radius: 3px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
  
  .body .tr .edit-mode{
    background-color: #c8dee4 !important;

    .saveBt{
        font-size: 18px;
        color: #999;
        background-color: transparent;
        border: 1px #c1c1c1 solid;
        box-shadow: none;
        padding: 4px 5px;
        margin-left: 10px;
        border-radius: 3px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
    
  }
    [data-sticky-td] {
      position: sticky;
    }

    [data-sticky-last-left-td] {
      box-shadow: 0px 0px 0px #ddd;
    }

    [data-sticky-first-right-td] {
      box-shadow: -1px 0px 0px #ddd;
    }
  }
}

}

.comp-container {
  .rbt-input-main {
    height:30px;
  }
}