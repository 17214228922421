@import "../general/mixin";
@import "./header";
@import "./content";
@import "./infoPopup";
@import "./knowledgeArticle";

.rBoxStyle {
  border: 1px solid #e1e1e1;
  box-shadow: none;
  @include bgWhite;

  .rPageHeading {
    color: hsla(0, 0%, 100%, .9);
    font-size: 16px;
    font-weight: 400;
    padding: 5px 15px;
    margin: 0px;
    background-color: var(--primaryColor);
    border-bottom: none;
    position: relative;

    .offNam {
      font-size: 17px;
      color: #ffffff;
      line-height: 18px;
      padding-bottom: 2px;
      min-height: 20px;
      max-width: 88%;
    }

    .editIcn {
      font-size: 14px;
      color: #ffffff;
      cursor: pointer;
      float: right;
      z-index: 1;
      padding: 2px 6px;
      margin: 3px 0px 0px;
      border: 1px solid;
      border-color: var(--primaryColor);
      background-color: transparent;
      box-shadow: none;

      &:hover,
      &:focus {
        border: 1px #36c4d2 solid;
        color: #b0f8ff;
      }
    }
  }

  .rPageHeadActBtn {
    float: right;
    position: relative;
    top: 9px;
    right: 4px;
    z-index: 1;

    ul,
    ul li {
      margin: 0px;
      padding: 0px;
      list-style-type: none;
    }

    ul {
      display: inline-flex;
      align-items: center;
    }

    ul li {
      display: inline-block;
    }

    button,
    a {
      font-size: 20px;
      color: #ffffff !important;
      background-color: transparent;
      border: none;
      box-shadow: none;
      margin-left: 5px;
      display: block;
      text-align: center;
      padding: 4px;
      line-height: 14px;

      &:hover,
      &:focus {
        background-color: var(--primaryColor-lighter);
        color: var(--primaryColor) !important;
      }
    }

    button.recticn {
      font-size: 18px !important;
    }

    a {
      font-size: 18px;
      position: relative;
      top: -1px;

      &.editRsBtn {
        font-size: 17px;
      }

      &.closeRsBtn {
        font-size: 20px;
      }

      >i {
        margin-top: 2px;
      }
    }
  }

  .rBoxGap {
    padding: 15px;
    min-height: 400px;

    form {
      font-size: 13px;
    }

    &+.rBoxGap {
      padding-top: 0px;
    }

    .form-control.dropFileBtn {
      position: relative;
      padding-right: 80px;
      height: initial;

      button {
        position: absolute;
        right: 5px !important;
      }
    }
  }

  .btn {
    border-radius: 0;
    padding: 6px 10px !important;
    font-size: 13px;
    font-weight: 500;
    box-shadow: none;
    color: #000000 !important;
    background: rgba(#000000, 0.1) !important;
    background-color: rgba(#000000, 0.1) !important;
    border: none !important;

    &.ciCrtCatBtn {
      padding: 3px 6px !important;
      font-size: 16px;
      margin-right: 3px;

      &:last-child {
        margin-right: 0px;
      }

      &.addCriteriaBtn {
        padding: 2px !important;
        @include display(inline-flex);
        font-size: 13px;
        margin-right: 0px;

        svg {
          top: 0px;
        }
      }
    }

    &.ciCrtCatBtn2 {
      padding:2px !important;
      font-size: 14px;
      margin-right: 3px;

      &:last-child {
        margin-right: 0px;
      }

      &.addCriteriaBtn {
        padding: 2px !important;
        @include display(inline-flex);
        font-size: 13px;
        margin-right: 0px;

        svg {
          top: 0px;
        }
      }
    }

    &.outputVarBtn {
      font-size: 13px;
      background-color: #ffffff !important;
      padding: 2px 6px !important;
      margin-top: 4px;
    }

    svg {
      position: relative;
      top: -2px;
    }

    &:hover,
    &:focus {
      background-color: #dbdbdb;
      border-color: #000000 !important;
      @include colorBlack;
      outline-offset: -4px;
    }

    &:focus {
      font-weight: 600;
    }

    &.disabled,
    &[disabled] {
      opacity: 0.3;

      &:hover,
      &:focus {
        background-color: #dbdbdb !important;
        border-color: #cacaca !important;
        color: #000000 !important;
      }
    }

    &.greenDot,
    &.blueDot {
      padding: 0px !important;
      border: none !important;
    }

    &.pad05 {
      padding: 0px 5px !important;
    }

    &.theme1 {
      color: var(--primaryColor) !important;
    }

    &.smbtnsave {
      background-color: #5cb85c !important;
      border-color: #4cae4c !important;
      color: #ffffff !important;
    }

    &.applyBtn {
      padding: 1px 4px !important;
      background-color: var(--primaryColor) !important;
      color: #ffffff !important;
      font-size: 11px;
      border-radius: 2px;
  
      &:hover {
          background-color: var(--primaryColor-darker) !important;
      }
    }
  }

  .rightSideFields {
    .myBtn {
      padding: 1px 6px !important;
    }

    .myBtn1 {
      padding: 5px 10px !important;
      color: #ffffff !important;
      background-color: var(--primaryColor) !important;
      border-color: #cacaca;
    }
  }

  .rgSidrkBtn,
  .rgSiLigBtn {
    padding: 10px 14px !important;
    margin-bottom: 5px;

    &:focus, &:hover {
      background-color: var(--primaryColor);
      color: #ffffff;
    }
  }

  .smallBtn {
    padding: 6px 12px !important;

    &.vendorBtn {
      padding: 8px 5px !important;
      font-size: 11px;
      @include display(inline-flex, center);
      margin-right: 2px;
      height: 100%;
    }

    &:focus {
      background-color: var(--primaryColor-darker) !important;
    }
  }

  .rgSidrkBtn {
    color: #ffffff !important;
    background-color: var(--primaryColor) !important;
    border-color: #cacaca;
  }

  .myAppdv .btn:focus,
  .myAppChange .btn:focus {
    color: #ffffff !important;
    background-color: var(--primaryColor) !important;
    border-color: #cacaca;
    outline-offset: 1px;
  }

  .closerightPanelBtn {
    display: block;
  }
}

.mediaCrd {
  border: none;
  margin-bottom: 10px;
  font-size: 14px;

  .card-title {
    font-size: 14px;
  }

  .card-body {
    padding: 0;

    .cTxt,
    .cTxt .short-text {
      word-break: break-word;
    }

    .card-header {
      background-color: transparent;
      padding: 0;
      border-bottom: none;
    }
  }

  &.botBor {
    border-bottom: 1px #e7e7e7 solid;
    padding-bottom: 8px;

    .card-body {
      .card-header {
        background-color: transparent;
        padding: 0;
        border-bottom: none;
      }
    }
  }
}

.dCheckbox {
  input[type="checkbox"] {
    height: 16px;
    width: 16px;
  }

  &.chkboxpow {
    input[type="checkbox"] {
      position: relative;
      top: -3px;
      margin-right: 5px;
    }
  }

  .editInput {
    margin: 0;
  }

  &.inlineCheckbox {
    label {
      width: auto;
    }

    span {
      top: 4px;
      margin-right: 4px;
    }
  }

  &.customCheckBoxContainer {
    margin-bottom: 5px;

    label {
      width: 100%;
      display: block;
      height: auto;
      float: left;
    }

    span {
      float: left;
    }

    .custmChkLbl {
      position: relative;
      left: 10px;
      float: left;
      white-space: normal;
      width: calc(100% - 40px);
      top: 0;
    }
  }
}

.closerightPanelBtn {
  @include displayNone;
}

.blueAnq {
  a {
    color: #399bff;

    &:hover {
      @include colorBlack;
    }

  }

  .proData {
    padding: 5px;
    margin-top: 5px;
    font-size: 10px;

    B {
      color: #399bff;
    }
  }
}

.attachment-break {
  width: 100%;
  word-break: break-all;
  text-align: left;

  li,
  .list-group-item {
    font-size: 13px;
    border-bottom: 1px #efefef solid;
    padding: 4px 2px 4px 8px;
    display: block;
  }

  li>span,
  .list-group-item span {
    font-size: 11px;
    cursor: pointer;
    padding: 2px 6px;
  }
}

.starSecDv {
  font-size: 12px !important;

  span[data-forhalf] {
    font-size: 20px !important;
  }

  label {
    font-size: 20px;

    span {
      font-size: 20px !important;
    }
  }
}

.caRelView a {
  line-height: normal;
  @include displayInlineBlock;

  &:hover {
    color: var(--primaryColor);
  }
}

.cellBor {
  @include bgTransparent;
  border: 1px #989898 dashed;
  padding: 4px 20px;
  border-radius: 24px 0px;
  min-width: 180px;
  @include displayInlineBlock;
  text-align: center;

  &:hover {
    background-color: rgba(128, 128, 128, 0.2);
  }
}

.reltxt1 {
  @include colorBlack;
  font-size: 20px;
  font-weight: 400;
  position: relative;
  text-align: center;
  margin-bottom: 35px;

  .cellBor {
    background-color: rgba(128, 128, 128, 0.2);
  }

  &:before {
    border-left: 6px solid transparent;
    border-top: 6px solid #97a6f3;
    border-right: 5px solid transparent;
    content: "";
    position: absolute;
    left: 50%;
    bottom: -30px;
    margin-left: -6px;
  }

  .relverticalLine {
    top: 37px;
  }
}

.reltxt2 {
  @include colorBlack;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  text-align: center;
  margin-bottom: 32px;

  &:before {
    border-left: 6px solid transparent;
    border-top: 6px solid #97a6f3;
    border-right: 5px solid transparent;
    content: "";
    position: absolute;
    left: 50%;
    bottom: -26px;
    margin-left: -6px;
  }

  .relverticalLine {
    top: 32px;
  }

  &.noChild {

    &:before,
    .relverticalLine {
      @include displayNone;
    }
  }
}

.relverticalLine {
  border-right: 1px #97a6f3 solid;
  position: absolute;
  min-height: 24px;
  left: 50%;
  margin-left: -1px;
}

.relParentBox {
  position: relative;
  text-align: center;
  margin-bottom: 20px;
}

.relTransform {
  padding-left: 50px;
  margin-bottom: 10px;

  &1 {
    padding-left: 140px;
    margin-bottom: 10px;
  }
}

.aproHiSho {
  width: 100%;
  display: table;

  #react-autowhatever-1 {
    bottom: 40px;
    top: initial;
  }

  .react-autosuggest__container .form-control {
    padding-right: 40px;
    line-height: 1.55;
    border-right: 0;
    border-radius: 4px 0 0 4px;
    height: 31px;
  }

  .col1v,
  .col2v {
    display: table-cell;
  }

  .col1v {
    width: 90%;
  }

  .col2v {
    width: 10%;
  }

  .approveBtnBreak {
    width: 100%;
    white-space: normal;
    word-wrap: break-word;
    position: relative;
    top: -2px;
  }
}

.dynTxt {
  font-size: 13px !important;
  color: #333 !important;
  max-height: 200px;
  overflow-y: auto;

  ul,
  ol {
    margin: 5px 15px 15px;
    padding: 0 10px;
  }

  ul li {
    list-style-type: disc;
  }

  ol li {
    list-style-type: decimal;
  }

  table {
    width: 100%;
    border: 1px #e1e1e1 solid;
    margin: 0;
    padding: 0;

    th,
    td {
      border: 1px #e1e1e1 solid;
      padding: 6px;
      font-size: 13px !important; // Repeated styling for table cells
    }

    tr:nth-child(odd) {
      background: #ededed;
    }
  }

  div,
  span,
  ul li,
  ol li,
  table th,
  table td {
    font-size: 13px !important;
  }
}

.details {
  clear: both;
  color: #555;
  font-size: 14px;
  font-weight: 400;
  padding: 4px 6px;

  span {
    @include colorBlack;
    font-size: 14px;
    font-weight: 600;
  }

  .mtx {
    color: #0e65ac;
    font-weight: 600;
  }
}

.ListDetails {
  margin: 0;

  li {
    clear: both;
    @include colorBlack;
    font-size: 14px;
    font-weight: 400;
    padding: 0 0 6px;

    ul {
      li {
        padding: 0;
      }
    }
  }

  .labSpan {
    color: #1d1d1d;
    font-size: 14px;
    font-weight: 600;
    margin-right: 5px;
  }
}

.cReviews {
  th {
    font-weight: 600;
  }

  td {
    border-top: none !important;
  }

  .progress {
    margin-bottom: 0px;
    margin-top: 3px;
    height: 14px;

    .progress-bar {
      box-shadow: none;
      background-color: #ffcf76;
    }
  }
}

.slahbtn {
  color: #ed8917;
  position: absolute;
  top: -12px;
  right: -42px;
  font-size: 28px;
  padding: 4px 11px;
  border-radius: 10px;

  &.criti {
    top: -12px;
    right: -34px;
    color: #ef1a1a;
    font-size: 24px;
  }
}

.rightSideFields {
  position: relative;

  label {
    font-size: 12px !important;
  }

  .btn {
    padding: 2px 6px !important;
    margin-left: 5px;
  }

  .form-control {
    font-size: 12px !important;
    padding: 4px !important;
    height: initial !important;
  }

  .form-select {
    font-size: 12px !important;
    padding: 4px 28px 4px 8px;
    height: initial !important;
  }

  .pagination>li>a,
  .pagination>li>span {
    padding: 4px 8px !important;
  }

  .myInputWidth {
    width: 105px;
  }

  .myInputWidths {
    width: 110px;
  }

  .mySelectWidth {
    max-width: 90px;
  }
}

.myAppvls {
  clear: both;
  word-break: break-word;

  input[type="checkbox"] {
    margin-top: 2px !important;
  }

  .apprvlCheck {
    input[type="checkbox"] {
      border-color: rgba(0, 0, 0, 0.4);
    }

    .form-check-input:checked[type="checkbox"] {
      border: none;
      background-color: var(--primaryColor);
    }
  }
}

.form-check-input[type=radio] {
  border-color: #a3a3a3;
}

.disclaimerCss {
  font-size: 12px !important;
  font-weight: 600;
  border: none;
  color: inherit;
  text-decoration: none;

  span {
    font-size: 14px;
  }
}

.disclaimerHtm {
  font-size: 12px !important;
  word-break: break-word;
}

.zoom-in-tree {
  @include bgWhite;
  position: fixed;
  bottom: 100px;
  right: 35px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: solid 1px #cccc;

  .zoom-btn {
    @include bgTransparent;
    border: none;
    width: 30px;
    height: 30px;
    padding: 5px;

    i {
      font-size: 14px;
      color: #7f7f7f;
    }
  }

  .zoom-btn:disabled {
    background-color: #ccc;
    pointer-events: none;
  }

  button:hover {
    background-color: var(--primaryColor);

    i {
      color: #ffffff;
    }
  }
}

.leftSeardv {

  >header,
  >div>header,
  &.hdprt header {
    @include displayInlineBlock;
    width: auto;
    padding-left: 0;
    min-height: 40px;
  }

  .hdprt {
    header {
      .tabFiltabDv {
        button {
          top: 2px;
        }
      }
    }
  }
}

.addto-playlists {
  max-height: 220px;
  overflow-y: auto;
  overflow-x: hidden;

  ul {
    margin: 0;
    padding: 0;
    width: auto;

    li {
      list-style: none;
      padding: 5px;

      &:nth-child(odd) {
        background-color: #f7f7f7;
      }
    }
  }
}

.addto-search {
  margin-top: 3px;

  .form-input {
    position: relative;

    .keyword {
      width: 100%;
      padding: 5px 8px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
    }

    .search-button {
      pointer-events: none;
      position: absolute;
      @include bgTransparent;
      border: none;
      right: 10px;
      top: 4px;
    }
  }
}

.valurdrpdown {
  display: inline-flex;

  .myControl {
    border-radius: 0;
    border-right: 0;

    &:focus {
      border: 1px solid #66afe9;
    }
  }

  .mySel {
    border-radius: 4px 0 0 4px;
    max-width: 95px;
  }

  .myBtn {
    margin-left: 0;
    border: 1px solid #ccc !important;
    border-radius: 0 4px 4px 0;
  }
}

.simTable {
  td {
    border: none !important;
    padding: 4px 12px !important;
    line-height: normal !important;
    vertical-align: middle !important;
  }

  .form-control {
    height: auto !important;
  }
}

.gjs-cell {
  word-break: break-word;
}

.rulesInptDv {
  .optrSelect {
    .k-dropdowntree {
      background-color: #ffffff;
      border: 1px solid #cccccc;

      .k-clear-value {
        @include widthHeight (14px, 14px);
      }

      .k-button {
        background-color: #ffffff;
        color: #424242 !important;
        border: none;
        border-left: 1px solid #cccccc
      }
    }
  }

  .optrVal {
    input {
      height: auto !important;
      border-color: #cccccc !important;
    }
  }

  .btn.ruleSerBtn {
    padding: 4px 3px !important;
    font-size: 13px;
    background-color: rgba(0, 0, 0, 0.1) !important;
    margin-right: 4px;
    border-radius: 2px;

    &:last-child {
      margin-right: 0px;
    }
  }
}


.threetabCul{
  .rdt_Table{
    .rdt_TableCol,.rdt_TableCell{
    &:last-child {
      flex: 0 0 70px !important;
      min-width:initial !important;
      max-width:initial !important;
      .paHedFilter .myBt{
        border: none;
        background-color: initial;
        padding: 0px;
        color: #707070;
        transform: rotate(-75deg);
        font-size: 16px;
        &:hover{
          color:var(--primaryColor);
          background-color:transparent !important;
        }
      }
    }

    &:nth-child(2) {
      flex: 1 !important;
      min-width:130px !important;
      max-width:initial !important;
      white-space: nowrap;
      .paHedFilter .myBt{
        padding: 0px 2px;
        border: none !important;
        position: relative;
        top: -3px;
        margin: 0px 2px;
      }
    }
    }
  }

}
/**Kendo UI***/
.no-labels {

  .k-floating-label-container {    
    padding-top: 0;    
    .k-label {
      display: none;
    }
  }
}

.foreCastHd {
  @include display(flex, center, space-between);
  width: 100%;
  gap: 5px;

  > div {
    &:first-child {
      @include display(flex);
      gap:10px
    }

    >div {
      position: relative;
    }

    .form-control {
      padding: 4px 6px;
    }

    .foreSearch {
      position: absolute;
      top: 8px;
      right: 5px;
      color: #838383;
    }

    .btn {
      border-radius: 4px;
      padding: 4px 8px !important;
    }
  }
}