@import "../general/mixin";

.rbt-menu {
    &>li a {
        overflow: visible;
        white-space: break-spaces;
        word-break: break-word;
    }
}

.rbt-input-hint {
    opacity: 0;
}

.dvTypehd {
    position: relative;

    .rbt-menu {
        &>li a {
            border-bottom: 1px #e1e1e1 solid;
            padding: 7px 0;
            margin: 0 15px;
        }

        &>.dropdown-item {
            overflow: initial;
            text-overflow: initial;
            white-space: normal;
            word-break: break-word;
        }

        &.dropdown-menu {
            &.show {
                z-index: 99;
                //transform: translate3d(0, 32px, 0) !important;
                max-height: 195px !important;
                border-radius: 2px;
                font-size: 13px;
                color: #000;
                background: #fff;
                box-shadow: 0 1px 4px -1px #000;
                word-break: break-word;
                white-space: initial;
                padding: 0 10px;
                min-width: 160px;

                a {
                    padding: 6px 4px;
                    border-bottom: 1px #d2d2d2 solid;
                    color: #000;
                    font-weight: 400;

                    &:hover,
                    &:focus {
                        background-color: #e2eaff;
                    }

                    span.typHdmainHead {
                        color: #222;
                        width: 100%;
                        font-size: 13px;
                    }

                    span.typHdmainSubHead {
                        margin-top: 0 !important;
                        font-size: 12px;
                    }
                }

                .active,
                &:active {
                    background-color: #e9ecef;
                }
            }

            .dropdown-item {
                border-bottom: 1px #e1e1e1 solid;
                padding: 7px;
                margin: 0;

                &:last-child {
                    border-bottom: none;
                }

                +.dropdown-divider {
                    border-bottom: none;
                    @include displayNone;
                }

                &.rbt-menu-pagination-option {
                    text-align: center;
                    margin-top: 5px;
                }
            }
        }
    }

    .icn {
        position: absolute;
        top: 6px;
        right: 5px;
        z-index: 2;
        font-size: 13px;
        cursor: pointer !important;
        @include displayInlineBlock;
        padding: 0 4px;

        svg {
            width: 14px;
        }
    }

    .form-control:focus+.rbt-input-hint {
        width: 100% !important;
        overflow: hidden;
        visibility: hidden;
    }

    input[type="text"] {
        // padding-right: 30px;
    }

    .rbt .rbt-input {
        padding-right: 25px;

        .rbt-token .rbt-token-remove-button {
            padding: 0 0.5rem;
        }
    }
   
    .react-autosuggest__suggestions-container {
        &.react-autosuggest__suggestions-container--open {
            z-index: 99;
            transform: translate3d(0, 32px, 0) !important;
            max-height: 200px !important;
            inset: 0 auto auto 0;
            width: 100% !important;

            &.react-autosuggest__container--open {
                width: 100% !important;
                bottom: 64px !important;
                top: initial !important;
                box-shadow: none;
                border-radius: 2px;
                z-index: 99 !important;

                #react-autowhatever-1 {
                    transform: initial !important;

                    &.cmdbdvTypehd {
                        right: 0;
                    }
                }
            }
        }
    }
}

#assignedTo {
    .rbt .rbt-input .rbt-token .rbt-token-remove-button {
    padding: 0.3rem 0.5rem;
 }  
 .rbt .rbt-input .rbt-token .rbt-token-label {
    max-width: 250px;
    display: block;
    margin: 1px 3px 2px 0;
    @include ovrFlowContent;
 }
}

.hambTab {
    .dvTypehd {
        .icn {
            top: 7px;
            right: 4px;
        }
    }
}

.dvTypehdropdown {
    .rbt-menu {
        &.dropdown-menu {
            &.show {
                bottom: 64px !important;
                top: initial !important;
            }
        }
    }

    &.dvTypehdPos {
        .rbt-menu.dropdown-menu.show {
            right: 0px !important;
            left: initial !important;
        }
    }

    .react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open {
        width: 100% !important;
        bottom: 64px !important;
        top: initial !important;
        box-shadow: none;
        border-radius: 2px;
        z-index: 99 !important;
      }
}

.cnfgdvTypehd {
    .dvTypehd {
        .react-autosuggest__container--open {
            #react-autowhatever-1 {
                //transform: initial !important;
            }
        }
    }
}

.cmdbdvTypehd {
    .dvTypehd {
        .react-autosuggest__container--open {
            #react-autowhatever-1 {
                transform: initial !important;
                right: 0;
            }
        }
    }
}

.react-autosuggest {
    &__suggestion--highlighted {
        background-color: #ddd;
    }

    &__suggestion {
        cursor: pointer;
        padding: 6px 0;
        border-bottom: 1px #d2d2d2 solid;
        margin: 0 4px;
        @include colorBlack;
        font-weight: 400;

        &:hover,
        &:focus {
            background-color: #e2eaff;
        }

        .suggBtn {

            &.btn-primary,
            & {
                font-size: 13px;
                color: #222 !important;
                width: 100%;
                text-align: left;
                background-color: transparent !important;
                border: none;
                padding: 0;

                >div {
                    font-size: 12px;
                    color: #777;
                }
            }
        }
    }

    &__suggestions-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
        max-height: 200px;
        overflow-y: auto;
    }

    &__suggestions-container--open {
        display: block;
        position: absolute;
        top: initial;
        width: 280px;
        border: 1px solid #aaa;
        @include bgWhite;
        font-family: inherit;
        font-weight: 400;
        font-size: 16px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        z-index: 2;
    }

    &__container {
        position: relative;

        .form-control {
            padding-right: 30px;
        }
    }

    &__input {
        width: 240px;
        height: 30px;
        padding: 10px 20px;
        font-family: inherit;
        font-weight: 300;
        font-size: 16px;
        border: 1px solid #aaa;
        border-radius: 4px;

        &--focused {
            outline: none;
        }

        &--open {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}

.notifydvTypehd {
    position: relative;

    .icn {
        position: absolute;
        top: 4px;
        right: 4px;
        z-index: 2;
        font-size: 13px;
        cursor: pointer !important;
        @include displayInlineBlock;
        padding: 0 4px;

        svg {
            width: 14px;
        }
    }

    .rbt {
        .rbt-input {
            min-height: auto;
            height: 80px;
            max-height: fit-content;
            overflow-y: auto;
            word-break: break-word;
            padding: 4px 25px 4px 8px;

            .rbt-token {
                word-break: break-all;
                //margin-right: 15px;
                color: var(--anchorColor);
                max-width: 100%;
                background-color: var(--anchorColor-lightest);

                &.rbt-token-active {
                    background-color: var(--anchorColor-lighter);
                }

                .rbt-token-remove-button {
                    padding: 0.25rem 0.5rem;
                    background-color: initial;
                    color: var(--primaryColor) !important;
                    border: none;
                }
            }

            @include scrollbar(6px);

            &.rbt-input-multi {
                .rbt-input-main {
                    &::placeholder {
                        opacity: 0.31;
                    }
                }
            }
        }

        .rbt-menu {
            &.dropdown-menu.show {
                z-index: 99;
                max-height: 195px !important;
                border-radius: 2px;
                font-size: 13px;
                color: #000;
                background: #fff;
                box-shadow: 0px 1px 4px -1px #000;
                word-break: break-word;
                white-space: initial;
                padding: 0 10px;

                a {
                    padding: 6px 4px;
                    border-bottom: 1px #d2d2d2 solid;
                    color: #000;
                    font-weight: 400;

                    &:hover,
                    &:focus {
                        background-color: #e2eaff;
                    }

                    span.typHdmainHead {
                        color: #222;
                        width: 100%;
                        font-size: 13px;
                    }

                    span.typHdmainSubHead {
                        margin-top: 0 !important;
                        font-size: 12px;
                    }
                }
            }

            >li a {
                border-bottom: 1px #e1e1e1 solid;
                padding: 7px 0;
                margin: 0 15px;
            }

            >.dropdown-item {
                overflow: initial;
                text-overflow: initial;
                white-space: normal;
                word-break: break-word;
            }
        }
    }

    .form-control:focus+.rbt-input-hint {
        width: 100% !important;
        overflow: hidden;
        visibility: hidden;
    }

    input[type="text"] {
        padding-right: 30px;
    }

    .dropdown-menu {
        .dropdown-item {
            border-bottom: 1px #e1e1e1 solid;
            padding: 7px;
            margin: 0;

            &:last-child {
                border-bottom: none;
            }

            +.dropdown-divider {
                border-bottom: none;
                @include displayNone;
            }

            &.rbt-menu-pagination-option {
                text-align: center;
                margin-top: 5px;
            }
        }
    }
}

.rbt-input-hint-container {
    div {
        input {
            line-height: 22px !important;
        }
    }

    .form-control {
        &::placeholder {
            color: #333 !important;
            padding-left: 2px;
        }
    }
}

.typeahead {
    .rbt-input-hint {
        width: 100% !important;

        input {
            width: 100% !important;
            box-sizing: border-box !important;
        }
    }
}

.serviceSearchType {
    @include displayInlineBlock;
    width: 100%;
    margin-right: 10px;

    .form-control {
        border-radius: 2px;
        width: 100%;
        height: 28px;
        padding: 3px;
    }
}

[class*="onCallSchedule-"] .dvTypehd>.custom-down-arrow {
    left: initial;
    right: 5px;
    position: absolute;
    z-index: 1;
    top: 6px;
}