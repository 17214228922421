@import "./mixin";

/************ Backgroun Color ************/

.custom-label- {
    &error {
        background-color: #FF3838 !important;
        color:#fff !important;
    }
    &warning {
        background-color: #FFB302 !important;
        color:#fff !important;
    }
    &inProgress {
        background-color: #ffa67b !important;
        color:#fff !important;
    }
    &success {
        background-color: #00bd6f !important;
        color:#fff !important;
    }
    &primary {
        background-color: #808080 !important;
        color:#fff !important;
    }
    &default {
        background-color: #A4ABB6 !important;
        color:#fff !important;
    }
    &danger {
        background-color: #e60000 !important;
        color:#fff !important;
    }
    &draft {
        background-color: #dedede !important;
        color:#000000 !important;
    }
}

.bg-white>*,
.bg-white input:disabled {
    background-color: #ffffff !important;
}

.bg-lightgray {
    background-color: #f3f2f2;
}

.searchbg {
    background-color: #336699;
}

.btn-text:hover {
    background-color: #e4e4e4;
}

.bgWhite {
    @include bgWhite;
}

.bgGray {
    background-color: #eee;
}

.bgActive {
    background-color: #f2f3f8;
}

.bgBtn {
    &1 {
        background-color: #4f6280;
    }
    &2 {
        background-color: #50b245;
    }
    &3 {
        background-color: #5fd4af;
    }
    &4 {
        background-color: #13c4ff;
    }
    &5 {
        background-color: #7aa7a6;
    }
    &6 {
        background-color: #598988;
    }
    &7 {
        background-color: #9a9595;
    }
    &8 {
        background-color: #babd2e;
    }
    &9 {
        background-color: #de972f;
    }
    &10 {
        background-color: #ffbf00;
    }
    &11 {
        background-color: #ff9554;
    }
    &12 {
        background-color: #1ab19f;
    }
    &13 {
        background-color: #c59400;
    }
    &14 {
        background-color: #27c6c4;
    }
    &15 {
        background-color: #26db15;
    }
    &16 {
        background-color: var(--primaryColor);
    }
    &17 {
        background-color: #ff4e00;
    }
    &18 {
        background-color: #1897d3;
    }
    &19 {
        background-color: #ececec !important;
    }
    &20 {
        background-color: #ececec !important;
    }
}

/************ Color ************/

.primaryColor {
    color: var(--primaryColor);
}

.primaryColorDarkest {
    color: var(--primaryColor-darkest);
}

::placeholder {
    color: #333 !important;
}

.color-red {
    color: #ff0000 !important;
    font-weight: 600;
}

.color-red1 {
    color: #df1b16;
}

.color-lightgrey {
    color: #9e9e9e;
}

.color-white {
    color: #ffffff !important;
    font-weight: 400;
}

.btn-danger {
    color: #ffffff !important;
}

.themeColor1 {
    color: var(--primaryColor);
}

.lgrayColor {
    color: #222;
    font-weight: 400;
}

.colorStBlue {
    color: #177bd2;
}

.colorStOrange {
    color: var(--secondaryColor);
}

.white {
    color: #ffffff;
}

.red {
    color: #f00 !important;
}

.black1 {
    color: #1d1d1d;
    word-break: break-word;
}

.black {
    color: #1d1d1d;
    font-weight: 600;
    word-break: break-word;
}

.blue {
    color: #0e65ac !important;
}

.gray {
    color: #575757;
}

.lgray {
    color: #88878c;
}

.green {
    color: var(--secondaryColor) !important;
}

.green1 {
    color: #50b245;
}

.skyBlue {
    color: #13c4ff;
}

.pinkDark {
    color: #9a9595;
}

.parrot {
    color: #babd2e;
}

.theme1 {
    color: var(--primaryColor);
}

.wgray {
    color: #bebebe;
}

.wgray1 {
    color: #7aa7a6;
}

.orange {
    color: #ed8917;
}

.btn-warning {
    color: #ffffff !important;
}

/************ Border ************/

.borderAll {
    border: 1px #d9d9d9 solid;
}

.border-r {
    border-right: 1px solid #ccc;
}

.border-r1 {
    border-right: 1px solid #dcdfec;
}

.border-t {
    border-top: 1px solid #dbdbdb;
    clear: both;
    margin: 0;
    padding: 10px 0;
}

.border-radius {
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.border-1 {
    border: 1px solid #dbdbdb;
}

.border-b {
    border-bottom: 1px solid #eee;
}

.bor-bottom {
    border-bottom: 1px solid #e3e3e3;
}
.border {
    border: 1px solid #e3e3eb;
}

/************ Font Style ************/

.f-size-60 {
    font-size: 60px;
}

.font1 {
    font-size: 12px;
}

.font2 {
    font-size: 13px;
}

.fw-300 {
    font-weight: 300;
    font-style: normal;
}

.fw-400 {
    font-weight: 400 !important;
    font-style: normal;
}

.fw-600 {
    font-weight: 600;
    font-style: normal;
}

/************ Margin Padding ************/

.midMargin {
    margin: 0px 15px 0px 75px;
    padding: 15px 0px 0px;
}

.midMarginR {
    margin: 75px 80px 0 20px;
    padding: 0;
}

.midAll {
    margin: 0px 15px 0px 15px;
    padding: 15px 0px 0px;
}

.row.noMar {
    margin: 0;
}

.marginM-5 {
    margin: -5px 0 0 0;
}

.marAuto {
    margin: auto;
}

/************ Shortcuts ************/

.cursorPoint{cursor:pointer;}
.cursorAuto{cursor:auto;}
.cursorNotallo{cursor:not-allowed;}

.nowrap{white-space:nowrap;flex-wrap:nowrap;}

/*** Float ***/
.float-l{float:left;}
.float-r{float:right;}
.float-none{float:none;}

/*** Z-Index ***/
.zIndex0{z-index:initial;}
.zIndex1{z-index:1;}
.zIndex10{z-index:10;}

/** Text Align **/
.text-l{text-align:left;}
.text-r{text-align:right;}
.text-c{text-align:center;}
.text-caps{text-transform:uppercase;}

/** Border None **/
.border-b-none{border-bottom:none;}
.border-t-none{border-top:none;}
.border-l-none{border-left:none;}
.border-r-none{border-right:none;}
.border-none{border:none !important;}

/** Display **/
.display-block{display:block;}
.display-inline-block{display:inline-block;}
.hidden{display:none;}

/** Font Properties **/
.f-normal{font-weight:normal;}
.f-bold{font-weight:bold;}
.f-italic{font-style:italic;}
.f-underline{text-decoration:underline;}
.clearboth{clear:both;}

/** line height **/
.line-height{line-height:normal;}

/** Positions **/
.position-re{position:relative;}
.position-ab{position:absolute;}
.Ovrflow{overflow:hidden;}

/** Width **/
.width100{width:100%;}
.width50{width:50%;}
.width49{width:49%;}
.width20{width:20%;}

/** Shortcuts **/
.padding-0{padding: 0px}
.padding-5{padding: 5px}
.padding-10{padding: 10px}
.padding-15{padding: 15px}
.padding-20{padding: 20px}
.padding-25{padding: 25px}
.padding-30{padding: 30px}
.padding-35{padding: 35px}
.padding-40{padding: 40px}
.padding-45{padding: 45px}
.padding-50{padding: 50px}

.padding-l-0{padding-left: 0px}
.padding-l-5{padding-left: 5px}
.padding-l-10{padding-left: 10px}
.padding-l-15{padding-left: 15px}
.padding-l-20{padding-left: 20px}
.padding-l-25{padding-left: 25px}
.padding-l-30{padding-left: 30px}
.padding-l-35{padding-left: 35px}
.padding-l-40{padding-left: 40px}
.padding-l-45{padding-left: 45px}
.padding-l-50{padding-left: 50px}

.padding-r-0{padding-right: 0px}
.padding-r-5{padding-right: 5px}
.padding-r-10{padding-right: 10px}
.padding-r-15{padding-right: 15px}
.padding-r-20{padding-right: 20px}
.padding-r-25{padding-right: 25px}
.padding-r-30{padding-right: 30px}
.padding-r-35{padding-right: 35px}
.padding-r-40{padding-right: 40px}
.padding-r-45{padding-right: 45px}
.padding-r-50{padding-right: 50px}

.padding-t-0{padding-top: 0px}
.padding-t-5{padding-top: 5px}
.padding-t-10{padding-top: 10px}
.padding-t-15{padding-top: 15px}
.padding-t-20{padding-top: 20px}
.padding-t-25{padding-top: 25px}
.padding-t-30{padding-top: 30px}
.padding-t-35{padding-top: 35px}
.padding-t-40{padding-top: 40px}
.padding-t-45{padding-top: 45px}
.padding-t-50{padding-top: 50px}

.padding-b-0{padding-bottom: 0px}
.padding-b-5{padding-bottom: 5px}
.padding-b-10{padding-bottom: 10px}
.padding-b-15{padding-bottom: 15px}
.padding-b-20{padding-bottom: 20px}
.padding-b-25{padding-bottom: 25px}
.padding-b-30{padding-bottom: 30px}
.padding-b-35{padding-bottom: 35px}
.padding-b-40{padding-bottom: 40px}
.padding-b-45{padding-bottom: 45px}
.padding-b-50{padding-bottom: 50px}

.margin-0{margin: 0px}
.margin-5{margin: 5px}
.margin-10{margin: 10px}
.margin-15{margin: 15px}
.margin-20{margin: 20px}
.margin-25{margin: 25px}
.margin-30{margin: 30px}
.margin-35{margin: 35px}
.margin-40{margin: 40px}
.margin-45{margin: 45px}
.margin-50{margin: 50px}

.margin-l-0{margin-left: 0px}
.margin-l-5{margin-left: 5px}
.margin-l-10{margin-left: 10px}
.margin-l-15{margin-left: 15px}
.margin-l-20{margin-left: 20px}
.margin-l-25{margin-left: 25px}
.margin-l-30{margin-left: 30px}
.margin-l-35{margin-left: 35px}
.margin-l-40{margin-left: 40px}
.margin-l-45{margin-left: 45px}
.margin-l-50{margin-left: 50px}

.margin-r-0{margin-right: 0px}
.margin-r-2{margin-right: 2px}
.margin-r-5{margin-right: 5px}
.margin-r-10{margin-right: 10px}
.margin-r-15{margin-right: 15px}
.margin-r-20{margin-right: 20px}
.margin-r-25{margin-right: 25px}
.margin-r-30{margin-right: 30px}
.margin-r-35{margin-right: 35px}
.margin-r-40{margin-right: 40px}
.margin-r-45{margin-right: 45px}
.margin-r-50{margin-right: 50px}

.margin-t-0{margin-top: 0px}
.margin-t-5{margin-top: 5px}
.margin-t-10{margin-top: 10px}
.margin-t-15{margin-top: 15px}
.margin-t-20{margin-top: 20px}
.margin-t-25{margin-top: 25px}
.margin-t-30{margin-top: 30px}
.margin-t-35{margin-top: 35px}
.margin-t-40{margin-top: 40px}
.margin-t-45{margin-top: 45px}
.margin-t-50{margin-top: 50px}

.margin-b-0{margin-bottom: 0px !important;}
.margin-b-5{margin-bottom: 5px}
.margin-b-10{margin-bottom: 10px}
.margin-b-15{margin-bottom: 15px}
.margin-b-20{margin-bottom: 20px}
.margin-b-25{margin-bottom: 25px}
.margin-b-30{margin-bottom: 30px}
.margin-b-35{margin-bottom: 35px}
.margin-b-40{margin-bottom: 40px}
.margin-b-45{margin-bottom: 45px}
.margin-b-50{margin-bottom: 50px}

/** Text **/
.f-size-10{font-size: 10px;}
.f-size-11{font-size: 11px;}
.f-size-12{font-size: 12px;}
.f-size-13{font-size: 13px !important;}
.f-size-14{font-size: 14px;}
.f-size-15{font-size: 15px;}
.f-size-16{font-size: 16px;}
.f-size-17{font-size: 17px;}
.f-size-18{font-size: 18px;}
.f-size-19{font-size: 19px;}
.f-size-20{font-size: 20px;}
.f-size-21{font-size: 21px;}
.f-size-22{font-size: 22px;}
.f-size-23{font-size: 23px;}
.f-size-24{font-size: 24px;}
.f-size-25{font-size: 25px;}
.f-size-26{font-size: 26px;}
.f-size-27{font-size: 27px;}
.f-size-28{font-size: 28px;}
.f-size-29{font-size: 29px;}
.f-size-30{font-size: 30px;}
.f-size-31{font-size: 31px;}
.f-size-32{font-size: 32px;}

/** border-radius **/
.border-radius2{-webkit-border-radius:2px  !important; -moz-border-radius:2px  !important;border-radius:2px  !important;}
.border-radius4{-webkit-border-radius:4px  !important; -moz-border-radius:4px  !important;border-radius:4px  !important;}
.border-radius30{-webkit-border-radius:30px  !important; -moz-border-radius:30px  !important;border-radius:30px  !important;}
