@import "../general/mixin";

.qurBtnSec {
    position: absolute;
    top: 7px;
    right: 10px;

    .form-control {
        font-size: 12px;
        @include displayInlineBlock;
        width: 150px;
        padding: 2px 6px 3px;
    }

    button {
        margin-left: 10px;
        font-size: 12px;
        padding: 2px 6px;

        &:not(.btn-primary) {
            border: 1px #9c9c9c solid;
        }
    }
}

.aiSearchBx {
    position: relative;
    padding: 10px;
    background: #f2f2f2;
    border-color: #dddddd;

    .form-control {
        padding: 6px 34px;
    }

    .sbxicn {
        position: absolute;
        top: 15px;
        left: 15px;
        z-index: 1;
        background-color: var(--aiButtonBG);
    }

    button {
        position: absolute;
        top: 14px;
        right: 18px;
        z-index: 1;
        font-size: 15px;
        color: var(--primaryColor);
        border: none;
        @include bgTransparent;
        padding: 2px 4px;

        &:hover,
        &:focus {
            background-color: #f2f2f2;
        }
    }
}

.queryBuilder {
    font-size: 13px;

    .ruleGroup {
        background: #f2f2f2;
        border-color: #dddddd;

        .rule {
            .rule-remove {
                color: #333;
            }
        }
    }

    input,
    select,
    .rule-value {
        color: #333;

        &:focus {
            border-color: #66afe9;
            outline: 0;
            -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
                0 0 8px rgba(102, 175, 233, 0.6);
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
                0 0 8px rgba(102, 175, 233, 0.6);
        }
    }

    .rule {
        span.rule-value {
            border: none;

            label {
                padding: 0px 4px;

                input {
                    border: none;
                    margin-right: 4px;
                }
            }
        }
    }

    .optrSelect {
        width: 30%;
    }

    .optrVal {
        width: 40%;

        .notifydvTypehd {
            .rbt {
                .rbt-token {
                    margin: 1px 15px 2px 0;

                    .rbt-token-remove-button {
                        padding: 0.30rem 0.45rem;
                    }
                }
            }
        }
    }

    &.queryBuilder-branches {
        button {
            border: 1px #9c9c9c solid;
            background-color: var(--primaryColor);
            color: #fff !important;
        }

        input {
            border: 1px #9c9c9c solid;
            height: 22px;
        }
    }
}

.qBuilderCtrl {
    .form-control {
        padding: 4px 10px;
        font-size: 13px;
    }

    .dvTypehd .icn {
        top: 4px;
    }

    .ruleGroup-combinators {
        display: block;
        width: auto;
        padding: 4px 10px;
        font-size: 13px;
        line-height: 1.42857143;
        color: #333;
        @include bgWhite;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    button.ruleGroup-addRule,
    button.ruleGroup-addGroup {
        padding: 4px 6px;
        font-size: 13px;
        border-radius: 4px;
        color: #333;
        border: 1px #b7b7b7 solid;
    }

    button.rule-remove,
    button.ruleGroup-remove {
        padding: 4px 8px;
        display: inline-flex;
        font-size: 13px;
        border-radius: 4px;
        color: #333;
        border: 1px #b7b7b7 solid;
    }
}

.cke.cke_reset.cke_chrome a.cke_button_off:hover,
.cke.cke_reset.cke_chrom a.cke_button_off:focus,
.cke.cke_reset.cke_chrom a.cke_button_off:active {
    padding: 4px 6px;
    border: 0;
}

.cke.cke_reset.cke_chrome a.cke_button_expandable {
    padding: 4px 6px;
}

.zIndex10{z-index:10 !important;}